<template>
  <q-dialog v-model="showDialog">
    <q-card bordered class="q-pa-xs" style="border-radius: 9px">
      <q-card-section className="row items-center q-px-md q-pt-md q-pb-sm">
        <q-icon class="q-pr-sm" name="fa-solid fa-file-export" size="md" color="accent" text-color="white"/>
        <span
            class="q-ml-sm text-dark">Czy na pewno chcesz pobrać dane wybranych aplikantów? Liczba plików: {{ checkedAppsCount }}</span>
      </q-card-section>
      <div class="q-mx-md q-my-xs">
        <q-separator></q-separator>
      </div>
      <q-card-actions align="right">
        <q-btn flat label="Anuluj" color="dark" dense v-close-popup/>
        <q-btn label="Pobierz" outline class="bg-accent text-dark" style="border-radius: 9px!important;" dense v-close-popup @click="sendExportEmit()"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  checkedAppsCount: String,
  showDialog: Boolean
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});

const checkedAppsCount = computed({
  get: () => props.checkedAppsCount,
  set: (val) => emit('update:checkedAppCount', val),
});

const emit = defineEmits(['export:applications'])

function sendExportEmit() {
  emit('export:applications')
}
</script>