<template>
  <q-dialog v-model="showDialog">
    <q-card bordered class="q-pa-xs" style="border-radius: 9px">
      <q-card-section class="row items-center q-pb-sm">
        <span
            class="q-mr-md text-dark">{{ text }}
        </span>
        <q-icon class="q-pr-sm" :name="iconName" size="md" color="accent"
                text-color="white"/>
      </q-card-section>
      <div class="q-mx-md q-my-xs">
        <q-separator></q-separator>
      </div>
      <q-card-actions align="left">
        <q-btn dense class="bg-accent text-dark q-ml-sm" outline label="Ok" color="dark" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  text: String,
  iconName: String,
  showDialog: Boolean
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});


const emit = defineEmits(['update:showDialog'])

</script>