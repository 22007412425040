<template>
  <div align="right" class="q-ml-lg">
    <q-btn
        class="q-px-md add-button bg-transparent text-white"
        outline
        size="17px"
        rounded align="left"
        dense
        @click="toAddingOffer()"><span class="text-white">DODAJ OGŁOSZENIE</span></q-btn>
  </div>
</template>

<script setup>

import {useRouter} from 'vue-router'

const router = useRouter()

async function toAddingOffer() {
  router.push({name: 'AddJobOfferAsLoggedUser'})
}


</script>

<style lang="sass" scoped>

p
  font-size: 15px
  color: #575757

.btn-fixed-size
  width: 100%
  height: 56px

.my-card
  width: 100%
  max-width: 350px
  background-color: rgb(226,231,239)

.separator
  width: 100%
  margin: 20px

.add-button
  border-radius: 7px
  border-width: 1px
  border-color: red
</style>