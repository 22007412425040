// services/OrganizationApplicationService.js

import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";

class OrganizationApplicationService {
    constructor(url) {
        this.url = url;
    }

    async getApplications(jobOfferId, status) {
        const params = {
            status: status,
            jobOfferId: jobOfferId
        };
        const url = `${this.url}/organizations/${currentUser.organizationId}/applications`;

        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
            },
            body: JSON.stringify(params)
        };

        // Removed the redundant fetch call here to avoid duplication

        const response = await FetchWrapper.fetchWithAuth(url, options);
        return await response.json();
    }

    async patchApplication(applicationId, payload) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/applications/${applicationId}`;
        const options = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
            },
            body: JSON.stringify(payload),
        };

        return await FetchWrapper.fetchWithAuth(url, options);
    }

    async patchApplicationStatus(applicationId, status) {
        const payload = {status};
        await this.patchApplication(applicationId, payload);
    }

    async patchApplicationRating(applicationId, rating) {
        const payload = {result: rating};
        await this.patchApplication(applicationId, payload);
    }

    async patchApplicationNotes(applicationId, notes) {
        const payload = {notes: notes};
        await this.patchApplication(applicationId, payload);
    }

    async downloadCV(applicationId) {
        const response = await this.getCV(applicationId);
        if (response.ok) {
            const json = await response.json();
            const byteCharacters = atob(json.file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = json.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            return 200
        } else {
            return response.status
        }

    }

    async getCV(applicationId) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/applications/${applicationId}/resume`;
        const options = {
            credentials: "include"
        };
        const response = await FetchWrapper.fetchWithAuth(url, options);
        return response;
    }

    async deleteApplication(applicationId) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/applications/${applicationId}`;
        const options = {
            credentials: 'include',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
            },
        };
        await FetchWrapper.fetchWithAuth(url, options);
    }
}

export default new OrganizationApplicationService(process.env.VUE_APP_BACKEND_URL);
