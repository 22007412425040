<template>
  <div class="q-pr-lg q-mt-none">
    <q-badge outline color="dark" :label="props.name"/>
    <q-rating
        v-model="level"
        size="1em"
        no-reset
        color="dark"
        icon-selected="fa-solid fa-square"
        icon="fa-regular fa-square"
        class="q-pl-sm"/>
    <div class="q-pt-xs">
      <p class="q-ma-none">
        {{ describeRatingValue() }}
      </p>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, computed} from 'vue'

const props = defineProps({
  name: String,
  modelValue: Object,
  level: String
})

const level = computed({
  get: () => props.level,
  set: (val) => emit('update:level', val),
});
const emit = defineEmits(['update:modelValue'])

const basicLevel = 'Nice to have'
const intermediateLevel = 'Junior'
const advancedLevel = 'Regular'
const expertLevel = 'Senior'
const masterLevel = 'Architekt'

function describeRatingValue() {
  if (level.value === 1) {
    return basicLevel
  } else if (level.value === 2) {
    return intermediateLevel
  } else if (level.value === 3) {
    return advancedLevel
  } else if (level.value === 4) {
    return expertLevel
  } else if (level.value === 5) {
    return masterLevel
  } else {
    return 'Wybierz poziom umiejętności'
  }
}

</script>
<style lang="sass" scoped>
@import "/src/assets/css/main.css"
.q-input
  font-size: 12px

.q-field-target
  font-size: 12px
  color: #581c9b

.q-select
  font-size: 12px

.q-editor
  font-size: 11px

.my-stepper
  width: 65%
</style>