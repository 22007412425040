<template>
  <div class="q-mt-lg">
    <adding-offer-section-description
        :is-warning="isWarning"
        title="Języki "
        description="- Dodaj wymagania dotyczące znajomości języków. Możesz dodać maksymalnie pięć pozycji."/>
    <q-form ref="formRef" greedy>
      <div class="row full-width">
        <div class="q-pt-md full-width q-mt-sm">
          <q-select
              ref="langSelect"
              color="dark"
              use-chips
              multiple
              v-model="stringModelLangValues"
              label="Języki (opcjonalne)"
              dense
              clear-icon="cancel"
              :options="OfferDataTranslator.translateLanguagesToPolish()"
              outlined
              :rules="[
              (val) => val.length <= 5 || 'Możesz dodać maksymalnie pięć pozycji.',
              () => validateLanguages()
            ]"
              @add="addLanguage"
              @remove="removeLanguage"
          ></q-select>
        </div>
        <div class="q-mx-xs row q-mt-sm" v-if="offer.languages.length !== 0">
          <Language
              v-for="lang in offer.languages"
              :key="lang.language"
              :language="lang.language"
              v-model:proficiency="lang.proficiency"
              :form-template-data="formTemplateData"
              @update:proficiency="onLanguageUpdate"
              v-model="offer"
          ></Language>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, nextTick, defineExpose } from 'vue';
import Language from '@/components/common/add-offer/job-offer/language/Language';
import OfferDataTranslator from '@/services/organization/offer/OfferDataTranslator';
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription.vue";

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
});

const emit = defineEmits(['update:modelValue']);

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
});

const stringModelLangValues = ref([]);

onMounted(() => {
  stringModelLangValues.value = initModelLangValues();
});

const isWarning = ref(false);
function initModelLangValues() {
  let modelValues = [];
  offer.value.languages.forEach((language) => {
    modelValues.push(language.language);
  });
  return modelValues;
}

function addLanguage(details) {
  offer.value.languages.push({language: details.value, proficiency: null});
}

function removeLanguage(key) {
  let index = offer.value.languages.findIndex((item) => item.language === key.value);
  if (index > -1) {
    offer.value.languages.splice(index, 1);
  }
}

const langSelect = ref(null);

function onLanguageUpdate() {
  nextTick(() => {
    if (langSelect.value) {
      langSelect.value.validate();
    }
  });
}

defineExpose({
  validate
});

const formRef = ref(null);

async function validate() {
  return await formRef.value.validate();
}

function validateLanguages() {
  for (const lang of offer.value.languages) {
    if (lang.proficiency === null || lang.proficiency === undefined) {
      isWarning.value = true;
      return 'Podaj poziom znajomości języka dla: ' + lang.language;
    }
  }
  isWarning.value = false;
  return true;
}
</script>
