import script from "./OrganizationAccessDenied.vue?vue&type=script&setup=true&lang=js"
export * from "./OrganizationAccessDenied.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QDrawer,QImg,QList,QBtn,QPageContainer,QPage,QDialog,QCard,QCardSection,QIcon});
