<template>
  <q-dialog v-model="internalDialogShow">
    <q-card class="q-pa-sm" bordered style="max-width: 100%; width: 600px">
      <div class="q-ma-sm">
        <p style="font-size: 20px !important;" class="text-bold q-mb-none q-pl-sm accent-3">Utwórz szablon</p>
      </div>
      <q-form ref="formRef" greedy @submit.prevent="submitTemplate">
        <div class="q-mx-md q-mb-lg q-mt-sm">
          <q-separator></q-separator>
        </div>
        <q-card-section class="q-pt-none q-pb-sm">
          <q-input
              color="dark"
              class="text-dark"
              v-model="internalName"
              label="Nazwa*"
              :rules="[val => val.length !== 0 || 'Nazwa jest wymagana']"
              dense
              outlined
          />
        </q-card-section>
        <q-card-section class="row q-gutter-md q-pt-none q-pb-none">
          <q-input
              color="dark"
              class="col"
              v-model="internalSubject"
              label="Tytuł maila*"
              dense
              :rules="[val => val.length !== 0 || 'Tytuł jest wymagany']"
              outlined
          />
        </q-card-section>
        <q-card-section class="q-py-none">
          <q-field
              no-error-icon
              v-model="internalMessage"
              class="col row"
              ref="fieldRef"
              label-slot
              borderless
              dense
              reactive-rules
              :rules="[val => val.length < 4000 || 'Zbyt długa wiadomość - max 4000 znaków.', val => val.length > 0 || 'Wiadomość jest wymagana.']"
          >
            <q-editor
                class="full-width text-black"
                v-model="internalMessage"
                min-height="13rem"
                max-height="13rem"
                toolbar-toggle-color="accent"
                style="border-radius: 9px;overflow-x: auto;word-break: break-word;"
                toolbar-color="dark"
                :toolbar="[]"
                :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"
            />
          </q-field>
        </q-card-section>
        <q-card-actions class="row justify-between q-px-md q-pb-sm q-pt-none">
          <q-btn
              style="border-radius: 7px !important;"
              outline
              dense
              label="Anuluj"
              color="dark"
              @click="internalDialogShow = false"
          />
          <q-btn
              style="border-radius: 7px !important;"
              outline
              :label="submitButtonText"
              type="submit"
              dense
              class="bg-accent text-dark"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineExpose, defineProps, ref} from 'vue';

// Define props for each value
const props = defineProps({
  dialogShow: Boolean,
  name: String,
  subject: String,
  message: String,
  submitButtonText: String
});
const emit = defineEmits(['submit']);


const internalDialogShow = computed({
  get: () => props.dialogShow,
  set: (val) => emit('update:dialogShow', val),
});

const internalName = computed({
  get: () => props.name,
  set: (val) => emit('update:name', val),
});

const internalSubject = computed({
  get: () => props.subject,
  set: (val) => emit('update:subject', val),
});

const internalMessage = computed({
  get: () => props.message,
  set: (val) => emit('update:message', val),
});

const formRef = ref(null)
defineExpose({
  validate: () => formRef.value.validate()
});

const submitTemplate = () => {
  emit('submit', {
    dialogShow: internalDialogShow.value,
    name: internalName.value,
    subject: internalSubject.value,
    message: internalMessage.value,
  });
  internalDialogShow.value = false;
};
</script>
