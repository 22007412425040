<template>
  <q-card bordered style="border-radius: 22px !important;">
    <div class="q-pt-none q-pl-lg q-px-sm">
      <p class="text-dark text-bold q-pt-none q-ma-none q-mb-sm" style="font-size: 12px">
        Podobne oferty
      </p>
    </div>
    <div class="q-px-lg q-pt-none q-pb-md q-mb-md">
      <q-separator class="q-px-sm"></q-separator>
    </div>
    <q-card-section class="q-pt-sm">
      <div class="q-gutter-md q-pb-sm q-px-xs">
        <div v-for="jobOffer in offers" :key="jobOffer.id" class="q-pt-none">
          <similar-offer-overview
              style="border-color: #afafaf"
              :job-offer="jobOffer"
              :is-fav="false"
              :is-selected="true"
              class="bg-white q-mb-none"
          ></similar-offer-overview>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from 'vue';
import JobOfferService from "@/services/JobOfferService";
import SimilarOfferOverview from "@/components/common/job-offers/SimilarOfferOverview";

const offers = ref([]);
const props = defineProps({
  jobOfferId: String,
});

onMounted(() => {
  fetchSimilarOffers();
});

watch(() => props.jobOfferId, () => {
  fetchSimilarOffers();
});

function fetchSimilarOffers() {
  JobOfferService.getSimilarJobOffers(props.jobOfferId).then(data => {
    offers.value = data;
  });
}
</script>
