<template>
  <div class="full-width">
    <adding-offer-section-description title="Umowy" description=" - Dodaj maksymalnie trzy umowy, określając rodzaj każdej z nich. Jeśli chcesz podać stawkę, zaznacz pole wyboru po lewej stronie i wprowadź kwotę wynagrodzenia."/>
    <q-form greedy v-if="contracts.length > 0" @submit="validateChildComponents()"
    >
      <contract-info-additional
          ref="childComponentRef"
          :form-template-data="formTemplateData"
          v-for="contract in contracts"
          :key="contract.id"
          :v-for-key="contract.id"
          v-model:salary="contract.salary"
          v-model:type="contract.type"
          @addContract="addContract"
          @removeContractInfo="removeContractById">
      </contract-info-additional>
    </q-form>
    <q-card flat bordered class="q-mx-none q-mt-lg q-mb-md" style="border-radius: 12px !important;">
      <q-card-section class="row justify-between">
        <my-text class="text-white q-mt-xs">
          <span class="text-dark q-pa-xs">
            <span class="q-pa-xs" v-if="contracts.length <= 0">Brak zdefiniowanych umów</span>
            <span class="q-pa-xs" v-else>Liczba zdefiniowanych umów: {{contracts.length}}</span>
            - maksymalna liczba 3.
        </span>
        </my-text>
        <q-btn class="q-px-md" no-caps label="Dodaj umowę" color="dark" dense size="5px" style="font-size: 10px;border-radius: 7px!important;" @click="addContract()">
          <q-icon
              class="q-ml-md"
              name="sym_o_add"
              size="1rem"
          />
        </q-btn>
      </q-card-section>
    </q-card>
<!--    <div class="q-my-md">-->
<!--      <q-separator></q-separator>-->
<!--    </div>-->
  </div>
</template>

<script setup>

import ContractInfoAdditional from "@/components/common/add-offer/job-offer/contracts/ContractInfoAdditional";

import {computed, defineEmits, defineExpose, defineProps, ref} from 'vue'
import {useQuasar} from 'quasar'
import NotificationService from "@/services/notification/NotificationService";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})


const contracts = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const contractId = ref(1);

const $q = useQuasar();

function validateContractsNumber() {
  if (contracts.value.length > 2) {
    NotificationService.showNotification($q, 'negative', 'Możesz dodać maksymalnie 3 umowy.');
    return false;
  }
  return true;
}

function addContract() {
  if (validateContractsNumber()) {
    contracts.value.push({id: contractId.value, type: null, salary: {from: null, to: null, currency: null}})
    contractId.value = contractId.value + 1
  }
}

function removeContractById(id) {
  for (let i = 0; i < contracts.value.length; i++) {
    if (contracts.value[i].id === id) {
      contracts.value.splice(i, 1);
      return true; // return true to indicate successful removal
    }
  }
  return false; // return false to indicate that contract with given id was not found
}

const emit = defineEmits(['update:modelValue'])
const childComponentRef = ref(null);
async function validateChildComponents() {
  try {
    const results = await Promise.all(
        childComponentRef.value.map(child => child.validate())
    );
    // Return true only if all validations pass
    return results.every(result => result);
  } catch (error) {
    console.error('Error during validation:', error);
    return false;
  }
}



defineExpose({
  validateChildComponents
})
</script>