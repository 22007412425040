<template>
  <div class="q-scrollarea" style="overflow-y: auto">
    <q-card bordered class="full-width" style="border-radius: 9px">
      <q-item class="q-pl-sm">
        <q-item-section avatar class="q-pr-md">
          <q-skeleton square style="border-radius: 7px;height: 70px;width:70px" type="QAvatar"/>
        </q-item-section>

        <q-item-section>
          <q-item-label>
            <q-skeleton type="text"/>
          </q-item-label>
          <q-item-label caption>
            <q-skeleton type="text"/>
          </q-item-label>
        </q-item-section>
      </q-item>
      <div class="row justify-between">
        <q-card-actions align="left" class="q-gutter-md">
          <q-skeleton type="QBtn" width="65px" height="35px"/>
          <q-skeleton type="QBtn" width="65px" height="35px"/>
          <q-skeleton type="QBtn" width="65px" height="35px"/>
          <q-skeleton type="QBtn" width="65px" height="35px"/>
        </q-card-actions>
        <q-card-actions align="left" class="q-gutter-md">
          <q-skeleton type="QBtn" width="35px" height="35px"/>
        </q-card-actions>
      </div>
      <q-card-actions class="q-gutter-md row justify-between">
        <q-skeleton type="QBtn" width="65px" height="25px"/>
        <q-skeleton type="QBtn" width="65px" height="25px"/>
        <q-skeleton type="QBtn" width="65px" height="25px"/>
      </q-card-actions>
    </q-card>

    <q-card bordered style="border-radius: 9px" class="q-mt-md full-width">
      <q-item>
        <q-item-section avatar>
          <q-skeleton type="QAvatar" style="border-radius: 7px" square height="25px" width="90px"/>
        </q-item-section>
      </q-item>
      <div class="q-mb-sm">
        <q-separator class="q-mx-md"></q-separator>
      </div>
      <q-card-section class="q-pt-sm col row" style="height: 130px">
        <q-skeleton class="col row"/>
      </q-card-section>
    </q-card>


    <q-card bordered style="border-radius: 9px" class="q-mt-md full-width">
      <q-item>
        <q-item-section avatar>
          <q-skeleton type="QAvatar" style="border-radius: 7px" square height="25px" width="90px"/>
        </q-item-section>
      </q-item>
      <div class="q-mb-sm">
        <q-separator class="q-mx-md"></q-separator>
      </div>
      <q-card-section class="q-pt-sm col row" style="height: 70px">
        <q-skeleton class="col row"/>
      </q-card-section>
    </q-card>


    <q-card bordered style="border-radius: 9px" class="full-width q-mt-md q-mb-sm">
      <q-item>
        <q-item-section avatar>
          <q-skeleton type="QAvatar" style="border-radius: 7px" square height="25px" width="90px"/>
        </q-item-section>
      </q-item>
      <div class="q-mb-sm">
        <q-separator class="q-mx-md"></q-separator>
      </div>
      <q-card-section class="q-pt-sm col row" style="height: 250px">
        <q-skeleton class="col row"/>
      </q-card-section>
    </q-card>

    <q-card bordered style="border-radius: 9px" class="full-width q-mt-md q-mb-sm">
      <q-item>
        <q-item-section avatar>
          <q-skeleton type="QAvatar" style="border-radius: 7px" square height="25px" width="90px"/>
        </q-item-section>
      </q-item>
      <div class="q-mb-sm">
        <q-separator class="q-mx-md"></q-separator>
      </div>
      <q-card-section class="q-pt-sm col row" style="height: 250px">
        <q-skeleton class="col row"/>
      </q-card-section>
    </q-card>

    <q-card bordered style="border-radius: 9px" class="full-width q-mt-md q-mb-sm">
      <q-item>
        <q-item-section avatar>
          <q-skeleton type="QAvatar" style="border-radius: 7px" square height="25px" width="90px"/>
        </q-item-section>
      </q-item>
      <div class="q-mb-sm">
        <q-separator class="q-mx-md"></q-separator>
      </div>
      <q-card-section class="q-pt-sm col row" style="height: 250px">
        <q-skeleton class="col row"/>
      </q-card-section>
    </q-card>
  </div>
</template>