<script setup>
import {watch, defineEmits, defineProps} from 'vue';
import {useEventSource} from '@vueuse/core';
import {useQuasar} from "quasar";

const $q = useQuasar();

const emit = defineEmits(['offerPaymentConfirmationReceived']);

const props = defineProps({
  sessionId: {
    type: String,
    required: true
  }
});

const eventSourceUrl = `${process.env.VUE_APP_BACKEND_URL}` + '/api/v1/payments/' + props.sessionId + '/status';

const events = ['status'];

const options = {
  withCredentials: true,
  autoReconnect: {
    retries: 3,
    delay: 1000,
    onFailed: () => console.info('Failed to reconnect'),
  },
  immediate: true,
};

const {data, status, event, error} = useEventSource(eventSourceUrl, events, options);

watch(data, (newData) => {
  if (newData !== null && newData === 'PAID') {
    $q.notify({
      type: 'positive',
      position: 'bottom',
      message: 'Oferta opłacona',
      icon: 'fa-solid fa-briefcase'
    })
    console.info('Event received:', newData);
    emit('offerPaymentConfirmationReceived', newData); // Emit custom event when new data arrives
  }
});

watch(event, (newEvent) => {
  if (newEvent !== null) {
    console.info('Event type:', newEvent);
  }
});

watch(status, (newStatus) => {
  console.log('Connection status:', newStatus);
});

watch(error, (newError) => {
  if (newError !== null) {
    console.error('EventSource error:', newError);
  }
});
</script>
