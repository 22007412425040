// services/ApplicantObservedOffers.js

import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";

class ApplicantObservedOffers {
    constructor(url) {
        this.url = url;
    }

    async getObservedOffers(applicantId) {
        try {
            const requestOptions = {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const url = `${this.url}/applicants/${applicantId}/favorite-offers`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error("Error during request:", error);
            throw error;
        }
    }

    async deleteObservedOffers(applicantId, offerId) {
        try {
            const requestOptions = {
                method: "DELETE",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
            };
            const url = `${this.url}/applicants/${applicantId}/favorite-offers/${offerId}`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error("Error during request:", error);
            throw error;
        }
    }

    async addObservedOffers(applicantId, offerId) {
        try {
            const requestOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
            };
            const url = `${this.url}/applicants/${applicantId}/favorite-offers?jobOfferId=${offerId}`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error("Error during request:", error);
            throw error;
        }
    }
}

export default new ApplicantObservedOffers(process.env.VUE_APP_BACKEND_URL);
