import {currentUser} from "@/global-variables/currentUser";
import FetchWrapper from "@/services/common/FetchWrapper";
import CookieService from "@/services/common/CookieService";

class OrganizationEmailsService {
    constructor(url) {
        this.url = url;
    }

    async getAllTemplates() {
        const url = `${this.url}/organizations/${currentUser.organizationId}/email-templates`;

        try {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return await response.json();
        } catch (error) {
            console.error("Error during organization user GET method:", error);
            throw error;
        }
    }

    async addTemplate(name, subject, message) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/email-templates`;

        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                credentials: "include",
                body: JSON.stringify({name: name, emailSubject: subject, emailMessage: message}),
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return await response.json();
        } catch (error) {
            console.error("Error during organization user GET method:", error);
            throw error;
        }
    }

    async editTemplate(name, subject, message, templateId) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/email-templates/`+templateId;

        try {
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                credentials: "include",
                body: JSON.stringify({name: name, emailSubject: subject, emailMessage: message}),
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return await response.json();
        } catch (error) {
            console.error("Error during organization user GET method:", error);
            throw error;
        }
    }

    async deleteTemplate(id) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/email-templates/` + id;

        try {
            const requestOptions = {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                credentials: "include",
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return await response.json();
        } catch (error) {
            console.error("Error during organization user GET method:", error);
            throw error;
        }
    }

}

export default new OrganizationEmailsService(process.env.VUE_APP_BACKEND_URL);
