import script from "./ApplicantPasswordReset.vue?vue&type=script&setup=true&lang=js"
export * from "./ApplicantPasswordReset.vue?vue&type=script&setup=true&lang=js"

import "./ApplicantPasswordReset.vue?vue&type=style&index=0&id=1b35052c&lang=css"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QIcon,QBtn,QSpinnerHourglass,QForm,QInput,QCardActions});
