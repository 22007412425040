class PasswordValidator {
    validatePassword(val) {
        const isValid =
            val.length >= 8 &&
            val.length <= 40 &&
            /[A-Z]/.test(val) &&
            /[a-z]/.test(val) &&
            /[0-9]/.test(val);

        return isValid || 'Hasło musi składać się z co najmniej 8 znaków, zawierać wielką literę oraz cyfrę';
    }
}

module.exports = new PasswordValidator();
