<template>
  <q-layout>
    <q-page-container>
      <q-page>
        <mobile-menu v-if="isMobile()"></mobile-menu>
        <menu-bar :is-menu-visible="true" v-else></menu-bar>
        <div class="login-center" v-if="successfulRegistration">
          <q-card style="width: 350px; border-radius: 9px">
            <q-card-section class="row justify-center q-pb-none">
              <div class="q-pt-none q-pb-sm">
                <q-spinner-hourglass
                    color="dark"
                    size="5em"
                    :thickness="1"/>
              </div>
              <div class="text-center text-h7 text-dark q-pb-none">Potwierdź rejestrację i ustaw hasło za pomocą
                maila
                powitającego
              </div>
            </q-card-section>
            <q-card-section class="text-center">
              <q-btn padding="3px xl"
                     outline
                     class="bg-accent text-dark"
                     type="submit"
                     label="Ok"
                     @click="toApplicantLoginPage()"></q-btn>
            </q-card-section>
          </q-card>
        </div>
        <div v-else class="login-center">
          <q-card class="q-px-sm" bordered style="width: 350px; border-radius: 9px;">
            <q-card-section class="row justify-center q-pb-sm">
              <p class="text-center text-black q-mb-none q-ma-none"
                 style="font-weight: 500; font-size: 18px !important;">
                Znajdź pracę z Byte Job
              </p>
            </q-card-section>
            <div class="text-center q-pt-sm q-pb-none"> <!-- Add the text-center class to this div -->
              <q-btn size="12px" @click="router.push({ name: 'ApplicantLoginPage' });"
                     icon="login"
                     flat
                     class="accent-3"
                     label="Masz konto? - Zaloguj się"
                     no-caps/>
            </div>
            <q-form @submit.prevent="register">
              <q-card-section class="q-pb-xs">
                <q-input
                    dense
                    color="dark"
                    lazy-rules
                    outlined
                    v-model="email"
                    label="E-mail"
                    id="email"
                    :rules="[val => EmailValidator.isEmailValid(val) || 'Niepoprawny format e-mail',val => !isBlank(val) || 'E-mail jest wymagany']"
                ></q-input>
              </q-card-section>
              <q-card-section class="q-pt-none q-pb-xs">
                <div class="q-mt-none text-center full-width">
                  <div>
                    <p class="q-ma-none q-mb-none text-grey-8 q-pt-xs"
                       style="margin-top: 0; font-size: 11px!important;">
                      Rejestrując się potwierdzasz, że przeczytałeś i akceptujesz
                    </p>
                  </div>
                  <div class="items-center full-width">
                    <div class="row full-width text-center justify-center">
                      <div class="q-pt-none">
                        <q-btn no-ripple @click="openApplicantRules()"
                               flat class="accent-3 q-ma-none q-px-xs q-mx-xs q-pt-none"
                               style="margin-top: 2px" dense no-caps size="11px">
                          regulamin
                        </q-btn>
                      </div>
                      <p class="q-ma-none q-mb-none text-grey-8" style="margin-top: 3px; font-size: 11px!important;">
                        Byte Job oraz
                      </p>
                      <div class="q-pt-none">
                        <q-btn no-ripple
                               @click="openPrivacyPolicy()"
                               style="margin-top: 2px"
                               flat class="accent-3 q-ma-none q-px-xs q-ml-xs q-pt-none" dense no-caps size="11px">
                          politykę prywatności
                        </q-btn>
                      </div>
                      <p style="margin-top: 0">.</p>
                    </div>
                  </div>
                </div>
              </q-card-section>
              <q-card-actions class="text-center q-pl-md q-pr-md q-pb-lg" align="center">
                <q-btn style="border-radius: 9px !important;"
                       outline
                       class="bg-accent text-dark"
                       rounded
                       padding="3px xl"
                       type="submit"
                       label="Dołącz"
                      ></q-btn>
              </q-card-actions>
            </q-form>
          </q-card>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import MenuBar from "@/components/common/MenuBar";
import {ref} from 'vue';
import {useRouter} from "vue-router/dist/vue-router";
import ApplicantRegistrationService from "@/services/applicant/ApplicantRegistrationService";
import {useQuasar} from 'quasar'
import MobileMenu from "@/components/mobile/MobileMenu";
import EmailValidator from "@/services/common/EmailValidator";
const email = ref('')

const $q = useQuasar();
const successfulRegistration = ref(false)
const router = useRouter()

const APPLICANTS_ERROR_CODES = require('/src/services/ErrorCodes.js');

async function register() {
  const response = await ApplicantRegistrationService.registerApplicant(email.value);
  console.info(response.status)
  if (response.status === 201) {
    successfulRegistration.value = true;
  } else if (response.status === 400) {
    const json = await response.json()
    if (json.errorCode === 'EMAIL_ALREADY_TAKEN') {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail jest już zarejestrowane w systemie.'
      })
    } else if (json.message.includes('email=must be a well-formed email address')) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny format adresu e-mail'
      })
    } else if (json.errorCode.includes(APPLICANTS_ERROR_CODES.EMAIL_ALREADY_TAKEN)) {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail już istnieje, zaloguj się lub zresetuj hasło'
      })
    }
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas logowania - skontaktuj się z supportem.'
    })
  }
}

function toApplicantLoginPage() {
  router.push({name: 'ApplicantLoginPage'})
}

function isBlank(str) {
  return str === null || /^\s*$/.test(str);
}

function isMobile() {
  return $q.platform.is.mobile
}

function openPrivacyPolicy() {
  window.open('https://stbytejobprodwe.blob.core.windows.net/legal/Polityka-Prywatnosci.pdf', '_blank')
}

function openApplicantRules() {
  window.open('https://stbytejobprodwe.blob.core.windows.net/legal/Regulamin-dla-Kandydatow.pdf', '_blank')
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>