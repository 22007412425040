<template>
  <div class="row q-mb-lg q-mt-none q-mx-lg column col">
    <div class="row justify-between q-my-md">
      <div class="row q-gutter-md items-center">
        <div>
          <q-btn v-if="isActive" size="sm" outline
                 class="q-px-md bg-accent text-dark q-py-sm" style="border-radius: 7px" dense label="Aktywne"
                 @click="getActiveJobOffers()"></q-btn>
          <q-btn v-else color="grey-7" size="sm" class="q-px-md q-py-sm"
                 style="border-radius: 7px" flat dense label="Aktywne" @click="getActiveJobOffers()"></q-btn>
        </div>
        <div>
          <q-btn v-if="isUnpaid" size="sm" class="q-px-md bg-accent text-dark q-py-sm" outline
                 dense style="border-radius: 7px" label="Nieopłacone" @click="getUnpaidJobOffers()"></q-btn>
          <q-btn v-else color="grey-7" size="sm" class="q-px-md q-py-sm" flat dense
                 style="border-radius: 7px" label="Nieopłacone" @click="getUnpaidJobOffers()"></q-btn>
        </div>
        <div>
          <q-btn v-if="isInactive" size="sm"
                 class="q-pl-md q-pr-md bg-accent text-dark q-py-sm" outline dense style="border-radius: 7px"
                 label="Nieaktywne"
                 @click="getInactiveOffers()"></q-btn>
          <q-btn v-else color="grey-7" size="sm" class="q-pl-md q-pr-md q-py-sm" flat dense
                 style="border-radius: 7px" label="Nieaktywne" @click="getInactiveOffers()"></q-btn>
        </div>

      </div>
      <div class="items-center">
        <q-select color="dark"
                  class="bg-white"
                  dense
                  outlined
                  v-model="sorting"
                  :options="sortingOptions"
                  @update:model-value="refreshOffers()"/>
      </div>
    </div>
    <div class="row col">
      <div v-if="isLoadingOffers" class="q-scrollarea q-pr-md" style="overflow-y: auto; width: 45%">
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
        <job-offer-overview-skeleton :show-avatar="false"></job-offer-overview-skeleton>
      </div>
      <div v-else-if="offers.length > 0"
           class="q-scrollarea q-pr-md" style="overflow-y: auto; width: 45%">

        <employer-offer-overview
            v-for="jobOffer in offers" :job-offer="jobOffer" :key="jobOffer.id"
            :scroll-target="scrollTargetRef"
            @show:dialog="showPublicationConfirmationDialog()"
            :is-active="props.isActive"
            :is-planned="props.isPlanned"></employer-offer-overview>
      </div>
      <div v-else
           class="q-pr-md" style="overflow-y: auto; width: 45%">
        <q-card bordered style="border-radius: 9px">
          <q-card-section class="q-pa-sm q-ml-xs text-center q-mb-xs">
            <p class="q-my-sm text-bold">Brak aktywnych ogłoszeń</p>
            <p class="q-mt-none q-pt-none">Panel wyświetla dodane ogłoszenia wraz ze statystykami aktywności</p>
            <q-btn size="md" style="border-radius: 12px !important;" class="q-px-md q-mb-sm text-dark bg-accent"
                   outline
                   label="Dodaj ogłoszenie za darmo" @click="router.push({ name: 'AddJobOfferAsLoggedUser' });
"></q-btn>
          </q-card-section>

        </q-card>
      </div>
      <div class="q-pl-xs column col q-pr-none row" style="width: auto; ">
        <div class="col row">
          <q-card bordered class="q-mb-md col row q-px-md" style="border-radius: 22px !important;">
            <apexchart
                style="background-color: white;"
                type="bar"
                height="98%"
                class="full-width"
                width="98%"
                :options="applicationsChartOptions"
                :series="applicationsGroupedChartData"
            />
          </q-card>
        </div>

        <div class="col row">
          <q-card bordered class="q-mb-md col row q-px-md" style="border-radius: 22px !important;">
            <apexchart
                style="background-color: white;"
                type="area"
                height="98%"
                width="98%"
                class="full-width"
                :options="viewsChartOptions"
                :series="viewsSeries"
            />
          </q-card>
        </div>

        <div class="col row">
          <q-card bordered class=" col row q-px-md" style="border-radius: 22px !important;">
            <apexchart
                style="background-color: white;"
                type="area"
                class="full-width"
                width="100%"
                height="98%"
                :options="activeOffersChartOptions"
                :series="activeOffersSeries"
            />
          </q-card>
        </div>

      </div>
      <publication-extension-confirmation v-model="showDeleteDialog"></publication-extension-confirmation>
    </div>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from 'vue';
import EmployerOfferOverview from "@/components/organization/job-offers/EmployerOfferOverview";
import jobOfferService from '@/services/JobOfferService.js';
import viewsChartService from "@/services/ViewsChartService";
import applicationsChartService from "@/services/ApplicationsChartService";
import PublicationExtensionConfirmation from "@/components/organization/job-offers/PublicationExtensionConfirmation";
import JobOfferOverviewSkeleton from "@/components/common/job-offers/skeletons/JobOfferOverviewSkeleton";
import {useRouter} from "vue-router/dist/vue-router";

const isActive = ref(true)
const isUnpaid = ref(false)
const isInactive = ref(false)
const showDeleteDialog = ref(false)
const isLoadingOffers = ref(true)

const sorting = ref('Najnowsze');
const sortingOptions = ref([
  'Najnowsze', 'Najstarsze',
])

function showPublicationConfirmationDialog() {
  showDeleteDialog.value = true
}

const props = defineProps({
  isActive: Boolean,
  isPlanned: Boolean
})
const router = useRouter()
const scrollTargetRef = ref(null)
const offers = ref([])
const applicationsGroupedChartData = ref([])
//TODO not use page index from vue, add own variobale, and based on response couynt until last page, than do not perform request
onMounted(() => {
  getActiveJobOffers();
});

async function refreshOffers(){
  isLoadingOffers.value = true
  offers.value = [];
  let sortingValue = toSortingQueryParam(sorting.value);
  await getOffers(isActive.value, !isUnpaid.value, sortingValue);
  initOffers();
}

function toSortingQueryParam(sorting){
  if(sorting === 'Najnowsze'){
    return 'NEWEST'
  }else{
    return 'OLDEST'
  }
}
async function getActiveJobOffers() {
  isActive.value = true;
  isUnpaid.value = false;
  isInactive.value = false;
  isLoadingOffers.value = true
  offers.value = [];
  let sortingValue = toSortingQueryParam(sorting.value);
  await getOffers(true, true, sortingValue);
  initOffers();
}

async function getOffers(active, paid, sort = "NEWEST") {
  let page = 0;
  const size = 50;
  let totalPages = 1;
  let queryParams = new URLSearchParams();
  queryParams.append("active", active);
  queryParams.append("paid", paid);
  queryParams.append("page", page);
  queryParams.append("size", size);
  queryParams.append("sort", sort);
  const firstPageData = await jobOfferService.getJobOffersForOrganization(queryParams);
  offers.value = firstPageData.content;
  totalPages = firstPageData.page.totalPages;
  const promises = [];
  for (page = 1; page < totalPages; page++) {
    let queryParams = new URLSearchParams();
    queryParams.append("active", active);
    queryParams.append("page", page);
    queryParams.append("size", size);
    promises.push(jobOfferService.getJobOffersForOrganization(queryParams));
  }
  const results = await Promise.all(promises);
  results.forEach(result => {
    offers.value = offers.value.concat(result.content);
  });
}

function initOffers() {
  isLoadingOffers.value = false
  viewsData.value = viewsChartService.createArrayOfSummaryViews(offers.value);
  activeOffersData.value = viewsChartService.findNumbersOfActiveOffersInSpecificDay(offers.value);
  applicationsGroupedChartData.value = [{
    name: 'Przesłane aplikacje',
    data: applicationsChartService.addApplicationsNumberToGroupedChartData(offers.value)
  }];
}

async function getUnpaidJobOffers() {
  isActive.value = false
  isUnpaid.value = true
  isInactive.value = false
  isLoadingOffers.value = true;
  offers.value = []
  let sortingValue = toSortingQueryParam(sorting.value);
  await getOffers(false, false, sortingValue);
  initOffers();
}

async function getInactiveOffers() {
  isActive.value = false
  isUnpaid.value = false
  isInactive.value = true
  isLoadingOffers.value = true;
  offers.value = []
  let sortingValue = toSortingQueryParam(sorting.value);
  await getOffers(false, true, sortingValue);
  initOffers();
}

const viewsChartOptions = ref({
  tooltip: {
    enabled: true, // Enable tooltips
  },
  legend: {
    offsetY: -10, // Adjust this value to decrease the space between legend and chart
  },
  dataLabels: {
    enabled: false,
    style: {
      colors: ['#6d4faf']
    },
  },
  stroke: {
    width: [2],
    curve: 'smooth', // Ensure smooth lines
    colors: ['#63DBD2FF'] // Line color
  },
  chart: {
    toolbar: {
      show: false // Disable toolbar
    },
    id: 'forecast-chart'
  },
  grid: {
    borderColor: '#eaeaea', // Color of grid lines
    yaxis: {
      lines: {
        show: false // Show Y-axis grid lines
      }
    }
  },
  xaxis: {
    labels: {
      style: {
        colors: '#484949', // X-axis label color
        fontFamily: 'Kanit', // Font family for X-axis
        fontSize: '11px', // Font size for X-axis labels
      },
    },
    categories: viewsChartService.getLast30DaysDateAsArray(), // X-axis categories
  },
  fill: {
    type: 'gradient', // Use gradient for area fill
    colors: ['#B1EDE9'], // Purple starting color
    gradient: {
      shade: 'dark', // Dark shading for the gradient
      shadeIntensity: 0, // Set shadeIntensity to 0 to avoid the blue tint
      type: 'vertical', // Vertical gradient direction
      opacityFrom: 0.8, // Start opacity (higher for more visible area)
      opacityTo: 0.3, // End opacity (fades to transparent)
      stops: [0, 100], // Gradient stop points
      gradientToColors: ['#B1EDE9'] // Purple color for the area fill (same as starting color)
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#424242' // Y-axis label color
      }
    },
    title: {
      text: 'Wyświetlenia ofert', // Y-axis title
      style: {
        fontFamily: 'Kanit', // Font for Y-axis title
        color: '#424242' // Y-axis title color
      }
    },
  }
});
const applicationsChartOptions = ref({
  grid: {
    borderColor: '#eaeaea',
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  legend: {
    show: false, // Hide legend since there's only one dataset now
  },
  chart: {
    toolbar: {
      show: false
    },
    id: 'forecast-chart',
    type: 'bar', // Keep it as a bar chart
  },
  plotOptions: {
    bar: {
      columnWidth: '60%', // Adjust this value to change the bar width
      horizontal: false, // Vertical bars
    }
  },
  dataLabels: {
    style: {
      colors: ['#ffffff']
    },
    enabled: false // Disable data labels
  },
  stroke: {
    width: 3,
    colors: ['#63DBD2FF'] // Optional stroke color
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '11px', // Set your desired font size
        fontFamily: 'Kanit',
        colors: '#484949' // X-axis label color
      },
    },
    categories: viewsChartService.getLast30DaysDateAsArray(), // X-axis categories
  },
  fill: {
    type: 'gradient', // Use gradient for bar fill
    colors: ['#B1EDE9'], // Starting color for the gradient
    gradient: {
      shade: 'dark', // Dark shading for the gradient
      shadeIntensity: 0, // Set shadeIntensity to 0 to avoid extra tint
      type: 'vertical', // Vertical gradient direction
      opacityFrom: 0.8, // Start opacity (for more visible bars)
      opacityTo: 0.3, // End opacity (fades to transparent)
      stops: [0, 100], // Gradient stop points
      gradientToColors: ['#B1EDE9'] // Same color for gradient end
    }
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return Math.floor(value); // Rounds down to remove decimal places
      },
      style: {
        colors: '#484949' // Y-axis label color
      }
    },
    title: {
      text: 'Przesłane aplikacje', // Y-axis title
      style: {
        fontFamily: 'Kanit',
        color: '#424242' // Y-axis title color
      }
    },
  }
});


// const applicationsSeries = ref(applicationsGroupedChartData.value);

const viewsData = ref([])
const activeOffersData = ref([])
const activeOffersSeries = ref([{
  name: 'Oferty pracy',
  data: activeOffersData

},])
const viewsSeries = ref([
  {
    name: 'Wyświetlenia ofert',
    data: viewsData,
    yaxis: 0,
  },
]);


const activeOffersChartOptions = ref({
  grid: {
    borderColor: '#eaeaea', // Color of grid lines
    yaxis: {
      lines: {
        show: false // Show Y-axis grid lines
      }
    }
  },
  legend: {
    offsetY: -10, // Adjust this value to decrease the space between legend and chart
  },
  dataLabels: {
    style: {
      colors: ['#484949']
    },
    enabled: false
  },
  stroke: {
    width: [2], // Line width
    curve: 'stepline', // Stepline curve for active offers
    colors: ['#63DBD2FF'] // Line color
  },
  chart: {
    toolbar: {
      show: false // Disable toolbar
    },
    id: 'forecast-chart'
  },
  xaxis: {
    labels: {
      style: {
        colors: '#424242', // X-axis label color
        fontFamily: 'Kanit', // Font family for X-axis labels
        fontSize: '11px', // Set your desired font size
      },
    },
    categories: viewsChartService.getLast30DaysDateAsArray(), // Categories for X-axis
  },
  fill: {
    type: 'gradient', // Use gradient for area fill
    gradient: {
      shade: 'dark', // Dark gradient
      shadeIntensity: 0, // Set shadeIntensity to 0 to avoid extra tint
      type: 'vertical', // Vertical gradient direction
      opacityFrom: 0.8, // Start opacity (higher for more visible area)
      opacityTo: 0.3, // End opacity (fades to transparent)
      stops: [0, 100], // Gradient stop points
      gradientToColors: ['#B1EDE9'] // Same gradient as for offer views (green to purple)
    },
    colors: ['#B1EDE9'] // Purple starting color to match the views chart
  },
  yaxis: {
    labels: {
      style: {
        colors: '#484949' // Y-axis label color
      }
    },
    title: {
      text: 'Oferty pracy', // Y-axis title text
      style: {
        fontFamily: 'Kanit', // Font family for Y-axis title
        color: '#424242', // Y-axis title color
      }
    },
  }
});


// const chartOptions = {
//   xaxis: {
//     tickAmount: 12,
//     type: "datetime",
//   },
//   yaxis: {
//     max: 70,
//   },
// };
//
// const chartSeries = [
//   {
//     name: "Bubble 1",
//     data: [
//       {
//         x: new Date("2023-09-05").getTime(),
//         y: 40,
//         z: 2,
//       },
//       {
//         x: new Date("2023-09-06").getTime(),
//         y: 6,
//         z: 5,
//       },
//       // Add more data points as needed
//     ],
//   },
// ];
// function changeToActive() {
//   offers.value = []
//   offerType.value = 'ACTIVE';
//   resetInfiniteScroll();
// }
//
// function changeToInactive() {
//   offers.value = []
//   offerType.value = 'INACTIVE';
//   resetInfiniteScroll();
// }
//
// function resetInfiniteScroll() {
//   // Reset the infinite scroll state
//   infinitiveScroll.value.reset();
//   // Clear the offers and other related variables
//   currentNumberOfElements.value = null;
//   totalNumberOfElements.value = null;
//   // Fetch the initial set of offers
//   fetchOffers(0, () => {
//   });
// }

</script>

<style lang="scss" scoped>
//.apexcharts-tooltip {
//  display: none !important;
//}
</style>