<template>
  <q-dialog
      v-model="showBanner"
      class="text-dark"
      persistent
      transition-show="fade"
      transition-hide="fade"
      transition-duration="700"
  >
    <div style="position: fixed; bottom: 0; left: 17px" class="q-mb-lg">
      <q-card style="max-width: 260px" class="q-pa-xs">
        <q-card-section class="text-black text-bold q-pb-none row">
          <p style="font-size: 15px !important;" class="q-ma-none">
            Informacja o plikach cookies
          </p>
          <span class="material-symbols-outlined q-ml-sm" style="font-size: 20px">cookie</span>
        </q-card-section>
        <q-card-section class="text-dark q-pb-sm">
          Nasza strona internetowa używa plików cookies w celu świadczenia usług.
          Szczegółowe informacje znajdziesz w naszej
          <a
              href="https://stbytejobprodwe.blob.core.windows.net/legal/Polityka-Prywatnosci.pdf"
              target="_blank"
              class="accent-3 q-mx-xs"
              style="text-decoration: none;"
          >polityce prywatności</a
          >
        </q-card-section>
        <q-card-section>
          <q-btn
              class="q-px-md q-py-xs text-dark bg-accent"
              no-caps
              style="border-radius: 9px!important;"
              dense
              label="Rozumiem"
              @click="acceptCookies"
          />
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>

<script setup>
import { ref, onMounted } from "vue";
import CookieService from "@/services/common/CookieService";

const showBanner = ref(false);

function checkConsent() {
  const consent = CookieService.getCookieValue("cookieConsent");
  showBanner.value = consent !== "accepted";
}

function acceptCookies() {
  CookieService.setCookie("cookieConsent", "accepted", 180); // 180 days = 6 months
  showBanner.value = false;
}

onMounted(() => {
  checkConsent();
  if (showBanner.value) {
    setTimeout(() => {
      showBanner.value = true;
    }, 30);
  }
});
</script>
