import AuthenticationService from "@/services/AuthenticationService";

class FetchWrapper {
    async fetchWithAuth(url, options = {}) {
        try {
            const response = await fetch(url, options);

            if (response.status === 401) {
                await AuthenticationService.logout();
                return Promise.reject(new Error("Unauthorized"));
            }

            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default new FetchWrapper();
