§ <template>
  <div class="q-mt-md q-mb-md">
    <q-card class="full-width column" bordered style="border-radius: 9px; border-color: #c9d7ec">
      <div class="q-mx-lg q-mt-lg q-mb-sm">
        <adding-offer-section-description title="Twoje CV" description=" – możesz dodać maksymalnie 3 pliki PDF. Podczas aplikowania na ofertę wybierz, które CV chcesz przesłać pracodawcy."/>
      </div>
      <div class="row col q-mx-lg q-my-md justify-between">

        <applicant-cv-card v-if="firstResume"
                           v-model="firstResume"
                           @resumes:update="initResumes()"
                           :applicant-id="props.applicantId"
                           :resume-index="0"
                           style="max-width: 31%">
        </applicant-cv-card>


        <applicant-cv-card v-if="secondResume"
                           v-model="secondResume"
                           @resumes:update="initResumes()"
                           :applicant-id="props.applicantId"
                           style="max-width: 31%">
        </applicant-cv-card>


        <applicant-cv-card v-if="thirdResume"
                           v-model="thirdResume"
                           @resumes:update="initResumes()"
                           :applicant-id="props.applicantId"
                           :resume-index="2"
                           style="max-width: 31%">
        </applicant-cv-card>
      </div>
    </q-card>
  </div>
</template>

<script setup>
import {defineProps, onMounted, reactive, ref} from "vue";

import ApplicantCvCard from "@/components/applicant/profile/ApplicantCvCard";
import {currentUser} from "@/global-variables/currentUser";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";

const props = defineProps({
  applicantId: {
    type: String,
    required: true
  }
});

const resumes = ref([])

onMounted(() => {
  initResumes()
})


const firstResume = reactive({
  id: null,
  fileName: null,
  file: [],
  defaultResume: false
})
const secondResume = reactive({
  id: null,
  fileName: null,
  file: [],
  defaultResume: false
})
const thirdResume = reactive({
  id: null,
  fileName: null,
  file: [],
  defaultResume: false
})

function getResumes() {
  try {
    return fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + props.applicantId + "/resumes", {
      method: 'GET',
      credentials: "include",
    })
        .then(response => {
          return response
        })
  } catch (error) {
    console.error(error);
    throw error
  }
}

function initResumes() {
  getResumes().then(response => {
    response.json().then(body => {
      currentUser.resumes = body
      resumes.value = body
      for (const resume of resumes.value) {
        resume.uploadedAt = new Date(resume.uploadedAt);
      }
      resumes.value.sort((a, b) => a.uploadedAt - b.uploadedAt);
      initializeResume(firstResume, 0)
      initializeResume(secondResume, 1)
      initializeResume(thirdResume, 2)
    })
  })
}

function initializeResume(resumeObj, index) {
  if (resumes.value.length > index) {
    resumeObj.id = resumes.value[index]?.id
    resumeObj.fileName = resumes.value[index]?.fileName
    resumeObj.file = resumes.value[index]?.file
    resumeObj.defaultResume = resumes.value[index]?.defaultResume
  }else {
    resumeObj.id = null
    resumeObj.fileName = null
    resumeObj.file = []
    resumeObj.defaultResume = false
  }
}
</script>

<style scoped>
.custom-full-height {
  height: 100%;
  box-sizing: border-box;
}
</style>