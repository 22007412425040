<template>
  <div class="full-width">
    <adding-offer-section-description :is-warning="isWarningForMode" title="Tryb pracy" description=" - Wybierz formę wykonywania pracy dla tego stanowiska. Możesz zaznaczyć wiele opcji"/>
    <div class="row q-pb-sm q-mt-sm">
      <q-checkbox
          v-model="offer.operatingModes" label="Praca stacjonarna"
          :val="props.formTemplateData.jobOffer.operatingModes[0]"
          color="accent"
          @update:model-value="validateOperatingModes"
          class="q-pr-lg text-dark"
      />
      <q-checkbox
          v-model="offer.operatingModes" label="Praca hybrydowa"
          :val="props.formTemplateData.jobOffer.operatingModes[1]"
          @update:model-value="validateOperatingModes"
          color="accent"
          class="q-pr-lg text-dark"
      />
      <q-checkbox
          v-model="offer.operatingModes" label="Praca zdalna" :val="props.formTemplateData.jobOffer.operatingModes[2]"
          color="accent"
          @update:model-value="validateOperatingModes"
          class="q-pr-lg text-dark"
      />
    </div>

    <div class="q-pt-lg q-mt-md">
      <adding-offer-section-description
          :is-warning="isWarningForTime"
          title="Wymiar czasu pracy" description=" - Określ, jaki wymiar czasu pracy oferujesz. Możesz zaznaczyć jedną lub obie opcje."/>
      <div class="row q-pb-sm q-mt-sm">
        <q-checkbox
            @update:model-value="validateWorkTypes"
            v-model="offer.workTypes" label="Pełny etat" val="FULL_TIME"
            color="accent"
            class="q-pr-lg text-dark"
        />
        <q-checkbox
            @update:model-value="validateWorkTypes"
            v-model="offer.workTypes" label="Część etatu" val="PART_TIME"
            color="accent"
            class="q-pr-lg text-dark"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref, defineExpose, nextTick} from 'vue'
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";
import ScrollUtils from "@/services/common/ScrollUtils";

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const isWarningForMode = ref(false)

async function validateOperatingModes() {
  await nextTick();
  if (offer.value.operatingModes.length === 0) {
    isWarningForMode.value = true;
    ScrollUtils.scrollToTopWithDelayAndSmooth("operatingModes", 0);
    return false;
  }
  isWarningForMode.value = false;
  return true;
}

const isWarningForTime = ref(false)

async function validateWorkTypes() {
  await nextTick();
    if (offer.value.workTypes.length === 0) {
      isWarningForTime.value = true;
      ScrollUtils.scrollToTopWithDelayAndSmooth("operatingModes", 0);
      return false;
    }
    isWarningForTime.value = false;
    return true;
}


defineExpose({
  validateOperatingModes,
  validateWorkTypes,
});

const emit = defineEmits(['update:modelValue'])

</script>