class PhoneNumberValidator {
    isValidPolishPhoneNumber(value) {
        const polishPhoneNumberRegex = /^(\+48)?\d{9}$/;
        return polishPhoneNumberRegex.test(value);
    }

}

export default new PhoneNumberValidator();

