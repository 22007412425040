<template>
  <div class="q-pa-xs col row column q-mt-xs" style="max-width: 800px">
    <!-- Buttons Section -->
    <div class="full-width q-px-none q-mb-sm q-pr-sm">
      <div class="q-my-md q-mx-sm">
        <div class="q-mb-md row q-px-none q-mt-md justify-between">
          <div class="q-gutter-sm items-center">
            <q-btn
                size="md"
                :outline="allAccountsButton"
                :flat="!allAccountsButton"
                style="border-radius: 12px !important;"
                :class="getColorForAreAllButton()"
                label="Wszystkie"
                @click="fetchAllAccounts()"
            ></q-btn>
            <q-btn
                size="md"
                style="border-radius: 12px !important;"
                :flat="!activeAccountsButton"
                :class="getColorForIsActiveButton()"
                :outline="activeAccountsButton"
                label="Aktywne"
                @click="fetchActiveAccounts()"
            ></q-btn>
            <q-btn
                size="md"
                :outline="!allAccountsButton || !activeAccountsButton"
                :flat="activeAccountsButton || allAccountsButton"
                style="border-radius: 12px !important;"
                :class="getColorForIsInactiveButton()"
                label="Nieaktywne"
                @click="fetchInactiveAccounts()"
            ></q-btn>
          </div>
          <organization-account-create @add:user="fetchAllAccounts()"></organization-account-create>
        </div>
      </div>
    </div>

    <!-- Organization Account Overview Section -->
    <div
        class="full-width q-scrollarea col q-px-sm q-pr-md"
        style="overflow-y: auto; display: flex; justify-content: center;"
    >
      <div class="full-width">
        <organization-account-overview
            v-for="(account, index) in accounts"
            :key="account.id"
            @account:deactivate="removeDeactivated"
            v-model:account="accounts[index]"
            @admin:pass="(a,b) => showAndHidePassAdminConfirmationDialog(a,b)"
        ></organization-account-overview>
      </div>
    </div>

    <!-- Confirmation Dialog -->
    <q-dialog v-model="passAdminConfirmationDialog">
      <q-card class="q-px-md" bordered style="width: 350px; border-radius: 9px;">
        <q-card-section class="row justify-center q-px-none q-pt-md q-pb-md">
          <div class="text-h7 text-dark text-center">
            <div class="q-pt-none q-pb-sm q-mb-none text-center">
              <q-icon
                  size="md"
                  name="fa-regular fa-circle-question"
                  color="positive"
                  class=""
              ></q-icon>
            </div>
            <div>
              <span class="text-center" style="font-size: 13px">
                <p class="q-mb-none text-dark">
                  Czy na pewno chcesz przekazać uprawnienia admina do
                  <span style="color: #3bb6c4; font-size: 13px">{{ passAdminTo }}</span>? Potwierdzając
                  wyrażasz zgodę na wylogowanie.
                </p>
              </span>
            </div>
          </div>
        </q-card-section>
        <div class="text-center q-mb-md">
          <q-btn
              @click="passAdmin()"
              style="border-radius: 7px"
              class="q-px-md q-mr-sm"
              dense
              label="Ok"
              no-caps
              color="primary"
          ></q-btn>
          <q-btn
              @click="passAdminConfirmationToFalse()"
              outline
              style="border-radius: 7px"
              v-close-popup
              class="q-px-md"
              dense
              label="Powrót"
              color="dark"
          ></q-btn>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>


<script setup>
import {onMounted, ref} from "vue";
import OrganizationAccountOverview from "@/components/organization/organization-accounts/OrganizationAccountOverview";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";
import OrganizationAccountCreate from "@/components/organization/organization-accounts/OrganizationAccountCreate";
import {useRouter} from "vue-router/dist/vue-router";

const activeAccountsButton = ref(true)
const allAccountsButton = ref(true)
const accounts = ref([]);
const selectedAccounts = ref({});
const passAdminTo = ref('');

onMounted(() => {
  fetchAllAccounts()
});

function passAdminConfirmationToFalse(){
  passAdminConfirmationDialog.value = false
}

function getColorForIsActiveButton() {
  if (activeAccountsButton.value && !allAccountsButton.value) {
    return 'text-dark bg-accent'
  } else {
    return 'text-dark'
  }
}

function getColorForAreAllButton() {
  if (!activeAccountsButton.value && allAccountsButton.value) {
    return 'text-dark bg-accent'
  } else {
    return 'text-dark'
  }
}

function getColorForIsInactiveButton() {
  if (activeAccountsButton.value || allAccountsButton.value) {
    return 'text-dark'
  } else {
    return 'text-dark bg-accent'
  }
}


function fetchActiveAccounts() {
  activeAccountsButton.value = true
  allAccountsButton.value = false
  OrganizationUsersService.getUsersWithCookie().then((data) => {
    accounts.value = data.content.filter(account => account.active === true && account.emailVerified === true);
    accounts.value.forEach(account => {
      selectedAccounts.value[account.id] = false;
    });
  });
}

function fetchInactiveAccounts() {
  activeAccountsButton.value = false
  allAccountsButton.value = false
  OrganizationUsersService.getUsersWithCookie().then((data) => {
    accounts.value = data.content.filter(account => account.active === false && account.emailVerified === true || account.active === true && account.emailVerified === false);
    accounts.value.forEach(account => {
      selectedAccounts.value[account.id] = false;
    });
  });
}

function fetchAllAccounts() {
  activeAccountsButton.value = false
  allAccountsButton.value = true
  OrganizationUsersService.getUsersWithCookie().then((data) => {
    accounts.value = data.content
    accounts.value.forEach(account => {
      selectedAccounts.value[account.id] = false;
    });
  });
}

function removeDeactivated(id) {
  if(!allAccountsButton.value){
    accounts.value = accounts.value.filter(acc => acc.id !== id);
  }else{
    fetchAllAccounts()
  }
}

const passAdminConfirmationDialog = ref(false);
const passAdminUserId = ref(null)

function showAndHidePassAdminConfirmationDialog(email, id) {
  passAdminTo.value = email
  passAdminUserId.value = id
  passAdminConfirmationDialog.value = true;
}

const router = useRouter()

function passAdmin() {
  OrganizationUsersService.passAdmin(passAdminUserId.value)
  router.push({name: 'OrganizationLoginPage'});
}
</script>

<style scoped>
</style>
