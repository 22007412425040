class ApplicationsChartService {


    createMapWithLast30Dates() {
        const today = new Date();
        const last30DaysMap = new Map();
        for (let i = 0; i < 30; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const formattedDate = this.formatDate(date);
            last30DaysMap.set(formattedDate, 0); // You can set the initial value as needed
        }
        return last30DaysMap;
    }

    formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    createArrayOfSummaryViews(offers) {
        this.clearMapValuesToZero(this.last30DaysAndViewsMap);
        this.addViewsFromOfferToSpecificDay(offers)
        return Array.from(this.last30DaysAndViewsMap.values());
    }

    clearMapValuesToZero(map) {
        for (const key of map.keys()) {
            map.set(key, 0);
        }
    }

    addApplicationsNumberToGroupedChartData(offers) {
        var dateToApplicationsSummary = this.createMapWithLast30Dates(); // Map to store the summary for each day

        for (var i = 0; i < offers.length; i++) {
            var offer = offers[i];

            // Iterate over daily applications for each offer
            for (const [date, applications] of Object.entries(offer.dailyApplications)) {
                // Only add the daily applications to the summary map if the date is within the last 30 days
                if (dateToApplicationsSummary.has(date)) {
                    dateToApplicationsSummary.set(date, dateToApplicationsSummary.get(date) + applications);
                }
                // Else, ignore the date as it's older than 30 days
            }
        }
        return Array.from(dateToApplicationsSummary.values()).reverse();
    }


    // addApplicationsNumberToGroupedChartData(offers) {
    //     var chartGroupedData = [];
    //     for (var i = 0; i < offers.length; i++) {
    //         var offer = offers[i];
    //         var jobPosition = offer.title;
    //         var dateToApplications = this.createMapWithLast30Dates();
    //         var chartData = []
    //         for (const [date, applications] of Object.entries(offer.dailyApplications)) {
    //             this.addDailyApplications(date, applications, dateToApplications)
    //         }
    //         var hue = (200 + (i * 15)) % 360; // Vary the hue in 30-degree increments
    //         var saturation = 70; // Keep saturation constant
    //         var lightness = 50; // Keep lightness constant
    //         var color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    //         chartData = Array.from(dateToApplications.values()).reverse();
    //         chartGroupedData.push({
    //             "name": jobPosition,
    //             "data": chartData,
    //             "color": color
    //         });
    //     }
    //     return chartGroupedData;
    // }

    addDailyApplications(date, applications, mapWithApplications) {
        if (mapWithApplications.has(date)) {
            mapWithApplications.set(date, applications)
        }
        return mapWithApplications;
    }

    dailyApplicationData(offer) {
        var dateToApplications = this.createMapWith30DaysFromOfferPublication(offer)
        for (const [date, applications] of Object.entries(offer.dailyApplications)) {
            this.addDailyApplications(date, applications, dateToApplications)
        }
        return Array.from(dateToApplications.values());
    }


    createMapWith30DaysFromOfferPublication(offer) {

        const last30DaysMap = new Map();
        for (let i = 0; i < 30; i++) {
            const publishedAt = new Date(offer.publication.publishedAt);
            const date = new Date(offer.publication.publishedAt);
            date.setDate(publishedAt.getDate() + i)
            const formattedDate = this.formatDate(date);
            last30DaysMap.set(formattedDate, 0); // You can set the initial value as needed
        }
        return last30DaysMap;
    }

    //TODO move to common file
    get30DaysFromOfferPublicationDateAsArray(jobOffer) {
        const dateStrings = Array.from(this.createMapWith30DaysFromOfferPublication(jobOffer).keys());
        const sorted = dateStrings.sort((a, b) => new Date(a) - new Date(b));
        return sorted.map(sortedDate => sortedDate.slice(5).replace('-', '.'))
    }


}

export default new ApplicationsChartService();
