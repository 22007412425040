import script from "./JobApplication.vue?vue&type=script&setup=true&lang=js"
export * from "./JobApplication.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QRange from 'quasar/src/components/range/QRange.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSeparator,QForm,QChip,QInput,QBtn,QIcon,QTooltip,QSelect,QSpace,QToggle,QBadge,QRange});qInstall(script, 'directives', {Ripple,ClosePopup});
