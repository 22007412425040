<template>
  <q-intersection
      transition="jump-down"
      style="width: 30%"
      transition-duration="1200"
  >
    <q-card bordered style="border-radius: 9px;"
            :class="getBorderClass()">
      <q-card-section>
        <div class="row q-pl-sm">
          <div class="q-mb-md row">
            <p style="font-size: 16px!important; margin-top: 2px" class="text-bold text-black">{{ plan.type }}</p>
            <div v-if="isButtonActive">
              <q-chip v-if="plan.type === 'STANDARD'" color="deep-purple" outline icon-right="mdi-tooltip-check-outline"
                      size="sm"
                      class="q-ml-sm text-bold accent-3" style="border-radius: 5px">
                Rekomendowany
              </q-chip>
            </div>
          </div>
          <div v-if="props.plan.promotionalPrice !== null">
            <q-chip outline icon-right="mdi-brightness-percent" size="sm" color="dark"
                    class="q-ml-sm text-bold" style="border-radius: 5px">
              Promocja
            </q-chip>
          </div>
        </div>
        <div class="q-pl-sm" v-if="props.plan.promotionalPrice !== null">
          <p class="text-grey-5" style="font-size: 20px!important;">
          <span class="text-bold" style=" text-decoration: line-through; text-decoration-thickness: 1px">{{
              props.plan.originalPrice.amount
            }}</span>
            <span style="font-size: 25px" class="text-accent text-bold">&nbsp;0 zł&nbsp;</span>
            <span style="font-size: 14px; font-weight: lighter" class="text-dark">netto/ogłoszenie</span>
          </p>
        </div>
        <div v-else class="q-pl-sm">
          <p class="text-green-7" style="font-size: 25px!important;">
            <span class="text-bold text-dark">{{ props.plan.originalPrice.amount }}</span>
            <span class="text-dark text-bold">&nbsp;zł&nbsp;</span>
            <span style="font-size: 14px; font-weight: lighter" class="text-dark">netto/ogłoszenie</span>
          </p>
        </div>
        <div class="q-my-lg text-center q-px-sm">
          <q-btn size="10px" class="text-dark q-py-xs q-my-sm" @click="emitNextStep()"
                 dense
                 outline
                 no-caps
                 style="background-color: #87d8d3!important;font-size:11px!important;border-radius: 7px !important;font-weight: bold; width:100%"><span
              class="text-black">Wybierz plan</span>
          </q-btn>
        </div>
        <div class="q-col-gutter-sm">
          <div class="row justify-between">
            <q-chip style="font-size: 13px" color="white" text-color="grey-9" icon="mdi-check">
              Okres publikacji &nbsp;<span class="text-bold">{{ plan.publicationDays }}</span>&nbsp;dni
            </q-chip>
            <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
              <q-tooltip style="border-width: 2px" max-width="200px" anchor="top middle" self="bottom middle"
                         :offset="[10, 10]" class="bg-white text-dark">
                Oferta będzie widoczna przez {{ plan.publicationDays }} dni od daty publikacji.
              </q-tooltip>
            </q-btn>
          </div>
          <div class="row justify-between">
            <q-chip style="font-size: 13px" color="white" text-color="grey-9" icon="mdi-check">
              Liczba podbić &nbsp;<span class="text-bold">{{ plan.bumpUps }}</span>&nbsp;
            </q-chip>
            <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                Podbicie pozwala promować ofertę na szczycie listy.
              </q-tooltip>
            </q-btn>
          </div>
          <div class="row justify-between">
            <q-chip style="font-size: 13px" color="white" text-color="grey-9" icon="mdi-check">
              Liczba lokalizacji &nbsp;<span class="text-bold text-dark">{{ plan.locationsLimit }}</span>&nbsp;
            </q-chip>
            <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                Liczba miast, które możesz dodać w ogłoszeniu.
              </q-tooltip>
            </q-btn>
          </div>
          <div v-if="plan.offerMonitoring" class="row justify-between">
            <q-chip style="font-size: 13px" color="white" text-color="grey-9" icon="mdi-check">
              Monitoring ogłoszeń
            </q-chip>
            <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                Monitoring ogłoszeń umożliwia analizowanie trendów dotyczących ofert pracy oraz aplikacji. Dzięki temu
                można lepiej kontrolować ogłoszenia w odniesieniu do sytuacji na rynku.
              </q-tooltip>
            </q-btn>
          </div>
          <div v-if="plan.recruitmentPortal" class="row justify-between">
            <q-chip style="font-size: 13px" color="white" text-color="dark" icon="mdi-check" class="text-bold">
              Portal pracodawcy
            </q-chip>
            <q-btn size="sm" flat round color="black" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                Portal pracodawcy usprawnia proces rekrutacji pracowników, umożliwiając szybką analizę i ocenę
                kandydatów.
              </q-tooltip>
            </q-btn>
          </div>
          <div class="row justify-between">
            <q-chip style="font-size: 13px" color="white" :text-color="evaluateColor()" icon="mdi-check">
              Identyfikacja wizualna firmy -&nbsp;<span class="text-bold">{{ getTranslatedBranding() }}</span>&nbsp;
            </q-chip>
            <q-btn size="sm" flat round color="grey-9" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                W planach Standard i Premium, karty Twoich ofert pracy z tytułem i podstawowymi informacjami będą
                wyświetlane w kolorze Twojej firmy, co pozwoli Ci wyróżnić się na tle innych ogłoszeń.
              </q-tooltip>
            </q-btn>
          </div>
          <div v-if="plan.socialMediaPromotion.amount === 0" class="row justify-between">
            <q-chip style="font-size: 13px" :color="evaluateTextColor()" text-color="grey-6" icon="mdi-close">
              Kampania promocyjna w social mediach
            </q-chip>
            <q-btn size="sm" flat round color="grey-5" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                Kampania promocyjna oferty w mediach społecznościowych Byte Job.
              </q-tooltip>
            </q-btn>
          </div>
          <div v-else class="row justify-between text-bold">
            <q-chip style="font-size: 13px" color="white" text-color="dark" icon="mdi-check">
              Kampania promocyjna w social mediach
            </q-chip>
            <q-btn size="sm" flat round color="grey-9" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                Kampania promocyjna oferty w mediach społecznościowych Byte Job.
              </q-tooltip>
            </q-btn>
          </div>
          <div v-if="!plan.highlighted" class="row justify-between">
            <q-chip style="font-size: 13px" color="white" text-color="grey-6" icon="mdi-close">
              Wyróżnienie oferty
            </q-chip>
            <q-btn size="sm" flat round color="grey-5" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                Wyróżnienie oferty na liście ogłoszeń
              </q-tooltip>
            </q-btn>
          </div>
          <div v-else class="row justify-between text-bold">
            <q-chip style="font-size: 13px" color="white" text-color="dark" icon="mdi-check">
              Wyróżnienie oferty
            </q-chip>
            <q-btn size="sm" flat round color="grey-9" icon="mdi-information-outline">
              <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                         class="bg-white text-dark">
                Wyróżnienie oferty na liście ogłoszeń
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-intersection>
</template>
<script setup>
import {computed, defineEmits, defineProps} from "vue";
import {currentUser} from "@/global-variables/currentUser";
import OrganizationDataService from "@/services/organization/OrganizationDataService";
import NotificationService from "@/services/notification/NotificationService";
import {useRouter} from 'vue-router'
import {useQuasar} from "quasar";

const $q = useQuasar();

const router = useRouter()

const props = defineProps({
  plan: Object,
  planType: String,
  isButtonActive: Boolean
});

const emit = defineEmits(['step:next'])

const planForOffer = computed({
  get: () => props.plan,
  set: (val) => emit('update:planForOffer', val),
});

function emitNextStep() {
  if (currentUser.isAuthenticated() && currentUser.isOrganizationUser()) {
    OrganizationDataService.getOrganizationInfo().then((organizationDataResponse) => {
      organizationDataResponse.json().then(body => {
        if (OrganizationDataService.areMandatoryFieldsMissing(body)) {
          router.push({name: 'OrganizationDataPage'});
          NotificationService.showNotification($q, 'negative', 'Dodaj wymagane dane przed publikacją oferty: logo, rodzaj firmy, branże, liczba pracowników')
        } else {
          if (props.isButtonActive || props.plan.type === "BASIC") {
            planForOffer.value = props.plan
            emit('step:next')
          } else {
            NotificationService.showNotification($q, 'info', 'Płatne plany dostępne wkrótce')
          }
        }
      })
    })
  } else {
    router.push({name: 'OrganizationRegistration'});
  }
}

function getTranslatedBranding() {
  let brandingTypes = props.plan.brandingTypes
  if (brandingTypes.includes("LOGO") && brandingTypes.includes("COLORS")) {
    return 'logo i kolor'
  } else if (brandingTypes.includes("LOGO")) {
    return 'logo'
  }
}

function evaluateTextColor() {
  if (planForOffer.value === "BASIC") {
    return 'text-dark'
  } else {
    return 'text-dark'
  }
}

function evaluateColor() {
  if (props.plan.type === "BASIC") {
    return 'grey-9'
  } else {
    return 'dark'
  }
}

function getBorderClass() {
  if (props.plan.type === "STANDARD") {
    return 'recommended-border'
  } else {
    return 'border'
  }
}
</script>
<style lang="scss" scoped>
.bg-gradient {
  background: linear-gradient(20deg, $primary, $light-blue-11); /* Use your primary and accent colors here */
  color: white; /* Text color */
}

.recommended-border {
  border-color: $accent; /* Use your primary and accent colors here */
}

.border {
  border-color: $grey-5; /* Use your primary and accent colors here */
}
</style>