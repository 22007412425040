<template>
  <div class="row full-width justify-between">
    <q-input class="col q-pr-md" color="dark" outlined
             v-model="all"
             label-color="dark"
             bg-color="white"
             popup-content-class="limiting"
             stack-label
             use-input
             dropdown-icon="sym_o_search"
             clear-icon="cancel"
             clearable
             multiple
             use-chips
             input-debounce="0"
             dense
             @update:model-value="(value) => addValueToFilter(value)"
             label="Szukaj po tytule lub nazwie firmy"
             transition-show="scale"
             transition-hide="scale"
    >
      <!--      const emit = defineEmits(['filterValues'])-->
      <!--      @filter="(val, update) => filterAll(val, update, allOptions)"-->
      <!--      :options="filteredAll"-->

      <template v-slot:option="{ itemProps, opt}">
        <q-item v-bind="itemProps">
          <q-item-section>
            <q-item-label v-html="opt.label"/>
          </q-item-section>
          <q-item-section side>
            <q-badge outline :color="opt.color" :label="opt.name"/>
          </q-item-section>
        </q-item>
      </template>
    </q-input>

    <q-select dense
              class="text-grey-9"
              style="font-size: 10px;  min-width: 120px;"
              label-color="grey-9"
              color="dark"
              bg-color="white"
              outlined
              transition-show="scale"
              transition-hide="scale"
              v-model="sorting"
              :popup-content-style="{ maxHeight: '200px', borderRadius: '0 0 7px 7px',fontSize: '12px' }"
              :options="sortingOptions"
              :options-dense="true"
              @update:model-value="emitSortigValues()"/>
  </div>
</template>
<script setup>
import {defineEmits, onMounted, ref} from 'vue'

const emit = defineEmits(['change:sorting', 'change:values'])

const all = ref([])
// const allOptions = ref([])

onMounted(() => {

})


function emitSortigValues() {
  all.value = []
  emit('change:sorting', mapSortingOptionToConstant(sorting.value))
}


const sorting = ref('Najnowsze');
const sortingOptions = ref([
  'Najnowsze', 'Najstarsze',
])

function mapSortingOptionToConstant(sortingOption) {
  switch (sortingOption) {
    case 'Najnowsze':
      return 'NEWEST';
    case 'Najstarsze':
      return 'OLDEST';
  }
}

function addValueToFilter(value) {
  emit('change:values', value);
}
</script>

<style lang="scss" scoped>
//to hide horizontally
//:deep(.q-select .q-field__native row) {
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  width: 100%;
//}

:deep(.q-chip) {
  font-size: 12px;
}

:deep(.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container) {
  padding-top: 10px;
}

:deep(.q-field--outlined .q-field__control) {
  border-radius: 9px;
}

:deep(.q-field--outlined .q-field__control::before) {
  //border-color: $accent;
}
</style>