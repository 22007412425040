import FetchWrapper from "@/services/common/FetchWrapper";
import CookieService from "@/services/common/CookieService";
import {currentUser} from "@/global-variables/currentUser";

class OrganizationUserConsentsService {
    constructor(url) {
        this.url = url;
    }

    async updateUserConsents(organizationId, userId, consentData) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "accept": "*/*",
                "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
            },
            credentials: "include",
            body: JSON.stringify(consentData),
        };

        const url = `${this.url}/organizations/${organizationId}/users/${userId}/consents`;
        try {
            let response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            if(response.ok){
                currentUser.userConsents = consentData;
            }
            return response;
        } catch (error) {
            console.error("Error updating user consents:", error);
            throw error;
        }
    }
}

export default new OrganizationUserConsentsService(
    process.env.VUE_APP_BACKEND_URL
);
