import script from "./OrganizationJobOfferDetailsBoard.vue?vue&type=script&setup=true&lang=js"
export * from "./OrganizationJobOfferDetailsBoard.vue?vue&type=script&setup=true&lang=js"

import "./OrganizationJobOfferDetailsBoard.vue?vue&type=style&index=0&id=33844f78&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QPage,QCard,QItem,QItemSection,QSeparator,QCardSection});
