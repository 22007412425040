<template>
  <div class="col row column">
    <q-dialog v-model="hideOfferConfirmationDialog">
      <q-card style="width: 300px; border-radius: 9px;">
        <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
          <div class="text-h7 text-grey-9 text-center">
            <div class="q-pt-none q-pb-sm q-mb-none text-center">
              <q-icon size="md" name="fa-regular fa-circle-question" color="pink-5" class=""></q-icon>
            </div>
            <div>
              <span class="text-center" style="font-size: 13px">Czy na pewno chcesz ukryć ofertę?</span>
              <span class="text-center" style="font-size: 13px"> Ogłoszenie zniknie z listy ofert.</span>
            </div>
          </div>
        </q-card-section>
        <div class="text-center q-mb-md">
          <q-btn @click="hideOffer()" style="border-radius: 7px"
                 class="q-px-md q-mr-sm bg-accent text-dark" dense label="Ok"
                 outline
                 ></q-btn>
          <q-btn @click="hideOfferConfirmationDialog = false" outline style="border-radius: 7px"
                 class="q-px-md" dense label="Powrót"
                 color="dark"></q-btn>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showOfferConfirmationDialog">
      <q-card style="width: 300px; border-radius: 9px;">
        <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
          <div class="text-h7 text-grey-9 text-center">
            <div class="q-pt-none q-pb-sm q-mb-none text-center">
              <q-icon size="md" name="fa-regular fa-circle-question" color="positive" class=""></q-icon>
            </div>
            <div>
              <span class="text-center" style="font-size: 13px">Czy na pewno chcesz pokazać ofertę?</span>
              <span class="text-center" style="font-size: 13px">Ogłoszenie pojawi się na liście ofert.</span>
            </div>
          </div>
        </q-card-section>
        <div class="text-center q-mb-md">
          <q-btn @click="showOffer()" style="border-radius: 7px"
                 class="q-px-md q-mr-sm" dense label="Ok"
                 no-caps
                 color="primary"></q-btn>
          <q-btn @click="showOfferConfirmationDialog = false" outline style="border-radius: 7px"
                 class="q-px-md" dense label="Powrót"
                 color="dark"></q-btn>
        </div>
      </q-card>
    </q-dialog>
    <div class="col row q-mr-sm">
      <div :style="{ paddingTop: paddingTop }" class="q-scrollarea q-pr-md" style="overflow-y: auto; width: 100%">
        <div class="q-mr-xs" :style="{ 'max-height': maxHeight + 'vh' }" :class="justifyContent">
          <intro-card
              class="q-pa-sm q-mb-lg"
              :title="props.jobOffer.title"
              :contracts="props.jobOffer.publication.contracts"
              :company-name="props.jobOffer.organization.name"
              :logo="props.jobOffer.organization.compressedLogoUrl"
              :web-page-url="props.jobOffer.organization.webPageUrl"
              :instagram-page-url="props.jobOffer.organization.instagramPageUrl"
              :facebook-page-url="props.jobOffer.organization.facebookPageUrl"
              :job-position="props.jobOffer.jobPosition"
              :seniority-level-name="props.jobOffer.seniority"
              :operating-modes="props.jobOffer.operatingModes"
              :work-types="props.jobOffer.workTypes"
              :id="props.jobOffer.id"
              :is-visible="isVisible"
              :branding="props.jobOffer.organization.branding"
              :locations="props.jobOffer.locations"
              :is-copy-available="true"
              :color-branding-available="props.jobOffer.colorBrandingAvailable"
          >
          </intro-card>
          <requirements-card class="q-pt-md q-mb-lg" :job="props.jobOffer" :skills="props.jobOffer.skills"/>
          <description class="q-pt-md q-mb-lg" :description="props.jobOffer.description"></description>
          <organization-description class="q-pb-sm q-mb-lg"
                                    v-if="isOrganizationDescription()"
                                    :description="props.jobOffer.organization.description"></organization-description>
        </div>
      </div>
    </div>
    <div class=" q-pr-md full-width" v-if="showApply">
      <q-card class=" q-pa-sm row justify-between" style="border-radius: 9px">
        <div>
          <q-btn
              style="width: 20vh; border-radius: 6px"
                 size="md"
                 icon="fa-regular fa-paper-plane"
                 color="accent"
                 align="center"
                 dense
                 label="Aplikuj"
                 class="text-white"
                 @click="toggleUserCard()"/>
        </div>
        <div class="q-pr-sm">
          <q-chip size="15px" square icon="alarm" color="white" text-color="primary"
                  class="q-ma-none bg-white text-grey-9">
            Ważna jeszcze 25 dni
          </q-chip>
        </div>
      </q-card>
    </div>
    <q-dialog v-model="showUserCard">
      <job-application :job-offer-id="jobOffer.id" :position="jobOffer.title" :questions="jobOffer.questions"
                       @close-modal="closeModal()"></job-application>
    </q-dialog>
    <q-dialog v-model="showApplyConfirmationDialog">
      <q-card style="border-radius: 9px">
        <q-card-section>
          <div class="text-center q-pb-md">
            <q-icon color="positive" name="mdi-file-document-check-outline" size="50px"/>
          </div>
          <div class="q-pb-xs">
            <q-separator></q-separator>
          </div>
          <span class="q-ml-sm text-dark" style="font-weight: 280">Twoje CV zostało wysłane</span>
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="q-mr-sm">
      <div class="row justify-between q-mr-md q-pr-xs col-1 q-mt-md" style="height: auto">
        <div class="q-ml-none" style="height: fit-content; align-self:flex-end">
          <q-btn
              v-if="isVisible.value"
              clickable square class="text-center text-data q-my-none q-px-sm q-mr-sm bg-accent" color="dark"
              style="border-radius: 7px; max-height: fit-content;align-self: flex-end;"
              dense
              outline
              label="Ukryj"
              @click="hideOfferConfirmationDialog = true"
          >
            <q-icon size="14px" class="q-ml-sm" name="fa-solid fa-eye-slash"></q-icon>
          </q-btn>
          <q-btn
              v-else
              clickable square class="text-center q-my-none q-px-sm q-mr-sm bg-accent" color="dark"
              label="Pokaż"
              dense
              outline
              no-caps
              @click="showOfferConfirmationDialog = true"
              style="border-radius: 7px; max-height: fit-content;align-self: flex-end;"

          >
            <q-icon size="14px" class="q-ml-sm" name="fa-solid fa-eye"></q-icon>
          </q-btn>
<!--          <q-btn clickable square class="text-center q-my-none q-px-sm q-mr-sm bg-accent" color="dark"-->
<!--                 style="border-radius: 7px; max-height: fit-content;align-self: flex-end;"-->
<!--                 dense-->
<!--                 outline-->
<!--                 no-caps-->
<!--                 label="Klonuj"-->
<!--                 @click="goToNewClonedOffer(jobOffer.id)"-->
<!--          >-->
<!--            <q-icon size="14px" class="q-ml-sm" name="fa-solid fa-clone"></q-icon>-->
<!--          </q-btn>-->
          <q-btn clickable
                 v-if="jobOffer.payment.status !== 'PAID'"
                 square class="text-center q-my-none q-px-sm q-mr-sm bg-accent" color="dark"
                 style="border-radius: 7px; max-height: fit-content;align-self: flex-end;"
                 label="Opłać"
                 dense
                 no-caps
                 outline
                 @click="goToPayment()"
          >
            <q-icon size="14px" class="q-ml-sm" name="fa-solid fa-wallet"></q-icon>
          </q-btn>
          <q-btn clickable square class="text-center q-my-none q-px-sm bg-accent" color="dark"
                 style="border-radius: 7px; max-height: fit-content;align-self: flex-end;"
                 v-if="isEditButtonActive"
                 label="Edytuj"
                 dense
                 outline
                 no-caps
                 @click="goToEdit(jobOffer.id)">
            <q-icon size="14px" class="q-ml-sm" name="fa-solid fa-pen-to-square"></q-icon>
          </q-btn>
        </div>
        <q-btn clickable square class="text-center q-mt-none q-my-none q-px-sm bg-accent" color="dark"
               style="border-radius: 7px; max-height: fit-content;align-self: flex-end;"
               label="Aplikacje"
               dense
               outline
               no-caps
               @click="goToApplications(jobOffer.id)"
        >
          <q-icon size="14px" class="q-ml-sm" name="fa-solid fa-clone"></q-icon>
        </q-btn>
      </div>
    </div>
  </div>

</template>

<script setup>
import {ref, defineProps, reactive, onMounted} from 'vue';
import Description from "@/components/common/job-offers/job-offer-details/Description";
import RequirementsCard from "@/components/common/job-offers/job-offer-details/RequirementsCard";
import OrganizationDescription from "@/components/common/job-offers/job-offer-details/OrganizationDescription";
import JobApplication from "@/components/common/job-offers/applications/JobApplication";
import jobOfferService from "@/services/JobOfferService";
import {useRouter} from "vue-router/dist/vue-router";
import IntroCard from "@/components/common/job-offers/job-offer-details/intro-card/IntroCard";
import StringUtils from "@/services/common/StringUtils";

const showApplyConfirmationDialog = ref(false)

function showAndHideDialog() {
  showApplyConfirmationDialog.value = true;
  setTimeout(() => {
    showApplyConfirmationDialog.value = false; // Close the dialog after 1.3 seconds
  }, 1900); // 1300 milliseconds = 1.3 seconds
}

function isOrganizationDescription(){
  return !StringUtils.isStringEmpty(props.jobOffer.organization.description)
}
const props = defineProps({
  maxHeight: Number,
  jobOffer: Object,
  showApply: Boolean,
  paddingTop: {
    type: String,
    default: '50px',
  },
  justifyContent: {
    type: String,
    default: 'justify-center',
  },
});
const isVisible = reactive({value: true})
const isEditButtonActive = ref(false)

onMounted(() => {
  isEditButtonActive.value = router.currentRoute.value.query.isEditButtonActive
  isVisible.value = props.jobOffer.visible
})
const maxHeight = ref(props.maxHeight);
const showUserCard = ref(false);
const hideOfferConfirmationDialog = ref(false)
const showOfferConfirmationDialog = ref(false)
// TODO IMPROVE IN DIFFERENT STORY
const toggleUserCard = () => {
  if (props.jobOffer.jobApplicationInfo.applicationType === 'EXTERNAL') {
    window.open(props.jobOffer.jobApplicationInfo.externalApplicationLink, '_blank')
  } else {
    showUserCard.value = !showUserCard.value;
  }
};

const router = useRouter()

function closeModal() {
  showUserCard.value = false;
  showAndHideDialog();
}

function hideOffer() {
  jobOfferService.updateOfferVisibility(props.jobOffer.id, false)
  isVisible.value = false;
  hideOfferConfirmationDialog.value = false
}

function showOffer() {
  jobOfferService.updateOfferVisibility(props.jobOffer.id, true)
  isVisible.value = true;
  showOfferConfirmationDialog.value = false
}

function goToApplications(id) {
  router.push({name: 'OrganizationApplicationsBoard', params: {id: id, activity: "active"}});
}

function goToEdit(id) {
  router.push({name: 'OrganizationJobOfferDetailsEditPage', params: {id: id, activity: "active"}});
}
//
// function goToNewClonedOffer(id) {
//   //TODO klonujac oferte pobierac musze z organizacji a nie ze wszystkich bo nieoplacone nie widnieja na tej liscie, btw czy mozna klonowac nieoplacone/>
//   router.push({name: 'AddJobOfferAsLoggedUser', params: {id: id}})
// }

function goToPayment() {
  window.location.href = props.jobOffer.payment.url;
}
</script>

<style lang="scss" scoped>
.q-chip {
  background: linear-gradient(45deg, $primary, $primary)
}

.q-footer {
  background: linear-gradient(45deg, $secondary, $primary)
}

.q-chip {
  margin-left: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.fixedElementTop {
  background-color: rgb(226,231,239);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.fixedElementBottom {
  background-color: rgb(226,231,239);
  //position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.scrollable-area {
  height: auto;
  overflow-y: auto;
}


.bg-gradient {
  background: linear-gradient(20deg, $primary, $light-blue-11); /* Use your primary and accent colors here */
  color: white; /* Text color */
}

</style>