<template>
  <div class="q-pa-xs col row column q-mt-xs">
      <div class="full-width row justify-center q-mt-md">
        <div style="width: 800px" class="justify-end row">
          <q-select color="dark"
                    class="bg-white "
                    style="max-width: 150px"
                    dense
                    outlined
                    v-model="sorting"
                    :options="sortingOptions"
                    @update:model-value="fetchInvoices()"/>
        </div>
      </div>

    <div class="full-width q-scrollarea text-center justify-center col row q-px-sm" style="overflow-y: auto;">
      <div class="q-mx-lg q-mb-lg q-mt-none full-width" style="max-width: 800px"
           v-if="invoicesReady"
      >
        <div v-if="invoices.length > 0">
          <invoice-overview
              class="q-ma-lg full-width"
              v-for="(account, index) in invoices"
              :key="account.id"
              @account:deactivate="removeDeactivated"
              v-model:invoice="invoices[index]"/>
        </div>
        <div v-else class="q-ma-md full-width q-mt-lg">
          <q-card bordered class="full-width">
            <q-card-section>
              <p class="q-ma-none">Brak faktur - Faktury będą widoczne po dodaniu i opłaceniu ogłoszenia.</p>
            </q-card-section>
          </q-card>
        </div>

      </div>
      <div v-else class="q-scrollarea full-width q-mt-lg" style="overflow-y: auto; max-width: 800px">
        <job-offer-overview-skeleton :show-avatar="false"/>
        <job-offer-overview-skeleton :show-avatar="false"/>
        <job-offer-overview-skeleton :show-avatar="false"/>
        <job-offer-overview-skeleton :show-avatar="false"/>
        <job-offer-overview-skeleton :show-avatar="false"/>
        <job-offer-overview-skeleton :show-avatar="false"/>
        <job-offer-overview-skeleton :show-avatar="false"/>
        <job-offer-overview-skeleton :show-avatar="false"/>
        <job-offer-overview-skeleton :show-avatar="false"/>
      </div>
    </div>
  </div>
</template>


<script setup>
import {onMounted, ref} from "vue";
import InvoiceOverview from "@/components/organization/invoices/InvoiceOverview";
import OrganizationInvoiceService from "@/services/organization/payments/OrganizationInvoiceService";
import JobOfferOverviewSkeleton from "@/components/common/job-offers/skeletons/JobOfferOverviewSkeleton";

const invoices = ref([]); // Using ref to make it reactive
const invoicesReady = ref(false)
// Fetch and populate the accounts
onMounted(() => {
  async function init() {
    await fetchInvoices();
  }

  init();
});

async function fetchInvoices() {
  invoices.value = []
  const firstPageData = await OrganizationInvoiceService.getInvoices(0, mapSortingOptionToConstant(sorting.value));
  const totalPages = firstPageData.page.totalPages;
  invoices.value.push(...firstPageData.content); // Update the value of ref directly
  for (let currentPage = 1; currentPage < totalPages; currentPage++) {
    const data = await OrganizationInvoiceService.getInvoices(currentPage, mapSortingOptionToConstant(sorting.value));
    invoices.value.push(...data.content); // Concatenate data to the end of the array
  }
  invoicesReady.value = true
}


function removeDeactivated(id) {
  invoices.value = invoices.value.filter(acc => acc.id !== id);
}

const sorting = ref('Najnowsze');
const sortingOptions = ref([
  'Najnowsze', 'Najstarsze',
])

function mapSortingOptionToConstant(sortingOption) {
  switch (sortingOption) {
    case 'Najnowsze':
      return 'NEWEST';
    case 'Najstarsze':
      return 'OLDEST';
  }
}

</script>

<style scoped>
</style>
