<template>
  <div style="height: 100vh">
    <q-layout view="hHh Lpr lff" container>
      <organization-menu-drawer :active-button="'ORGANIZATION_DATA'"/>
      <q-page-container class="col row column bg-primary" style="background-color: rgb(217,225,238);">
        <q-page class="column col row">
          <menu-bar class="bg-gradient-menu-organization" style="padding-top: 22px; padding-bottom: 22px"></menu-bar>
          <div class="col row">
            <q-card flat class="bg-secondary col column" style="border-radius: 25px 0 0 0 !important;">
              <div class="justify-center row q-mb-lg q-mx-lg">
                <adding-offer-section-description title="Dane organizacji"
                                                  description=" - uzupełnij dane aby dodać ogłoszenie"
                                                  style="border-radius: 12px!important; max-width: 1000px; margin-top: 30px"/>
              </div>
              <div class="col row justify-center q-mx-lg">
                <q-card bordered v-if="organizationData && formTemplateData"
                        class="q-ma-none column q-mb-xs q-scrollarea col q-pb-lg"
                        style="border-radius: 9px; max-width: 1000px; overflow-y: auto">
                  <div>
                    <q-card-section class="q-mx-sm">
                      <basic-organization-data v-if="organizationData && formTemplateData"
                                               ref="basicDataForm"
                                               :form-template-data="formTemplateData"
                                               v-model="organizationData"></basic-organization-data>

                      <invoice-organization-data v-if="organizationData && formTemplateData"
                                                 class="q-mt-lg q-px-sm q-pt-lg"
                                                 :form-template-data="formTemplateData"
                                                 v-model="organizationData"></invoice-organization-data>
                    </q-card-section>
                  </div>
                </q-card>
                <q-dialog v-model="showConfirmationDialog">
                  <q-card bordered>
                    <q-card-section class="row q-pb-sm">
                      <div class="q-pt-none q-pb-sm q-mb-xs">
                        <q-icon size="md" name="fa-solid fa-circle-info" color="dark"></q-icon>
                      </div>
                      <div class="col q-mt-xs q-ml-sm">
                        <p class="q-ml-sm q-mb-none text-dark">Czy na pewno chcesz zaktualizować dane organizacji?</p>
                      </div>
                    </q-card-section>
                    <div class="q-mx-md q-mb-sm">
                      <q-separator></q-separator>
                    </div>
                    <q-card-actions align="right" class="q-pt-none q-mr-none">
                      <q-btn dense class="q-mr-sm" flat label="Nie" color="dark" v-close-popup/>
                      <q-btn dense class="q-mr-sm bg-accent text-dark" label="Tak" outline v-close-popup
                             @click="updateOrganizationData(organizationData)"/>
                    </q-card-actions>
                  </q-card>
                </q-dialog>
              </div>
            </q-card>
          </div>
          <div class="bg-secondary full-width row justify-center items-center q-pa-md">
            <q-card bordered class="col row q-mx-sm q-mb-none q-mt-sm" style="max-width: 1000px;">
              <q-card-section class="q-pl-none q-pt-none q-mb-none">
                <div class="justify-start q-mx-lg">
                  <q-btn
                      style="border-radius: 7px; width: auto"
                      size="md"
                      icon="fa-solid fa-pen-to-square"
                      align="center"
                      label="Zapisz dane"
                      outline
                      class="q-mr-md bg-accent text-dark q-mt-md"
                      @click="submitForm()"
                  />
                  <q-btn
                      flat
                      style="border-radius: 7px; width: auto;"
                      size="md"
                      icon="clear"
                      align="center"
                      label="Cofnij edycje danych"
                      outline
                      class="bg-white text-dark q-mt-md"
                      @click="initOrganizationInfoData()"
                  />
                </div>
              </q-card-section>
            </q-card>
          </div>

        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>
<script setup>
import {onMounted, reactive, ref} from "vue";
import MenuBar from "@/components/common/MenuBar";
import BasicOrganizationData from "@/components/organization/data/BasicOrganizationData";
import {useQuasar} from "quasar";
import OrganizationDataService from "@/services/organization/OrganizationDataService";
import organizationService from "@/services/organization/OrganizationDataService";
import NotificationService from "@/services/notification/NotificationService";
import InvoiceOrganizationData from "@/components/organization/data/InvoiceOrganizationData";
import OrganizationDataTranslator from "@/services/organization/OrganizationDataTranslator";
import OrganizationMenuDrawer from "@/components/organization/common/OrganizationMenuDrawer";
import organizationInvoiceService from "@/services/organization/payments/OrganizationInvoiceService";
import ScrollUtils from "@/services/common/ScrollUtils";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription.vue";

onMounted(() => {

})

const organizationData = reactive({
  name: "",
  webPageUrl: "",
  branding: {
    primaryColor: "white",
    secondaryColor: "white"
  },
  type: "",
  address: {},
  description: '<strong>Dodaj informacje o firmie:</strong> <ul>\n' +
      '  <li>Charakterystyka</li>\n' +
      '  <li>Projekty</li>\n' +
      '  <li>Styl pracy</li>\n' +
      '</ul>',
  industries: [],
  size: "",
  logoUrl: "",
  dataControllerClause: "",
  billingData: {
    companyName: "",
    nip: "",
    address: {
      street: "",
      city: "",
      flatNumber: "",
      streetNumber: "",
      zipCode: ""
    }
  }
});

const formTemplateData = ref(null)

function getFormtTemplateData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
  };
  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          return data
        }).catch(() => {
          // console.log(err);
        })
      })
}

onMounted(() => {
  getFormtTemplateData().then(data => {
    formTemplateData.value = data;
  });
  initOrganizationInfoData();
})
const $q = useQuasar();
const showConfirmationDialog = ref(false);

// function checkDataAndShowConfirmationDialog() {
//   if (OrganizationDataService.areMandatoryFieldsMissing(organizationData)) {
//     NotificationService.showNotification($q, 'negative', 'Dodaj wymagane dane przed publikacją oferty: logo, rodzaj firmy, branże, liczba pracowników, oraz zaktualizuj klauzulę informacyjną.')
//   } else {
//     showConfirmationDialog.value = true
//   }
// }

function initOrganizationInfoData() {
  organizationService.getOrganizationInfo().then(response => {
    response.json().then(data => {
      organizationData.logoUrl = data.logoUrl;
      organizationData.name = data.name;
      organizationData.description = data.description
      organizationData.type = OrganizationDataTranslator.translateFromCompanyTypeToPolish(data.type)
      organizationData.size = OrganizationDataTranslator.translateSizeToPolish(data.size);
      organizationData.dataControllerClause = data.dataControllerClause;
      if (organizationData.dataControllerClause === null) {
        organizationData.dataControllerClause = "Informujemy, że __________ sp. z o.o. z siedzibą w __________ pełni rolę administratora danych osobowych (dalej \"administrator\"). Przysługuje Ci prawo dostępu do swoich danych, ich poprawienia, usunięcia, ograniczenia przetwarzania, a także prawo wniesienia sprzeciwu wobec przetwarzania, przenoszenia danych i zgłoszenia skargi do organu nadzorczego. Administrator przetwarza dane wyłącznie na potrzeby procesu rekrutacyjnego. Podanie danych wymaganych zgodnie z ustawą z dnia 26 czerwca 1974 r. Kodeks pracy jest obligatoryjne, natomiast przekazanie danych wykraczających poza ten zakres jest dobrowolne. Brak podania wymaganych danych może uniemożliwić przeprowadzenie rekrutacji. Przetwarzanie danych obowiązkowych opiera się na przepisach prawa. Dane będą przechowywane przez czas trwania procesu rekrutacji oraz przez okres, w którym możliwe jest dochodzenie roszczeń. Dane mogą być przekazywane serwisowi Byte Job oraz innym podmiotom, którym zlecono ich przetwarzanie na potrzeby rekrutacji."
      }
      if (data.branding === null) {
        organizationData.branding = {primaryColor: "#5f449a", secondaryColor: "#5f449a"};
      } else {
        organizationData.branding = data.branding;
      }
      organizationData.industries = data.industries.map(industry => OrganizationDataTranslator.translateIndustryToPolish(industry));
      if (data.billingData !== null) {
        organizationData.billingData = data.billingData;
      } else {
        resetBillingData()
      }
      if (OrganizationDataService.areMandatoryFieldsMissing(data)) {
        NotificationService.showNotification($q, 'negative', 'Dodaj wymagane dane przed publikacją oferty: nazwa firmy, logo, rodzaj firmy, branże, liczba pracowników, oraz zaktualizuj klauzulę informacyjną.')
      }
    });
  })
}

function resetBillingData() {
  organizationData.billingData.companyName = "";
  organizationData.billingData.nip = "";
  organizationData.billingData.address.street = "";
  organizationData.billingData.address.city = "";
  organizationData.billingData.address.zipCode = "";
  organizationData.billingData.address.flatNumber = "";
  organizationData.billingData.address.streetNumber = "";
}

function updateOrganizationData(organization) {
  if (isInvoiceFieldsAreMandatory(organization)) {
    const organizationDataForRequest = Object.assign({}, organization);
    if (isBillingDataNotSet(organization)) {
      delete organizationDataForRequest.billingData;
    }
    organizationDataForRequest.branding.secondaryColor = organization.branding.primaryColor;
    organizationDataForRequest.type = OrganizationDataTranslator.translateFromPolishToCompanyType(organizationData.type)
    organizationDataForRequest.size = OrganizationDataTranslator.translateFromPolishToCompanySize(organizationData.size);
    organizationDataForRequest.industries = organizationData.industries.map(translated => OrganizationDataTranslator.translateFromPolishToCompanyIndustry(translated));
    if (organizationInvoiceService.validatePolishPostalCodeAndShowNotification(organizationDataForRequest, $q)) {
      organizationService.updateOrganizationInfo(organizationDataForRequest).then(response => {
        if (response.ok) {
          NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane profilu')
        } else {
          response.json().then(body => {
            if (body.errorCode === 'INVALID_NIP') {
              NotificationService.showNotification($q, 'negative', 'Niepoprawny NIP')

            } else {
              NotificationService.showNotification($q, 'negative', 'Błąd podczas zapisu danych: ' + body.message)
            }
          })
        }
      })
    }
  } else {
    NotificationService.showNotification(
        $q,
        'negative',
        'Aby zapisać dane do faktury uzupełnij pola'
    );
  }
}

function isBillingDataNotSet(organization) {
  return isNullOrWhiteSpace(organization.billingData.companyName)
}

function isBlank(str) {
  return /^\s*$/.test(str);
}

function isNullOrWhiteSpace(str) {
  return str === null || str === undefined || isBlank(str);
}

function isInvoiceFieldsAreMandatory(organizationData) {
  const billingData = organizationData.billingData;

  if (
      isNullOrWhiteSpace(billingData.nip) &&
      isNullOrWhiteSpace(billingData.companyName) &&
      isNullOrWhiteSpace(billingData.address.city) &&
      isNullOrWhiteSpace(billingData.address.street) &&
      isNullOrWhiteSpace(billingData.address.streetNumber) &&
      isNullOrWhiteSpace(billingData.address.zipCode)
  ) {
    return true;
  }

  if (
      !isNullOrWhiteSpace(billingData.nip) &&
      !isNullOrWhiteSpace(billingData.companyName) &&
      !isNullOrWhiteSpace(billingData.address.city) &&
      !isNullOrWhiteSpace(billingData.address.street) &&
      !isNullOrWhiteSpace(billingData.address.streetNumber) &&
      !isNullOrWhiteSpace(billingData.address.zipCode)
  ) {
    return true;
  }
  return false;
}

const basicDataForm = ref(null);

function submitForm() {
  basicDataForm.value.validate().then(valid => {
    if (valid) {
      showConfirmationDialog.value = true
    } else {
      if (OrganizationDataService.isDataControllerClauseNotUpdated(organizationData)) {
        ScrollUtils.scrollToTopWithDelayAndSmooth("controllerClause", 0)
      } else {
        ScrollUtils.scrollToTopWithDelayAndSmooth("basicData", 0)
      }
    }
    if (organizationData.logoUrl === null) {
      ScrollUtils.scrollToTopWithDelayAndSmooth("basicData", 0)
    }
  });
}


</script>
