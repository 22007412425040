<template>
  <div class="row q-gutter-md q-pa-xs" :class="getClassForWebApp()">
    <div class="q-px-sm text-center" v-for="contract in props.contracts" :key="contract.type">
      <div class="row q-ma-none q-pt-xs">
          <span class="text-dark">{{
              OfferDataTranslator.translateContractTypeToPolish(contract.type)
            }}</span>
        <span class="q-pr-xs"></span>
        <span class="text-bold q-px-xs" :class="getSalaryColor(contract)">{{ toSalaryRange(contract) }} </span>
        <span class="text-dark">&nbsp;{{ toCurrency(contract) }} </span>
        <span v-if="contract.salary !== null && contract.salary.from !== null" class="text-dark q-px-xs">{{ toNettoBrutto(contract) }} </span>
      </div>
    </div>
    <div class="row q-px-sm" v-if="!doesContractsContain('B2B')">
      <p style="font-size: 8px" class="q-ma-none text-grey-6">
        <span class="text-grey-6 text">{{ 'B2B - niedostępna' }}</span></p>
    </div>
    <div class="row q-px-sm" v-if="!doesContractsContain('UOP')">
      <p style="font-size: 8px" class="q-ma-none  text-grey-6">
        <span class="text-grey-6 text">{{ 'Umowa o pracę - niedostępna' }}</span></p>
    </div>
    <div class="row q-px-sm" v-if="!doesContractsContain('UZ')">
      <p style="font-size: 8px" class="q-ma-none  text-grey-6">
        <span class="text-grey-6 text">{{ 'Umowa zlecenie - niedostępna' }}</span></p>
    </div>
  </div>

</template>

<script setup>
import {defineProps} from 'vue';
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import {useQuasar} from "quasar";

const props = defineProps({
  contracts: {
    type: Array,
    required: true
  }
});

function toCurrency(contract) {
  if (contract.salary !== null && contract.salary.currency !== null) {
    return contract.salary.currency + ' ';
  } else {
    return ''
  }
}

function toSalaryRange(contract) {
  if (isSalaryUsed(contract)) {
    return ' ' + contract.salary.from.toString() + ' - ' + contract.salary.to.toString()
  } else {
    return '- brak stawki'
  }
}

function isSalaryUsed(contract) {
  return contract.type !== null && contract.salary !== null && contract.salary !== undefined && contract.salary.from !== null && contract.salary.to !== null;
}

function doesContractsContain(type) {
  const typesArray = props.contracts.map(contract => contract.type);
  return typesArray.includes(type);
}

function toNettoBrutto(contract) {
  if (contract.type === 'UOP') {
    return 'brutto'
  } else {
    return 'netto'
  }
}

function getSalaryColor(contract){
  if (isSalaryUsed(contract)) {
    return 'text-dark'
  } else {
    return 'text-dark'
  }
}

const $q = useQuasar()

function getClassForWebApp(){
  if(isMobile()){
    return ''
  }else{
    return 'justify-between'
  }
}

function isMobile() {
  return $q.platform.is.mobile
}
</script>

<style scoped lang="scss">
my-text-contract {
  color: $primary;
}

span {
  font-size: 11px;
}
</style>
