<template>
  <div class="q-pt-none">
    <div class="q-pt-none q-pl-xs">
      <p class="text-dark text-bold q-pt-none q-ma-none q-mb-sm" style="font-size: 12px">
        Umiejętności
      </p>
    </div>
    <div class="q-px-xs q-pt-none q-pb-md">
      <q-separator></q-separator>
    </div>
    <div class="row q-gutter-sm">
      <div v-for="skill in sortedTech" :key="skill.name">
        <div class="col q-pr-md">
          <q-chip
              :key="skill.name"
              color="dark"
              text-color="primary"
              outline
              style="font-weight: 400; border-radius: 4px;"
              class="q-mb-none"
          >
            {{ skill.name }}
          </q-chip>
          <div class="q-pl-xs q-pt-none">
            <my-text style="font-size:10px;" class="text-dark">
              {{OfferDataTranslator.translateFromSkillToPolish(skill.level) }}
            </my-text>
          </div>
          <div class="q-ml-xs">
            <my-rating color="grey-9" :model-value="OfferDataTranslator.convertLevelToNumber(skill.level)">
            </my-rating>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="q-px-sm q-my-sm">-->
<!--      <q-separator></q-separator>-->
<!--    </div>-->
<!--    <div class="full-width center-chart q-ma-none">-->
<!--      <div class="q-ma-none full-width">-->
<!--        <apexchart width="50%" class="center-chart" type="donut" height="280px" :series="series" :options="chartOptions"-->
<!--                   :key="jobOfferId"></apexchart>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted} from 'vue';
import MyRating from "@/components/common/MyRating";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";

const props = defineProps({
  skills: {
    type: Array,
    required: true,
    default: () => []
  },
  jobOfferId: String
});

const series = ref([])
const sortedTech = ref([])
onMounted(() => {
  sortedTech.value = props.skills;
  sortedTech.value.sort((a, b) => {
    const levelA = OfferDataTranslator.convertLevelToNumber(a.level);
    const levelB = OfferDataTranslator.convertLevelToNumber(b.level);
    if (levelA < levelB) return 1;
    if (levelA > levelB) return -1;
    return 0;
  });
  series.value = props.skills.map(skill => OfferDataTranslator.convertLevelToNumber(skill.level))
})


// const chartOptions = {
//   xaxis: {
//     type: 'category'
//   },
//   labels: props.skills.map(skill => skill.name), // Use skill names as labels
//   chart: {
//     type: 'donut',
//   },
//   fill: {
//     type: 'gradient',
//   },
//   colors: [
//     '#85d4f5',
//     '#85d4f5',
//     '#85d4f5',
//     '#9bbfe8',
//     '#9bbfe8',
//     '#9bbfe8',
//     '#6062dc',
//     '#6062dc',
//     '#6062dc',
//   ],
//   dataLabels: {
//     enabled: true,
//     formatter: function () {
//       return ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5"]['master'];
//     }
//   },
//
//   responsive: [{
//     breakpoint: 480,
//     options: {
//       legend: {
//         position: 'bottom'
//       }
//     }
//   }]
// }
// const chartOptions = {
//
//   chart: {
//     type: "donut",
//     // height: 350,
//     // animations: {
//     //   enabled: true,
//     //   easing: 'easein',
//     //   speed: 300,
//     // }
//   },
//   plotOptions: {
//     // donut: {
//     //   borderRadius: 5,
//     //   horizontal: true,
//     //   distributed: true,
//     //   isFunnel: true,
//     // },
//   },
//   colors: [
//     '#85d4f5',
//     '#85d4f5',
//     '#85d4f5',
//     '#9bbfe8',
//     '#9bbfe8',
//     '#9bbfe8',
//
//     '#6062dc',
//     '#6062dc',
//     '#6062dc',
//   ],
//   dataLabels: {
//     enabled: true,
//     formatter: function (val, opt) {
//       return opt.w.globals.labels[opt.dataPointIndex];
//     },
//     dropShadow: {
//       enabled: true,
//     },
//   },
//   animation: {
//     dynamicAnimation: {
//       enabled: true,
//       speed: 200, // Adjust the speed as needed (in milliseconds)
//     },
//   },
//   xaxis: {
//     categories: techNames
//   },
//   legend: {
//     show: false,
//   },
// };

</script>

<style scoped lang="scss">
.my-card {
  margin: 5px auto;
}

my-text {
  color: $primary;
}

.title {
  color: $primary;
}

.apexcharts-tooltip {
  background-color: #80207b;
  color: $secondary;
  font-size: 12px;
}

.apexcharts-tooltip-title {
  background-color: #80207b;
  color: white;
  font-size: 12px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: $secondary !important;
}

.full-width {
  width: 100%;
}

.center-chart {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

</style>