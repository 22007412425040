import script from "./Invoices.vue?vue&type=script&setup=true&lang=js"
export * from "./Invoices.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QCard,QCardSection});
