<template>
  <q-card class="q-pa-none q-pt-none q-mt-md" flat>
    <div class="row items-center q-ml-sm">
      <div class="col">
        <div class="q-mb-sm">
          <p class="q-mb-md text-bold">
            Zgody
          </p>
        </div>
        <p class="text-dark" style="font-size: 11px!important;">
          Przesyłając aplikację, wyrażasz zgodę na przetwarzanie przez Byte Job Twoich danych osobowych zawartych w formularzu aplikacyjnym,
          <span v-if="showMore">
            dokumentach rekrutacyjnych oraz w Twoim profilu kandydata, w celu przeprowadzenia procesu rekrutacji. Podanie danych jest dobrowolne, ale niezbędne do wzięcia udziału w rekrutacji. Więcej informacji o przetwarzaniu danych osobowych oraz Twoich prawach znajdziesz w
            <a href="https://stbytejobprodwe.blob.core.windows.net/legal/Regulamin-dla-Kandydatow.pdf" target="_blank" class="accent-3 q-mx-xs">regulaminie</a>
            Byte Job oraz
            <a href="https://stbytejobprodwe.blob.core.windows.net/legal/Polityka-Prywatnosci.pdf" target="_blank" class="accent-3 q-mx-xs">polityce prywatności</a>.
          </span>
        </p>
        <p v-if="showMore" class="q-pt-md q-ma-none text-dark" style="font-size: 11px!important;" v-html="props.dataControllerClause">
        </p>
        <div id="hideButton"
        >
          <q-btn
              flat
              dense
              @click.stop="toggleShowMore"
              no-caps
              size="11px"
              class="q-mt-xs text-bold accent-3"
          >
            {{ showMore ? 'Zwiń' : 'Rozwiń' }}
          </q-btn>
        </div>
      </div>
    </div>
  </q-card>
</template>

<script setup>
import {ref, defineProps, nextTick} from 'vue';
import ScrollUtils from "@/services/common/ScrollUtils";

const props = defineProps({
  dataControllerClause: String
});

const showMore = ref(false);
const toggleShowMore = () => {
  showMore.value = !showMore.value;
  nextTick(() => {
    ScrollUtils.scrollToBottom("applicationCardSection");
  });
};


</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5em;
  font-size: 12px !important;
}

.row.items-center {
  display: flex;
  align-items: center;
}
</style>
