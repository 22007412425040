<template>
  <q-layout>
      <organization-menu-drawer :active-button="'JOB_OFFERS'"/>
    <q-page-container class="bg-primary">
      <q-page class="column">
        <menu-bar class="bg-gradient-menu-organization" style="padding-top: 22px; padding-bottom: 22px"></menu-bar>
        <q-card flat class="bg-secondary col row" style="border-radius: 25px 0 0 0 !important;">
          <employer-offers class="justify-center col row"
                           :is-active="true"
                           :is-planned="false">
          </employer-offers>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script setup>
import {onMounted} from 'vue'
import MenuBar from "@/components/common/MenuBar";

import EmployerOffers from "@/components/organization/job-offers/EmployerOffers";
import OrganizationMenuDrawer from "@/components/organization/common/OrganizationMenuDrawer";


onMounted(() => {
})</script>

<style lang="scss">
.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}
</style>