import script from "./ApplicantApplications.vue?vue&type=script&setup=true&lang=js"
export * from "./ApplicantApplications.vue?vue&type=script&setup=true&lang=js"

import "./ApplicantApplications.vue?vue&type=style&index=0&id=60758b61&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QPage,QDialog,QCard,QCardSection,QIcon,QSeparator,QBtn});
