<template>
  <div class="q-pt-none q-pb-md">
    <payment-confirmation-watcher v-if="jobOffer.payment.status !== 'PAID'" :session-id="jobOffer.payment.sessionId"
                                  @offerPaymentConfirmationReceived="status => changePaymentStatus(status)"></payment-confirmation-watcher>
    <q-card bordered class="my-card full-width q-pt-md q-pb-sm q-px-sm" v-ripple style="border-radius: 9px"
            @mouseover="toggleMouseOverCard(true)"
            @mouseout="toggleMouseOverCard(false)"
            @click="goToDetails(props.jobOffer.id)"
            :class="{ 'q-hoverable': !isMouseOverFavBtn, 'cursor-pointer': !isMouseOverFavBtn }">

      <span :class="{ 'q-focus-helper': !isMouseOverFavBtn}" style="color: #3e799b"></span>
      <q-card-section class="q-px-sm q-py-none">
        <div class="row">
          <div class="col">
            <div>
              <div class="row justify-between">
                <p v-if="!isVisible" class="q-pl-sm q-pt-xs text-dark text-bold ellipsis"
                   style="font-size: 13px; color: black;">{{ jobOffer.title }}
                  <q-chip dense outline square label="Oferta ukryta" style="font-size: 12px !important; border-radius: 5px !important;"
                          class="text-white bg-dark q-ml-sm"></q-chip>
                </p>
                <p id="title" ref="jobTitle" v-else
                   class="col-5 q-pl-sm text-dark text-bold q-pt-none q-mb-sm ellipsis"
                   style="font-size: 13px; color: black;">
                  {{ jobOffer.title }}
                  <q-tooltip v-if="jobTitle !== null && isEllipsis" class="bg-white text-dark">
                    {{ jobOffer.title }}
                  </q-tooltip>

                </p>
                <div class="row q-mr-xs">
                  <div class="q-pt-none q-pr-xs row">
                    <q-icon size="20px"
                            :color="evaluateColor()"
                            class="q-pb-none q-mr-sm"
                            :name="evaluateExpirationIconName()"
                            style="padding-top: 2px"></q-icon>
                    <p class="q-pl-xs text-dark"
                       style="font-weight: 400; padding-top: 3px;">
                      {{ provideExpirationDescription() }}
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>

      <q-separator class="q-mx-md q-mb-sm"/>

      <q-card-actions class="justify-between q-gutter-sm q-pa-xs q-ml-xs">
        <div class="row">
          <div class="row">
            <q-chip dense outline class="q-ma-none q-pa-sm q-mr-sm" color="grey-9" size="sm"
                    style="font-size: small; border-radius: 5px">
              {{ toCapitalFirstAndLowercase(props.jobOffer.plan.type) }}
            </q-chip>
            <!--                  <p class="q-pl-xs q-pa-none q-ma-none" style="font-size: small; color: gray">{{jobOffer.locations}}</p>-->
          </div>
          <div class="row">
            <q-chip dense outline class="q-ma-none q-pa-sm" color="grey-9" size="sm"
                    style="font-size: small; border-radius: 5px">
              {{ toCapitalFirstAndLowercase(props.jobOffer.seniority) }}
            </q-chip>
          </div>
        </div>
        <div class="row" v-if="!isForPayment()">
          <q-btn dense
                 flat
                 class="bg-white q-ml-sm text-dark q-mr-sm"
                 style="border-radius: 7px !important; padding-right: 0"
                 size="sm"
                 label="Nowe aplikacje"
                 @mouseover="toggleMouseOverFavBtn(true)"
                 @mouseout="toggleMouseOverFavBtn(false)"
                 @click="goToApplications(jobOffer.id)"
          >
            <div class="row">
              <div style="padding-left: 10px; padding-right: 0; padding-top: 0">
                <q-btn size="sm" class="bg-accent text-dark" outline style="border-radius: 5px !important;">
                  <strong style="font-size: 12px">{{ newApplications }}</strong>
                </q-btn>
              </div>
            </div>
          </q-btn>
        </div>
        <div v-else>
          <q-btn clickable square class="text-center q-my-none q-mr-sm bg-accent text-dark"
                 outline
                 size="11px"
                 @mouseover="toggleMouseOverFavBtn(true)"
                 @mouseout="toggleMouseOverFavBtn(false)"
                 dense
                 style="border-radius: 7px !important; max-height: fit-content;align-self: flex-end;"
                 label="Opłać"
                 @click="goToPayment()"
          ></q-btn>
        </div>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script setup>
import {useRouter} from 'vue-router';
import {defineProps, onMounted, onUnmounted, ref} from "vue";
import organizationApplicationService from '@/services/OrganizationApplicationService.js';
import PaymentConfirmationWatcher from "@/components/organization/job-offers/payment/PaymentConfirmationWatcher";

const router = useRouter()

const props = defineProps({
  jobOffer: Object,
  isActive: Boolean,
  isPlanned: Boolean
})

const newApplications = ref(0)
const daysToEndOffer = ref(0);
const daysToPlannedPublication = ref(0);
const dateAfterOfferExpiration = ref(0);
const isVisible = ref(true)
const isMouseOverFavBtn = ref(false)
const isEllipsis = ref(false)
onMounted(() => {
  countNewApplications();
  window.addEventListener("resize", myEventHandler);
  daysToEndOffer.value = countDaysToOfferEnd();
  dateAfterOfferExpiration.value = countDaysAfterOfferEnd();
  daysToPlannedPublication.value = countDaysToPlannedPublication();
  isVisible.value = props.jobOffer.visible
  paymentStatus.value = props.jobOffer.payment.status
  if (jobTitle.value) {
    console.log('Element found:', jobTitle.value);
    console.log('InnerText:', jobTitle.value.innerText);
  } else {
    console.error('jobTitle is still null after mounting');
  }
  isEllipsis.value = isEllipsisActive()
});

onUnmounted(() => {
  window.removeEventListener("resize", myEventHandler);
})

function myEventHandler() {
  isEllipsis.value = isEllipsisActive()
}

function goToPayment() {
  window.open(props.jobOffer.payment.url, '_blank')
}

function toggleMouseOverFavBtn(val) {
  isMouseOverFavBtn.value = val;
}

function goToApplications(id) {
  router.push({name: 'OrganizationApplicationsBoard', params: {id: id, activity: "active"}});
}

// function getPlanColor() {
//   if (props.jobOffer.plan.type === 'BASIC') {
//     return 'text-dark'
//   } else if (props.jobOffer.plan.type === 'STANDARD') {
//     return 'text-dark'
//   } else {
//     return 'text-dark'
//
//   }
// }
//
// function getPlanIconColor() {
//   if (props.jobOffer.plan.type === 'BASIC') {
//     return 'grey-9'
//   } else if (props.jobOffer.plan.type === 'STANDARD') {
//     return 'grey-9'
//   } else {
//     return 'grey-9'
//   }
// }

async function countNewApplications() {
  newApplications.value = await organizationApplicationService.getApplications(props.jobOffer.id, "NEW").then((data) => {
    return data.page.totalElements
  }).catch((err) => {
    console.log(err);
  })
}

function countDaysToOfferEnd() {
  const publishEndAt = new Date(props.jobOffer.publication.publishEndAt);
  const today = new Date();
  const timeDiff = publishEndAt.getTime() - today.getTime();
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
}

function countDaysAfterOfferEnd() {
  const publishEndAt = new Date(props.jobOffer.publication.publishEndAt);
  const today = new Date();
  if (isNaN(publishEndAt.getTime())) {
    console.error('Invalid publishEndAt date');
    return null;
  }
  const timeDiff = today.getTime() - publishEndAt.getTime();
  if (timeDiff < 0) {
    return 0;
  }

  return Math.ceil(timeDiff / (1000 * 3600 * 24));
}


function countDaysToPlannedPublication() {
  const publishEndAt = new Date(props.jobOffer.publication.publishedAt);
  const today = new Date();
  const timeDiff = publishEndAt.getTime() - today.getTime();
  let days = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return days;
}

const jobTitle = ref(null);

function isEllipsisActive() {
  let e = jobTitle.value
  if (e != null) {
    return e.offsetWidth < e.scrollWidth
  } else {
    return false;
  }
}

function provideExpirationDescription() {
  if (props.jobOffer.active && isPaid()) {
    return 'Oferta wygasa za ' + daysToEndOffer.value + ' dni';
  } else if (isForPayment()) {
    return 'Oferta nieopłacona';
  } else {
    return 'Oferta wygasła ' + dateAfterOfferExpiration.value + ' dni temu';
  }
}


function evaluateColor() {
  if (isForPayment()) {
    return 'dark'
  } else if (props.jobOffer.active) {
    return 'dark'
  } else {
    return 'grey-5'
  }
}

function evaluateExpirationIconName() {
  if (isForPayment()) {
    return "fa-regular fa-credit-card"
  } else if (props.jobOffer.active) {
    return "fa-regular fa-calendar-check"
  } else {
    return "fa-regular fa-calendar-xmark"
  }
}

const paymentStatus = ref('PAID')

function isPaid() {
  return paymentStatus.value === 'PAID'
}

function isForPayment() {
  return paymentStatus.value === 'UNPAID' || paymentStatus.value === 'INITIATED'
}

function goToDetails(id) {
  if (!isMouseOverFavBtn.value) {
    router.push({name: 'OrganizationJobOfferDetailsBoard', params: {id: id}});
  }
}

const isMouseOverCard = ref(false)

function toggleMouseOverCard(val) {
  isMouseOverCard.value = val;
}

function changePaymentStatus(newData) {
  if (newData === 'PAID') {
    paymentStatus.value = 'PAID'
  }
}

function toCapitalFirstAndLowercase(string) {
  if (string.length > 1) {
    return string.charAt(0) + string.slice(1).toLowerCase();
  }
  return string;
}

</script>

<style lang="scss" scoped>
.my-card {
  width: 40%
}


.my-border {
  color: #666;
  position: relative;
}

.my-border:after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  border-radius: 9px;
  height: 100%;
  transition: all 0.3s linear;
  width: 100%;
  border-bottom: 2px solid #bec7cc;
  transform: scaleX(0);
  bottom: -2px;
}

.my-border:hover:after {
  transform: scale(1);
}
</style>