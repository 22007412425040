import { currentUser } from '@/global-variables/currentUser.js';
import router from '@/router/index.js'
import CookieService from "@/services/common/CookieService";
import fetchWrapper from "@/services/common/FetchWrapper";
class AuthenticationService {
    constructor(url) {
        this.url = url;
    }

    async _sendRequest(endpoint, body) {
        try {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(body),
            };
            const response = await fetch(this.url + endpoint, requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async loginToOrganization(email, password) {
        return this._sendRequest('/organizations/login', {email, password});
    }

    async loginToApplicantAccount(email, password) {
        let response = this._sendRequest('/applicants/login', {email, password})
        return response;
    }

    async logout() {
        try {
            await this._sendRequest("/logout", {});
            await localStorage.clear();
            currentUser.resetCurrentUser();
            let currentRouteName = router.currentRoute.value.name
            if (currentRouteName === 'JobOffersMainBoard') {
                window.location.reload();
            } else {
                await router.push({ name: 'JobOffersMainBoard'});
            }
        } catch (error) {
            console.error('Error during logout:', error);
            throw error;
        }
    }


    async getAuthenticatedUser() {
        try {
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const response = fetchWrapper.fetchWithAuth(this.url + '/users/current', requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async isAuthenticatedOrganizationUser() {
        try {
            const response = await this.getAuthenticatedUser();
            const isResponseOk = response.ok;
            if (isResponseOk) {
                let body = await response.json()
                return body.type !== 'APPLICANT'
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

    async isAuthenticatedApplicantUser() {
        try {
            const response = await this.getAuthenticatedUser();
            const isResponseOk = response.ok;
            if (isResponseOk) {
                let body = await response.json()
                return body.type === 'APPLICANT'
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }

}

export default new AuthenticationService(process.env.VUE_APP_BACKEND_URL);
