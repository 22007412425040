<template>
  <div class="row justify-start q-pr-sm q-pt-none">
    <div class="row q-mr-md">
      <q-card bordered flat class="q-ma-none text-dark q-mt-md q-ml-none column small-card "
              style="font-size: 13px; border-radius: 10px !important;">
        <!--        <q-icon class="q-pr-sm" color="white" size="15px" name="fa-solid fa-user-gear"></q-icon>-->
        <q-chip label="Stanowisko"
                class="bg-transparent q-pl-xs q-pt-none q-mb-none q-pb-none q-mt-none dark-3"
                style="border-radius:5px;font-size: 10px"
                size="sm"/>

        <p style="margin-bottom: 1px; margin-left: 5px;margin-right: 5px;font-size: 12px!important;"
           class="text-bold q-px-xs q-mb-none q-mt-none col q-pt-none q-pb-xs">
          {{ JobPositionTranslator.translateConstant(props.jobPosition) }}</p>
      </q-card>
    </div>
    <div class="row q-mr-md">
      <q-card bordered flat class="q-ma-none  text-dark  q-mt-md column small-card"
              style="font-size: 13px; border-radius: 10px !important;">
        <!--        background: rgba(132,189,192,0.29) !important;-->
        <!--        <q-icon class="q-pr-sm" color="white" size="15px" name="fa-solid fa-user-gear"></q-icon>-->
        <q-chip label="Doświadczenie"
                class=" bg-transparent q-pl-xs q-pt-none q-mb-none q-pb-none q-mt-none dark-3"
                size="sm"></q-chip>

        <p style="margin-bottom: 1px; margin-left: 5px;margin-right: 5px;font-size: 12px!important;"
           class="text-bold q-px-xs q-mb-none q-mt-none col q-pt-none q-pb-xs">{{ translateExp() }}</p>
      </q-card>
    </div>
    <div class="row q-mr-md">
      <q-card bordered flat class="q-ma-none text-dark  q-mt-md q-ml-none column small-card"
              style="font-size: 13px; border-radius: 10px !important;">
        <!--        <q-icon class="q-pr-sm" color="white" size="15px" name="fa-solid fa-user-gear"></q-icon>-->
        <q-chip label="Tryb pracy"
                class="bg-transparent q-pl-xs q-pt-none q-mb-none q-pb-none q-mt-none dark-3"
                style="border-radius:5px;font-size: 10px"
                size="sm"></q-chip>

        <p style="margin-bottom: 1px; margin-left: 5px;margin-right: 5px;font-size: 12px!important;"
           class="text-bold q-px-xs q-mb-none q-mt-none col q-pt-none">{{ translateOperatingModes() }}</p>
      </q-card>
    </div>
    <div class="row q-mr-md">
      <q-card bordered flat class="q-ma-none text-dark  q-mt-md column small-card"
              style="font-size: 13px;border-radius: 10px !important;">
        <!--        <q-icon class="q-pr-sm" color="white" size="15px" name="fa-solid fa-user-gear"></q-icon>-->
        <q-chip label="Wymiar pracy"
                class=" bg-transparent q-pl-xs q-pt-none q-mb-none q-pb-none q-mt-none dark-3"
                style="border-radius:5px;font-size: 10px"
                size="sm"></q-chip>

        <p style="margin-bottom: 1px; margin-left: 5px;margin-right: 5px;font-size: 12px!important;"
           class="text-bold q-px-xs q-mb-none q-mt-none col q-pt-none q-pb-xs">{{ translateWorkTypes() }}</p>
      </q-card>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";

const props = defineProps({
  jobPosition: {
    type: String,
    required: true,
    default: ''
  },
  modes: {
    type: Array,
    required: true,
  },
  workTypes: {
    type: Array,
    required: true,
  },
  seniorityLevelName: {
    type: String,
    required: true,
    default: ''
  },
  branding: {
    type: Object,
    required: true
  },
  locations: {
    type: Array,
    required: true,
  }
});

function translateOperatingModes() {
  const translations = {
    REMOTE: "zdalna",
    HYBRID: "hybrydowa",
    IN_OFFICE: "stacjonarna",
  };

  const translatedArray = props.modes.map((value) => {
    // Use the translation or the original value if not found
    return translations[value] || value;
  });

  return translatedArray.join(" | ");
}

function translateWorkTypes() {
  const translations = {
    FULL_TIME: "full-time",
    PART_TIME: "part-time",
  };

  const translatedArray = props.workTypes.map((value) => {
    // Use the translation or the original value if not found
    return translations[value] || value;
  });

  return translatedArray.join(" | ");
}

function translateExp() {
  return OfferDataTranslator.translatePolishExperienceLevel(props.seniorityLevelName)
}</script>

<style scoped lang="scss">
.my-card {
  width: 700px;
  margin: 5px auto;
}

my-text {
  color: $primary;
}

.small-card {
  border-color: $dark-2 !important;
}
</style>