// services/ApplicationService.js

import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";

class ApplicationService {
    constructor(url) {
        this.url = url;
    }

    async addResume(uploadedFile) {
        try {
            const formData = new FormData();
            formData.append("resume", uploadedFile);
            formData.append("setToDefault", true);

            const requestOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"), // Add the CSRF token to the headers
                },
                body: formData,
            };

            const url = `${process.env.VUE_APP_BACKEND_URL}/applicants/${currentUser.id}/resumes`;
            return FetchWrapper.fetchWithAuth(url, requestOptions).then((response) => {
                return response;
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getResumes() {
        try {
            const requestOptions = {
                method: "GET",
                credentials: "include",
            };

            const url = `${process.env.VUE_APP_BACKEND_URL}/applicants/${currentUser.id}/resumes`;
            return FetchWrapper.fetchWithAuth(url, requestOptions).then((response) => {
                return response;
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getApplications(queryParams) {
        try {
            const requestOptions = {
                method: "GET",
                credentials: "include",
            };

            const url = `${process.env.VUE_APP_BACKEND_URL}/applicants/${currentUser.id}/applications?${queryParams.toString()}`;
            return FetchWrapper.fetchWithAuth(url, requestOptions).then((response) => {
                return response;
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async applyAsAnonymousUser(uploadedFile, email, applicantName, preferredContactTime, jobOfferId, answers) {
        try {
            const formData = new FormData();
            formData.append("resume", uploadedFile);
            formData.append("contactEmail", email);
            formData.append("applicantName", applicantName);
            formData.append("preferredContactTime", preferredContactTime);
            formData.append("answers", answers);

            const requestOptions = {
                method: "POST",
                body: formData,
            };

            const url = `${process.env.VUE_APP_BACKEND_URL}/job-offers/${jobOfferId}/applications`;
            return FetchWrapper.fetchWithAuth(url, requestOptions).then((response) => {
                return response;
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async applyAsLoggedUser(resumeId, preferredContactTime, jobOfferId, answers) {
        try {
            const requestBody = {
                jobOfferId: jobOfferId,
                resumeId: resumeId,
                preferredContactTime: preferredContactTime,
                answers: answers,
            };

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                credentials: "include",
                body: JSON.stringify(requestBody),
            };

            const url = `${process.env.VUE_APP_BACKEND_URL}/applicants/${currentUser.id}/applications`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async setResumeAsDefault(resumeId) {
        try {
            const requestBody = {
                resumeId: resumeId,
            };

            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                credentials: "include",
                body: JSON.stringify(requestBody),
            };

            const url = `${process.env.VUE_APP_BACKEND_URL}/applicants/${currentUser.id}/resumes/default`;
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export default new ApplicationService(process.env.VUE_APP_BACKEND_URL);
