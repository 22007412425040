<template>
  <q-card bordered style="width: 300px;">
    <q-card-section class="q-px-md q-pt-md q-pb-md">
      <div class="row justify-between">
        <div>
          <div class="q-mb-sm">
            <q-chip outline class="col-1 q-ml-none">
              <p class="q-ma-none text-center">
                {{ template.name }}
              </p>
            </q-chip>
          </div>
          <div>
            <p
                style="font-size: 15px!important; margin: 0;"
                class="q-ma-none"
            >
              <span class="text-bold"> Tytuł maila:  </span>{{ template.emailSubject }}
            </p>
          </div>
        </div>
        <div class="items-center row">
          <q-btn outline dense class="bg-accent text-dark q-mr-md q-px-md" label="edytuj"
                 @click="openEditTemplateDialog()"></q-btn>
          <q-btn outline dense class="bg-white text-dark q-px-md" label="usuń" @click="deleteTemplate()"></q-btn>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>
<script setup>
import {defineEmits, defineProps, onMounted, ref} from "vue";

const name = ref("")
const subject = ref("")
const message = ref("")
const id = ref("")
const props = defineProps({
  template: Object,
});

onMounted(() => {
  name.value = props.template.name
  subject.value = props.template.emailSubject
  message.value = props.template.emailMessage
  id.value = props.template.id
})
const emit = defineEmits(['delete']);

function deleteTemplate() {
  emit('delete', props.template.id);
}

function openEditTemplateDialog() {
  emit('open:edit', {name: name.value, subject: subject.value, message: message.value, id: id.value});
}


</script>