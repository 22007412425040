// services/JobOfferPaymentsService.js

import PromoCodeWrongTypeError from './PromoCodeWrongTypeError';
import FetchWrapper from "@/services/common/FetchWrapper";

class JobOfferPaymentsService {
    constructor(url) {
        this.url = url;
    }

    async getPromoCode(code) {
        const endpoint = `/promo-codes/${code}`;
        try {
            const response = await this._sendGetRequest(endpoint);
            return response;
        } catch (error) {
            console.error('Error retrieving promo code:', error);
            throw error;
        }
    }

    async _sendGetRequest(endpoint) {
        try {
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const url = `${this.url}${endpoint}`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error('Error during request:', error);
            throw error;
        }
    }

    async evaluatePlanPrice(code, planType) {
        try {
            const response = await this.getPromoCode(code);
            if (response.status === 404) {
                throw new Error("Invalid promo code");
            } else {
                const body = await response.json();
                return this.findAmountByPlanType(body, planType);
            }
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    findAmountByPlanType(promotionData, planType) {
        const lowercasePlanType = planType.toLowerCase();
        const supportedPlanTypesLowercase = promotionData.supportedPlanTypes.map(type => type.toLowerCase());
        const index = supportedPlanTypesLowercase.indexOf(lowercasePlanType);
        if (index !== -1) {
            const amount = promotionData.newPrices[promotionData.supportedPlanTypes[index]].amount;
            return amount;
        } else {
            throw new PromoCodeWrongTypeError(promotionData.supportedPlanTypes);
        }
    }
}

export default new JobOfferPaymentsService(process.env.VUE_APP_BACKEND_URL);
