<template>
  <div class="col row">
    <div>
      <adding-offer-section-description
          title="Dane do faktury"
          description=" - Podając je w tej sekcji, umożliwisz automatyczne wypełnienie formularza faktury podczas zakupu oferty. Dane do faktury możesz również edytować bezpośrednio w formularzu płatności przed zakupem ogłoszenia. Wypełnienie tej sekcji jest opcjonalne.
">
      </adding-offer-section-description>
      <div class="row q-gutter-md q-mt-sm">
        <div class="row full-width q-gutter-md q-mb-none q-mt-none">
          <q-input class="q-pb-sm col "
                   stack-label
                   bg-color="white"
                   outlined
                   color="dark"
                   label-color="dark"
                   v-model="organizationData.billingData.companyName"
                   label="Pełna nazwa firmy"
                   dense>
          </q-input>
          <q-input class="q-pb-sm col"
                   stack-label
                   color="dark"
                   label-color="dark"
                   v-model="organizationData.billingData.nip"
                   label="Nip"
                   outlined
                   dense>
          </q-input>
          <q-input class="q-pb-sm col "
                   stack-label
                   color="dark"
                   label-color="dark"
                   v-model="organizationData.billingData.address.city"
                   label="Miasto"
                   outlined
                   dense>
          </q-input>
        </div>
        <div class="row q-gutter-md q-mt-none full-width">
          <q-input class="q-pb-md col"
                   stack-label
                   label-color="dark"
                   v-model="organizationData.billingData.address.street"
                   label="Ulica"
                   outlined
                   color="dark"
                   dense>
          </q-input>
          <q-input class="q-pb-md col"
                   stack-label
                   label-color="dark"
                   v-model="organizationData.billingData.address.streetNumber"
                   label="Numer budynku"
                   color="dark"
                   outlined
                   dense>
          </q-input>

          <q-input class="q-pb-md col"
                   stack-label
                   label-color="dark"
                   v-model="organizationData.billingData.address.flatNumber"
                   label="Numer lokalu"
                   color="dark"
                   outlined
                   dense>
          </q-input>
          <q-input class="q-pb-md col-2"
                   stack-label
                   color="dark"
                   label-color="dark"
                   outlined
                   v-model="organizationData.billingData.address.zipCode"
                   label="Kod pocztowy"
                   dense>
          </q-input>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted} from "vue";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";


const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const organizationData = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {
})
</script>