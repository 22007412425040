import script from "./ApplicantLoginPage.vue?vue&type=script&setup=true&lang=js"
export * from "./ApplicantLoginPage.vue?vue&type=script&setup=true&lang=js"

import "./ApplicantLoginPage.vue?vue&type=style&index=0&id=6d997b1c&lang=css"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QPage,QCard,QCardSection,QBtn,QForm,QInput,QCardActions});
