class DefaultAvatarService {

    getInitials(currentUser){
        if(currentUser.firstName !== null && currentUser.lastName !== null){
            return (currentUser.firstName.slice(0,1) + currentUser.lastName.slice(0,1)).toUpperCase()
        }else{
            return currentUser.email.slice(0,2).toUpperCase()
        }
    }

    getColorByInitials(currentUser) {
        const colors = [
            'bg-light-blue-3',
            'bg-light-green-3',
            'bg-deep-orange-3',
            'bg-cyan-3',
            'bg-orange-3',
            'bg-teal-3',
            'bg-blue-grey-3',
            'bg-deep-purple-3'
        ];
        let hash = 0;
        let initials = this.getInitials(currentUser)
        for (let i = 0; i < initials.length; i++) {
            hash = initials.charCodeAt(i) + ((hash << 5) - hash);
        }
        hash = Math.abs(hash);
        const colorIndex = hash % colors.length;
        return colors[colorIndex];
    }
}

export default new DefaultAvatarService();