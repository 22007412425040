import script from "./OrganizationAccountPage.vue?vue&type=script&setup=true&lang=js"
export * from "./OrganizationAccountPage.vue?vue&type=script&setup=true&lang=js"

import "./OrganizationAccountPage.vue?vue&type=style&index=0&id=264b5dab&lang=scss"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QPageContainer,QPage,QCard,QBtn});
