// services/JobOfferService.js

import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";

class JobOfferService {
    constructor(url) {
        this.url = url;
    }

    async getAllJobOffers(params, signal) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            signal: signal,
        };

        try {
            const response = await FetchWrapper.fetchWithAuth(
                `${process.env.VUE_APP_BACKEND_URL}/job-offers/active?${params}`,
                requestOptions
            );
            // You can process the response here if needed
            return response;
        } catch (err) {
            if (err.name === "AbortError") {
                console.info("Request was aborted!");
            } else {
                console.error("Error fetching all job offers:", err);
                throw err;
            }
        }
    }

    async getFavJobOffers(applicantId) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        };

        try {
            const response = await FetchWrapper.fetchWithAuth(
                `${process.env.VUE_APP_BACKEND_URL}/applicants/${applicantId}/favorite-offers`,
                requestOptions
            );
            return response;
        } catch (error) {
            console.error("Error fetching favorite job offers:", error);
            throw error;
        }
    }

    async getJobOffer(jobOfferId) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        };

        try {
            const response = await FetchWrapper.fetchWithAuth(
                `${process.env.VUE_APP_BACKEND_URL}/job-offers/${jobOfferId}`,
                requestOptions
            );
            return response;
        } catch (error) {
            console.error("Error fetching job offer:", error);
            throw error;
        }
    }

    async getJobOffersForOrganization(queryParams) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        };

        try {
            const response = await FetchWrapper.fetchWithAuth(
                `${process.env.VUE_APP_BACKEND_URL}/organizations/${currentUser.organizationId}/job-offers?${queryParams.toString()}`,
                requestOptions
            );
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching job offers for organization:", error);
            throw error;
        }
    }

    async getJobOfferForOrganization(offerId) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        };

        try {
            const response = await FetchWrapper.fetchWithAuth(
                `${process.env.VUE_APP_BACKEND_URL}/organizations/${currentUser.organizationId}/job-offers/${offerId}`,
                requestOptions
            );
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching job offer for organization:", error);
            throw error;
        }
    }

    async updateOfferVisibility(offerId, isVisible) {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
            },
            credentials: "include",
            body: JSON.stringify({ visible: isVisible }),
        };

        try {
            const response = await FetchWrapper.fetchWithAuth(
                `${process.env.VUE_APP_BACKEND_URL}/organizations/${currentUser.organizationId}/job-offers/${offerId}/visible`,
                requestOptions
            );

            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error updating offer visibility:", error);
            throw error;
        }
    }

    async getSimilarJobOffers(jobOfferId) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        };

        try {
            const response = await FetchWrapper.fetchWithAuth(
                `${process.env.VUE_APP_BACKEND_URL}/job-offers/${jobOfferId}/similar`,
                requestOptions
            );
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching similar job offers:", error);
            throw error;
        }
    }
}

export default new JobOfferService(process.env.VUE_APP_BACKEND_URL);
