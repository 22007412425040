<template>
  <div class="">
    <q-dialog v-model="infoMessageDialog">
      <q-card bordered style="width: 350px;border-radius: 9px">
        <q-card-section class="row justify-center q-pb-none">
          <div class="text-center text-h7 text-dark">
            <div class="q-pt-none q-pb-sm q-mb-xs">
              <q-icon size="md" name="fa-solid fa-circle-info" color="dark"></q-icon>
            </div>
            Aby przeglądać i aplikować na oferty pracy uzupełnij dane.
            <span style="font-size: 14px" class="text-bold">Imię</span>,
            <span style="font-size: 14px" class="text-bold">nazwisko</span>
            i
            <span style="font-size: 14px" class="text-bold">CV</span>
            są wymagane.
          </div>
        </q-card-section>
        <q-card-section class="text-center q-mt-xs">
          <q-btn padding="3px xl" outline class="bg-accent text-dark" label="Ok" @click="infoMessageDialog=false"></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-card bordered class="q-mb-lg col">
      <q-card-section>
        <p class="text-white q-ma-none">
        <span class="text-weight-bold text-primary">
          Dane profilu
        </span>
          <span class="text-dark">
           - wykorzystywane podczas aplikacji na wybrane oferty.
        </span>
        </p>
      </q-card-section>
    </q-card>
    <q-card bordered class="q-mb-lg col row" style="border-radius: 9px; border-color: #c9d7ec">
      <div class="row full-width">
        <div class="col">
          <div class="row">
            <q-card-section class="row col q-pb-sm">
              <q-form ref="childForm" class="row full-width" greedy>
                <div class="full-width col">
                  <q-input class="q-pa-sm q-pb-md col" color="dark" stack-label outlined bg-color="white"
                           v-model="user.firstName"
                           label-color="dark"
                           label="Imię"
                           :rules="[ val => val !== null && val.length !== 0 || 'Imię jest wymagane' ]"
                           dense>
                    <template v-slot:append>
                      <q-avatar>
                        <q-icon
                            name="sym_o_title"
                            size="1rem"/>
                      </q-avatar>
                    </template>
                  </q-input>
                </div>
                <div class="full-width col">
                  <q-input class="q-pa-sm q-pb-md col"
                           color="dark" stack-label outlined bg-color="white"
                           v-model="user.lastName"
                           label-color="dark"
                           label="Nazwisko"
                           :rules="[ val =>  val !== null && val.length !== 0 || 'Nazwisko jest wymagane' ]"
                           dense>
                    <template v-slot:append>
                      <q-avatar>
                        <q-icon
                            name="sym_o_title"
                            size="1rem"/>
                      </q-avatar>
                    </template>
                  </q-input>
                </div>
              </q-form>
            </q-card-section>
          </div>
          <div class="row q-mx-md q-mb-sm">
            <div class="col">
              <q-input class="q-pa-sm q-pb-md col"
                       color="dark" stack-label outlined bg-color="white"
                       v-model="user.phoneNumber"
                       label-color="dark"
                       label="Numer telefonu"
                       :rules="[ val => val === null || val.length === 0 || PhoneNumberValidator.isValidPolishPhoneNumber(val) || 'Podaj 9 cyfr z prefiksem +48 lub bez niego.' ]"
                       dense>
                <template v-slot:append>
                  <q-avatar>
                    <q-icon
                        name="sym_o_title"
                        size="1rem"/>
                  </q-avatar>
                </template>
              </q-input>
            </div>
            <div class="full-width col">
              <q-input class="q-pa-sm q-pb-md col"
                       color="dark" stack-label outlined bg-color="white"
                       v-model="user.email"
                       disable
                       label-color="dark"
                       label="E-mail"
                       :rules="[ val => val.length <= 40 || 'Max. 40 znaków' ]" dense>
                <template v-slot:append>
                  <q-avatar>
                    <q-icon
                        name="sym_o_title"
                        size="1rem"/>
                  </q-avatar>
                </template>
              </q-input>
            </div>
          </div>
        </div>
      </div>

    </q-card>


    <q-card bordered style="border-radius: 9px; border-color: #c9d7ec">
      <q-card-section>
        <div class="q-mx-sm q-mt-sm q-mb-md">
          <adding-offer-section-description title="Preferowany czas kotaktu" description=" - poinformuj pracodawcę o przedziale czasowym w którym może się z Tobą skontaktować."/>
        </div>
        <div class="col-8">
          <div style="margin-top: 1px" class="q-mb-xs q-ml-sm">
            <p style="font-size: 12px" color="white" class="text-dark">
              Preferowane godziny kontaktu: od <span style="font-weight: bold"
                                                     class="text-dark">{{ PreferredContactTimeService.fromRangeValueToTime(user.contactInSpecificHours.min) }}
            </span> do <span style="font-weight: bold" class="text-dark">
              {{ PreferredContactTimeService.fromRangeValueToTime(user.contactInSpecificHours.max) }}</span>
            </p>
          </div>
          <div class="q-px-md">
            <q-range
                color="dark"
                v-model="user.contactInSpecificHours"
                :min="0"
                :max="96"
                :step="1"
                thumb-size="12px"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>

  </div>

</template>
<script setup>
import {computed, defineEmits, defineExpose, defineProps, onMounted, ref} from "vue";
import ApplicantDataService from "@/services/applicant/ApplicantDataService"
import {useRouter} from "vue-router/dist/vue-router";
import StringUtils from "@/services/common/StringUtils";
import PreferredContactTimeService from "@/services/applicant/PreferredContactTimeService";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";
import PhoneNumberValidator from "@/services/common/PhoneNumberValidator";
const userAvatar = ref(null)

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const user = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue'])

const message = ref(null)
const infoMessageDialog = ref(false)
const router = useRouter()
onMounted(() => {
  initUserAvatar();
  message.value = router.currentRoute.value.query.infoMessage;
  if (!StringUtils.isStringEmpty(message.value)) {
    infoMessageDialog.value = true;
  }
});

const isAvatarReady = ref(false)

async function initUserAvatar() {
  await ApplicantDataService.getAvatar(user.value.id).then(response => {
    response.json().then(json => {
      userAvatar.value = json
    });
  })
  isAvatarReady.value = true
}

defineExpose({
  validate
})

const childForm = ref(null)

async function validate() {
  let validate = await childForm.value.validate();
  return validate;
}
</script>