<!--        TYTUL STANOWISKO DOSWIADCZENIE-->
<template>
  <q-card bordered class="q-pa-lg">
    <q-form ref="formRef" greedy>
      <div id="basicData" class="row q-gutter-sm">
        <q-input class="q-pa-xs col"
                 color="dark"
                 stack-label
                 outlined
                 :disable="isTitleDisabled"
                 v-model="offer.title"
                 label="Tytuł ogłoszenia"
                 style="width: 17rem"
                 :rules="titleRules"
                 dense>
          <template v-slot:append>
            <q-avatar>
              <q-icon
                  name="sym_o_title"
                  size="1rem"/>
            </q-avatar>
          </template>
        </q-input>
        <q-select
            class="col q-pa-xs"
            color="dark"
            stack-label
            outlined
            v-model="offer.jobPosition"
            :options="filteredPosition"
            :rules="jobPositionRules"
            label="Stanowisko"
            :popup-content-style="{height: '300px', overflowY: 'auto'}"
            dense
            @filter="(val, update) => filterAllPositions(val, update)"
        >
        </q-select>
        <q-select class="col-3 q-pa-xs" color="dark" stack-label outlined v-model="offer.seniority"
                  :options="OfferDataTranslator.translateExperienceLevelsTypeToPolish()"
                  dense
                  :rules="seniorityRules"
                  label="Poziom stanowiska">
        </q-select>
      </div>
      <div id="description" class="q-mx-none q-mt-lg q-pt-md">
        <adding-offer-section-description class="q-mx-lg" title="Opis oferty"
                                          :is-warning="isDescriptionMissing"
                                          description=" - Wprowadź szczegółowy opis stanowiska, zakres obowiązków, wymagania oraz korzyści, które oferujesz, aby zainteresować odpowiednich kandydatów."/>
      </div>

      <div class="col row q-mb-lg">
        <q-card flat bordered class="q-mt-sm q-mx-none q-mt-lg col row" style="border-radius: 9px">
          <q-field class="col row" no-error-icon ref="fieldRef" v-model="offer.description" label-slot borderless dense
                   :rules="[val => val.length < 10000|| 'Zbyt długi opis - max 10000 znaków.', val => isDescription(val)|| 'Opis jest wymagany.']">
            <q-editor
                ref="editorRef"
                class="col q-pb-md q-mt-sm text-dark full-height column"
                v-model="offer.description"
                toolbar-toggle-color="accent"
                min-height="20rem"
                max-height="20rem"
                @paste="onPaste"
                style="border-radius: 9px;overflow-x: auto;word-break: break-word;font-size: 14px;border-width: 0;"
                toolbar-color="dark"
                :toolbar="[
        ['bold', 'underline'],
        ['quote', 'unordered', 'ordered'],
        ['undo', 'redo']
      ]" :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"
            />
          </q-field>
        </q-card>
      </div>
      <div id="contracts" class="q-pt-md">
        <contracts-section ref="contractsComponentRef" v-model="offer.contracts"
                           :form-template-data="formTemplateData"/>
      </div>

      <div class="q-pt-md" id="applicationType">
        <div class="q-mt-md">
          <adding-offer-section-description class="q-mb-md" title="Formularz aplikacyjny"
                                            description=" - Wybierz sposób, w jaki kandydaci będą aplikować na Twoją ofertę. Możesz skorzystać z naszej platformy rekrutacyjnej i zarządzać aplikacjami bezpośrednio tutaj, lub podać link do zewnętrznego formularza."/>
        </div>
        <div class="q-pa-none q-pb-md">
          <q-btn-toggle
              style="border-radius: 12px!important;"
              v-model="offer.jobApplicationInfo.applicationType"
              spread
              no-caps
              toggle-color="dark"
              color="white"
              text-color="dark"
              :options="[
          {label: 'Domyślny formularz aplikacyjny', value: 'INTERNAL'},
          {label: 'Zewnętrzny formularz aplikacyjny', value: 'EXTERNAL'}
        ]"
          />
        </div>
        <q-input class="q-pa-xs q-pb-sm" color="dark" stack-label outlined
                 v-if="offer.jobApplicationInfo.applicationType==='EXTERNAL'"
                 v-model="offer.jobApplicationInfo.externalApplicationLink"
                 :rules="[val => validateExternalFormUrl(val)]"
                 label="URL do zewnętrznego formularza"
                 dense>
          <template v-slot:append>
            <q-avatar>
              <q-icon
                  name="sym_o_title"
                  size="1rem"
              />
            </q-avatar>
          </template>
        </q-input>
        <questions-section v-if="offer.jobApplicationInfo.applicationType ==='INTERNAL'"
                           ref="questionsRef" v-model="offer.questions" :form-template-data="formTemplateData"/>
      </div>
      <div id="skills" class="q-pt-md">
        <skills ref="skillsRef" :form-template-data="formTemplateData" v-model="offer"></skills>
      </div>

      <div class="q-pt-md q-pb-lg" id="languages">
        <languages-section ref="languagesRef" :form-template-data="formTemplateData" v-model="offer"/>
      </div>

      <div id="operatingModes" class="q-pt-lg q-pb-md">
        <operating-mode
            ref="operatingModesRef"
            :form-template-data="formTemplateData" v-model="offer"></operating-mode>
      </div>
      <div class=" q-pt-lg full-width q-pb-md">
        <adding-offer-section-description title="Lokalizacje"
                                          :is-warning="isLocationError"
                                          description=" - Dokładny adres jest opcjonalny. Maksymalna liczba lokalizacji zależy od wybranego planu oferty."/>
        <q-select
            dense
            class="q-mt-md"
            color="dark"
            use-chips
            multiple
            v-model="offer.cities"
            :rules="[val => locationsValidationAccordingToPlan(val) || locationPlanRulesMsg(val)]"
            label="Lokalizacje biur"
            clear-icon="cancel"
            use-input
            :options="locationsOptions"
            outlined
            @update:model-value="val => handleModelValueUpdate(val)"
            @filter="(val, update) => filterAllLocations(val, update)"
        >
        </q-select>
        <div v-for="address in offer.locations" :key="address.city">
          <div class="row q-gutter-md q-mb-sm">
            <q-chip color="dark" style="border-radius: 12px!important; height: 40px" outline
                    :label="address.city"></q-chip>
            <q-input v-model="address.street" rounded label-color="grey-6" outlined dense
                     class="col bg-white text-dark"
                     color="dark"
                     label="Ulica">
            </q-input>
            <q-input v-model="address.streetNumber" rounded label-color="grey-6"
                     outlined dense
                     class="col bg-white text-dark"
                     color="dark"
                     label="Numer budynku">
            </q-input>
            <q-input v-model="address.flatNumber" rounded label-color="grey-6" outlined dense
                     class="col bg-white text-dark"
                     color="dark"
                     label="Numer lokalu">
            </q-input>
          </div>
        </div>
      </div>
      <q-stepper-navigation class="q-pa-none" v-if="showButtons">
        <q-btn size="md" outline class="q-px-lg bg-accent text-dark" :label="buttonName" @click="nextStep()"/>
        <q-btn v-if="isBackButtonVisible" size="md" flat @click="previousStep" color="dark" label="Powrót"
               class="q-ml-md q-px-md"/>
      </q-stepper-navigation>
    </q-form>
  </q-card>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from "vue"
import LanguagesSection from "@/components/common/add-offer/job-offer/language/LanguagesSection";
import OperatingMode from "@/components/common/add-offer/job-offer/operating-mode/OperatingModeSection";
import Skills from "@/components/common/add-offer/job-offer/skills/Skills";
import ContractsSection from "@/components/common/add-offer/job-offer/contracts/ContractsSection";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from 'quasar'
import QuestionsSection from "@/components/common/add-offer/job-offer/questions/QuestionsSection";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";
import JobOfferPlansService from "@/services/organization/offer/JobOfferPlansService";
import ScrollUtils from "@/services/common/ScrollUtils";
import StringUtils from "@/services/common/StringUtils";

const props = defineProps({
  formTemplateData: Object,
  title: String,
  modelValue: Object,
  showButtons: Boolean,
  buttonName: {
    type: String,
    default: 'Dalej'
  },
  isBackButtonVisible: {
    type: Boolean,
    default: true
  },
  isTitleDisabled: {
    type: Boolean,
    default: false
  }
})

const offer = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const emit = defineEmits(['update:modelValue', 'step:next', 'step:previous'])

const filteredPosition = ref(null)

const editorRef = ref(null);

function onPaste(evt) {
  if (evt.target.nodeName === 'INPUT') return
  let text, onPasteStripFormattingIEPaste
  evt.preventDefault()
  evt.stopPropagation()
  if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
    text = evt.originalEvent.clipboardData.getData('text/plain')
    editorRef.value.runCmd('insertText', text)
  } else if (evt.clipboardData && evt.clipboardData.getData) {
    text = evt.clipboardData.getData('text/plain')
    editorRef.value.runCmd('insertText', text)
  } else if (window.clipboardData && window.clipboardData.getData) {
    if (!onPasteStripFormattingIEPaste) {
      onPasteStripFormattingIEPaste = true
      editorRef.value.runCmd('ms-pasteTextOnly', text)
    }
    onPasteStripFormattingIEPaste = false
  }
}

function jobPositionOptions() {
  let options = []
  props.formTemplateData.jobOffer.jobPositions.forEach(jobPosition => {
    options.push(JobPositionTranslator.translateConstant(jobPosition))
  })
  return options;
}

function isDescription(val) {
  let htmlToPlainText = StringUtils.htmlToPlainText(val);
  let isValid = htmlToPlainText.length > 0;
  if (!isValid) {
    isDescriptionMissing.value = true;
    ScrollUtils.scrollToTopWithDelayAndSmooth("description", 0)
  } else {
    isDescriptionMissing.value = false;
  }
  return isValid
}

function validateExternalFormUrl(val) {
  if (val !== null && val !== '') {
    const httpsRegex = /^https:\/\//;
    if (httpsRegex.test(val)) {
      return true;
    } else {
      ScrollUtils.scrollToTopWithDelayAndSmooth("applicationType", 0);
      return 'Adres URL musi zaczynać się od https://';
    }
  } else {
    ScrollUtils.scrollToTopWithDelayAndSmooth("applicationType", 0);
    return 'Podaj adres www zewnętrznego formularza';
  }
}


function locationsValidationAccordingToPlan(val) {
  if (offer.value.planType === 'BASIC') {
    let isValid = val.length > 0 && val.length <= Number(JobOfferPlansService.getBasicPlan(props.formTemplateData.plans).locationsLimit);
    if (isValid) {
      isLocationError.value = false
    } else {
      isLocationError.value = true
    }
    return isValid;
  } else if (offer.value.planType === 'STANDARD') {
    isLocationError.value = true
    let isValid = val.length > 0 && val.length <= Number(JobOfferPlansService.getStandardPlan(props.formTemplateData.plans).locationsLimit);
    if (isValid) {
      isLocationError.value = false
    } else {
      isLocationError.value = true
    }
    return isValid;
  } else if (offer.value.planType === 'PREMIUM') {
    isLocationError.value = true
    let isValid = val.length > 0 && val.length <= Number(JobOfferPlansService.getPremiumPlan(props.formTemplateData.plans).locationsLimit);
    if (isValid) {
      isLocationError.value = false
    } else {
      isLocationError.value = true
    }
    return isValid;
  }
}

function locationPlanRulesMsg() {
  if (offer.value.planType === 'BASIC') {
    isLocationError.value = true
    return 'Wybrałeś plan Basic, możesz podać od 1 do ' + JobOfferPlansService.getBasicPlan(props.formTemplateData.plans).locationsLimit + ' lokalizacji'
  }
  if (offer.value.planType === 'STANDARD') {
    isLocationError.value = true
    return 'Wybrałeś plan Standard, możesz podać od 1 do ' + JobOfferPlansService.getStandardPlan(props.formTemplateData.plans).locationsLimit + ' lokalizacji'
  }
  if (offer.value.planType === 'PREMIUM') {
    isLocationError.value = true
    return 'Wybrałeś plan Premium, możesz podać od 1 do ' + JobOfferPlansService.getPremiumPlan(props.formTemplateData.plans).locationsLimit + ' lokalizacji'
  }
  isLocationError.value = false
}

const questionsRef = ref(null)
const contractsComponentRef = ref(null)

const $q = useQuasar();

const titleRules = [
  val => {
    if (!val || val.length === 0) {
      ScrollUtils.scrollToTopWithDelayAndSmooth("basicData", 0)
      return 'Podaj tytuł ogłoszenia';
    }
    if (val.length > 50) {
      ScrollUtils.scrollToTopWithDelayAndSmooth("basicData", 0)
      return 'Max. 50 znaków';
    }
    return true;
  }
];

const jobPositionRules = [
  val => {
    if (!val || val.length === 0) {
      ScrollUtils.scrollToTopWithDelayAndSmooth("basicData", 0)
      return 'Podaj stanowisko';
    }
    return true;
  }
];

const seniorityRules = [
  val => {
    if (!val || val.length === 0) {
      ScrollUtils.scrollToTopWithDelayAndSmooth("basicData", 0)
      return 'Podaj stanowisko';
    }
    return true;
  }
];

const formRef = ref(null);
const operatingModesRef = ref(null);

const isLocationError = ref(false);

async function nextStep() {
  const isFormValid = await formRef.value.validate();
  console.log('isFormValid:', isFormValid);

  const skillsValid = await validateSkills();
  console.log('skillsValid:', skillsValid);

  const operatingModesValid = await operatingModesRef.value.validateOperatingModes();
  console.log('operatingModesValid:', operatingModesValid);

  const workTypesValid = await operatingModesRef.value.validateWorkTypes();
  console.log('workTypesValid:', workTypesValid);

  const languageProficiencyValid = await validateLanguages();
  console.log('languageProficiencyValid:', languageProficiencyValid);

  const contractsValid = await validateContracts();
  console.log('contractsValid:', contractsValid);

  if (!contractsValid && isFormValid) {
    ScrollUtils.scrollToTopWithDelayAndSmooth("contracts", 0);
  }

  const questionsValid = await validateQuestions();
  console.log('questionsValid:', questionsValid);

  if (
      isFormValid &&
      skillsValid &&
      operatingModesValid &&
      languageProficiencyValid &&
      contractsValid &&
      questionsValid &&
      workTypesValid
  ) {
    console.log('All validations passed. Proceeding to the next step.');
    emit('step:next');
  } else {
    console.error('Validation failed. Cannot proceed to the next step.');
  }
}


function previousStep() {
  emit('step:previous')
}


async function validateSkills() {
  try {
    const isValid = await skillsRef.value.validate();
    if (!isValid) {
      ScrollUtils.scrollToTopWithDelayAndSmooth("skills", 0);
    }
    return isValid;
  } catch (error) {
    console.error("Validation failed:", error);
    return false;
  }
}

const languagesRef = ref(null)

async function validateLanguages() {
  if (offer.value.languages.length > 0) {
    try {
      const isValid = await languagesRef.value.validate();
      if (!isValid) {
        ScrollUtils.scrollToTopWithDelayAndSmooth("languages", 0);
      }
      return isValid;
    } catch (error) {
      console.error("Validation failed:", error);
      return false;
    }
  } else {
    return true;
  }

}

async function validateContracts() {
  if (offer.value.contracts.length > 0) {
    try {
      let hasNotDuplicates = !hasDuplicateContractTypes();
      let componentValid = await contractsComponentRef.value.validateChildComponents();
      const isValid = componentValid && hasNotDuplicates;
      if (isValid) {
        return true;
      } else {
        if (!componentValid && hasNotDuplicates) {
          ScrollUtils.scrollToTopWithDelayAndSmooth("contracts", 0);
        }
        return false;
      }
    } catch (error) {
      console.error('Error during contract validation:', error);
      NotificationService.showNotification($q, 'negative', 'Wystąpił błąd podczas walidacji umów.');
      return false;
    }
  } else {
    return true;
  }
}

function hasDuplicateContractTypes() {
  const contractTypeCount = new Map();
  for (const contract of offer.value.contracts) {
    const contractType = contract.type;
    if (contractType) {
      contractTypeCount.set(contractType, (contractTypeCount.get(contractType) || 0) + 1);
      if (contractTypeCount.get(contractType) > 1) {
        NotificationService.showNotification($q, 'negative', 'Popraw typ umowy. Jeden typ umowy zostal użyty więcej niż raz.');
        return true;
      }
    }
  }
  return false;
}


async function validateQuestions() {
  if (offer.value.questions.length > 0) {
    let newVar = await questionsRef.value.validateChildComponents();
    console.info("QUESTION VALIDATION " + newVar)
    return newVar;
  } else {
    return true;
  }
}


const isDescriptionMissing = ref(false)
const skillsRef = ref(null)

function filterAllPositions(val, update) {
  let options = jobPositionOptions()
  update(() => {
    if (val === "") {
      filteredPosition.value = jobPositionOptions()
    } else {
      let needle = val.toLowerCase();
      filteredPosition.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

const locationsOptions = ref([])

function filterAllLocations(val, update) {
  let options = props.formTemplateData.jobOffer.cities
  update(() => {
    if (val === "") {
      locationsOptions.value = options;
    } else {
      let needle = val.toLowerCase();
      locationsOptions.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}

function addCityToAddresses(city) {
  const cityExists = offer.value.locations.some(address => address.city === city);
  if (!cityExists) {
    offer.value.locations.push({city});
  }
}

function handleModelValueUpdate(val) {
  offer.value.cities = val;
  val.forEach(city => addCityToAddresses(city));
  offer.value.locations.forEach(address => {
    if (!val.includes(address.city)) {
      removeCityFromAddresses(address.city);
    }
  });
}

function removeCityFromAddresses(city) {
  offer.value.locations = offer.value.locations.filter(address => address.city !== city);
}
</script>
<style lang="scss" scoped>
@import "/src/assets/css/main.css";

.q-input {
  font-size: 12px;
}

.q-field-target {
  font-size: 12px;
  color: #581c9b;
}

.q-select {
  font-size: 12px;
}

.q-editor {
  font-size: 11px;
}

.my-stepper {
  width: 65%;
}

:deep(.q-chip) {
  font-size: 15px !important;
}
</style>

