// services/RegistrationService.js

import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";

class RegistrationService {
    constructor(url) {
        this.url = url;
    }

    async _sendPostRequest(endpoint, body) {
        try {
            const requestOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(body),
            };
            const response = await FetchWrapper.fetchWithAuth(
                this.url + endpoint,
                requestOptions
            );
            return response;
        } catch (error) {
            console.error("Error during request:", error);
            throw error;
        }
    }

    async registerApplicant(email) {
        return this._sendPostRequest("/applicants", { email });
    }

    async _sendPutRequest(endpoint, body) {
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(body),
            };
            const response = await FetchWrapper.fetchWithAuth(
                this.url + endpoint,
                requestOptions
            );
            return response;
        } catch (error) {
            console.error("Error during request:", error);
            throw error;
        }
    }

    async confirmRegistration(userId, token, password) {
        return this._sendPutRequest(
            `/applicants/${userId}/password`,
            { token, password }
        );
    }
}

export default new RegistrationService(process.env.VUE_APP_BACKEND_URL);
