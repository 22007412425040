<template>
  <q-dialog v-model="showDialog">
    <q-card bordered class="full-width q-pa-sm" style="border-radius: 9px">
      <q-card-section>
        <span
            class="q-ml-sm">Wybierz szablon, edytuj go i otwórz klienta pocztowego aby wysłać wiadomość</span>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="row q-gutter-md items-center">
          <div class="col">
            <q-select color="dark"
                      @update:model-value="val => updateTemplate(val)"
                      outlined
                      v-model="currentTemplateName"
                      :options="templateOptions"
                      label="Szablon e-mail"/>
          </div>
        </div>
      </q-card-section>
      <q-card-section>
        <q-input outlined
                 color="dark"
                 v-model="currentSubject"
                 label="Temat e-mail"/>
      </q-card-section>

      <q-card-section class="row items-center">
        <div class="full-width">
          <q-editor
              style="border-radius: 12px;overflow-x: auto;word-break: break-word;"
              class="full-width full-height q-px-md"
              v-model="currentMessage"
              toolbar-toggle-color="accent"
              min-height="200px"
              max-height="200px"
              toolbar-color="dark"
              :toolbar="[
            [],
          ]"
          />
        </div>
      </q-card-section>

      <q-card-section class="q-pt-none q-mt-xs">
        <div class="">
          <q-btn outline dense label="Otwórz klieta pocztowego" class="bg-accent text-dark q-px-md q-mr-md"
                 v-close-popup @click="sendOpenEmit()"/>
          <q-btn flat dense class="q-px-md" label="Anuluj" color="dark" v-close-popup/>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted, ref} from "vue";
import OrganizationEmailsService from "@/services/organization/emails/OrganizationEmailsService";

const props = defineProps({
  checkedAppsCount: String,
  showDialog: Boolean
})

const allTemplates = ref([])
onMounted(() => {
  OrganizationEmailsService.getAllTemplates().then(bodyArr => {
    allTemplates.value = bodyArr;
    let templateNames = bodyArr.map(template => template.name);
    let emptyTemplateName = 'Pusty szablon';
    templateOptions.value.push(emptyTemplateName)
    templateOptions.value.push(...templateNames)
    if (templateNames.length > 0) {
      currentTemplateName.value = allTemplates.value[0].name
      currentSubject.value = allTemplates.value[0].emailSubject
      currentMessage.value = allTemplates.value[0].emailMessage
    } else {
      currentTemplateName.value = emptyTemplateName
      currentSubject.value = ''
      currentMessage.value = ''
    }
  })
})

function updateTemplate(newTemplateName) {
  if (newTemplateName === 'Pusty szablon') {
    currentSubject.value = ''
    currentMessage.value = ''
  } else {
    const selectedTemplate = allTemplates.value.find(template => template.name === newTemplateName);
    if (selectedTemplate) {
      currentSubject.value = selectedTemplate.emailSubject;
      currentMessage.value = selectedTemplate.emailMessage;
    }
  }
}

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});
const emit = defineEmits(['show:mails'])

const templateOptions = ref([])
const currentTemplateName = ref(null)
const currentSubject = ref(null)
const currentMessage = ref(null)

function sendOpenEmit() {
  emit('open:mails', {
    subject: currentSubject.value,
    message: currentMessage.value
  });
}
</script>
<style lang="scss" scoped>
</style>