class StringUtils {
    isStringEmpty(str) {
        if (str === undefined || str === null) {
            return true;
        }
        const regex = /^\s*$/; // Regular expression for empty or whitespace-only string
        return regex.test(str);
    }

    htmlToPlainText(html) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.innerText || tempDiv.textContent || '';
    }
}

export default new StringUtils();