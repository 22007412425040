<template>
  <div class="q-pa-xs col row column q-mt-xs" style="max-width: 800px">
    <div class="full-width row justify-end q-pr-md q-mt-md">
      <q-btn align="right"
             @click="showCreationDialog(true)"
             label="UTWÓRZ SZABLON"
             outline
             class="bg-white text-dark"></q-btn>
    </div>
    <div
        class="full-width q-scrollarea col q-px-sm q-mb-md q-pr-md"
        style="overflow-y: auto; display: flex; justify-content: center;"
    >
      <div v-if="templates.length > 0" class=" full-width ">
        <email-template-overview
            class="q-my-lg full-width"
            v-for="(template, index) in templates"
            :key="template.id"
            @open:edit="showEditDialog(template)"
            @delete="deleteTemplate"
            v-model:template="templates[index]"
        />
      </div>
      <div v-else class="q-ma-md full-width q-mt-lg">
        <q-card bordered class="full-width">
          <q-card-section>
            <p class="q-ma-none">Brak zdefiniowanych szablonów. Aby korzystać z predefiniowaych e-maili do kandydatów,
              utwórz nowy szablon.</p>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
  <div>
    <email-template-form
        ref="addFormRef"
        v-model:dialog-show="creationDialog"
        v-model:name="name"
        v-model:subject="subject"
        v-model:message="message"
        submit-button-text="UTWÓRZ"
        @submit="addTemplate"
    />

    <email-template-form
        ref="editFormRef"
        v-model:dialog-show="editDialog"
        v-model:name="editName"
        v-model:subject="editSubject"
        v-model:message="editMessage"
        submit-button-text="EDYTUJ"
        @submit="editTemplate"
    />
  </div>
</template>
<script setup>
import EmailTemplateOverview from "@/components/organization/emails/EmailTemplateOverview";
import {onMounted, ref} from "vue";
import OrganizationEmailsService from "@/services/organization/emails/OrganizationEmailsService";
import EmailTemplateForm from "@/components/organization/emails/EmailTemplateForm";

const templates = ref([]);
const creationDialog = ref(false)
const addFormRef = ref(null)
const editFormRef = ref(null)
const editDialog = ref(false)
onMounted(() => {
  fetchAllTemplates()
});

const name = ref('');
const message = ref('');
const subject = ref('');

function fetchAllTemplates() {
  OrganizationEmailsService.getAllTemplates().then(body => {
    templates.value = body;
  });
}

function addTemplate(template) {
  addFormRef.value.validate()
  OrganizationEmailsService.addTemplate(template.name, template.subject, template.message).then(body => {
    templates.value.push(body)
    fetchAllTemplates()
    showCreationDialog(false)
  })
}

function editTemplate(template) {
  console.info(JSON.stringify(template))
  editFormRef.value.validate()
  OrganizationEmailsService.editTemplate(template.name, template.subject, template.message, editTemplateId.value).then(body => {
    templates.value.push(body)
    fetchAllTemplates()
    showCreationDialog(false)
  })
}

function showCreationDialog(val) {
  creationDialog.value = val
}

function deleteTemplate(id) {
  OrganizationEmailsService.deleteTemplate(id)
  templates.value = templates.value.filter(template => template.id !== id)
}

const editMessage = ref('');
const editName = ref('');
const editSubject = ref('');
const editTemplateId = ref('');

function showEditDialog(emailTemplate) {
  console.info(JSON.stringify(emailTemplate))
  editMessage.value = emailTemplate.emailMessage;
  editName.value = emailTemplate.name;
  editSubject.value = emailTemplate.emailSubject;
  editTemplateId.value = emailTemplate.id;
  editDialog.value = true;
}


</script>