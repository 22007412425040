<template>
  <div>
    <q-dialog v-model="showColorDialog">
      <q-card bordered class="" style="border-radius: 9px">
        <q-card-section>
          <div class="row col">
            <q-color v-model="organizationData.branding.primaryColor"
                     no-header-tabs
                     no-footer
                     class="my-picker full-width q-pa-none"/>
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none row q-gutter-md">
          <q-btn class="bg-accent text-dark q-px-md" style="border-radius: 7px" dense size="13px" outline
                 label="Wybierz"
                 @click="showColorDialog=false">
          </q-btn>
          <q-btn class="q-px-md" style="border-radius: 7px" size="13px" dense outline color="dark"
                 label="Przywróć domyślny"
                 @click="resetColor()"></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
  <div class="column">
    <!-- Wrap the form content inside q-form -->
    <q-form ref="formRef" greedy>
      <div id="basicData" class="q-my-md q-ma-sm q-mb-lg">
        <q-card flat bordered style="border-radius: 12px !important;">
          <q-card-section class="q-pa-md row  items-center">
            <div class="col q-mr-sm">
              <p class="q-ma-none q-mr-sm"><span class="text-bold text-primary">Identyfikacja wizualna firmy</span> -
                Wybierz główny kolor swojej firmy. W planach <span class="text-bold">Standard</span> i <span
                    class="text-bold">Premium</span>, karty Twoich ofert pracy z tytułem i podstawowymi informacjami
                będą
                wyświetlane w tym kolorze, co pozwoli Ci wyróżnić się na tle innych ogłoszeń.
              </p>
            </div>
            <div class=" q-gutter-md col-auto">
              <q-btn style="border-radius: 9px!important;" @click="showColorDialog=true" size="sm"
                     :style="definePrimaryColorButton()"></q-btn>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <q-card bordered flat class="q-pa-md q-ma-sm" style="border-radius: 9px" :style="calculateBackgroundGradient()">
          <div class="row">
            <div class="q-mt-sm q-ml-sm">
              <organization-data-logo v-model="organizationData"></organization-data-logo>
            </div>
            <div class="col q-ml-md">
              <div>
                <q-input class="q-pa-sm q-pb-md"
                         v-model="organizationData.name"
                         outlined
                         color="dark"
                         bg-color="white"
                         label="Nazwa firmy"
                         :rules="[  val => val.length > 0 || 'Nazwa firmy jest wymagana' ]"
                         dense>
                </q-input>
              </div>
              <div>
                <q-input class="q-pa-sm q-pb-sm" color="dark" stack-label outlined
                         v-model="organizationData.webPageUrl"
                         label="Strona firmy (opcjonalne)"
                         bg-color="white"
                         :rules="[ val => val.length <= 40 || 'Zbyt długi adres strony www' ]" dense>
                  <template v-slot:append>
                    <q-avatar>
                      <q-icon
                          name="sym_o_title"
                          size="1rem"/>
                    </q-avatar>
                  </template>
                </q-input>
              </div>
              <div class="row q-mt-sm">
                <div class="col">
                  <q-select class="col q-pa-sm"
                            :autofocus="false"
                            bg-color="white"
                            outlined
                            :rules="[  val => val !== null && val.length > 0 || 'Rodzaj firmy jest wymagany' ]"
                            color="dark"
                            stack-label
                            v-model="organizationData.type"
                            :options="organizationTypeOptions"
                            label="Rodzaj firmy"
                            dense>
                  </q-select>
                </div>
                <div class="col">
                  <q-select class="col q-pa-sm" color="dark"
                            outlined
                            stack-label
                            bg-color="white"
                            :autofocus="false"
                            :rules="[  val => val !== null && val.length > 0 || 'Rozmiar firmy jest wymagany' ]"
                            v-model="organizationData.size"
                            :options="companySizeOptions"
                            dense
                            label="Liczba pracowników">
                  </q-select>
                </div>
              </div>
            </div>
          </div>
        </q-card>
      </div>
      <div class="q-mx-sm">
        <div class="q-mt-lg q-pt-md">
          <adding-offer-section-description title="Branże"
                                            description=" - Wybierz branże, w których działa firma"
                                            :is-warning="isIMissingIndustry"/>
        </div>
        <div>
          <q-select
              ref="industriesSelection"
              class="col q-mt-lg"
              color="dark"
              stack-label
              outlined
              v-model="organizationData.industries"
              multiple
              use-chips
              bg-color="white"
              :options="OrganizationDataTranslator.translateCompanyIndustriesToPolish()"
              dense
              :popup-content-style="{ width: '250px' }"
              :rules="[val => validateIndustries(val)]"
              label="Branże"
          >
            <template v-slot:option="scope">
              <q-item clickable @click="toggleSelection(scope.opt)">
                <q-checkbox
                    v-model="scope.selected"
                    label=" "
                    @click.stop
                />
                <q-item-section>
                  {{ scope.opt }}
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
      </div>
      <div class="q-mt-lg q-mx-sm q-mb-md">
        <adding-offer-section-description title="Opis firmy"
                                          description=" - Dodaj informacje o swojej firmie, aby kandydaci mogli ją lepiej poznać"/>
      </div>
      <div class="col row q-mb-lg">
        <q-card flat bordered class="q-mt-sm q-mx-sm col row" style="border-radius: 9px">
          <q-field no-error-icon ref="fieldRef" class="col row" v-model="organizationData.description" label-slot
                   borderless dense
                   :rules="[val => val === null || val.length < 15000 || 'Zbyt długi opis - max 15000 znaków.']">
            <q-editor
                class="col q-pb-md q-mt-sm q-mx-sm text-dark full-height column"
                color="accent"
                ref="editorRef"
                toolbar-toggle-color="accent"
                stack-label
                @paste="onPasteEditor"
                style="border-radius: 9px;overflow-x: auto;word-break: break-word;font-size: 14px;border-width: 0;"
                bg-color="dark"
                min-height="13rem"
                max-height="13rem"
                v-model="organizationData.description"
                toolbar-color="dark"
                dense
                :toolbar="[
        ['bold', 'underline'],
        ['quote', 'unordered', 'ordered'],
        ['undo', 'redo']
      ]">
            </q-editor>
          </q-field>
        </q-card>
      </div>
      <div class="q-mt-lg q-mx-sm q-mb-md q-pt-lg">
        <adding-offer-section-description title="Klauzula informacyjna administratora danych osobowych"
                                          description=" - zaktualizuj lub dodaj własną klauzulę"
                                          :is-warning="isIMissingUpdatedClause"/>
      </div>
      <div id="controllerClause"
           class="col row">
        <q-card flat bordered class="q-mt-sm q-mx-sm col row" style="border-radius: 9px">
          <q-field no-error-icon ref="fieldRef" class="col row" v-model="organizationData.dataControllerClause"
                   label-slot
                   borderless dense
                   :rules="[val => validateDataControllerClause(val)]">
            <q-editor
                class="col q-pb-md q-mt-sm q-mx-sm text-dark full-height column"
                color="accent"
                ref="dataEditorRef"
                @paste="onPasteDataEditor"
                toolbar-toggle-color="accent"
                stack-label
                style="font-size: 12px; border-width: 0; border-radius: 9px;overflow-x: auto;
      word-break: break-word;"
                outlined
                bg-color="dark"
                min-height="13rem"
                max-height="13rem"
                v-model="organizationData.dataControllerClause"
                toolbar-color="dark"
                dense
                :toolbar="[
        ['bold', 'underline'],
        ['quote', 'unordered', 'ordered'],
        ['undo', 'redo']
      ]"
            >
            </q-editor>
          </q-field>
        </q-card>
      </div>
    </q-form>
  </div>
</template>

<script setup>
import OrganizationDataLogo from "@/components/organization/data/OrganizationDataLogo";
import {computed, defineEmits, defineProps, onMounted, ref, defineExpose} from "vue";
import OrganizationDataTranslator from "@/services/organization/OrganizationDataTranslator";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";
import StringUtils from "@/services/common/StringUtils";
import OrganizationDataService from "@/services/organization/OrganizationDataService";

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})

const industryOptions = ref([])
const organizationTypeOptions = ref([])
const companySizeOptions = ref([])
const industriesSelection = ref(null)

const organizationData = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {
  industryOptions.value = OrganizationDataTranslator.translateCompanyIndustriesToPolish()
  organizationTypeOptions.value = OrganizationDataTranslator.translateCompanyTypeToPolish()
  companySizeOptions.value = OrganizationDataTranslator.translateCompanySizesToPolish()
})

const editorRef = ref(null)

function onPasteEditor(evt) {
  if (evt.target.nodeName === 'INPUT') return
  let text, onPasteStripFormattingIEPaste
  evt.preventDefault()
  evt.stopPropagation()
  if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
    text = evt.originalEvent.clipboardData.getData('text/plain')
    editorRef.value.runCmd('insertText', text)
  } else if (evt.clipboardData && evt.clipboardData.getData) {
    text = evt.clipboardData.getData('text/plain')
    editorRef.value.runCmd('insertText', text)
  } else if (window.clipboardData && window.clipboardData.getData) {
    if (!onPasteStripFormattingIEPaste) {
      onPasteStripFormattingIEPaste = true
      editorRef.value.runCmd('ms-pasteTextOnly', text)
    }
    onPasteStripFormattingIEPaste = false
  }
}

const dataEditorRef = ref(null)

function onPasteDataEditor(evt) {
  if (evt.target.nodeName === 'INPUT') return
  let text, onPasteStripFormattingIEPaste
  evt.preventDefault()
  evt.stopPropagation()
  if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
    text = evt.originalEvent.clipboardData.getData('text/plain')
    dataEditorRef.value.runCmd('insertText', text)
  } else if (evt.clipboardData && evt.clipboardData.getData) {
    text = evt.clipboardData.getData('text/plain')
    dataEditorRef.value.runCmd('insertText', text)
  } else if (window.clipboardData && window.clipboardData.getData) {
    if (!onPasteStripFormattingIEPaste) {
      onPasteStripFormattingIEPaste = true
      dataEditorRef.value.runCmd('ms-pasteTextOnly', text)
    }
    onPasteStripFormattingIEPaste = false
  }
}

const showColorDialog = ref(false)
const isIMissingIndustry = ref(false)

function validateIndustries(val) {
  if (val.length > 0) {
    isIMissingIndustry.value = false
    return true;
  } else {
    isIMissingIndustry.value = true
    return 'Wybierz przynajmniej jedną branżę'
  }
}

const isIMissingUpdatedClause = ref(false)


const toggleSelection = (option) => {
  const index = organizationData.value.industries.indexOf(option);
  if (index === -1) {
    organizationData.value.industries.push(option);
  } else {
    organizationData.value.industries.splice(index, 1);
  }
  industriesSelection.value.validate()
  validateIndustries(organizationData.value.industries)
};

function calculateBackgroundGradient() {
  return {
    background: 'linear-gradient(45deg,' + getDefaultColorOrBranding() + ',' + getDefaultColorOrBranding() + ')'
  }
}

function getDefaultColorOrBranding() {
  if (organizationData.value.branding !== null) {
    return organizationData.value.branding.primaryColor
  } else {
    return "#5f449a"
  }
}

function definePrimaryColorButton() {
  return 'background:' + getDefaultColorOrBranding()
}

function resetColor() {
  organizationData.value.branding.primaryColor = "#5f449a"
}

function isClauseNotEmpty(val) {
  let plain = StringUtils.htmlToPlainText(val)
  return !StringUtils.isStringEmpty(plain)
}

const formRef = ref(null);

function validate() {
  return formRef.value.validate();
}

function validateDataControllerClause(val) {
  if (!isClauseNotEmpty(val)) {
    isIMissingUpdatedClause.value = true
    return 'Klauzula jest wymagana.';
  }

  if (OrganizationDataService.isControllerClauseToUpdate(val)) {
    isIMissingUpdatedClause.value = true
    return 'Zaktualizuj klauzulę';
  }

  if (val.length >= 4000) {
    isIMissingUpdatedClause.value = true
    return 'Zbyt długi opis - max 4000 znaków.';
  }
  isIMissingUpdatedClause.value = false
  return true;
}


// Expose the validate method to the parent component
defineExpose({validate});
</script>
