<template>
  <q-layout v-if="!isMobile()">
    <applicant-menu-drawer :active-button="'APPLICATIONS'"/>
    <q-page-container class="bg-primary">
      <q-page class="column items-center">
        <q-dialog v-model="noApplications">
          <q-card class="q-pa-sm" bordered style="width: 380px; border-radius: 9px;">
            <q-card-section class="row justify-center q-px-md q-pt-md q-pb-sm">
              <div class="text-center text-h7 text-dark">
                <div class="q-pt-none q-pb-sm q-mb-xs">
                  <q-icon size="md" name="fa-solid fa-circle-info" color="accent"></q-icon>
                </div>
                <p class="q-ma-none" style="font-size: 14px!important;">Brak aplikacji do wyświetlenia. Złóż CV na
                  wybrane oferty pracy, aby mieć wgląd do historii aplikacji.</p>
              </div>
            </q-card-section>
            <div class="q-mx-md q-my-sm">
              <q-separator></q-separator>
            </div>
            <q-card-section class="row justify-between q-mx-md q-pa-none q-pt-sm">
              <div class="">
                <q-btn @click="router.push({name: 'JobOffersMainBoard'})" style="border-radius: 7px"
                       class="q-px-sm bg-accent text-dark"
                       dense
                       size="14px"
                       outline
                       label="Zobacz oferty pracy"
                ></q-btn>
              </div>
              <div class="text-center q-mb-sm">
                <q-btn style="border-radius: 7px" class="q-px-sm"
                       dense
                       size="14px"
                       flat
                       @click="router.push({name: 'ApplicantProfilePage'})"
                       label="Powrót"
                ></q-btn>
              </div>
            </q-card-section>
          </q-card>
        </q-dialog>
        <menu-bar class="bg-gradient-menu-organization"></menu-bar>
        <q-card flat class="bg-secondary col row full-width column items-center"
                style="border-radius: 25px 0 0 0 !important;">
          <div class="col q-mt-lg row column" style="width: 800px">
            <application-filtering-and-sorting
                v-if="!noApplications"
                class="q-mb-lg q-px-md q-mt-lg"
                @change:sorting="receiveFilterOffersEvent"
                @change:values="filterApplications"></application-filtering-and-sorting>
            <div v-if="isReady && applications.length > 0"
                 class="q-scrollarea col q-mb-none"
                 style="overflow-y: auto">
              <div v-for="application in applications" :key="application.id" class="full-width q-px-md q-mb-md q-mt-sm">
                <application-overview
                    v-cloak
                    :application="application"
                    class="bg-white full-width">
                </application-overview>
              </div>
            </div>
          </div>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>
  <mobile-applicant-applications-content v-else/>
</template>


<script setup>
import {onMounted, ref} from 'vue'

import {useRouter} from "vue-router/dist/vue-router";
import MenuBar from "@/components/common/MenuBar";
import ApplicationService from "@/services/applicant/ApplicationService";
import ApplicationOverview from "@/components/applicant/applications/ApplicationOverview";
import ApplicationFilteringAndSorting from "@/components/applicant/applications/ApplicationFilteringAndSorting";
import ApplicantMenuDrawer from "@/components/applicant/common/ApplicantMenuDrawer";
import MobileApplicantApplicationsContent
  from "@/components/mobile/applicantprofile/MobileApplicantApplicationsContent";
import {useQuasar} from "quasar";

const router = useRouter()
const applications = ref([])


onMounted(async () => {
  let params = new URLSearchParams({})
  params.append('sort', "NEWEST")
  params.append('size', "30")
  await initAllApp(params)
})

const noApplications = ref(false)
const allApps = ref([])
const isReady = ref(false)

async function initAllApp(params) {
  applications.value = []
  let totalPages = 1;

  // Fetch the first page
  params.set('page', "0");
  let response = await ApplicationService.getApplications(params);
  let body = await response.json();
  totalPages = body.page.totalPages;
  if (body.page.totalElements === 0) {
    noApplications.value = true;
    return
  }
  isReady.value = true
  let allApplications = [];
  applications.value.push(...body.content)
  let promises = [];
  for (let pageNumber = 1; pageNumber < totalPages; pageNumber++) {
    let pageParams = new URLSearchParams(params);
    pageParams.set('page', pageNumber);
    let promise = ApplicationService.getApplications(pageParams)
        .then(response => response.json())
        .then(body => ({pageNumber: pageNumber, content: body.content}));
    promises.push(promise);
  }

  let results = await Promise.all(promises);
  results.forEach(result => {
    allApplications[result.pageNumber] = result.content;
  });
  applications.value.push(...allApplications.flat())
  allApps.value = applications.value;
}

function receiveFilterOffersEvent(sorting) {
  let params = new URLSearchParams({})
  params.append('sort', sorting)
  initAllApp(params)
}

function filterApplications(val) {
  applications.value = allApps.value
  if (!val) {
    applications.value = allApps.value
    return
  }

  val = val.toLowerCase();
  applications.value = applications.value.filter(application => {
    const organizationMatch = application.jobOffer.organization.name.toLowerCase().includes(val);
    const titleMatch = application.jobOffer.title.toLowerCase().includes(val);
    return organizationMatch || titleMatch;
  });
}

const $q = useQuasar();

function isMobile() {
  return $q.platform.is.mobile
}
</script>
<style lang="scss">
.q-btn--dense .on-left {
  margin-right: 22px;
  margin-left: 8px;
}
</style>