// services/OrganizationInvoiceService.js

import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";
import NotificationService from "@/services/notification/NotificationService";

class OrganizationInvoiceService {
    constructor(url) {
        this.url = url;
    }

    async getInvoices(pageNumber, sorting) {
        const params = new URLSearchParams();
        params.append("page", pageNumber);
        params.append("size", 15);
        params.append("sort", sorting || "NEWEST");

        const requestOptions = {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        };

        const url = `${process.env.VUE_APP_BACKEND_URL}/organizations/${currentUser.organizationId}/invoices?${params}`;

        try {
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching invoices:", error);
            throw error;
        }
    }

    async getInvoice(id) {
        const requestOptions = {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        };

        const url = `${process.env.VUE_APP_BACKEND_URL}/organizations/${currentUser.organizationId}/invoices/${id}`;

        try {
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return await response.json();
        } catch (error) {
            console.error("Error fetching invoice:", error);
            throw error;
        }
    }

    async downloadInvoice(id, invoiceNumber) {
        try {
            const json = await this.getInvoice(id);

            if (!json.file) {
                throw new Error("Invalid invoice data received");
            }

            const byteCharacters = atob(json.file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], {type: "application/pdf"});

            const url = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = 'Faktura-nr-' + `${invoiceNumber}.pdf`; // Use `number` as the filename, appending ".pdf"

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading invoice:", error);
            throw error;
        }
    }

    validatePolishPostalCodeAndShowNotification(organizationDataRequest, $q) {
        const billingData = organizationDataRequest.billingData;

        if (billingData !== null && billingData !== undefined) {
            const isValidPostalCode = this.validatePolishPostalCode(billingData.address.zipCode);
            if (!isValidPostalCode) {
                NotificationService.showNotification(
                    $q,
                    'negative',
                    'Niepoprawny format kodu pocztowego.'
                );
                return false;
            }
        }
        return true;
    }

    validatePolishPostalCode(zipCode) {
        const postalCodePattern = /^\d{2}-\d{3}$/;
        return postalCodePattern.test(zipCode);
    }



}

export default new OrganizationInvoiceService(process.env.VUE_APP_BACKEND_URL);
