<template>
  <menu-bar :is-menu-visible="true" class="q-pl-sm q-pr-sm"></menu-bar>
  <div class="text-center q-mt-lg q-pt-lg">
    <p class="text-h5 text-black q-mt-none" style="font-size: 23px !important;">Zarejestruj się <span
        class="text-black">
    </span> i dodaj
      ogłoszenie już od <span
          class="text-bold text-accent">0zł</span></p>
    <p class="text-h7 q-mt-md text-black q-mt-sm q-mb-sm" style="font-size: 17px!important;">Zyskaj dostęp do
      systemu
      rekrutacyjnego za darmo</p>
  </div>
  <div class="login-center" v-if="successfulPasswordSetup">
    <q-card bordered style="width: 350px;border-radius: 9px; margin-top: 95px">
      <q-card-section class="row justify-center q-pb-none">
        <div class="q-pt-none q-pb-sm">
          <q-icon size="xl" name="task_alt" color="positive"></q-icon>
        </div>
        <div class="text-center text-h7 text-grey-9">Pomyślna rejestracja - przejdź do strony logowania
        </div>
      </q-card-section>
      <q-card-section class="text-center">
        <q-btn outline padding="3px xl" type="submit" class="bg-accent text-dark" label="Ok"
               @click="router.push({ name: 'OrganizationLoginPage' });"></q-btn>
      </q-card-section>
    </q-card>
  </div>
  <div class="login-center" v-else>
    <q-card bordered class="q-px-sm" style="width: 350px; border-radius: 9px;margin-top: 95px">
      <q-card-section class="row justify-center q-pb-sm q-pt-lg">
        <div class="text-center text-h6 text-dark">Dokończ rejestrację</div>
      </q-card-section>
      <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
        <q-btn size="13px"
               class="accent-3"
               flat
               @click="router.push({ name: 'OrganizationLoginPage' });"
               label="Masz konto? - Zaloguj się"
               no-caps/>
      </div>
      <q-form @submit="submitForm()" greedy>
        <q-card-section class="q-pb-xs">
          <q-input
              dense
              outlined
              rounded
              color="dark"
              v-model="password"
              :type="isPwd ? 'password' : 'text'"
              label="Hasło"
              id="companyName"
              :rules="[PasswordValidator.validatePassword]"
          >
            <template v-slot:append>
              <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </q-card-section>
        <q-card-actions class="text-center q-pl-md q-pr-md q-pb-lg q-pt-sm" align="center">
          <q-btn padding="3px xl"
                 type="submit"
                 outline
                 class="bg-accent text-dark"
                 label="Dołącz"></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script setup>
import RegistrationService from '@/services/RegistrationService';
import MenuBar from "@/components/common/MenuBar";
import PasswordValidator from "@/services/common/security/PasswordValidator";
import {ref} from 'vue'

import {useQuasar} from 'quasar'
import {useRouter} from "vue-router";

const $q = useQuasar();
const router = useRouter()
const password = ref('')
const successfulPasswordSetup = ref(false)
const isPwd = ref(true)

async function submitForm() {
  const organizationId = router.currentRoute.value.query.organizationId;
  const userId = router.currentRoute.value.query.userId;
  const token = router.currentRoute.value.query.token;

  const response = await RegistrationService.confirmOrganizationRegistration(organizationId, userId, token, password.value);
  if (response.status === 200) {
    successfulPasswordSetup.value = true;
  } else if (response.status === 400) {
    $q.notify({
      type: 'negative',
      message: 'Link weryfikacyjny wygasł lub rejestracja została już potwierdzona.'
    })
  }
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>