class JsonBlankFieldsCleaner {
    removeEmptyFields(obj) {
        if (Array.isArray(obj)) {
            return obj
                .map(item => this.removeEmptyFields(item))
                .filter(item => {
                    if (typeof item === 'object' && item !== null) {
                        return Object.keys(item).length > 0;
                    }
                    return item !== null && item !== undefined;
                });
        } else if (typeof obj === 'object' && obj !== null) {
            for (let key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const value = obj[key];
                    if (
                        value === null ||
                        value === undefined ||
                        value === '' ||
                        (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) ||
                        (Array.isArray(value) && value.length === 0)
                    ) {
                        delete obj[key];
                    } else {
                        obj[key] = this.removeEmptyFields(value);
                    }
                }
            }
            // After processing all fields, check if the object is empty and return null to remove it.
            if (Object.keys(obj).length === 0) {
                return null;
            }
            return obj;
        }
        return obj;
    }}


export default new JsonBlankFieldsCleaner();