import {nextTick} from "vue";

class ScrollUtils {
    scrollToTop(divId) {
        const scrollArea = document.getElementById(divId);
        if (scrollArea) {
            scrollArea.scrollTo({top: 0});
        }
    }

    scrollToTopWithDelayAndSmooth(elementId, delayInMs) {
        nextTick(() => {
            setTimeout(() => {
                const element = document.getElementById(elementId);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                } else {
                    console.error("Element with ID 'topDiv' not found");
                }
            }, delayInMs);
        });
    }

    scrollToBottom(containerId) {
        const scrollArea = document.getElementById(containerId);
        if (scrollArea) {
            scrollArea.scrollTo({
                top: scrollArea.scrollHeight,
                behavior: 'smooth'
            });
        }
    }
}

export default new ScrollUtils();