<template>
  <q-layout>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        @mouseover="miniStateLeft = false"
        @mouseout="miniStateLeft = true"
        :breakpoint="500"
        :width="250"
    >
      <div>
        <div class="text-center q-mt-lg">
          <q-img fit="contain" :src="logo" style="width: 100px; height: 100px"/>
        </div>

        <div class="text-center">
          <my-text class="text-bold text-white" style="font-size: 30px;">Byte Job</my-text>
        </div>
      </div>

      <q-list class="column q-col-gutter-sm" style="margin-top: 30px">
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'ApplicantProfilePage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-account-edit"
              align="left"
              class="q-mx-md full-width text-white"
              color="primary"
              label="Mój profil"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'ApplicantApplications' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-briefcase-outline"
              align="left"
              class="q-mx-md full-width text-white"
              color="white"
              label="Rekrutacje"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="router.push({ name: 'ObservedApplicationsPage' });"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-star-box-multiple-outline"
              align="left"
              class="q-mx-lg full-width text-white bg-dark"
              color="white"
              label="Obserwowane"
              no-caps
          />
        </div>
        <div class="q-mx-md">
          <q-btn
              rounded
              @click="logout()"
              dense
              flat
              style="border-radius: 6px"
              size="15px"
              icon="mdi-logout"
              align="left"
              class="q-mx-md full-width text-white q-mt-lg"
              color="primary"
              label="Wyloguj"
              no-caps
          />
        </div>
      </q-list>
    </q-drawer>
    <q-page-container style="background-color: rgb(217,225,238);">
      <q-page class="column items-center">
        <menu-bar class="q-mb-lg"></menu-bar>
        <div class="col q-mt-lg row" style="width: 60%">
          <observed-applications ></observed-applications>
        </div>
        <account-removal-confirmation v-model:show-dialog="showAccountRemovalDialog"
                                      @remove:account="removeAccount()"></account-removal-confirmation>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script setup>
import {onMounted, ref} from 'vue'
import MenuBar from "@/components/common/MenuBar";

import {useRouter} from "vue-router/dist/vue-router";
import ObservedApplications from "@/components/applicant/observed/ObservedApplications";
import ApplicantDataService from "@/services/applicant/ApplicantDataService";
import AuthenticationService from "@/services/AuthenticationService";
import AccountRemovalConfirmation from "@/components/applicant/profile/AccountRemovalConfirmation";
import logo from '@/assets/logo.png';
import {currentUser} from "@/global-variables/currentUser"; // Adjust the path as needed

const router = useRouter()
onMounted(() => {
})

const showAccountRemovalDialog = ref(false)

function removeAccount() {
  showAccountRemovalDialog.value = false;
  let userId = currentUser.id
  ApplicantDataService.deleteAccount(userId)
      .then(() => {
        AuthenticationService.logout()
            .then(() => {
              router.push({name: 'JobOfferMainBoard'});
            });
      })
}


function logout() {
  AuthenticationService.logout()
}
</script>
<style lang="scss">
.q-btn--dense .on-left {
  margin-right: 22px;
  margin-left: 8px;
}
</style>