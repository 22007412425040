<template>
  <q-dialog v-model="showFavButtonNotAvailable">
    <q-card class="q-px-sm" bordered style="width: 250px; border-radius: 9px;">
      <q-card-section class="row justify-center q-px-none q-pt-md q-pb-md">
        <div class="text-center text-h7 text-dark">
          <div class="q-pt-none q-pb-sm q-mb-xs">
            <q-icon size="md" name="fa-solid fa-circle-info" color="accent"></q-icon>
          </div>
          <span style="font-size: 13px">Filtrowanie ulubionych ofert dostępne jest po zalogowaniu do konta.</span>
        </div>
        <q-card-section class="q-py-none">
          <div class="row text-center col items-center">
            <q-btn size="13px" flat color="accent" @click="goToLogin()"
                   label="Zaloguj się"
                   class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"
                   dense
                   no-caps/>
            <span class="q-mx-xs q-pb-sm" style="font-size: 13px; padding-top: 10px">lub</span>
            <q-btn size="13px" flat color="primary" @click="goToRegistration()"
                   label="Zarejestruj"
                   class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"
                   dense
                   no-caps/>
          </div>
        </q-card-section>
      </q-card-section>
    </q-card>
  </q-dialog>
  <div class="row full-width justify-between q-pr-none q-mb-sm q-mt-md q-pr-sm">
    <q-btn
        v-if="countActiveFilters() > 0"
        align="left"
        outline
        class="q-mr-md bg-primary text-white"
        @click="openFilters()"
    >
      <q-icon name="tune" color="white"></q-icon>
      <p v-if="!isMobile()" class="q-ma-none q-pl-sm text-white">FILTRUJ OGŁOSZENIA
        <span v-if="countActiveFilters()>0">+{{ countActiveFilters() }}</span></p>
    </q-btn>
    <q-btn
        v-else
        align="left"
        no-caps
        outline
        class="q-mr-md button-border text-dark filterButton"
        @click="openFilters()"
    >
      <q-icon name="tune" color="dark"></q-icon>
      <p v-if="!isMobile()" class="q-ma-none q-pl-sm text-dark">FILTRUJ OGŁOSZENIA
      </p>
    </q-btn>

    <q-btn
        align="left"
        class="button-border"
        outline
        :flat="isRemote"
        :class="getColorsForRemoteWorkButton()" @click="addRemoteWorkToFilter()">
      <q-icon name="fa-solid fa-house-laptop" :color="getRemoteWorkIconColor()"></q-icon>
      <p v-if="!isMobile()" class="q-ma-none q-pl-sm">PRACA ZDALNA</p>
    </q-btn>
    <q-select class="col q-px-md text-dark"
              ref="mySelect"
              v-model="all"
              label-color="dark"
              color="dark"
              :options="filteredAll"
              bg-color="white"
              standout="bg-white text-dark"
              stack-label
              :input-style="{color:'#212121'}"
              :popup-content-style="getContentStyleForSearchBar(filteredAll.length)"
              use-input
              clear-icon="cancel"
              clearable
              rounded
              outlined
              hide-hint
              menu-shrink
              multiple
              use-chips
              dense
              @clear="clearSearchValues()"
              label="Szukaj"
              transition-show="scale"
              transition-hide="scale"
              @update:model-value="(value) => addValueToFilter(value)"
              @filter="(val, update) => filterAll(val, update, allOptions)"
    >
      <template v-slot:option="{ itemProps, opt}">
        <q-item v-bind="itemProps">
          <q-item-section>
            <q-item-label v-html="opt.label"/>
          </q-item-section>
          <q-item-section side>
            <q-badge outline :color="opt.color" :label="opt.name"/>
          </q-item-section>
        </q-item>
      </template>
    </q-select>

    <q-select dense
              style="font-size: 10px; border-radius: 9px"
              transition-show="scale"
              transition-hide="scale"
              borderless
              color="accent"
              v-model="sorting"
              class="bg-white q-pl-sm"
              rounded
              :popup-content-style="{borderRadius: '0 0 9px 9px', fontSize: '12px',width: '200px' }"
              :options="sortingOptions"
              :options-dense="true"
              @update:model-value="emitFilterValues()"/>
    <q-btn
        v-if="isObserved"
        color="accent"
        outline
        @click="changeObservedValueAndEmit()"
        class="q-ml-md bg-white"
        icon="fa-solid fa-star"></q-btn>
    <q-btn
        @click="changeObservedValueAndEmit()"
        v-else
        outline
        color="dark"
        text-color="grey-5"
        class="q-ml-md bg-white" icon="fa-solid fa-star"></q-btn>
    <q-dialog v-model="card" position="left" class="full-height" @before-hide="searchUsingFilters()">
      <q-card bordered style="border-radius:0px 20px 20px 0px !important; width: 450px; border-width: 1px;">
        <q-card-section class="q-pt-md">
          <div class="q-pb-sm">
            <p style="font-size: 20px" class="q-mb-none q-pl-sm text-dark">FILTROWANIE OFERT</p>
          </div>
          <div>
            <q-separator class="q-mx-sm"></q-separator>
          </div>
          <div class="q-pt-md q-pb-md">
            <q-select class="q-px-sm q-pt-xs" color="dark"
                      ref="skillsSelect"
                      @update:model-value="hideSkillsSelectPopup()"
                      outlined
                      dense
                      v-model="skills"
                      stack-label
                      :options="filteredSkills"
                      label="Technologie"
                      popup-content-class="limiting"
                      label-color="dark"
                      use-input
                      clear-icon="cancel"
                      clearable
                      @clear="clearSkills()"
                      multiple
                      :popup-content-style="getContentStyleForFilterSelect(filteredSkills.length)"
                      use-chips transition-show="scale"
                      transition-hide="scale"
                      @filter="(val,update) => filterSkills(val,update,skillsOptions)"
            >
              <template v-slot:option="{ itemProps, opt}">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt"/>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="q-pb-md">
            <q-select class="q-px-sm q-pt-xs" color="dark" outlined
                      ref="positionSelect"
                      v-model="jobPositions"
                      :options="filteredJobPositions"
                      label-color="dark"
                      stack-label
                      @clear="clearJobPositios()"
                      use-input
                      clear-icon="cancel"
                      clearable
                      multiple
                      :virtual-scroll-item-size="5"
                      :popup-content-style="getContentStyleForFilterSelect(filteredJobPositions.length)"
                      use-chips
                      input-debounce="0"
                      dense
                      label="Stanowisko"
                      popup-content-class="limiting"
                      transition-show="scale"
                      transition-hide="scale"
                      @update:model-value="hidePositionSelectPopup()"
                      @filter="(val, update) => filterPositions(val, update, jobPositionsOptions)"
            >
              <template v-slot:option="{ itemProps, opt}">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt"/>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div>
            <q-select class="q-px-sm q-pt-xs" color="dark" outlined
                      ref="locationsSelect"
                      @update:model-value="hideLocationsSelectPopup()"
                      v-model="localizations"
                      :options="filteredLocalizations"
                      label-color="dark"
                      stack-label
                      use-input
                      dense
                      label="Lokalizacja"
                      use-chips
                      @clear="clearLocations()"
                      clear-icon="cancel"
                      clearable
                      popup-content-class="limiting"
                      multiple
                      :popup-content-style="getContentStyleForFilterSelect(filteredLocalizations.length)"
                      transition-show="scale"
                      transition-hide="scale"
                      @filter="(val,update) => filterLocalizations(val,update,localizationOptions)"
            >
              <template v-slot:option="{ itemProps, opt}">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt"/>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="q-px-sm q-pt-md q-pb-sm">
            <p class="q-mb-none text-dark">Wynagrodzenie</p>
          </div>
          <div class="q-px-md q-pt-lg">
            <q-range
                v-model="range"
                :inner-min="0"
                color="dark"
                label-always
                :left-label-value="range.min + ' zł'"
                :right-label-value="evaluateRightSalaryLabel()"
                :min="0"
                :popup-content-style="{ maxHeight: '200px',height: '200px', borderRadius: '0 0 9px 9px',fontSize: '12px' }"
                style="max-width: 98%"
                :step="1000"
                :max="40000"
                :inner-max="41000"
                drag-rang
            />
          </div>
          <div class="q-px-sm q-pt-xs">
            <p class="q-mb-none text-dark">Doświadczenie</p>
            <div class="q-pt-sm full-width">
              <q-btn-group outline style="border-radius: 9px;" :v-model="experience" class="full-width">
                <q-btn outline
                       @click="setInternTo(false)"
                       v-if="isIntern" style="font-size: 10px; border-radius: 9px 0 0 9px!important;"
                       class="text-black bg-grey-4 q-px-sm col"
                       label="Staż"/>
                <q-btn outline
                       @click="setInternTo(true)"
                       v-else style="font-size: 10px; border-radius: 9px 0 0 9px!important;"
                       class="text-grey-6 q-px-sm col"
                       label="Staż"/>
                <q-btn outline
                       @click="setJuniorTo(false)"
                       v-if="isJunior"
                       style="font-size: 10px; border-radius: 0px!important;"
                       class="text-black bg-grey-4 q-px-sm col"
                       label="Junior"/>
                <q-btn outline
                       @click="setJuniorTo(true)"
                       v-else
                       style="font-size: 10px; border-radius: 0px!important;"
                       class="text-grey-6 q-px-sm col"
                       label="Junior"/>
                <q-btn outline
                       @click="setMidTo(false)"
                       v-if="isMid" style="font-size: 10px; border-radius: 0px!important;"
                       class="text-black bg-grey-4 q-px-sm col"
                       label="Mid"/>
                <q-btn outline
                       @click="setMidTo(true)"
                       v-else
                       style="font-size: 10px; border-radius: 0px!important;"
                       class="text-grey-6 q-px-sm col"
                       label="Mid"/>
                <q-btn outline
                       @click="setSeniorTo(false)"
                       v-if="isSenior"
                       style="font-size: 10px; border-radius: 0px!important;"
                       class="text-black bg-grey-4 q-px-sm col"
                       label="Senior"/>
                <q-btn outline
                       @click="setSeniorTo(true)"
                       v-else style="font-size: 10px; border-radius: 0px!important;"
                       class="text-grey-6 q-px-sm col"
                       label="Senior"/>
                <q-btn outline
                       @click="setArchitectTo(false)"
                       v-if="isArchitect"
                       style="font-size: 10px; border-radius: 0px 9px 9px 0px!important;"
                       class="text-black bg-grey-4 q-px-sm col" label="Architekt"/>
                <q-btn outline
                       @click="setArchitectTo(true)"
                       v-else
                       style="font-size: 10px; border-radius: 0px 9px 9px 0px!important;"
                       class="text-grey-6 q-px-sm col"
                       label="Architekt"/>
              </q-btn-group>
            </div>
          </div>
          <div class="q-px-sm q-pt-xs">
            <p class="q-mb-none text-dark">Tryb pracy</p>
            <div class="q-pt-sm full-width">
              <q-btn-group style="border-radius: 9px;" outline :v-model="experience" class="full-width">
                <q-btn outline @click="setRemoteTo(false)" v-if="isRemote"
                       style="font-size: 10px;border-radius: 9px 0 0 9px!important;"
                       class="full-width ext-black bg-grey-4"
                       label="Zdalna"/>
                <q-btn outline @click="setRemoteTo(true)" v-else
                       style="font-size: 10px;border-radius: 9px 0 0 9px!important;" class="full-width text-grey-6"
                       label="Zdalna"/>
                <q-btn outline @click="setIsHybrid(false)" v-if="isHybrid"
                       style="font-size: 10px;border-radius: 0px!important;"
                       class="full-width ext-black bg-grey-4" label="Hybrydowa"/>
                <q-btn outline @click="setIsHybrid(true)" v-else style="font-size: 10px;border-radius: 0px!important;"
                       class="text-grey-6 full-width"
                       label="Hybrydowa"/>
                <q-btn outline @click="setInOffice(false)" v-if="isInOffice"
                       style="font-size: 10px;border-radius: 0px 9px 9px 0px!important;"
                       class="text-black bg-grey-4 full-width" label="Stacjonarna"/>
                <q-btn outline @click="setInOffice(true)" v-else
                       style="font-size: 10px;border-radius: 0px 9px 9px 0px!important;"
                       class="text-grey-6 full-width"
                       label="Stacjonarna"/>
              </q-btn-group>
            </div>
          </div>

          <div class="q-px-sm q-pt-xs">
            <p class="q-mb-none text-dark">Zatrudnienie</p>
            <div class="q-pt-sm full-width">
              <q-btn-group style="border-radius: 9px;" outline :v-model="experience" class="full-width">
                <q-btn outline @click="setFullTime(false)" v-if="isFullTime"
                       style="font-size: 10px; border-radius: 9px 0 0 9px!important;"
                       class="full-width ext-black bg-grey-4"
                       label="Pełny etat"/>
                <q-btn outline @click="setFullTime(true)" v-else
                       style="font-size: 10px; border-radius: 9px 0 0 9px!important;"
                       class="full-width text-grey-6"
                       label="Pełny etat"/>
                <q-btn outline @click="setPartTime(false)" v-if="isPartTime"
                       style="font-size: 10px; border-radius: 0px 9px 9px 0px!important;"
                       class="full-width text-black bg-grey-4" label="Część etatu"/>
                <q-btn outline @click="setPartTime(true)" v-else
                       style="font-size: 10px; border-radius: 0px 9px 9px 0px!important;"
                       class="text-grey-6 full-width"
                       label="Część etatu"/>
              </q-btn-group>
            </div>

          </div>

          <div class="q-px-sm q-pt-xs">
            <p class="q-mb-none text-dark">Typ umowy</p>
            <div class="q-pt-sm full-width">
              <q-btn-group outline style="border-radius: 9px;" :v-model="experience" class="full-width">
                <q-btn outline @click="setB2BTo(false)" v-if="isB2B"
                       style="font-size: 10px; border-radius: 9px 0 0 9px!important;"
                       class="full-width text-black bg-grey-4"
                       label="B2B"/>
                <q-btn outline @click="setB2BTo(true)" v-else
                       style="font-size: 10px; border-radius: 9px 0 0 9px!important;"
                       class="full-width text-grey-6"
                       label="B2B"/>
                <q-btn outline @click="setUOPTo(false)" v-if="isUOP"
                       style="font-size: 10px; border-radius: 0px!important;"
                       class="full-width text-black bg-grey-4" label="UoP"/>
                <q-btn outline @click="setUOPTo(true)" v-else style="font-size: 10px; border-radius: 0px!important;"
                       class="text-grey-6 full-width"
                       label="UoP"/>
                <q-btn outline @click="setZlecenieTo(false)" v-if="isZlecenie"
                       style="font-size: 10px; border-radius: 0px 9px 9px 0px!important;"
                       class="text-black bg-grey-4 full-width" label="Zlecenie"/>
                <q-btn outline @click="setZlecenieTo(true)" v-else
                       style="font-size: 10px; border-radius: 0px 9px 9px 0px!important;"
                       class="text-grey-6 full-width"
                       label="Zlecenie"/>
              </q-btn-group>
            </div>

          </div>
        </q-card-section>
        <q-card-section class="q-pt-none q-pb-lg">
          <div class="row q-pt-sm justify-between q-px-sm q-pb-none">
            <q-btn size="md" color="dark" outline label="Resetuj filtry" @click="resetFiltersAndEmitEvent()"
                   style="border-radius: 12px !important;"/>
            <q-btn size="md" class="text-dark bg-accent" outline label="Szukaj" @click="searchUsingFilters()"
                   style="border-radius: 12px !important;"/>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script setup>
import {defineEmits, defineExpose, onMounted, ref} from 'vue'
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";
import {useQuasar} from 'quasar'
import {currentUser} from "@/global-variables/currentUser";
import {useRouter} from "vue-router/dist/vue-router";

const card = ref(false)
const formTemplateData = ref(null)

const emit = defineEmits(['filterValues', 'observed'])
const jobPositions = ref([])
const jobPositionsOptions = ref([])
const skillsOptions = ref([])
const localizations = ref([])
const localizationOptions = ref([])
const skills = ref([])
const all = ref([])
const allOptions = ref([])
const isObserved = ref(false)
const showFavButtonNotAvailable = ref(false)
const mySelect = ref(null)
const positionSelect = ref(null)
const skillsSelect = ref(null)
const locationsSelect = ref(null)
onMounted(() => {
  getFormtTemplateData().then(data => {
    formTemplateData.value = data;
    allOptions.value = initializeAllOptions();
    jobPositionsOptions.value = formTemplateData.value.jobOffer.jobPositions.map(jobPosition => JobPositionTranslator.translateConstant(jobPosition));
    skillsOptions.value = formTemplateData.value.jobOffer.skills.map(skill => skill.name);
    localizationOptions.value = formTemplateData.value.jobOffer.cities
  });
})

function resetFiltersAndEmitEvent() {
  resetAllFilters()
  // emitFilterValues()
}

function initializeAllOptions() {
  const skillsOptions = formTemplateData.value.jobOffer.skills.map(skill => ({
    label: skill.name,
    name: 'Technologia',
    color: 'accent'
  }));

  const seniorityLevels = translateSeniorityLevelsToPolish(formTemplateData.value.jobOffer.seniorityLevels).map(seniority => ({
    label: seniority,
    name: 'Doświadczenie',
    color: 'blue-8'
  }));

  const jobPositionsOptions = formTemplateData.value.jobOffer.jobPositions.map(jobPosition => ({
    label: JobPositionTranslator.translateConstant(jobPosition),
    name: 'Stanowisko',
    color: 'primary'
  }));

  const localizationOptions = formTemplateData.value.jobOffer.cities.map(city => ({
    label: city,
    name: 'Lokalizacja',
    color: 'blue-grey-5'
  }));

  const contractTypes = translateContractTypesToPolish(formTemplateData.value.jobOffer.contractTypes).map(type => ({
    label: type,
    name: 'Typ umowy',
    color: 'red-4'
  }));

  const operatingModes = translateOperatingModesToPolish(formTemplateData.value.jobOffer.operatingModes).map(mode => ({
    label: mode,
    name: 'Tryb pracy',
    color: 'purple-5'
  }));

  const allOptions = [
    ...skillsOptions,
    ...jobPositionsOptions,
    ...seniorityLevels,
    ...contractTypes,
    ...operatingModes,
    ...localizationOptions]
  return allOptions;
}

function changeObservedValueAndEmit() {
  if (currentUser.isAuthenticated()) {
    if (isObserved.value) {
      isObserved.value = false;
      emitFilterValues()
    } else {
      isObserved.value = true;
      emitFilterValues()
    }
  } else {
    showFavButtonNotAvailable.value = true;
  }
}

async function getFormtTemplateData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
  };
  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          return data
        }).catch((err) => {
          console.log(err);
        })
      })
}

const experience = ref([])

const isIntern = ref(false)
const isJunior = ref(false)
const isMid = ref(false)
const isSenior = ref(false)
const isArchitect = ref(false)

function clearSkills(){
  skills.value = []
}

function clearJobPositios(){
  jobPositions.value = []
}

function clearLocations(){
  localizations.value = []
}

function setInternTo(boolean) {
  isIntern.value = boolean;
}

function setJuniorTo(boolean) {
  isJunior.value = boolean;
}

function setMidTo(boolean) {
  isMid.value = boolean;
}

function setSeniorTo(boolean) {
  isSenior.value = boolean;
}

function setArchitectTo(boolean) {
  isArchitect.value = boolean;
}

const isB2B = ref(false)
const isUOP = ref(false)
const isZlecenie = ref(false)

function setB2BTo(boolean) {
  isB2B.value = boolean;
}

function setUOPTo(boolean) {
  isUOP.value = boolean;
}

function setZlecenieTo(boolean) {
  isZlecenie.value = boolean;
}

const range = ref({
  min: 0,
  max: 40000
})

function evaluateRightSalaryLabel() {
  if (range.value.max >= 40000) {
    return range.value.max + '+ zł'
  } else {
    return range.value.max + ' zł'
  }
}

const filteredJobPositions = ref([])

function filterPositions(val, update, options) {
  filter(val, update, options, filteredJobPositions)
}

function filter(val, update, options, filtered) {
  update(() => {
    if (val === "") {
      filtered.value = options
    } else {
      const needle = val.toLowerCase()
      filtered.value = options.filter(
          v => v.toLowerCase().indexOf(needle) > -1
      )
    }
  })
}

const filteredSkills = ref([])

function filterSkills(val, update, options) {
  filter(val, update, options, filteredSkills)
}

const filteredLocalizations = ref([])

function filterLocalizations(val, update, options) {
  filter(val, update, options, filteredLocalizations)
}

const filteredAll = ref([])

function filterAll(val, update, options) {
  update(() => {
    if (val === "") {
      filteredAll.value = options;
    } else {
      let needle = val.toLowerCase();
      filteredAll.value = options.filter(
          v => v.label.toLowerCase().indexOf(needle) > -1
      );
    }
  });
}


function searchUsingFilters() {
  clearMapWithAllOptions()
  all.value = []
  emitFilterValues()
}

function emitFilterValues() {
  if (!isBarSearchNotUsed()) {
    emit('filterValues',
        JobPositionTranslator.getTranslatedJobPositions(optionsMap['Stanowisko'].value),
        optionsMap['Technologia'].value,
        seniorityLevelToSearchConstantForSearch(optionsMap['Doświadczenie'].value),
        jobOperatingModeToSearchConstantForSearch(optionsMap['Tryb pracy'].value),
        contractTypesToSearchConstantForSearch(optionsMap['Typ umowy'].value),
        range.value.min,
        range.value.max,
        optionsMap['Lokalizacja'].value,
        mapSortingOptionToConstant(sorting.value),
        ['PART_TIME', 'FULL_TIME'],
        isObserved.value,
    )
  } else {
    card.value = false;
    emit('filterValues',
        translatePositionsToModel(),
        skills.value,
        seniorityLevelToSearchConstantForFilter(),
        jobOperatingModeToSearchConstantForFilter(),
        contractTypesToSearchConstantForFilter(),
        range.value.min,
        range.value.max,
        localizations.value,
        mapSortingOptionToConstant(sorting.value),
        jobWorkTypesToSearchConstantForFilter(),
        isObserved.value,
    )
  }
}

function translatePositionsToModel() {
  return jobPositions.value.map(position => JobPositionTranslator.translateToModel(position))
}

//TODO expose this method to reset filters when result not found
function resetAllFilters() {
  jobPositions.value = []
  skills.value = []
  localizations.value = []
  range.value.min = 0
  range.value.max = 40000
  isIntern.value = false
  isJunior.value = false
  isMid.value = false
  isSenior.value = false
  isArchitect.value = false
  isRemote.value = false
  isHybrid.value = false
  isInOffice.value = false
  isB2B.value = false
  isUOP.value = false
  isZlecenie.value = false
  isFullTime.value = false
  isPartTime.value = false
  isObserved.value = false
  all.value = []
  clearMapWithAllOptions()
}

function resetDialogFilters() {
  jobPositions.value = []
  skills.value = []
  localizations.value = []
  range.value.min = 0
  range.value.max = 40000
  isIntern.value = false
  isJunior.value = false
  isMid.value = false
  isSenior.value = false
  isArchitect.value = false
  isHybrid.value = false
  isInOffice.value = false
  isB2B.value = false
  isUOP.value = false
  isZlecenie.value = false
  isFullTime.value = false
  isPartTime.value = false
}

function seniorityLevelToSearchConstantForFilter() {
  const constants = [];

  if (isIntern.value) {
    constants.push('TRAINEE');
  }
  if (isJunior.value) {
    constants.push('JUNIOR');
  }
  if (isMid.value) {
    constants.push('MID');
  }
  if (isSenior.value) {
    constants.push('SENIOR');
  }
  if (isArchitect.value) {
    constants.push('ARCHITECT');
  }
  return constants;
}


const isRemote = ref(false)
const isHybrid = ref(false)
const isInOffice = ref(false)

const isFullTime = ref(false)
const isPartTime = ref(false)

function setRemoteTo(boolean) {
  isRemote.value = boolean;
}

function setIsHybrid(boolean) {
  isHybrid.value = boolean;
}

function setInOffice(boolean) {
  isInOffice.value = boolean;
}

function setFullTime(boolean) {
  isFullTime.value = boolean;
}

function setPartTime(boolean) {
  isPartTime.value = boolean;
}

function jobOperatingModeToSearchConstantForFilter() {
  const constants = [];

  if (isHybrid.value) {
    constants.push('HYBRID');
  }
  if (isRemote.value) {
    constants.push('REMOTE');
  }
  if (isInOffice.value) {
    constants.push('IN_OFFICE');
  }

  return constants;
}

function jobWorkTypesToSearchConstantForFilter() {
  const constants = [];

  if (isFullTime.value) {
    constants.push('FULL_TIME');
  }
  if (isPartTime.value) {
    constants.push('PART_TIME');
  }
  return constants;
}

function contractTypesToSearchConstantForFilter() {
  const constants = [];

  if (isB2B.value) {
    constants.push('B2B');
  }
  if (isUOP.value) {
    constants.push('UOP');
  }
  if (isZlecenie.value) {
    constants.push('UZ');
  }

  return constants;
}

const sorting = ref('Domyślne');
const sortingOptions = ref([
  'Najwyższe wynagrodzenie', 'Najniższe wynagrodzenie', 'Domyślne'
])

function mapSortingOptionToConstant(sortingOption) {
  switch (sortingOption) {
    case 'Najwyższe wynagrodzenie':
      return 'HIGHEST_SALARY';
    case 'Najniższe wynagrodzenie':
      return 'LOWEST_SALARY';
    case 'Domyślne':
      return 'DEFAULT';
    case 'Najstarsze':
      return 'OLDEST';
    case 'Obserwowane':
      return 'Obserwowane';
    default:
      return 'DEFAULT'; // Or throw an error, depending on your use case
  }
}

function translateSeniorityLevelsToPolish(levels) {
  const seniorityTranslations = {
    TRAINEE: "Staż",
    JUNIOR: "Junior",
    MID: "Mid",
    SENIOR: "Senior",
    ARCHITECT: "Architekt"
  };
  return levels.map(level => seniorityTranslations[level]);
}

function translateContractTypesToPolish(types) {
  const contractTypes = {
    B2B: "B2B",
    UOP: "UoP",
    UZ: "Umowa zlecenie",
  };
  return types.map(type => contractTypes[type]);
}

function translateOperatingModesToPolish(modes) {
  const contractTypes = {
    IN_OFFICE: "Stacjonarna",
    HYBRID: "Hybrydowa",
    REMOTE: "Zdalna",
  };
  return modes.map(type => contractTypes[type]);
}


const optionsMap = {
  'Stanowisko': ref([]),
  'Technologia': ref([]),
  'Lokalizacja': ref([]),
  'Doświadczenie': ref([]),
  'Typ umowy': ref([]),
  'Tryb pracy': ref([]),
};

function isBarSearchNotUsed() {
  return Object.values(optionsMap).every(refItem => refItem.value.length === 0);
}

function addValueToFilter(values) {
  mySelect.value.hidePopup();
  resetDialogFilters()
  clearMapWithAllOptions();
  addValuesToAllOptions(values);
  emitFilterValues()
}

function addValuesToAllOptions(values) {
  if (values !== null) {
    values.forEach(obj => {
      const {name, label} = obj;
      optionsMap[name].value.push(label);
    });
  }
}

function clearMapWithAllOptions() {
  for (const key in optionsMap) {
    if (Object.prototype.hasOwnProperty.call(optionsMap, key)) {
      optionsMap[key].value = [];
    }
  }
}

function clearSearchValues() {
  clearMapWithAllOptions()
  emit('filterValues',
      JobPositionTranslator.getTranslatedJobPositions(optionsMap['Stanowisko'].value),
      optionsMap['Technologia'].value,
      seniorityLevelToSearchConstantForSearch(optionsMap['Doświadczenie'].value),
      jobOperatingModeToSearchConstantForSearch(optionsMap['Tryb pracy'].value),
      contractTypesToSearchConstantForSearch(optionsMap['Typ umowy'].value),
      range.value.min,
      range.value.max,
      optionsMap['Lokalizacja'].value,
      mapSortingOptionToConstant(sortingOptions));
}

function seniorityLevelToSearchConstantForSearch(searchValues) {
  const constants = []
  if (searchValues.includes('Staż')) {
    constants.push('TRAINEE');
  }
  if (searchValues.includes('Junior')) {
    constants.push('JUNIOR');
  }
  if (searchValues.includes('Mid')) {
    constants.push('MID');
  }
  if (searchValues.includes('Senior')) {
    constants.push('SENIOR');
  }
  if (searchValues.includes('Architekt')) {
    constants.push('ARCHITECT');
  }
  return constants;
}

function contractTypesToSearchConstantForSearch(values) {
  const constants = [];

  if (values.includes('B2B')) {
    constants.push('B2B');
  }
  if (values.includes('UoP')) {
    constants.push('UOP');
  }
  if (values.includes('Umowa zlecenie')) {
    constants.push('UZ');
  }
  return constants;
}

function jobOperatingModeToSearchConstantForSearch(values) {
  const constants = [];
  if (values.includes('Hybrydowa')) {
    constants.push('HYBRID');
  }
  if (values.includes('Zdalna')) {
    constants.push('REMOTE');
  }
  if (values.includes('Stacjonarna')) {
    constants.push('IN_OFFICE');
  }

  return constants;
}

function showFilterDialog() {
  card.value = true;
}

const $q = useQuasar()

function isMobile() {
  return $q.platform.is.mobile
}

function addRemoteWorkToFilter() {
  if (isRemote.value) {
    setRemoteTo(false)
    isHybrid.value = false
    isInOffice.value = false
  } else {
    setRemoteTo(true)
    isHybrid.value = false
    isInOffice.value = false
  }
  if (!isBarSearchNotUsed()) {
    if (isRemote.value) {
      optionsMap['Tryb pracy'].value = [];
      optionsMap['Tryb pracy'].value.push("Zdalna");
    } else {
      optionsMap['Tryb pracy'].value = [];
    }

  }
  emitFilterValues();
}

function getColorsForRemoteWorkButton() {
  if (isRemote.value) {
    return 'remote-button bg-primary text-white'
  } else {
    return 'text-dark bg-white'
  }
}

function getRemoteWorkIconColor() {
  if (isRemote.value) {
    return 'accent-3'
  } else {
    return 'dark'
  }
}

function hidePositionSelectPopup() {
  positionSelect.value.hidePopup()
}

function hideSkillsSelectPopup() {
  skillsSelect.value.hidePopup()
}

function hideLocationsSelectPopup() {
  locationsSelect.value.hidePopup()
}

defineExpose({
  showFilterDialog,
  resetAllFilters
})

function openFilters() {
  card.value = true
}

function getContentStyleForSearchBar(length) {
  if (length > 4) {
    return {width: '300px', maxHeight: '200px', height: '200px', borderRadius: '0 0 9px 9px', fontSize: '12px'};
  } else {
    return {width: '300px', borderRadius: '0 0 9px 9px', fontSize: '12px'};
  }
}

function getContentStyleForFilterSelect(length) {
  if (length > 4) {
    return {height: '200px', borderRadius: '0 0 9px 9px', fontSize: '12px'};
  } else {
    return {borderRadius: '0 0 9px 9px', fontSize: '12px'};
  }
}

function countActiveFilters() {
  let counter = 0;

  if (jobPositions?.value?.length > 0) {
    counter++;
  }
  if (skills?.value?.length > 0) {
    counter++;
  }
  if (localizations?.value?.length > 0) {
    counter++;
  }
  if (range?.value?.min > 0) {
    counter++;
  }
  if (range?.value?.max < 40000) {
    counter++;
  }
  if (isIntern?.value || isJunior?.value || isMid?.value || isSenior?.value || isArchitect?.value) {
    counter++;
  }

  if (isHybrid?.value || isInOffice?.value) {
    counter++;
  }

  if (isB2B?.value || isUOP?.value || isZlecenie?.value) {
    counter++;
  }

  if (isFullTime?.value || isPartTime?.value) {
    counter++;
  }

  return counter;
}


const router = useRouter()

function goToRegistration() {
  router.push({name: 'EmployeeRegistration'});
}

function goToLogin() {
  router.push({name: 'ApplicantLoginPage'});
}
</script>

<style lang="scss" scoped>
//to hide horizontally
//:deep(.q-select .q-field__native row) {
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  width: 100%;
//}

:deep(.q-chip) {
  font-size: 10px;
}

:deep(.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container) {
  padding-top: 10px;
}

//:deep(.q-field--outlined .q-field__control) {
//  border-radius: 9px;
//}

:deep(.q-field--outlined .q-field__control::before) {
  border-color: $primary;
}

//.q-btn-group {
//  border-radius: 5px;
//}
.button-border {
  border-radius: 9px;
  font-size: 13px;
  height: 39px;
  border-width: 1px;
  border-color: $dark-2;
}

.filterButton {
  background-color: white !important;
  border-color: $dark-2 !important;
}

.active-filter-button {
  background-color: $primary !important;
  color: white !important;
}
</style>