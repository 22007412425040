class HtmlToPlainTextParser {
    constructor() {
    }

    htmlToText(html) {
        html = html.replace(/\n/g, "");
        html = html.replace(/\t/g, "");

        // Keep HTML breaks and tabs
        html = html.replace(/<\/td>/gi, "\t");
        html = html.replace(/<\/table>/gi, "\n");
        html = html.replace(/<\/tr>/gi, "\n");
        html = html.replace(/<\/p>/gi, "\n");
        html = html.replace(/<\/div>/gi, "\n");
        html = html.replace(/<\/h[1-6]>/gi, "\n");
        html = html.replace(/<br\s*\/?>/gi, "\n");

        // Replace opening tags that should start with a newline
        html = html.replace(/<div[^>]*>/gi, "\n");
        html = html.replace(/<p[^>]*>/gi, "\n");
        html = html.replace(/<h[1-6][^>]*>/gi, "\n");

        // Replace multiple newlines with a single newline
        html = html.replace(/\n+/g, "\n");

        // Parse HTML into text
        var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
        return dom.body.textContent.trim();
    }
}

export default new HtmlToPlainTextParser(process.env.VUE_APP_BACKEND_URL);
