<template>
  <div class="full-width column" :id="componentKey">
    <new-application-watcher :job-offer-id="props.jobOfferId"
                             @applicationReceived="informAboutNewReceivedApplication()"></new-application-watcher>
    <div class="row q-pb-none  col full-width">
      <div class="col row">
        <div class="row justify-center col q-gutter-md q-pt-sm full-width">
          <div class="drop-target q-pt-sm q-mt-none column q-mb-lg col row bg-secondary">
            <q-card flat bordered style="border-color:darkgray!important; border-radius: 9px !important;"
                    class="q-mb-md bg-white row justify-between">
              <div class="row q-pt-xs q-mb-xs">
                <q-checkbox keep-color color="primary" size="35px" class="q-pl-sm q-pr-sm" dense
                            v-model="checkedNew"
                            @click="checkOrUncheckAllNew()"/>
                <my-text class="column-title text-dark">Nowe</my-text>
              </div>
              <div v-if="newReceivedAppsCounter > 0">
                <q-btn text-color="accent"
                       icon="fa-solid fa-arrows-rotate"
                       @click="refreshNewApplications()"
                       style="border-radius: 5px"
                       class="text-black q-ma-none q-pa-sm"
                       flat
                       size="xs"
                       label="Odśwież"></q-btn>
                <q-badge style="border-radius: 5px" class="q-ma-sm bg-positive"
                         :label="newReceivedAppsCounter"></q-badge>
              </div>
            </q-card>
            <div
                v-if="newApplications.length !== 0"
                v-mutation="handler1"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @dragover="onDragOver"
                style="border-radius: 9px; overflow-y: auto"
                @drop="onDrop"
                class="full-width drop-target q-pt-sm rounded-borders q-scrollarea col q-px-sm"
            >
              <div
                  v-for="application in newApplications"
                  :key="application.id"
                  :id="application.id"
                  draggable="true"
                  class="full-width"
                  @dragstart="onDragStart">
                <application
                    @check-update="uncheckAllInNewIfNeeded()"
                    :model-value="application"
                >
                </application>
              </div>
            </div>
            <div
                v-else
                v-mutation="handler1"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @dragover="onDragOver"
                style="border-radius: 9px; overflow-y: auto"
                @drop="onDrop"
                class="full-width drop-target q-pt-sm rounded-borders q-scrollarea col q-px-sm"
            >
              <q-card flat bordered class="q-mt-sm" style="border-radius: 9px">
                <q-card-section>
                  <span class="text-dark" style="font-size: 12px">Brak <span class="text-bold">nowych</span> aplikacji dla podanych kryteriów filtrowania.</span>
                </q-card-section>
              </q-card>
            </div>


          </div>
          <div class="drop-target col row q-pt-sm q-mt-none column q-mb-lg q-mr-none bg-secondary"
          >
            <q-card bordered flat style="border-color: darkgray!important; ;border-radius: 9px !important;"
                    class="q-mb-md bg-white">
              <div class="row q-pt-xs q-mb-xs">
                <q-checkbox keep-color color="primary" size="35px" class="q-pl-sm q-pr-sm" dense
                            v-model="checkedInProgress"
                            @click="checkOrUncheckAllInProgress()"/>
                <my-text class="column-title text-dark">W trakcie analizy</my-text>
              </div>
            </q-card>
            <div
                v-if="inProgressApplications.length !==0"
                v-mutation="handler2"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @dragover="onDragOver"
                @drop="onDrop"
                style="border-radius: 9px; overflow-y: auto"
                class="full-width drop-target q-pt-sm rounded-borders q-scrollarea col q-px-sm"
            >
              <div
                  v-for="jobOffer in inProgressApplications"
                  :key="jobOffer.id"
                  :id="jobOffer.id"
                  draggable="true"
                  class="full-width"
                  @dragstart="onDragStart">
                <application
                    @check-update="uncheckAllInProgressIfNeeded()"
                    :model-value="jobOffer"
                >
                </application>
              </div>
            </div>
            <div
                v-else
                v-mutation="handler1"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @dragover="onDragOver"
                style="border-radius: 9px; overflow-y: auto"
                @drop="onDrop"
                class="full-width drop-target q-pt-sm rounded-borders q-scrollarea col q-px-sm"
            >
              <q-card flat bordered class="q-mt-sm" style="border-radius: 9px">
                <q-card-section>
                  <span class="text-dark" style="font-size: 12px">Brak <span class="text-bold">przetwarzanych</span> aplikacji dla podanych kryteriów filtrowania.</span>
                </q-card-section>
              </q-card>
            </div>
          </div>

          <div class="drop-target q-pt-sm q-mt-none column q-mb-lg col row q-mr-md bg-secondary">
            <q-card bordered flat style="border-color: darkgray!important;border-radius: 9px !important;"
                    class="q-mb-md bg-white">
              <div class="row q-pt-xs q-mb-xs">
                <q-checkbox keep-color color="primary" size="35px" class="q-pl-sm q-pr-sm" dense
                            v-model="checkedDone"
                            @click="checkOrUncheckAllDone()"/>
                <my-text class="column-title text-dark">Zakończone</my-text>
              </div>
            </q-card>
            <div
                v-mutation="handler2"
                v-if="doneApplications.length !==0"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @dragover="onDragOver"
                style="border-radius: 9px; overflow-y: auto"
                @drop="onDrop"
                class="full-width drop-target q-pt-sm rounded-borders q-scrollarea col q-px-sm"
            >
              <draggable
                  v
                  v-for="jobOffer in doneApplications"
                  :key="jobOffer.id"
                  :id="jobOffer.id"
                  draggable="true"
                  class="full-width"
                  @dragstart="onDragStart">
                <application
                    @check-update="uncheckAllDoneIfNeeded()"
                    :model-value="jobOffer"
                >
                </application>
              </draggable>
            </div>
            <div
                v-else
                v-mutation="handler1"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @dragover="onDragOver"
                style="border-radius: 9px; overflow-y: auto"
                @drop="onDrop"
                class="full-width drop-target q-pt-sm rounded-borders q-scrollarea col q-px-sm"
            >
              <q-card flat bordered class="q-mt-sm" style="border-radius: 9px">
                <q-card-section>
                  <span class="text-dark" style="font-size: 12px">Brak <span class="text-bold">zakończonych</span> aplikacji dla podanych kryteriów filtrowania.</span>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineExpose, defineProps, onMounted, ref} from 'vue';
import Application from "@/components/organization/applications/Application";
import {useRouter} from "vue-router/dist/vue-router";
import OrganizationApplicationService from "@/services/OrganizationApplicationService";
import ApplicationsService from "@/services/organization/ApplicationsService";
import NewApplicationWatcher from "@/components/organization/applications/NewApplicationWatcher";
import CookieService from "@/services/common/CookieService";
import {currentUser} from "@/global-variables/currentUser";
// const ratingColor = ref('positive')
//
//
// const ratings = ref(['accepted', 'neutral','accepted'])
// const isMarked = ref(false);
// const isFav = ref(false);
const props = defineProps({
  jobOfferId: String,
  componentKey: Number,
  filterText: String,
  sortingType: String,
  resultsFilter: String,
  questionsFilterParamsDto: Object
})

const componentKey = computed({
  get: () => props.componentKey,
  set: (val) => emit('update:modelValue', val),
});

const filterText = computed({
  get: () => props.filterText,
  set: (val) => emit('filterText:modelValue', val),
});

const sortingType = computed({
  get: () => props.sortingType,
  set: (val) => emit('sortingType:modelValue', val),
});

const resultsFilter = computed({
  get: () => props.resultsFilter,
  set: (val) => emit('resultsFilter:modelValue', val),
});

const emit = defineEmits(['update:modelValue'])

const checkedNew = ref(false)
const checkedInProgress = ref(false)
const checkedDone = ref(false)
const newApplications = ref([])
const inProgressApplications = ref([])
const doneApplications = ref([])
onMounted(() => {
  async function init() {
    await initApplicationsWithFilteredText();
  }

//TODO CHECK IF V_IF NEEDED TO RENDER ALL ADDED APPLICATIONS - how now it is rendered i.e. for invoices it does not work
  init();
})


async function initApplicationsWithFilteredText() {
  await Promise.all([
    initApplications(inProgressApplications, "IN_PROGRESS"),
    initApplications(doneApplications, "DONE"),
    initApplications(newApplications, "NEW")
  ]);
}


async function initApplications(applications, status) {
  const jobOfferId = router.currentRoute.value.params.id;
  const params = {
    jobOfferId,
    status,
    size: '50',
    sort: mapSortingTypeToModel(),
    result: resultsFilter.value,
    answers: props.questionsFilterParamsDto
  };
  const jsonData = await ApplicationsService.getApplications(params);
  const {content, page} = jsonData;

  const processedApplications = processApplications(content);
  applications.value.push(...processedApplications);

  const fetchPagesPromises = [];
  for (let currentPage = 1; currentPage <= page.totalPages; currentPage++) {
    params.page = currentPage;
    fetchPagesPromises.push(
        ApplicationsService.getApplications(params)
            .then(pageData => processApplications(pageData.content))
    );
  }

  for (const pageDataPromise of fetchPagesPromises) {
    const processedPageApplications = await pageDataPromise;
    applications.value.push(...processedPageApplications);
  }
}

function processApplications(content) {
  return content.map(e => ({
    ...e,
    checked: false
  })).filter(isMatchingFiltering);
}

function mapSortingTypeToModel() {
  if (sortingType.value === 'najnowsze') {
    return 'NEWEST'
  } else {
    return 'OLDEST'
  }
}

function isMatchingFiltering(application) {
  let filterTextValue = filterText.value.toLowerCase();
  if (isNotNullOrBlank(filterTextValue)) {
    const lowerCaseApplicantName = application.applicantName.toLowerCase();
    const lowerCaseEmail = application.applicantEmail.toLowerCase();
    const lowerCaseStatus = application.status.toLowerCase();
    const lowerCaseResult = application.result.toLowerCase();
    const lowerCaseAppliedAt = application.appliedAt.toLowerCase();
    const lowerCaseNotes = application.notes ? application.notes.toLowerCase() : '';
    const lowerCaseFrom = application.preferredContactTime
        ? application.preferredContactTime.from.toLowerCase()
        : '';
    const lowerCaseTo = application.preferredContactTime
        ? application.preferredContactTime.to.toLowerCase()
        : '';

    return (
        lowerCaseApplicantName.includes(filterTextValue) ||
        lowerCaseEmail.includes(filterTextValue) ||
        lowerCaseStatus.includes(filterTextValue) ||
        lowerCaseResult.includes(filterTextValue) ||
        lowerCaseNotes.includes(filterTextValue) ||
        lowerCaseFrom.includes(filterTextValue) ||
        lowerCaseTo.includes(filterTextValue) ||
        lowerCaseAppliedAt.includes(filterTextValue));
  } else {
    return true;
  }
}

function isNotNullOrBlank(str) {
  return str !== null && str.trim() !== '';
}

const router = useRouter()

const status1 = ref([])
const status2 = ref([])

function handler1(mutationRecords) {
  status1.value = []
  for (const index in mutationRecords) {
    const record = mutationRecords[index]
    const info = `type: ${record.type}, nodes added: ${record.addedNodes.length > 0 ? 'true' : 'false'}, nodes removed: ${record.removedNodes.length > 0 ? 'true' : 'false'}, oldValue: ${record.oldValue}`
    status1.value.push(info)
  }
}


function handler2(mutationRecords) {
  status2.value = []
  for (const index in mutationRecords) {
    const record = mutationRecords[index]
    const info = `type: ${record.type}, nodes added: ${record.addedNodes.length > 0 ? 'true' : 'false'}, nodes removed: ${record.removedNodes.length > 0 ? 'true' : 'false'}, oldValue: ${record.oldValue}`
    status2.value.push(info)
  }
}

function onDragStart(e) {
  e.dataTransfer.setData('text', e.target.id)
  e.dataTransfer.dropEffect = 'move'
}


function onDragEnter(e) {
  console.log(e)
  if (e.target.draggable !== true) {
    e.target.classList.add('drag-enter')
  }
}

function onDragLeave(e) {
  e.target.classList.remove('drag-enter')
}


function onDragOver(e) {
  e.preventDefault()
}


function onDrop(e) {
  e.preventDefault();

  if (e.target.draggable === true || !e.target.closest('.drop-target')) {
    console.error("Drop target is invalid or element is draggable");
    return;
  }

  const draggedId = e.dataTransfer.getData('text');
  const draggedEl = document.getElementById(draggedId);
  if (!draggedEl) {
    console.error("Dragged element not found in DOM for ID:", draggedId);
    return;
  }

  const dropTarget = e.target.closest('.drop-target');
  if (!dropTarget) {
    console.error("Drop target not found");
    return;
  }

  if (draggedEl.parentNode === dropTarget) {
    console.log("Element already in target drop zone. No action needed.");
    dropTarget.classList.remove('drag-enter');
    return;
  }

  draggedEl.parentNode.removeChild(draggedEl);
  dropTarget.classList.remove('drag-enter');
  let toUpperCase = dropTarget.parentElement.querySelector('my-text').textContent.toUpperCase();
  let targetStatus = translateStatus(toUpperCase);
  let mergedApplications = newApplications.value.concat(inProgressApplications.value, doneApplications.value);

  let draggedApp = mergedApplications.find(app => app.id === draggedId);
  if (!draggedApp) {
    console.error("Dragged application not found in merged applications");
    return;
  }

  newApplications.value = newApplications.value.filter(app => app.id !== draggedApp.id);
  inProgressApplications.value = inProgressApplications.value.filter(app => app.id !== draggedApp.id);
  doneApplications.value = doneApplications.value.filter(app => app.id !== draggedApp.id);

  addApplicationToDropTargetCollection(targetStatus, draggedApp);


  if (doneApplications.value.length === 0) {
    checkedDone.value = false;
  }
  if (newApplications.value.length === 0) {
    checkedNew.value = false;
  }
  if (inProgressApplications.value.length === 0) {
    checkedInProgress.value = false;
  }

  checkMainCheckboxIfAllChecked(newApplications, checkedNew);
  checkMainCheckboxIfAllChecked(inProgressApplications, checkedInProgress);
  checkMainCheckboxIfAllChecked(doneApplications, checkedDone);

  if (draggedApp) {
    draggedApp.status = targetStatus;
    patchResume(draggedId, targetStatus)
        .then(() => console.log("Status updated successfully"))
        .catch(error => console.error("Failed to update status:", error));
  }
}


function checkMainCheckboxIfAllChecked(applications, checked) {
  let allChecked = true;
  if (applications.value.length === 0) {
    allChecked = false
    return
  }
  for (let i = 0; i < applications.value.length; i++) {
    if (applications.value[i].checked === false) {
      allChecked = false;
    }
  }
  checked.value = allChecked
}

function addApplicationToDropTargetCollection(targetStatus, draggedApp) {
  ApplicationsService.getApplication(draggedApp.id).then(
      body => {
        body.checked = false
        if (targetStatus === 'NEW') {
          newApplications.value.push(body)
        }
        if (targetStatus === 'IN PROGRESS') {
          inProgressApplications.value.push(body)
        }
        if (targetStatus === 'DONE') {
          doneApplications.value.push(body)
        }
      }
  )
}

function translateStatus(status) {
  if (status === "NOWE") {
    return 'NEW'
  } else if (status === "ZAKOŃCZONE") {
    return 'DONE'
  } else {
    return 'IN PROGRESS'
  }
}

function checkOrUncheckAllNew() {
  if (newApplications.value && newApplications.value.length > 0) {
    for (let i = 0; i < newApplications.value.length; i++) {
      newApplications.value[i].checked = !!checkedNew.value;
    }
  }
}

function checkOrUncheckAllInProgress() {
  if (inProgressApplications.value && inProgressApplications.value.length > 0) {
    for (let i = 0; i < inProgressApplications.value.length; i++) {
      inProgressApplications.value[i].checked = !!checkedInProgress.value;
    }
  }
}

function checkOrUncheckAllDone() {
  if (doneApplications.value && doneApplications.value.length > 0) {
    for (let i = 0; i < doneApplications.value.length; i++) {
      doneApplications.value[i].checked = !!checkedDone.value;
    }
  }
}

function uncheckAllInNewIfNeeded() {
  checkAllInNewIfNeeded(newApplications, checkedNew)
  let anyUnchecked = false;
  if (newApplications.value && newApplications.value.length > 0) {
    for (let i = 0; i < newApplications.value.length; i++) {
      if (!newApplications.value[i].checked) {
        anyUnchecked = true;
      }
    }
    if (anyUnchecked === true) {
      checkedNew.value = false
    }
  }
}

function checkAllInNewIfNeeded(applications, checked) {
  let allChecked = true;
  checked.value = true
  if (applications.value && applications.value.length > 0) {
    for (let i = 0; i < applications.value.length; i++) {
      if (!applications.value[i].checked) {
        allChecked = false;
      }
    }
    if (allChecked === false) {
      checked.value = false
    }
  }
}

function uncheckAllInProgressIfNeeded() {
  checkAllInNewIfNeeded(inProgressApplications, checkedInProgress)
  let anyUnchecked = false;
  if (inProgressApplications.value && inProgressApplications.value.length > 0) {
    for (let i = 0; i < inProgressApplications.value.length; i++) {
      if (!inProgressApplications.value[i].checked) {
        anyUnchecked = true;
      }
    }
    if (anyUnchecked === true) {
      checkedInProgress.value = false
    }
  }
}

function uncheckAllDoneIfNeeded() {
  checkAllInNewIfNeeded(doneApplications, checkedDone)
  let anyUnchecked = false;
  if (doneApplications.value && doneApplications.value.length > 0) {
    for (let i = 0; i < doneApplications.value.length; i++) {
      if (!doneApplications.value[i].checked) {
        anyUnchecked = true;
      }
    }
    if (anyUnchecked === true) {
      checkedDone.value = false
    }
  }
}

async function getCheckedNumber() {
  let counter = 0
  let mergedApplications = newApplications.value.concat(inProgressApplications.value, doneApplications.value);
  for (let i = 0; i < mergedApplications.length; i++) {
    if (mergedApplications[i].checked) {
      counter++
    }
  }
  return counter;
}

function getCheckedApplications() {
  let checkedApps = ref([])
  let mergedApplications = newApplications.value.concat(inProgressApplications.value, doneApplications.value);
  for (let i = 0; i < mergedApplications.length; i++) {
    if (mergedApplications[i].checked) {
      checkedApps.value.push(mergedApplications[i])
    }
  }
  return checkedApps;
}


async function downloadChecked() {
  let mergedApplications = newApplications.value.concat(inProgressApplications.value, doneApplications.value);
  for (let i = 0; i < mergedApplications.length; i++) {
    if (mergedApplications[i].checked) {
      await OrganizationApplicationService.downloadCV(mergedApplications[i].id)
    }
  }
}

defineExpose({
  downloadChecked,
  getCheckedNumber,
  deleteChecked,
  getCheckedApplications,
  exportSelectedMailsToCsv,
})

function deleteChecked() {
  let mergedApplications = newApplications.value.concat(inProgressApplications.value, doneApplications.value);
  let deletionPromises = [];

  for (let i = 0; i < mergedApplications.length; i++) {
    if (mergedApplications[i].checked) {
      let deletionPromise = OrganizationApplicationService.deleteApplication(mergedApplications[i].id, router.currentRoute.value.params.id);
      deletionPromises.push(deletionPromise);
    }
  }
  return Promise.all(deletionPromises);
}

async function patchResume(applicationId, status) {
  if (status === 'IN PROGRESS') {
    status = 'IN_PROGRESS'
  }
  const url = process.env.VUE_APP_BACKEND_URL + `/organizations/` + currentUser.organizationId + `/applications/${applicationId}`
  const body = {status: status};
  const options = {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN")
    },
    body: JSON.stringify(body),
  };
  return await fetch(url, options);
}

function exportSelectedMailsToCsv() {
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += "Imię i nazwisko,Email,Data aplikacji\n";
  getCheckedApplications().value.forEach(function (cv) {
    if (cv.checked) {
      csvContent += cv.applicantName + "," + cv.applicantEmail + "," + cv.appliedAt + "\n";
    }
  });
  let encodedUri = encodeURI(csvContent);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  let date = new Date();
  link.setAttribute("download", "Dane-aplikantów-" + date.toLocaleDateString() + "-" + date.toLocaleTimeString() + " " + ".csv");
  document.body.appendChild(link);
  link.click();
}

const newReceivedAppsCounter = ref(0);

function informAboutNewReceivedApplication() {
  newReceivedAppsCounter.value = newReceivedAppsCounter.value + 1;
}

function refreshNewApplications() {
  newReceivedAppsCounter.value = 0
  newApplications.value = []
  initApplications(newApplications, "NEW")
}
</script>


<style lang="scss" scoped>
.input-box {
  .q-field__control,
  .q-field__marginal {
    height: 75%;
    font-size: 16px;
  }
}

.column-title {
  font-size: 15px;
}

.drop-target {
  background-color: $dark-1;
  border-radius: 16px !important;
  //height: 100%;
  //min-width: 250px;
  //width: 30%;

  @media only screen and (max-width: 500px) {
    //height: 100px;
    width: max-content;
    //min-width: 100px;
    background-color: #eaf1f6;
  }
}

.box {
  width: 100px;
  height: 100px;
  float: left;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    width: 50px;
    height: 50px;
  }

  &:nth-child(3) {
    clear: both;
  }
}

.navy {
  background-color: navy;
}

.red {
  background-color: firebrick;
}

.green {
  background-color: darkgreen;
}

.orange {
  background-color: orange;
}

.scrollable-area {
  overflow-y: auto;
}

:deep(.q-chip--dense .q-chip__icon--left) {
  margin-left: 5px !important;
  margin-right: 7px !important;
}
</style>