import script from "./OrganizationAccounts.vue?vue&type=script&setup=true&lang=js"
export * from "./OrganizationAccounts.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QCard,QCardSection,QIcon});qInstall(script, 'directives', {ClosePopup});
