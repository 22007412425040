class PreferredContactTimeService {

    fromRangeValueToTime(value) {
        if (value !== 96) {
            const totalMinutes = value * 15;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${hours}:${minutes.toString().padStart(2, '0')}`;
        } else {
            return `23:59`;
        }
    }

    fromTimeToRangeValue(timeString) {
        const [hoursStr, minutesStr] = timeString.split(':');
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        const totalMinutes = hours * 60 + minutes;
        const value = totalMinutes / 15;
        return value;
    }

}

export default new PreferredContactTimeService();
