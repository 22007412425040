<template>
  <div class="col row column q-ma-sm">
    <q-dialog v-model="showApplicationNotAvailableForOrganizationUser">
      <q-card class="q-py-md q-px-lg " bordered>
        <q-card-section class="row justify-center q-px-none q-pt-md q-pb-md">
          <div class="text-center text-h7 text-dark">
            <div class="q-pt-none q-pb-sm q-mb-xs">
              <q-icon size="md" name="fa-solid fa-circle-info" color="dark"></q-icon>
            </div>
            <span style="font-size: 14px;font-weight: 400">Aby zaaplikować na ofertę</span>
            <div class="row">
              <div class="text-center q-pt-none q-pb-none q-mt-xs"> <!-- Add the text-center class to this div -->
                <q-btn size="13px" flat color="accent" @click="router.push({ name: 'ApplicantLoginPage' });"
                       label="Zaloguj się"
                       class="q-px-sm"
                       dense
                       no-caps/>
              </div>
              <span class="q-mx-xs" style="font-size: 12px; padding-top: 10px">lub</span>
              <q-btn size="13px" flat color="primary" @click="router.push({ name: 'EmployeeRegistration' });"
                     label="zarejestruj"
                     class="text-center q-pt-none q-pb-none q-mt-xs q-px-sm"
                     dense
                     no-caps/>
            </div>
            <p class="q-mb-none" style="font-size: 12px">używając konta aplikanta</p>

          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="provideCurrentUserMissingDataDialog">
      <q-card bordered style="border-radius: 9px">
        <q-card-section class="q-pa-sm q-px-lg">
          <div class="q-pt-none q-pb-sm q-mb-xs text-center q-mt-sm">
            <q-icon size="md" name="fa-solid fa-circle-info" color="dark"></q-icon>
          </div>
          <div class="q-mb-xs">
              <span class="q-pt-xs text-dark text-center"
                    style="font-weight: 400; font-size: 15px">Aby aplikować na oferty pracy uzupełnij dane profilu
        </span>
          </div>
          <div class="text-center q-mb-xs">
          <span class=" text-dark"
                style="font-weight: 300; font-size: 13px">{{ jobOffer.title }}
        </span>
          </div>
          <q-card-section class="text-center q-px-sm q-pt-md">
            <q-btn class="q-mr-sm bg-accent text-dark" outline padding="3px xl" label="Ok"
                   @click="toApplicantProfileWithMissingDataMsg()"></q-btn>
            <q-btn outline no-caps padding="3px xl" color="dark" label="Później"
                   @click="hideMissingInfoDialog()"></q-btn>
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>
    <div>

    </div>
  <q-card class="col row card-background" flat style="border-radius: 20px !important; " >
    <q-card-section class="q-py-none col row q-px-none">
      <q-scroll-area
          id="scrollArea"
          ref="scrollArea"
          :style="{ paddingTop: paddingTop }"
          class="col row column q-px-md q-my-md">
        <div
            class="full-width"
            :class="justifyContent"
        >
            <div class="q-pb-md q-pt-xs col row" style="border-radius: 12px!important;">
              <mobile-intro-card
                  class="q-mt-none"
                  :is-visible="true"
                  :title="props.jobOffer.title"
                  :contracts="getContracts()"
                  :company-name="props.jobOffer.organization.name"
                  :logo="props.jobOffer.organization.compressedLogoUrl"
                  :logo-in-base64="props.logoInBase64"
                  :web-page-url="props.jobOffer.organization.webPageUrl"
                  :instagram-page-url="props.jobOffer.organization.instagramPageUrl"
                  :facebook-page-url="props.jobOffer.organization.facebookPageUrl"
                  :job-position="props.jobOffer.jobPosition"
                  :seniority-level-name="props.jobOffer.seniority"
                  :operating-modes="props.jobOffer.operatingModes"
                  :work-types="props.jobOffer.workTypes"
                  :id="props.jobOffer.id"
                  :color-branding-available="props.jobOffer.colorBrandingAvailable"
                  :branding="props.jobOffer.organization.branding"
                  :is-copy-available="true"
                  :locations="props.jobOffer.locations"
              >
              </mobile-intro-card>
            </div>
            <div class="q-pb-md">
              <requirements-card class="q-pt-md" :job="props.jobOffer" :skills="props.jobOffer.skills"/>
            </div>
            <description class="q-pt-md" :description="props.jobOffer.description"></description>
            <div class="q-pb-md">
              <organization-description
                  v-if="props.jobOffer.organization.description"
                  class="q-pt-md"
                  :description="props.jobOffer.organization.description">
              </organization-description>
            </div>
            <div class="q-pb-none">
              <mobile-similar-offers v-if="showApply"
                                     class="q-pt-md"
                                     :job-offer-id="props.jobOffer.id">
              </mobile-similar-offers>
            </div>
          </div>
        </q-scroll-area>
      </q-card-section>
    </q-card>
    <div class="q-px-none q-pt-md" v-if="showApply">
      <q-card bordered
              class="q-pa-sm row justify-between items-center"
              style="border-radius: 20px!important; border-color: lightgray; border-width: 1px">
        <div class="row q-ma-xs">
          <q-btn outline
                 icon="fa-solid fa-close"
                 class="q-mr-xs q-px-sm"
                 color="dark"
                 size="md"
                 @click="closeDetailsModal()">

          </q-btn>
          <div class="items-center">
            <q-btn style="border-radius: 6px"
                   size="md"
                   align="center"
                   label="Aplikuj"
                   outline
                   class=" text-dark bg-accent q-px-sm q-ml-sm" @click="toggleUserCard()"/>
          </div>
        </div>
        <div class="q-pr-sm items-center">
          <q-chip
              square
              style="font-size: 13px!important; margin-right: 35px"
              text-color="black"
              icon="fa-regular fa-clock"
              color="white"
              class="q-ma-none bg-white text-dark q-ml-md">
            Ważna {{ daysToPublicationEnd(jobOffer.publication.publishEndAt) }} dni
          </q-chip>
        </div>
      </q-card>
    </div>
  </div>
  <q-dialog v-model="showApplicationDialog">
    <div>
      <job-application :job-offer-id="jobOffer.id" :position="jobOffer.title" :questions="jobOffer.questions"
                       @close-modal="closeModal()"></job-application>
    </div>
  </q-dialog>
  <q-dialog v-model="showApplyConfirmationDialog">
    <q-card bordered style="border-radius: 9px">
      <q-card-section class="q-pa-md q-px-lg">
        <div class="text-center q-mb-sm q-mt-xs">
          <q-icon color="accent" name="fa-regular fa-circle-check" size="50px"/>
        </div>
        <div class="q-mb-xs text-center">
              <span class="q-pt-xs text-dark "
                    style="font-weight: 400; font-size: 15px">Twoje CV zostało przesłane
        </span>
        </div>
        <div class="text-center">
          <span class=" text-dark"
                style="font-weight: 300; font-size: 13px">{{ jobOffer.title }}
        </span>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {defineEmits, defineExpose, defineProps, onMounted, ref} from 'vue';
import Description from "@/components/common/job-offers/job-offer-details/Description";
import RequirementsCard from "@/components/common/job-offers/job-offer-details/RequirementsCard";
import OrganizationDescription from "@/components/common/job-offers/job-offer-details/OrganizationDescription";
import JobApplication from "@/components/common/job-offers/applications/JobApplication";
import ScrollUtils from "@/services/common/ScrollUtils";
import MobileIntroCard from "@/components/mobile/MobileIntroCard";
import MobileSimilarOffers from "@/components/mobile/MobileSimilarOffers";
import {currentUser} from "@/global-variables/currentUser";
import {useRouter} from "vue-router/dist/vue-router";
// import ApplicationSentConfirmation from "@/components/organization/applications/ApplicationSentConfirmation";


const showApplicationDialog = ref(false);
const provideCurrentUserMissingDataDialog = ref(false);
const showApplyConfirmationDialog = ref(false)
const showApplicationNotAvailableForOrganizationUser = ref(false)

const router = useRouter()

function showAndHideApplyingConfirmationDialog() {
  showApplyConfirmationDialog.value = true;
  setTimeout(() => {
    showApplyConfirmationDialog.value = false;
  }, 2300);
}

function hideMissingInfoDialog(){
  provideCurrentUserMissingDataDialog.value = false
}

const toggleUserCard = () => {
  console.info("Toggle User Card");
  if (props.jobOffer.jobApplicationInfo.applicationType === 'EXTERNAL') {
    window.open(props.jobOffer.jobApplicationInfo.externalApplicationLink, '_blank');
  } else if (currentUser.isAuthenticated() && !currentUser.isApplicant()) {
    console.log("Showing dialog: Application not available for organization users");
    showApplicationNotAvailableForOrganizationUser.value = true;
  } else if (!currentUser.isAuthenticated() ||
      (currentUser.isAuthenticated() && currentUser.isApplicant() && currentUser.isRequiredApplicationDataProvided())) {
    console.log("Toggling application dialog");
    showApplicationDialog.value = !showApplicationDialog.value;
  } else {
    console.log("Showing missing data dialog");
    provideCurrentUserMissingDataDialog.value = true;
  }
};

function closeModal() {
  showApplicationDialog.value = false;
  showAndHideApplyingConfirmationDialog();
}

const props = defineProps({
  maxHeight: Number,
  jobOffer: Object,
  showApply: Boolean,
  logoInBase64: String,
  paddingTop: {
    type: String,
    default: '50px',
  },
  justifyContent: {
    type: String,
    default: 'justify-center',
  },
});
onMounted(() => {
})

function toApplicantProfileWithMissingDataMsg() {
  provideCurrentUserMissingDataDialog.value = false
  let message = 'Aby aplikować na oferty wymagane jest uzupełnienie profilu. Dodaj imię, naziwsko oraz przynajmniej jedno CV.'
  router.push({name: 'ApplicantProfilePage', query: {infoMessage: message}});

}

function scrollToTop() {
  // this.$refs.scrollArea.scrollTop = 0;
  ScrollUtils.scrollToTop('scrollArea')
}

function daysToPublicationEnd(publishEndAtStr) {
  // Parse the date strings to Date objects
  const publishEndAt = new Date(publishEndAtStr);
  const currentDate = new Date();
  const differenceInTime = publishEndAt - currentDate;
  return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
}

defineExpose({
  scrollToTop
})

function getContracts() {
  if (props.jobOffer.publication === null || props.jobOffer.publication === undefined) {
    return props.jobOffer.contracts;
  } else {
    return props.jobOffer.publication.contracts;
  }
}

const emit = defineEmits(['closeJobOfferDetailsDialog'])

function closeDetailsModal() {
  emit('closeJobOfferDetailsDialog')
}
</script>

<style lang="scss" scoped>
.q-chip {
  background: linear-gradient(45deg, $primary, $primary)
}

.q-footer {
  background: linear-gradient(45deg, $secondary, $primary)
}

my-text {
  color: $accent
}


.q-chip {
  margin-left: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.fixedElementTop {
  background-color: rgb(226, 231, 239);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.fixedElementBottom {
  background-color: rgb(226, 231, 239);
  //position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}


.scrollable-area {
  height: auto;
  overflow-y: auto;
}


.bg-gradient {
  background: linear-gradient(20deg, $primary, $light-blue-11); /* Use your primary and accent colors here */
  color: white; /* Text color */
}


::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-button {
  background: #ffffff
}

::-webkit-scrollbar-track-piece {
  background: #ffffff
}

::-webkit-scrollbar-thumb {
  background: #8e959a
}

.card-background {
  background: linear-gradient(30deg, $info,$primary 80%) !important; /* Use your primary and accent colors here */
  color: white !important; /* Text color */
}
</style>