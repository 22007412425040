<template>
  <q-layout>
    <organization-menu-drawer :active-button="'JOB_OFFERS'"/>
    <q-page-container style="background-color: #eaf1f6;">
      <q-page class="column col row bg-primary">
        <menu-bar class="bg-gradient-menu-organization"/>
        <q-card flat class="bg-secondary col row column" style="border-radius: 25px 0 0 0 !important;">
          <div>
            <information-dialog v-model:show-dialog="noApplicationsToSendSelectedDialog"
                                icon-name="fa-solid fa-envelope"
                                text="Wybierz aplikacje dla których chcesz wysłać wiadomość"/>
            <information-dialog v-model:show-dialog="noApplicationsToRemoveSelectedDialog"
                                icon-name="fa-solid fa-file-circle-xmark"
                                text="Wybierz aplikacje które chcesz usunąć"/>
            <information-dialog v-model:show-dialog="noApplicationsToDownloadCsvSelectedDialog"
                                icon-name="fa-solid fa-file-export"
                                text="Wybierz aplikacje dla których chcesz pobrać dane"/>
            <quesion-filter v-model:show-dialog="showQuestionFilterDialog"
                            :questions-for-filter-model="questionsForFilterModel"
                            @reset-filters="resetQuestionFilters()"
                            @questions-filter="updateQuestionFiltering()"/>
            <email-dialog-with-templates v-model:show-dialog="showMultipleEmailsDialog" @open:mails="handleOpenMails"/>

            <!--          <multiple-download-dialog-confirmation-->
            <!--              v-model:show-download-dialog="showDownloadsDialog"-->
            <!--              v-model:checked-apps-count="checkedApps"-->
            <!--              @download:applications="downloadAllCheckedCV()">-->
            <!--          </multiple-download-dialog-confirmation>-->
            <multiple-application-removal-dialog-confirmation
                v-model:show-dialog="showDeleteDialog"
                v-model:checked-apps-count="checkedApps"
                @delete:applications="deleteAllCheckedCV()">
            </multiple-application-removal-dialog-confirmation>
            <export-application-data-confirmation-dialog
                v-model:show-dialog="showExportDialog"
                v-model:checked-apps-count="checkedApps"
                @export:applications="exportSelectedMailsToCsv()">
            </export-application-data-confirmation-dialog>
          </div>
          <div class="row justify-between q-ml-sm" style="max-height: fit-content">
            <div class="row justify-start q-ma-sm items-center">
              <div>
                <q-btn clickable
                       class="bg-transparent q-px-sm"
                       size="sm"
                       text-color="grey-9"
                       no-caps
                       dense
                       flat
                       @click="router.push({ name: 'OrganizationJobOffersBoard' });"
                       label='Ogłoszenia'/>
                <q-icon color="grey-5" class="q-pt-none q-px-sm" size="10px" name="fa-solid fa-chevron-right"></q-icon>
              </div>
              <div>
                <q-btn clickable
                       size="sm"
                       flat
                       no-caps
                       dense
                       class=" text-dark bg-transparent q-px-sm"
                       @click="router.push({ name: 'OrganizationJobOfferDetailsBoard', params: {id: router.currentRoute.value.params.id}});"
                       :label='jobOfferTitle'/>
                <q-icon color="grey-5" class="q-pt-none q-px-sm" size="10px" name="fa-solid fa-chevron-right"></q-icon>
              </div>
              <div>
                <q-btn
                    class="text-primary bg-transparent q-px-sm"
                    text-color="primary"
                    flat
                    size="sm"
                    dense
                    no-caps
                    label='Przesłane aplikacje'/>
              </div>
            </div>
            <div class="row q-pb-none q-gutter-md q-mx-lg q-mt-sm">
              <div>
                <q-checkbox
                    size="lg"
                    dense
                    color="teal-14"
                    v-model="isAccepted"
                    checked-icon="o_thumb_up"
                    unchecked-icon="o_thumb_up"
                    @click="updateAcceptedFilterValue()"
                />
              </div>
              <div>
                <q-checkbox
                    size="lg"
                    dense
                    color="accent"
                    v-model="isNone"
                    checked-icon="o_live_help"
                    unchecked-icon="o_live_help"
                    @click="updateUndecidedFilterValue()"

                />
              </div>
              <div>
                <q-checkbox
                    size="lg"
                    dense
                    class="q-pr-sm"
                    color="warning"
                    v-model="isRejected"
                    checked-icon="o_thumb_down"
                    unchecked-icon="o_thumb_down"
                    @click="updateRejectedFilterValue()"
                />
              </div>

            </div>
            <div class="col">
              <q-input class="q-pr-md text-accent input-box q-my-md" color="dark" outlined
                       v-model="all"
                       label-color="dark"
                       bg-color="white"
                       popup-content-class="limiting"
                       stack-label
                       style="font-size: 5px; width: 100%"
                       use-input
                       dropdown-icon="sym_o_search"
                       clear-icon="cancel"
                       label="Filtruj dane aplikantów i notatki"
                       clearable
                       multiple
                       use-chips
                       input-debounce="0"
                       dense
                       @update:model-value="(value) => filterApplications(value)"
                       transition-show="scale"
                       transition-hide="scale"
              ></q-input>
            </div>
            <div class="col-2">
              <q-select class="q-pr-md text-accent input-box q-my-md" color="dark" outlined
                        v-model="sortingType"
                        label-color="dark"
                        bg-color="white"
                        popup-content-class="limiting"
                        stack-label
                        :options="['najnowsze','najstarsze']"
                        style="font-size: 5px;"
                        label="Sortuj"
                        dense
                        @update:model-value="(value) => updateSorting(value)"
                        transition-show="scale"
                        transition-hide="scale"
              ></q-select>
            </div>
            <div v-if="questionsForFilterModel.questions.length > 0"
                 class="q-mr-md">
              <q-btn class="q-px-md q-mt-md bg-white"
                     v-if="countActiveFilters() === 0"
                     color="dark"
                     outline
                     :disabled="questionsForFilterModel.questions.length ===0"
                     style="height: 40px; border-radius: 7px"
                     v-model="sortingType"
                     label="Filtruj pytania"
                     @click="setQuestionFilterDialogToTrue()"
                     dense
              ></q-btn>
              <q-btn class="q-px-md q-mt-md bg-primary text-white"
                     v-else
                     outline
                     :disabled="questionsForFilterModel.questions.length ===0"
                     style="height: 40px; border-radius: 7px"
                     v-model="sortingType"
                     :label="'Filtruj pytania +' + countActiveFilters()"
                     @click="setQuestionFilterDialogToTrue()"
                     dense
              ></q-btn>
            </div>
            <div class="q-mr-md items-center">
              <q-btn class="q-px-md q-mt-md bg-accent text-dark"
                     outline
                     style="height: 38px; border-radius: 7px"
                     label="Resetuj filtry"
                     @click="resetFilters()"
                     dense
              ></q-btn>
            </div>
          </div>

          <applications
              v-if="jobOfferId !==null"
              :job-offer-id="jobOfferId"
              v-model:key="applicationsComponentKey"
              v-model:filterText="filterText"
              v-model:sorting-type="sortingType"
              v-model:results-filter="resultFilterValue"
              v-model:questions-filter-params-dto="questionsFilterParamsDto"
              ref="applicationsChild"
              class="justify-center col row">
          </applications>
          <div class="row q-pl-md q-gutter-lg col-1" style="height: auto">
            <q-btn clickable square class="text-center q-my-md bg-white text-black border border-dark"
                   size="11px"
                   outline
                   style="border-radius: 12px!important;"
                   @click="downloadAllCheckedCV()"
                   label="Pobierz CV"/>

            <q-btn clickable square class="text-center q-my-md bg-white text-black border border-dark"
                   size="11px"
                   outline
                   style="border-radius: 12px!important;"
                   @click="openMultipleEmailsDialog()"
                   label="Napisz wiadomość e-mail"/>

            <q-btn clickable square class="text-center q-my-md bg-white text-black border border-dark q-py-sm"
                   color="dark"
                   size="11px"
                   outline
                   style="border-radius: 12px!important;"
                   @click="showDialogForAppsDelete()"
                   label="Usuń aplikacje"/>

            <q-btn clickable square class="text-center q-my-md bg-white text-black border border-dark"
                   size="11px"
                   outline
                   style="border-radius: 12px!important;"
                   @click="exportSelectedMailsToCsv()"
                   label="Pobierz dane aplikantów"/>
          </div>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>
  <q-dialog v-model="nothingToDownload">
    <q-card bordered class="q-pa-xs" style="border-radius: 9px">
      <q-card-section class="row items-center q-pb-sm">
        <span
            class="q-mr-md text-dark">Wybierz aplikacje dla których chcesz pobrać pliki CV
        </span>
        <q-icon class="q-pr-sm" name="fa-solid fa-file-circle-xmark" size="md" color="accent" text-color="white"/>
      </q-card-section>
      <div class="q-mx-md q-my-xs">
        <q-separator></q-separator>
      </div>
      <q-card-actions align="left">
        <q-btn dense class="bg-accent text-dark q-ml-sm" outline label="Ok" color="dark" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="openEmailDialog">
    <q-card bordered class="q-pa-sm">
      <q-card-section>
        <div>
          <p style="font-size: 18px!important;">Wiadomość e-mail do {{ currentEmail.applicantName }}</p>
        </div>
        <div>
          <p class="q-ma-none"> Temat: {{ mailData.subject }}</p>
        </div>
      </q-card-section>
      <div class="q-mx-md q-my-xs">
        <q-separator></q-separator>
      </div>
      <q-card-actions align="right">
        <q-btn dense class="q-mr-sm" flat label="Anuluj" @click="closeDialog"/>
        <q-btn dense outline class="bg-accent text-dark q-px-md" label="Otwórz klienta pocztowego" @click="openEmail"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script setup>
import {computed, onMounted, reactive, ref} from 'vue'
import MenuBar from "@/components/common/MenuBar";
import Applications from "@/components/organization/applications/Applications";
import JobOfferService from "@/services/JobOfferService";
import MultipleApplicationRemovalDialogConfirmation
  from "@/components/organization/applications/MultipleApplicationDeleteDialogConfirmation";
import {useRouter} from "vue-router/dist/vue-router";
import ExportApplicationDataConfirmationDialog
  from "@/components/organization/applications/ExportApplicationDataConfirmationDialog";
import QuesionFilter from "@/components/organization/applications/questions/QuesionFilter";
import questionFilterTranslator from "@/services/offer/questions/QuestionFilterTranslator";
import OrganizationMenuDrawer from "@/components/organization/common/OrganizationMenuDrawer";
import InformationDialog from "@/components/organization/applications/InformationDialog";
import EmailDialogWithTemplates from "@/components/organization/applications/EmailDialogWithTemplates";
import HtmlToPlainTextParser from "@/services/organization/emails/HtmlToPlainTextParser";
import StringUtils from "@/services/common/StringUtils";

const showQuestionFilterDialog = ref(false)
const noApplicationsToSendSelectedDialog = ref(false)
const noApplicationsToRemoveSelectedDialog = ref(false)
const noApplicationsToDownloadCsvSelectedDialog = ref(false)

const isRejected = ref(false)
const isNone = ref(false)
const isAccepted = ref(false)
const sortingType = ref('najnowsze')
const router = useRouter()


const jobOfferId = ref(null)
const jobOfferTitle = ref(null)
// const numberOfApplications = ref(null)
const applicationsChild = ref()
const filterText = ref('')
const questionsForFilterModel = reactive({
  questions: []
})
const questionsFilterParamsDto = ref([])

onMounted(() => {
  initPage();
})

function setQuestionFilterDialogToTrue() {
  showQuestionFilterDialog.value = true
}

function initPage() {
  JobOfferService.getJobOffer(router.currentRoute.value.params.id).then((response) => {
    response.json().then((data) => {
      let questions = []
      jobOfferTitle.value = data.title
      jobOfferId.value = data.id
      data.questions.forEach(question => {
        questions.push({
          questionId: question.id,
          question: question.question,
          answerType: question.answerType,
          operator: '=',
          search: null,

        });
      })
      questionsForFilterModel.questions = questions
    })
  });
}


function countActiveFilters() {
  return questionsForFilterModel.questions.filter(q => q.search !== null && !StringUtils.isStringEmpty(q.search)).length;
}

const resultFilterValue = ref(null)

function updateAcceptedFilterValue() {
  isRejected.value = false
  isNone.value = false
  if (isAccepted.value) {
    resultFilterValue.value = "ACCEPTED"
  } else {
    resetFilters()
  }
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function updateRejectedFilterValue() {
  isAccepted.value = false
  isNone.value = false
  if (isRejected.value) {
    resultFilterValue.value = "REJECTED"
  } else {
    resetFilters()
  }
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function updateUndecidedFilterValue() {
  isAccepted.value = false
  isRejected.value = false
  if (isNone.value) {
    resultFilterValue.value = "UNDECIDED"
  } else {
    resetFilters()
  }
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

const checkedApps = ref(0)

async function getCheckedNumbers() {
  let checkedNumber = await applicationsChild.value.getCheckedNumber();
  checkedApps.value = checkedNumber
  return checkedNumber;
}

const nothingToDownload = ref(false)

async function downloadAllCheckedCV() {
  if (await getCheckedNumbers() > 0) {
    await applicationsChild.value.downloadChecked()
  } else {
    nothingToDownload.value = true;
  }
}

const applicationsComponentKey = ref(0);
const showDeleteDialog = ref(false)
const showExportDialog = ref(false)

async function showDialogForAppsDelete() {
  if (await getCheckedNumbers() > 0) {
    showDeleteDialog.value = true
  } else {
    noApplicationsToRemoveSelectedDialog.value = true;
  }
}


const showMultipleEmailsDialog = ref(false)
//
// function handleOpenMails(mailData) {
//   const {subject, message} = mailData;
//   console.info("MAIL " + JSON.stringify(mailData))
//   let applications = applicationsChild.value.getCheckedApplications();
//   applications.value.forEach((data, index) => {
//     setTimeout(function () {
//       const mailtoURL = `mailto:${data.applicantEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
//       const link = document.createElement('a');
//       link.href = mailtoURL;
//       link.target = '_blank';
//       link.style.display = 'none';
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }, 500 * index); // Delay each email client opening by multiplying the timeout with the loop index
//   });
// }
const mailsToSend = ref([])
const openEmailDialog = ref(false)
// function handleOpenMails(mailData) {
//   const {subject, message} = mailData;
//   console.info("MAIL " + JSON.stringify(mailData));
//   let applications = applicationsChild.value.getCheckedApplications();
//   applications.value.forEach((data) => {
//     setTimeout(function () {
//       const mailtoURL = `mailto:${data.applicantEmail}?subject=${subject}&body=${message}`;
//       mailsToSend.value.push({applicantName: data.applicantName, href: mailtoURL})
//       openEmailDialog.value = true
//
//     }); // Delay each email client opening by multiplying the timeout with the loop index
//   });
// }

// const link = document.createElement('a');
// link.href = mailtoURL;
// link.target = '_blank';
// link.style.display = 'none';
// document.body.appendChild(link);
// link.click();

// const globalEmailSubject = ref('')
// const globalEmailContent = ref('')
// function openMultipleEmailClient() {
//   let checkedApps = applicationsChild.value.getCheckedApplications();
//   for (let app of checkedApps) {
//     window.location.href = `mailto:${app.applicantEmail}?subject=${globalEmailSubject.value}&body=${globalEmailContent.value}`;
//   }
// }
const currentEmailIndex = ref(0);
const mailData = ref({subject: '', message: ''});

// Function to handle opening emails
function handleOpenMails(data) {
  mailData.value = data;
  const {subject, message} = data;
  let applications = applicationsChild.value.getCheckedApplications();
  applications.value.forEach((app) => {
    const mailtoURL = `mailto:${app.applicantEmail}?subject=${encodeURIComponent(
        subject
    )}&body=${encodeURIComponent(HtmlToPlainTextParser.htmlToText(message))}`;
    mailsToSend.value.push({
      applicantName: app.applicantName,
      href: mailtoURL,
    });
  });

  if (mailsToSend.value.length > 0) {
    currentEmailIndex.value = 0;
    openEmailDialog.value = true;
  }
}

// Computed property to get the current email
const currentEmail = computed(
    () => mailsToSend.value[currentEmailIndex.value]
);

// Function to open the email client
function openEmail() {
  window.location.href = currentEmail.value.href;

  if (currentEmailIndex.value < mailsToSend.value.length - 1) {
    currentEmailIndex.value++;
  } else {
    openEmailDialog.value = false;
    resetEmailProcess();
  }
}

function closeDialog() {
  openEmailDialog.value = false;
  resetEmailProcess();
}

function resetEmailProcess() {
  mailsToSend.value = [];
  currentEmailIndex.value = 0;
}

async function deleteAllCheckedCV() {
  await applicationsChild.value.deleteChecked();
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

async function openMultipleEmailsDialog() {
  let checkedNum = await getCheckedNumbers();
  if (checkedNum > 0) {
    showMultipleEmailsDialog.value = true;
  } else {
    noApplicationsToSendSelectedDialog.value = true
  }
}

function filterApplications(value) {
  filterText.value = value
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function updateSorting(value) {
  sortingType.value = value
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

async function exportSelectedMailsToCsv() {
  if (await getCheckedNumbers() > 0) {
    applicationsChild.value.exportSelectedMailsToCsv();
  } else {
    noApplicationsToDownloadCsvSelectedDialog.value = true;
  }
}

function updateQuestionFiltering() {
  questionsFilterParamsDto.value = questionsForFilterModel.questions
      .filter(item => item.search !== null && !StringUtils.isStringEmpty(item.search))
      .map(item => {
        const newItem = {...item};
        if (newItem.operator) {
          newItem.operator = questionFilterTranslator.translateToModel(newItem.operator);
        }
        if (newItem.search === "tak") {
          newItem.search = "true"
        }
        if (newItem.search === "nie") {
          newItem.search = "false"
        }
        return newItem;
      });
  applicationsComponentKey.value = applicationsComponentKey.value + 1;

}

function resetFilters() {
  initPage()
  resultFilterValue.value = null
  unsetAllResultButtons();
  filterText.value = ""
  questionsFilterParamsDto.value = []
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function resetQuestionFilters() {
  resultFilterValue.value = null
  questionsFilterParamsDto.value = []
  applicationsComponentKey.value = applicationsComponentKey.value + 1;
}

function unsetAllResultButtons() {
  isAccepted.value = false
  isNone.value = false
  isRejected.value = false
}

</script>

<style lang="scss">

.input-box {
  .q-field__control,
  .q-field__marginal {
    border-radius: 7px;
    height: 40px;
    font-size: 16px;
  }
}

.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}

.input-box .q-field__control, .input-box .q-field__marginal {
  font-size: 13px;
}
</style>