<template>
  <q-form ref="childForm" greedy>
    <div class="row q-mb-sm q-mt-sm">
      <q-input class="col q-py-sm"
               outlined
               color="dark"
               v-model="question"
               label="Pytanie"
               stack-label
               dense
               :rules="[val => validateQuestion(val)]">
        <template v-slot:append>
          <q-avatar>
            <q-icon
                name="mdi-head-question"
                size="1rem"
            />
          </q-avatar>
        </template>
      </q-input>
      <q-checkbox
          v-model="required"
          icon="alarm"
          size="lg"
          color="accent"
          style="font-size: 13px"
          class="text-dark q-mt-none q-mx-none q-mr-sm"
          label="wymagana odpowiedź"
      />
      <q-select class="col-3 q-pa-sm" color="dark" v-model="answerType"
                :options="['Tekst','Liczba','Tak lub nie']" dense
                :rules="[val => val != null || 'Wybierz typ odpowiedzi']"
                label="Typ odpowiedzi" outlined stack-label>
        <template v-slot:append>
          <q-icon
              name="mdi-crosshairs-question"
              size="1rem"
          />
        </template>
      </q-select>
      <div v-if="props.vForKey !== 0" class="q-pa-sm">
        <q-btn color="dark" outline style="height: 40px; width: 40px; font-size: 10px"
               @click="emitRemoveContractInfo">
          <q-icon
              class="q-pa-none"
              name="sym_o_remove"
              size="2rem"
          />
        </q-btn>
      </div>
      <div v-if="props.vForKey === 0" class="q-pa-sm">
        <q-btn color="dark" style="height: 40px; width: 40px; font-size: 10px" @click="emitAddContractInfo()">
          <q-icon
              name="sym_o_add"
              size="2rem"
          />
        </q-btn>
      </div>
    </div>
  </q-form>
</template>

<script setup>
import {defineEmits, defineProps, computed, defineExpose, ref} from 'vue'
import StringUtils from "@/services/common/StringUtils";

const props = defineProps({
  formTemplateData: Object,
  vForKey: Number,
  question: String,
  answerType: String,
  required: Boolean
})

const question = computed({
  get: () => props.question,
  set: (val) => emit('update:question', val),
});

const answerType = computed({
  get: () => props.answerType,
  set: (val) => emit('update:answerType', val),
});

const required = computed({
  get: () => props.required,
  set: (val) => emit('update:required', val),
});
const emit = defineEmits(['add:question', 'update:answerType', 'update:question', 'remove:contractInfo'])

function emitAddContractInfo() {
  emit('add:question')
}

function emitRemoveContractInfo() {
  emit('remove:question', props.vForKey)
}


function validateQuestion(val){
  return !StringUtils.isStringEmpty(val) || 'Zdefiniuj pytanie';
}

defineExpose({
  validate
})

const childForm = ref(null)

function validate() {
  return childForm.value.validate().then(function(resolvedValue) {
    return resolvedValue;
  }).catch(function(error) {
    console.error('Validation error:', error);
    throw error;
  });
}

</script>