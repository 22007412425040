<template>
  <q-layout>
    <q-page-container>
      <q-page>
        <menu-bar :is-menu-visible="true" v-if="true" class="q-pl-sm q-pr-sm"></menu-bar>
        <div class="text-center q-mt-lg q-pt-lg">
          <p class="text-h5 text-black q-mt-none" style="font-size: 23px !important;">Zarejestruj się <span
              class="text-black">
    </span> i dodaj
            ogłoszenie już od <span
                class="text-bold text-accent">0zł</span></p>
          <p class="text-h7 q-mt-md text-black q-mt-sm q-mb-sm" style="font-size: 17px!important;">Zyskaj dostęp do
            systemu
            rekrutacyjnego za darmo</p>
        </div>
        <div id="topDiv" class="login-center" v-if="successfulRegistration">
          <q-card bordered style="width: 350px; border-radius: 9px; margin-top: 95px">
            <q-card-section class="row justify-center q-pb-none">
              <div class="q-pt-none q-pb-sm">
                <q-spinner-hourglass
                    color="dark"
                    size="5em"
                    :thickness="1"/>
              </div>
              <div class="text-center text-h7 text-dark q-pb-sm">Potwierdź rejestrację i ustaw hasło za pomocą
                maila
                powitającego
              </div>
            </q-card-section>
            <q-card-section class="text-center">
              <q-btn outline padding="3px xl" type="submit" class="bg-accent text-dark" label="Ok"
                     @click="toOrganizationLoginPage"></q-btn>
            </q-card-section>
          </q-card>
        </div>
        <div class="row column items-center" v-else>
          <transition>
            <q-card bordered style="border-radius: 9px; max-width: 430px; margin-top: 95px">
              <q-card-section class="row justify-center q-px-lg q-pt-lg">
                <p class="text-center text-black q-mb-none"
                   style="font-weight: 500; font-size: 18px !important;">
                  Szukaj pracowników z Byte Job
                </p>
              </q-card-section>
              <div class="text-center q-pt-none q-pb-none q-mb-md"> <!-- Add the text-center class to this div -->
                <q-btn size="12px" flat @click="router.push({ name: 'OrganizationLoginPage' });"
                       label="Masz konto? - Zaloguj się"
                       icon="login"
                       class="accent-3 q-px-md"
                       no-caps/>
              </div>
              <q-form class="q-mx-sm" @submit.prevent="submitForm" greedy>
                <q-card-section class="q-pb-none q-pt-none">
                  <q-input
                      dense
                      outlined
                      color="dark"
                      v-model="organizationName"
                      label="Nazwa firmy"
                      id="companyName"
                      :rules="[val => !!val || 'Nazwa jest wymagana']"
                  ></q-input>
                  <q-input
                      dense
                      outlined
                      v-model="email"
                      label="E-mail pracowniczy"
                      color="dark"
                      lazy-rules
                      id="email"
                      :rules="[val => EmailValidator.isEmailValid(val) || 'Niepoprawny format e-mail',val => !isBlank(val) || 'E-mail jest wymagany']"
                  ></q-input>
                  <organization-marketing-clause v-model="directMarketingConsent"></organization-marketing-clause>
                </q-card-section>
                <q-card-section class="q-pt-none q-py-xs">
                  <div class="q-mt-none text-center full-width">
                    <div>
                      <p class="q-ma-none q-mb-none text-grey-8 q-pt-xs"
                         style="margin-top: 13px; font-size: 11px!important;">
                        Rejestrując się potwierdzasz, że przeczytałeś i akceptujesz
                      </p>
                    </div>
                    <div class="items-center full-width">
                      <div class="row full-width text-center justify-center">
                        <div class="q-pt-none">
                          <q-btn no-ripple @click="openApplicantRules()"
                                 flat class="accent-3 q-ma-none q-px-xs q-mx-xs q-pt-none"
                                 style="margin-top: 2px" dense no-caps size="11px">
                            regulamin
                          </q-btn>
                        </div>
                        <p class="q-ma-none q-mb-none text-grey-8" style="margin-top: 3px; font-size: 11px!important;">
                          Byte Job oraz
                        </p>
                        <div class="q-pt-none">
                          <q-btn no-ripple
                                 @click="openPrivacyPolicy()"
                                 style="margin-top: 2px"
                                 flat class="accent-3 q-ma-none q-px-xs q-ml-xs q-pt-none" dense no-caps size="11px">
                            politykę prywatności
                          </q-btn>
                        </div>
                        <p style="margin-top: 0">.</p>
                      </div>
                    </div>
                  </div>
                </q-card-section>

                <q-card-actions class="text-center q-pl-md q-pr-md q-pb-lg" align="center">
                  <q-btn padding="3px xl"
                         type="submit"
                         outline
                         class="bg-accent text-center text-dark"
                         label="Dołącz"
                         :loading="loading"></q-btn>
                </q-card-actions>
              </q-form>
            </q-card>
          </transition>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import RegistrationService from '@/services/RegistrationService';
import MenuBar from "@/components/common/MenuBar";
import EmailValidator from "@/services/common/EmailValidator";
import OrganizationMarketingClause from "@/components/organization/registration/OrganizationMarketingClause.vue";
import {ref} from 'vue'

import {useQuasar} from 'quasar'
import {useRouter} from "vue-router";

const $q = useQuasar();

const router = useRouter()
const organizationName = ref('')
const email = ref('')

const directMarketingConsent = ref(false)

const successfulRegistration = ref(false)

const ERROR_CODES = require('/src/services/ErrorCodes.js');

async function submitForm() {
  const response = await RegistrationService.registerOrganization(organizationName.value, email.value, directMarketingConsent.value);
  if (response.status === 201) {
    successfulRegistration.value = true;
  } else if (response.status === 400) {
    const json = await response.json()
    if (json.message.includes('Validation failed on fields: [email].')) {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail jest już zarejestrowane w systemie.'
      })
    } else if (json.message.includes('email=must be a well-formed email address')) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny format adresu e-mail'
      })
    } else if (json.message.includes('Organization with that NIP already exists')) {
      $q.notify({
        type: 'negative',
        message: 'Firma o podanym numerze NIP jest już zarejestrowana w systemie.'
      })
    } else if (json.errorCode.includes(ERROR_CODES.EMAIL_ALREADY_TAKEN)) {
      $q.notify({
        type: 'negative',
        message: 'Podany adres e-mail jest już przypisany do konta - zaloguj się lub zresetuj hasło'
      })
    }
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas rejestracji - skontaktuj się z supportem.'
    })
  }
}

function isBlank(str) {
  return str === null || /^\s*$/.test(str);
}

function toOrganizationLoginPage() {
  router.push({name: 'OrganizationLoginPage'})
}

function openPrivacyPolicy() {
  window.open('https://stbytejobprodwe.blob.core.windows.net/legal/Polityka-Prywatnosci.pdf', '_blank')
}

function openApplicantRules() {
  window.open('https://stbytejobprodwe.blob.core.windows.net/legal/Regulamin-dla-Pracodawcow.pdf', '_blank')
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>