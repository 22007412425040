<template>
  <q-layout>
    <q-page-container>
      <q-page>
        <menu-bar class="q-pl-sm q-pr-sm" :is-menu-visible="true"></menu-bar>
        <div class="text-center q-mt-lg q-pt-lg q-pb-lg">
          <p class="text-h5 text-black q-mt-none" style="font-size: 23px !important;">Zaloguj się <span
              class="text-black">
    </span> i dodaj
            ogłoszenie już od <span
                class="text-bold text-accent">0zł</span></p>
          <p class="text-h7 q-mt-md text-black q-mt-sm q-mb-sm" style="font-size: 17px!important;">Zyskaj dostęp do
            systemu
            rekrutacyjnego za darmo</p>
        </div>
        <div class="login-center q-mt-lg">
          <q-card bordered style="width: 400px; border-radius: 9px;margin-top: 95px">
            <q-card-section class="row justify-center">
              <p class="text-center text-black q-mb-none q-ma-none"
                 style="font-weight: 500; font-size: 18px !important;">
                Szukaj pracowników z Byte Job
              </p>
            </q-card-section>

            <q-card-section class="q-pt-none q-pb-xs">
              <div class="q-mb-xs text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
                <q-btn size="12px" flat @click="router.push({ name: 'OrganizationRegistration' });"
                       class="accent-3"
                       no-caps
                       label="Nie masz konta? - Dołącz"/>
              </div>
            </q-card-section>

            <q-form class="q-mx-sm" @submit.prevent="login()" greedy>
              <q-card-section class="q-pb-none q-mb-none q-pt-sm">
                <q-input
                    dense
                    outlined
                    color="dark"
                    v-model="email"
                    lazy-rules
                    label="E-mail pracowniczy"
                    id="email"
                    :rules="[val => EmailValidator.isEmailValid(val) || 'Niepoprawny format e-mail',val => !isBlank(val) || 'E-mail jest wymagany']"
                ></q-input>
                <q-input
                    outlined
                    color="dark"
                    dense
                    class=" q-pb-sm"
                    v-model="password"
                    :type="isPwd ? 'password' : 'text'"
                    label="Hasło"
                    id="password"
                    :rules="[val => !!val || 'Hasło jest wymagane']"
                >
                  <template v-slot:append>
                    <q-icon
                        :name="isPwd ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                    />
                  </template>
                </q-input>
                <q-card-actions class="text-center q-pt-none" align="center">
                  <div class="text-center q-ma-none q-pa-none q-py-none"> <!-- Add the text-center class to this div -->
                    <q-btn size="11px" flat color="dark" @click="router.push({ name: 'OrganizationPasswordReset' });"
                           no-caps
                           label="Resetuj hasło"/>
                  </div>
                </q-card-actions>
              </q-card-section>
              <q-card-actions class="text-center q-pl-md q-pr-md q-pt-sm q-gutter-md q-mb-md" align="center">
                <q-btn outline style="border-radius: 7px" padding="4px xl" type="submit"
                       class="bg-accent text-dark"
                       label="Zaloguj się" :loading="loading"></q-btn>
              </q-card-actions>
            </q-form>
          </q-card>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import AuthenticationService from '@/services/AuthenticationService';
import MenuBar from "@/components/common/MenuBar";
import {useQuasar} from 'quasar'
import {ref} from 'vue'
import {useRouter} from "vue-router";
import {currentUser} from "@/global-variables/currentUser";
import EmailValidator from "@/services/common/EmailValidator";

const $q = useQuasar();
const router = useRouter()
const email = ref('')
const password = ref('')
const loading = ref(false)

function isBlank(str) {
  return str === null || /^\s*$/.test(str);
}

const isPwd = ref(true)

async function login() {
  loading.value = true;
  try {
    const response = await AuthenticationService.loginToOrganization(email.value, password.value);
    if (response.status === 201) {
      localStorage.setItem("isAuthenticated", "true")
      const json = await response.json();
      Object.assign(currentUser, json);
      let redirectPath = sessionStorage.getItem('redirectPath');
      if (redirectPath) {
        router.push(redirectPath)
        sessionStorage.removeItem('redirectPath');
      } else {
        router.push({name: 'OrganizationJobOffersBoard'});
      }
    } else if (response.status === 404) {
      $q.notify({
        type: 'negative',
        message: 'Błędny login lub hasło.'
      })
    } else if (response.status === 401) {
      const errorCode = await response.json().then(body => {
        return body.errorCode
      })
      if (errorCode === 'ACCOUNT_TEMPORARILY_LOCKED') {
        $q.notify({
          type: 'negative',
          message: 'Konto zablokowane na 5 minut - zbyt wiele błędnych prób logowania.'
        })
      } else {
        $q.notify({
          type: 'negative',
          message: 'Błędny login lub hasło.'
        })
      }
    }
  } catch (error) {
    console.error('Error during login:', error);
    throw error; // or handle error as you see fit
  } finally {
    loading.value = false;
  }
}

</script>
<style scoped lang="scss">
.login-center {
  position: absolute;
  top: 30%;
}


.login-background {
  background: linear-gradient(20deg, $info, $primary) !important; /* Use your primary and accent colors here */
  color: white !important; /* Text color */
}
</style>