<script setup>
import { watch, defineEmits, defineProps } from 'vue';
import { useEventSource } from '@vueuse/core';
import {useQuasar} from "quasar";
import {currentUser} from "@/global-variables/currentUser";

const $q = useQuasar();

const emit = defineEmits(['dataReceived']);

const props = defineProps({
  jobOfferId: {
    type: String,
    required: true
  }
});

const baseUrl = `${process.env.VUE_APP_BACKEND_URL}/organizations/${currentUser.organizationId}/applications/incoming`;
const eventSourceUrl = `${baseUrl}?jobOfferId=${props.jobOfferId}`;
console.log('EventSource URL:', eventSourceUrl);

const events = ['jobApplication'];

const options = {
  withCredentials: true,
  autoReconnect: {
    retries: 3,
    delay: 1000,
    onFailed: () => console.info('Failed to reconnect'),
  },
  immediate: true,
};

const { data, status, event, error } = useEventSource(eventSourceUrl, events, options);

watch(data, (newData) => {
  if (newData !== null) {
    $q.notify({
      type: 'positive',
      position:'bottom-left',
      message: 'Otrzymano nowe CV',
      icon: 'fa-regular fa-paper-plane'
    })
    console.info('Event received:', newData);
    emit('applicationReceived', newData); // Emit custom event when new data arrives
  }
});

watch(event, (newEvent) => {
  if (newEvent !== null) {
    console.info('Event type:', newEvent);
  }
});

watch(status, (newStatus) => {
  console.log('Connection status:', newStatus);
});

watch(error, (newError) => {
  if (newError !== null) {
    console.error('EventSource error:', newError);
  }
});
</script>
