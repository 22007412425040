<template>
  <div class="q-mt-sm q-pt-sm q-mb-md">
    <q-input class="col" color="dark" outlined
             label-color="dark"
             bg-color="white"
             dense
             stack-label
             v-model="questionFilter.search"
             label="Szukaj w odpowiedzi (opcjonalne)"
    ></q-input>
  </div>
</template>
<script setup>
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  questionFilter: Object,
})

const questionFilter = computed({
  get: () => props.questionFilter,
  set: (val) => emit('update:questionFilter', val),
});

const emit = defineEmits(['update:questionFilter'])

</script>