<template>
  <div class="justify-center q-mb-lg q-scrollarea col row column q-mt-none items-center"
       style="overflow-y: auto"
       :class="calculatedTopMarginAndPadding()"
       v-if="formTemplateData !== null">
    <div class="col q-mt-md">
      <div v-if="step === 1" class="text-center q-mb-lg">
        <p class="text-h5 text-black text-bold" style="font-size: 20px !important;">Znajdź pracownika z Byte Job. Dodaj ogłoszenie już od <span
            class="text-bold text-accent">0zł</span></p>
        <p class="text-h7 q-mt-md text-dark q-mt-sm q-mb-sm" style="font-size: 15px!important;">Zyskaj dostęp do systemu rekrutacyjnego za darmo</p>
      </div>
      <q-form @submit="showConfirmationDialogForPaymentIfNeeded()" greedy :style="calculateWidthBasedOnStep()">

        <!--      //TODO disable click-moving jumping between steps -->
        <q-stepper
            v-model="step"
            flat
            header-nav
            ref="stepper"
            color="primary"
            inactive-color="dark"
            animated
            class="q-mb-lg bg-transparent"
        >
          <q-step
              :name="1"
              title="Plan"
              active-color="accent"
              icon="settings"
              :done="step > 1"
              :header-nav="step > 1"
          >
            <div class="row justify-between q-mx-none">
              <plan-basic @step:next="nextStep()"
                          :plan="getBasicPlan()"
                          class="q-my-lg"
                          v-model:plan-for-offer="jobOfferData.plan"
                          style="border-width: 1px"
              >
              </plan-basic>

              <plan-basic @step:next="nextStep()"
                          :plan="getStadardPlan()"
                          class="q-my-lg"
                          style="border-width: 1px"
                          v-model:plan-for-offer="jobOfferData.plan">
              </plan-basic>

              <plan-basic @step:next="nextStep()"
                          :plan="getPremiumPlan()"
                          class="q-my-lg"
                          style="border-width: 1px"
                          v-model:plan-for-offer="jobOfferData.plan">
              </plan-basic>
            </div>
          </q-step>

          <q-step
              style="max-width: 950px;"
              :name="2"
              class="full-height"
              active-color="accent"
              title="O firmie"
              icon="fa-solid fa-building"
              :done="step > 2"
              :header-nav="step > 2"
          >
            <q-card class="q-px-lg q-pb-lg" style="padding-top: 40px" bordered>
              <company-form
                  style="max-width: 950px"
                  :account-details="accountDetails"
                  :form-template-data="formTemplateData"
                  v-model="jobOfferData.organization"
                  @step:next="nextStep"
                  @step:previous="previousStep"
              ></company-form>
            </q-card>
          </q-step>

          <q-step
              :name="3"
              active-color="accent"
              title="Ogłoszenie"
              icon="fa-regular fa-file-lines"
              :done="step > 3"
              :header-nav="step > 3"
          >
            <job-offer-form :form-template-data="formTemplateData"
                            v-model="jobOfferData"
                            @step:next="nextStep"
                            @step:previous="previousStep"
                            :show-buttons="true"
            >
            </job-offer-form>
          </q-step>

          <q-step
              :name="4"
              title="Podgląd"
              active-color="accent"
              icon="fa-solid fa-eye"
              :done="step > 4"
              :header-nav="step > 4"
          >
            <div id="navigationBar"></div>
            <q-card bordered style="padding: 40px">
              <div>
                <adding-offer-section-description class="q-mb-lg"
                                                  title="Zobacz jak wygląda Twoje ogłoszenie!"
                                                  description=" - Jeśli chcesz wprowadzić zmiany, wróć do poprzedniego kroku i zaktualizuj informacje.">
                </adding-offer-section-description>
              </div>
              <div class="col row q-mb-md q-pb-sm" v-if="copiedJobOfferUiRepresentation !==null">
                <job-offer-details
                    :is-copy-link-available="false"
                    :show-apply="false"
                    :logo-in-base64="jobOfferData.organization.logoInBase64"
                    :padding-top="0"
                    :job-offer="copiedJobOfferUiRepresentation">
                </job-offer-details>
              </div>
              <q-stepper-navigation class="q-pt-none">
                <div class="q-mb-md">
                  <q-separator></q-separator>
                </div>
                <!--            TODO SUBMIT BUTTON REMOVED - ADD LOGIC FOR SAVING OFFER-->
                <q-btn dense outline class="q-px-md bg-accent text-dark"
                       label="Dalej"
                       @click="nextStep"/>
                <q-btn flat @click="previousStep()" color="dark" dense label="Powrót" class="q-ml-md"/>
              </q-stepper-navigation>
            </q-card>
          </q-step>
          <q-step
              :name="5"
              :done="step > 5"
              @step:previous="previousStep()"
              title="Płatność"
              active-color="accent"
              icon="fa-solid fa-wallet"
              :header-nav="step > 5"
          >
            <q-card bordered style="padding: 40px">
              <div class="col row q-mb-md full-width q-mb-sm">
                <job-offer-payment
                    ref="paymentDataRef"
                    :plan="jobOfferData.plan"
                    :is-logged-organization-user="false"
                    :payment="jobOfferData.payment">
                </job-offer-payment>

              </div>
              <q-stepper-navigation class="q-pt-none">
                <div class="q-my-lg">
                  <q-separator></q-separator>
                </div>

                <div class="q-my-md">
                  <organization-clauses v-model="accountDetails.userConsents.directMarketingConsent"></organization-clauses>
                </div>

                <q-btn type="submit"
                       dense
                       outline
                       class="q-px-md text-dark bg-accent"
                       label="Dodaj ogłoszenie"
                />

                <q-btn
                    flat
                    @click="step = 4"
                    color="dark"
                    dense
                    label="Powrót"
                    class="q-ml-md"
                />

              </q-stepper-navigation>
            </q-card>

          </q-step>
        </q-stepper>
        <q-dialog v-model="showPaymentConfirmationDialog">
          <q-card style="width: 300px; border-radius: 9px;">
            <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
              <div class="text-h7 text-dark text-center">
                <div class="q-pt-none q-pb-sm q-mb-none text-center">
                  <q-icon size="md" name="fa-regular fa-circle-question" color="accent"></q-icon>
                </div>
                <div>
                  <span class="text-center" style="font-size: 13px">Twoja oferta zostanie zapisana.</span>
                </div>
                <div>
                  <span class="text-center" style="font-size: 13px">Zapłać za ofertę teraz lub później w portalu pracodawcy, aby była widoczna na tablicy.</span>
                </div>
              </div>
            </q-card-section>
            <div class="q-mb-md row justify-center">
              <div>
                <q-btn @click="addOfferAndGoToPrzelewy()" style="border-radius: 7px"
                       class="q-pa-sm q-mr-sm bg-accent text-dark"
                       dense
                       outline
                       label="Przejdź do Przelewy24"
                       size="sm"
                       ></q-btn>
              </div>
              <div>
                <q-btn @click="addOfferAndGoToMainBoard()"
                       outline style="border-radius: 7px"
                       class="q-pa-sm" dense
                       label="Zapłać później"
                       size="sm"
                       color="dark"></q-btn>
              </div>

            </div>
          </q-card>
        </q-dialog>
      </q-form>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue"
import JobOfferForm from "@/components/common/add-offer/job-offer/JobOfferForm";
import CompanyForm from "@/components/common/add-offer/company-info/CompanyForm";
import JobOfferPlansService from "@/services/organization/offer/JobOfferPlansService";
import _ from 'lodash';
import JsonBlankFieldsCleaner from "@/services/common/JsonBlankFieldsCleaner";
import OrganizationDataTranslator from "@/services/organization/OrganizationDataTranslator";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import PlanBasic from "@/components/common/add-offer/plans/PlanBasic";
import JobOfferDetails from "@/components/common/job-offers/JobOfferDetails";
import JobOfferPayment from "@/components/organization/job-offers/adding/JobOfferPayment";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";
import CookieService from "@/services/common/CookieService";
import {useRouter} from "vue-router/dist/vue-router";
import OrganizationClauses from "@/components/organization/registration/OrganizationMarketingClause.vue";

onMounted(() => {
  getFormtTemplateData().then(data => {
    formTemplateData.value = data;
  });
})

function getFormtTemplateData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
  };
  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => {
        return response.json().then((data) => {
          return data
        })
      })
}

const formTemplateData = ref(null)

const step = ref(1)

const jobOfferData = reactive({
  organization: {
    name: "",
    webPageUrl: "",
    type: "",
    description: "",
    industries: [],
    size: "",
    nip: "",
    logoInBase64: "",
    branding: {
      primaryColor: "#6d4faf",
      secondaryColor: "#6d4faf"
    }
  },
  plan: {},
  title: "",
  seniority: "",
  jobPosition: "",
  planType: "",
  contracts: [{type: null, salary: {from: null, to: null, currency: null}, id: 0}],
  questions: [],
  description: '<strong>Dodaj informacje o:</strong> <ul>\n' +
      '  <li>Firmie</li>\n' +
      '  <li>Projekcie</li>\n' +
      '  <li>Obowiązkach</li>\n' +
      '  <li>Benefitach</li>\n' +
      '</ul>',
  operatingModes: [],
  workTypes: [],
  skills: [],
  languages: [],
  locations: [],
  cities: [],
  active: true,
  jobApplicationInfo: {
    externalApplicationLink: null,
    applicationType: ref("INTERNAL")
  },
  payment: {
    email: "",
    payNow: true,
    billingData: {
      companyName: "",
      nip: "",
      address: {
        street: "",
        streetNumber: "",
        flatNumber: "",
        city: "",
        zipCode: ""
      }
    },
    promoCode: ""
  }
})

const accountDetails = reactive({
  adminEmail: "",
  userConsents: {
    directMarketingConsent: false
  }
})

const copiedJobOfferUiRepresentation = ref(null)

function nextStep() {
  console.info("PLAN " + JSON.stringify(jobOfferData.plan))
  console.info("PLAN TYPE " + jobOfferData.planType)
  step.value = step.value + 1;
  if (isStepForJobOfferDetails()) {
    mapValuesToRequestModel()
  }
  if (step.value === 5) {
    jobOfferData.payment.email = accountDetails.adminEmail;
    jobOfferData.payment.billingData.companyName = jobOfferData.organization.name
  }
}

function isStepForJobOfferDetails() {
  return step.value === 4
}

function mapValuesToRequestModel() {
  copiedJobOfferUiRepresentation.value = _.cloneDeep(jobOfferData);
  OfferDataTranslator.mapSkillLevelsToModel(copiedJobOfferUiRepresentation.value)
  copiedJobOfferUiRepresentation.value.jobPosition = JobPositionTranslator.translateToModel(copiedJobOfferUiRepresentation.value.jobPosition)
  copiedJobOfferUiRepresentation.value.seniority = OfferDataTranslator.translateFromPolishToExperienceLevel(copiedJobOfferUiRepresentation.value.seniority)
  mapContractsTypeToModel(copiedJobOfferUiRepresentation.value)
  mapLanguagesToModel(copiedJobOfferUiRepresentation.value)
  mapQuestionsToModel(copiedJobOfferUiRepresentation.value)
}

function previousStep() {
  step.value = step.value - 1;
}

// const router = useRouter()

const $q = useQuasar()

const showPaymentConfirmationDialog = ref(false)

async function showConfirmationDialogForPaymentIfNeeded() {
  let isCorrect = await validatePaymentData();
  if (isCorrect) {
    let planPrice = paymentDataRef.value.getFinalPrice();
    if (planPrice === 0) {
      await addOfferAndGoToMainBoard()
    } else {
      showPaymentConfirmationDialog.value = true
    }
  }
}

const paymentUrl = ref(null)

async function sendPostWithJobOffer() {
  let jobOfferForPost = _.cloneDeep(jobOfferData);
  await OfferDataTranslator.mapSkillLevelsToModel(jobOfferForPost)
  jobOfferForPost.organization.type = OrganizationDataTranslator.translateFromPolishToCompanyType(jobOfferForPost.organization.type)
  jobOfferForPost.organization.industries = jobOfferForPost.organization.industries.map(industry => OrganizationDataTranslator.translateFromPolishToCompanyIndustry(industry));
  jobOfferForPost.organization.size = OrganizationDataTranslator.translateFromPolishToCompanySize(jobOfferForPost.organization.size)
  jobOfferForPost.organization.logoInBase64 = jobOfferForPost.organization.logoInBase64.split(',')[1];
  jobOfferForPost.seniority = OfferDataTranslator.translateFromPolishToExperienceLevel(jobOfferForPost.seniority)
  jobOfferForPost.jobPosition = JobPositionTranslator.translateToModel(jobOfferForPost.jobPosition)
  mapContractsTypeToModel(jobOfferForPost)
  mapLanguagesToModel(jobOfferForPost)
  mapQuestionsToModel(jobOfferForPost)
  jobOfferForPost.planType = jobOfferForPost.plan.type
  jobOfferForPost.plan = null
  JsonBlankFieldsCleaner.removeEmptyFields(jobOfferForPost)
  const offerDto = {
    organization: jobOfferForPost.organization,
    offer: jobOfferForPost,
    accountDetails: accountDetails,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': CookieService.getCookieValue('XSRF-TOKEN'),
    },
    body: JSON.stringify(offerDto),
  };

  try {
    const response = await fetch(process.env.VUE_APP_BACKEND_URL + '/job-offers', requestOptions);
    if (response.status === 201) {
      NotificationService.showNotification($q, 'positive', 'Dodano ofertę');
      const body = await response.json();
      if (body.jobOffer.payment.url !== null) {
        paymentUrl.value = body.jobOffer.payment.url;
      }
      return true;
    } else if (response.status === 400) {
      const json = await response.json();
      if (json.message.includes('Wrong file format.')) {
        NotificationService.showNotification($q, 'negative', 'Błąd podczas dodawania oferty - skontaktuj sie z supportem');
      } else if (json.errorCode === 'EMAIL_ALREADY_TAKEN') {
        NotificationService.showNotification($q, 'negative', 'Konto o podanym adresie e-mail już istnieje, wróć do danych organizacji i zmień adres e-mail.');
      } else if (json.errorCode === 'INVALID_NIP') {
        NotificationService.showNotification($q, 'negative', 'Niepoprawny NIP.');
      }else {
        console.error(json);
        NotificationService.showNotification($q, 'negative', 'Błąd podczas dodawania oferty - skontaktuj się z supportem.');
      }
      return false;
    } else {
      console.error(`Unexpected status code: ${response.status}`);
      NotificationService.showNotification($q, 'negative', 'Wystąpił nieoczekiwany błąd - skontaktuj się z supportem.');
      return false;
    }
  } catch (error) {
    console.error('Fetch error:', error);
    NotificationService.showNotification($q, 'negative', 'Wystąpił nieoczekiwany błąd - skontaktuj się z supportem.');
    return false;
  }
}

function mapContractsTypeToModel(jobOfferData) {
  jobOfferData.contracts.forEach(contract => {
    contract.type = OfferDataTranslator.translateFromPolishToContractType(contract.type)
  })
}

function mapLanguagesToModel(jobOfferData) {
  jobOfferData.languages.forEach(language => {
    language.language = OfferDataTranslator.translateFromPolishToLanguage(language.language)
    language.proficiency = OfferDataTranslator.mapFromLanguageRateToProficiency(language.proficiency)
  })
}

function mapQuestionsToModel(jobOfferData) {
  jobOfferData.questions.forEach(question => {
    question.answerType = OfferDataTranslator.translateFromPolishToQuestionAnswerType(question.answerType);
  })
}

function getBasicPlan() {
  return JobOfferPlansService.getBasicPlan(formTemplateData.value.plans)
}

function getStadardPlan() {
  return JobOfferPlansService.getStandardPlan(formTemplateData.value.plans)
}

function getPremiumPlan() {
  return JobOfferPlansService.getPremiumPlan(formTemplateData.value.plans)
}

function calculateWidthBasedOnStep() {
  if (step.value === 4) {
    return 'width: 800px'
  } else if (step.value === 1) {
    return 'width: 1100px'
  } else {
    return 'width: 800px'
  }
}

const paymentDataRef = ref(null)

async function validatePaymentData() {
  let validate = await paymentDataRef.value.validate();
  return await validate
}

const router = useRouter()

async function addOfferAndGoToPrzelewy() {
  let isAdded = await sendPostWithJobOffer()
  if (isAdded) {
    window.open(paymentUrl.value, '_blank')
    await router.push({name: 'OrganizationJobOffersBoard'});
  } else {
    showPaymentConfirmationDialog.value = false;
  }

}

async function addOfferAndGoToMainBoard() {
  let isAdded = await sendPostWithJobOffer()
  if (isAdded) {
    await router.push({name: 'OrganizationJobOffersBoard'});
  } else {
    showPaymentConfirmationDialog.value = false;
  }
}

function calculatedTopMarginAndPadding() {
  if (step.value === 1) {
    return "q-mt-md q-pt-none column"
  } else {
    return "q-mt-md q-pt-lg"
  }
}
</script>

<style lang="scss" scoped>
.q-chip {
  background: white;
}

</style>
