<template>
  <div class="q-pb-md">
    <p class="text-weight-bold text-dark q-ma-none" style="font-size: 13px">Pytania</p>
  </div>
  <q-form ref="childForm" greedy>
    <div v-for="answer in answers" :key="answer.questionId">
      <div v-if="answer.type !== 'BOOLEAN'">
        <p class="text-dark q-ma-none">{{ answer.question }}</p>
        <q-input
            v-if="answer.required"
            v-model="answer.answer"
            color="dark"
            class="q-pt-sm q-mb-sm"
            outlined
            :rules="[
            val => val.length !== 0 || 'Odpowiedź jest wymagana',
            val => (answer.type !== 'NUMBER' || !isNaN(Number(val))) || 'Odpowiedź musi być liczbą',
            val => (answer.type !== 'BOOLEAN' || ['tak', 'nie'].includes(val.toLowerCase())) || 'Odpowiedź: \'tak\' lub \'nie\''
        ]"
            dense
            label="Wymagane"
        ></q-input>
        <q-input
            v-else
            v-model="answer.answer"
            class="q-pt-sm q-mb-sm"
            outlined
            color="dark"
            :rules="[
            val => {
              if (val !== null && val.length === 0) {
                return true;
              }
              if (answer.type === 'NUMBER') {
                return !isNaN(Number(val)) || 'Odpowiedź musi być liczbą';
              }
              if (answer.type === 'BOOLEAN') {
                return ['tak', 'nie'].includes(val.toLowerCase()) || 'Odpowiedź: \'tak\' lub \'nie\''
        }
        return true;
        }
        ]"
            dense
            label="Opcjonalne"
        ></q-input>
      </div>
      <div v-else>
        <p class="text-dark q-ma-none">{{ answer.question }}</p>
        <q-select
            v-if="answer.required"
            v-model="answer.answer"
            color="dark"
            :options="['tak','nie']"
            class="q-pt-sm q-mb-sm"
            outlined
            :rules="[
            val => val !== null && val.length !== 0 || 'Odpowiedź jest wymagana',
        ]"
            dense
            label="Wymagane"
        ></q-select>
        <q-select
            v-else
            v-model="answer.answer"
            :options="['tak','nie',null]"
            class="q-pt-sm q-mb-sm"
            outlined
            color="dark"
            :rules="[
            val => {
              if (val === null || val.length === 0) {
                return true;
        }
        return true;
        }
        ]"
            dense
            label="Opcjonalne"
        ></q-select>
      </div>
    </div>
  </q-form>
</template>

<script setup>
import {computed, defineEmits, defineExpose, defineProps, onMounted, ref} from 'vue';

const props = defineProps({
  modelValue: Object,
});

const emit = defineEmits(['update:modelValue']);

onMounted(()=>{

})
const answers = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const childForm = ref(null);

async function validate() {
  childForm.value.submit();
  const isValid = await childForm.value.validate();
  return isValid;
}

defineExpose({
  validate,
});
</script>

<style scoped>
</style>
