<template>
  <q-layout style="width: 100%;">
    <organization-menu-drawer :active-button="'JOB_OFFERS'"></organization-menu-drawer>
    <q-page-container>
      <q-page class="column bg-primary">
        <menu-bar class="bg-gradient-menu-organization"/>
        <q-card flat class="bg-secondary column col" style="border-radius: 25px 0 0 0 !important;">
          <div class="column col">
            <div class="q-ml-sm" v-if="!isLoading">
              <organization-job-offer-details-nav-bar
                  class="q-ma-xs"
                  :job-offer-title="jobOffer.title"
              ></organization-job-offer-details-nav-bar>
            </div>
            <div v-if="isLoading && formTemplateData" class="col row  q-mt-sm">
              <div class="col row q-ma-lg" style="width: 45%">
                <job-offer-details-skeleton class="col row "></job-offer-details-skeleton>
              </div>
              <div class="col-6 column q-mb-md q-mt-lg q-ml-sm q-mr-md">
                <q-card bordered class="q-mb-lg col" style="padding-left: 2px">
                  <apexchart
                      type="area"
                      height="98%"
                      width="97%"
                      :options="applicationsChartOptions"
                      :series="applicationsSeries"
                  />
                </q-card>
                <q-card bordered class="col">
                  <apexchart
                      type="area"
                      height="97%"
                      width="100%"
                      :options="viewsChartOptions"
                      :series="viewsSeries"
                  />
                </q-card>
              </div>
            </div>
            <div v-else class="row col">
              <div class="col q-mb-md q-ml-md q-pr-md q-scrollarea" style="overflow-y: auto" v-if="formTemplateData">
                <job-offer-form
                    :form-template-data="formTemplateData"
                    v-model="jobOfferData"
                    :show-buttons="true"
                    :button-name="'Aktualizuj dane'"
                    :is-back-button-visible="false"
                    @step:next="updateOfferData()"
                    :is-title-disabled="true"
                >
                </job-offer-form>
              </div>
              <div class="col-6 column q-mb-md q-ml-sm q-mr-md">
                <q-card bordered class="q-mb-lg col" style="padding-left: 2px">
                  <apexchart
                      type="area"
                      height="98%"
                      width="97%"
                      :options="applicationsChartOptions"
                      :series="applicationsSeries"
                  />
                </q-card>
                <q-card bordered class="col">
                  <apexchart
                      type="area"
                      height="97%"
                      width="100%"
                      :options="viewsChartOptions"
                      :series="viewsSeries"
                  />
                </q-card>
              </div>
            </div>
          </div>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import {onMounted, reactive, ref} from 'vue'
import {useRouter} from 'vue-router';
import MenuBar from "@/components/common/MenuBar";
import viewsChartService from "@/services/ViewsChartService";
import jobOfferService from "@/services/JobOfferService";
import applicationsChartService from "@/services/ApplicationsChartService";
import OrganizationJobOfferDetailsNavBar
  from "@/components/organization/job-offers/details/OrganizationJobOfferDetailsNavBar";
import OrganizationMenuDrawer from "@/components/organization/common/OrganizationMenuDrawer";
import JobOfferDetailsSkeleton from "@/components/common/job-offers/skeletons/JobOfferDetailsSkeleton";
import FormTemplateDataService from "@/services/organization/offer/FormTemplateDataService";
import JobOfferForm from "@/components/common/add-offer/job-offer/JobOfferForm";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import JobOfferSkillsTranslator from "@/services/offer/JobOfferSkillsTranslator";
import OrganizatioOfferManagementService from "@/services/organization/offer/OrganizatioOfferManagementService";
import JobPositionTranslator from "@/services/offer/JobPositionTranslator";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";

const router = useRouter()


const jobOfferId = router.currentRoute.value.params.id

const isLoading = ref(true);
const jobOffer = ref(null);
const isVisible = ref(true)
const formTemplateData = ref(null)

onMounted(() => {
  FormTemplateDataService.getFormTemplateData().then(data => {
    formTemplateData.value = data;
  });
  fetchData().then(offer => {
    initOfferData(offer);
    isLoading.value = false
  });

});


const jobOfferData = reactive({
  title: "",
  id: "",
  seniority: "",
  jobPosition: "",
  planType: "",
  contracts: [{type: null, salary: {from: null, to: null, currency: null}, id: 0}],
  questions: [],
  plan: {},
  description: "",
  operatingModes: [],
  skills: [],
  languages: [],
  cities: [],
  locations: [],
  workTypes: [],
  contactEmails: [],
  active: true,
  jobApplicationInfo: {
    externalApplicationLink: null,
    applicationType: ref("INTERNAL")
  }
})

function initOfferData(jobOffer) {
  jobOfferData.id = jobOffer.id
  jobOfferData.title = jobOffer.title;
  jobOfferData.jobPosition = JobPositionTranslator.translateConstant(jobOffer.jobPosition);
  jobOfferData.contracts = jobOffer.publication.contracts
  OfferDataTranslator.translateContractsToPolish(jobOffer.publication.contracts);
  jobOfferData.description = jobOffer.description
  jobOfferData.questions = jobOffer.questions;
  jobOfferData.questions.forEach(question => {
    question.answerType = OfferDataTranslator.translateAnswerTypeToPolish(question.answerType);
  });
  jobOfferData.seniority = OfferDataTranslator.translateFromSeniorityToPolish(jobOffer.seniority);
  if (jobOffer.languages !== null && jobOffer.languages.length > 0) {
    jobOfferData.languages = OfferDataTranslator.translateArrayLanguagesToPolish(jobOffer.languages);
  }
  jobOfferData.locations = jobOffer.locations;
  jobOfferData.planType = jobOffer.plan.type
  jobOfferData.cities = jobOffer.locations.map(location => location.city);
  jobOfferData.skills = JobOfferSkillsTranslator.translateLevelFromEnumToIntegerLevel(jobOffer.skills)
  jobOfferData.operatingModes = jobOffer.operatingModes
  jobOfferData.workTypes = jobOffer.workTypes
  jobOfferData.jobApplicationInfo = jobOffer.jobApplicationInfo
}

function fetchData() {
  let queryParams = new URLSearchParams();
  queryParams.append("page", 0);
  queryParams.append("size", 500);
  return jobOfferService.getJobOffersForOrganization(queryParams).then(json => {
    const selectedOffer = json.content.find(offer => offer.id === jobOfferId);
    if (selectedOffer) {
      jobOffer.value = selectedOffer;
      isVisible.value = selectedOffer.visible
      loadData(jobOffer.value)
      return jobOffer.value;
    } else {
      console.warn("Selected job offer not found.");
    }
  });
}

async function loadData(jobOffer) {
  applicationsChartOptions.value.xaxis.categories = applicationsChartService.get30DaysFromOfferPublicationDateAsArray(jobOffer);
  viewsChartOptions.value.xaxis.categories = applicationsChartService.get30DaysFromOfferPublicationDateAsArray(jobOffer);
  await new Promise(resolve => setTimeout(resolve, 10));
  viewsData.value = viewsChartService.calculateViewsForOneOffer(jobOffer);
  applicationData.value = applicationsChartService.dailyApplicationData(jobOffer);
}

const $q = useQuasar()

function updateOfferData() {
  let modelToUpdate = OfferDataTranslator.mapValuesToRequestModel(jobOfferData)
  OrganizatioOfferManagementService.updateJobOffer(modelToUpdate)
      .then(response => {
        if (response.ok) {
          NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane')
        } else {
          NotificationService.showNotification($q, 'negative', 'Błąd poczas aktualizacji oferty')
        }
      })
}

const applicationsChartOptions = ref({
  grid: {
    borderColor: '#63DBD2FF', // Color of grid lines
    yaxis: {
      lines: {
        show: false // Show Y-axis grid lines
      }
    }
  },
  legend: {
    fontSize: '9px'
  },
  chart: {

    toolbar: {
      show: false // Disable toolbar
    },
    id: 'forecast-chart',
    type: 'area',
    stacked: true, // Stacked bar chart
  },
  plotOptions: {},
  dataLabels: {
    style: {
      colors: ['#ffffff'] // Color for data labels
    },
    enabled: false // Disable data labels
  },
  stroke: {
    width: 2, // Stroke width for smoothness
    curve: 'smooth', // Smooth curve for line charts
    colors: ['#63DBD2FF'] // Stroke color
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '11px', // X-axis label font size
        colors: '#868686' // X-axis label color
      },
    },
    categories: [] // Add your categories here
  },
  fill: {
    type: 'gradient', // Use gradient for bar fill
    gradient: {
      shade: 'dark', // Dark gradient shade
      shadeIntensity: 1, // Intensity of the gradient
      type: 'vertical', // Vertical gradient direction
      opacityFrom: 0.8, // Starting opacity for the gradient
      opacityTo: 0.3, // Ending opacity for smoother transition
      stops: [0, 100], // Gradient stops to define the gradient effect
      gradientToColors: ['#B1EDE9'] // Gradient transition color (matching others)
    },
    colors: ['#B1EDE9'], // Start color (matches other charts)
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return Math.floor(value); // Rounds down to remove decimal places
      },
      style: {
        colors: '#868686' // Y-axis label color
      }
    },
    title: {
      text: 'Przesłane aplikacje', // Y-axis title
      style: {
        fontFamily: 'Kanit', // Font family for Y-axis title
        color: '#424242' // Y-axis title color
      }
    },
  }
});

const viewsChartOptions = ref({
  legend: {
    fontSize: '9px', // Smaller font size for the legend
    offsetY: -10 // Adjust the space between legend and chart
  },
  chart: {
    toolbar: {
      show: false // Disable the toolbar
    },
    id: 'forecast-chart',
    type: 'area', // Bar chart type
    stacked: true, // Enable stacking for the bars
  },
  plotOptions: {},
  grid: {
    borderColor: '#63DBD2FF', // Same grid line color
    yaxis: {
      lines: {
        show: false // Hide Y-axis grid lines for cleaner look
      }
    }
  },
  dataLabels: {
    style: {
      colors: ['#ffffff'] // White color for data labels
    },
    enabled: false // Disable data labels
  },
  stroke: {
    width: 2, // Line stroke width for better visibility
    curve: 'smooth', // Smooth curve for the bars
    colors: ['#63DBD2FF'] // Stroke color to match the other chart
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '11px', // X-axis label font size
        fontFamily: 'Kanit', // Consistent font family
        colors: '#868686' // X-axis label color (consistent)
      },
    },
    categories: [] // Add your categories (data) here
  },
  fill: {
    type: 'gradient', // Use gradient for bar fill
    gradient: {
      shade: 'dark', // Dark gradient shade
      shadeIntensity: 1, // Intensity of the gradient
      type: 'vertical', // Vertical gradient direction
      opacityFrom: 0.8, // Starting opacity for the gradient
      opacityTo: 0.3, // Ending opacity for smoother transition
      stops: [0, 100], // Gradient stops to define the gradient effect
      gradientToColors: ['#B1EDE9'] // Gradient transition color (matching others)
    },
    colors: ['#B1EDE9'], // Start color (matches other charts)
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return Math.floor(value); // Rounds down values to remove decimals
      },
      style: {
        fontSize: '11px', // Y-axis label font size
        colors: '#868686', // Y-axis label color (consistent with X-axis)
      }
    },
    title: {
      text: 'Wyświetlenia', // Y-axis title
      style: {
        fontFamily: 'Kanit', // Font family for Y-axis title
        color: '#424242' // Y-axis title color
      }
    },
  },
  tooltip: {
    theme: 'light', // Light theme for tooltips
    x: {
      format: 'dd MMM' // Date format for X-axis tooltips (if dates are used)
    }
  }
});


const viewsData = ref([])
const viewsSeries = ref([
  {
    name: 'Wyświetlenia ofert',
    data: viewsData,
  },
]);

const applicationData = ref([])
const applicationsSeries = ref([
  {
    name: 'Przesłane aplikacje',
    data: applicationData,
    yaxis: 0,
  },
]);
</script>

<style lang="scss">
.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}
</style>