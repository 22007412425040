<template>
  <q-layout>
    <q-page-container style="background-color: #dde5ee;">
      <q-page class="column items-center col row bg-primary">
      <mobile-menu></mobile-menu>
        <q-card flat class="bg-secondary col row full-width" style="border-radius: 0px!important;">
          <div class="col row q-scrollarea q-mb-md"
               style="width: 100%; overflow-y: auto; justify-content: center;">
            <div  v-if="user.id !== null" class="column q-mt-none" style="max-width: 800px; width: 100%;">
              <mobile-applicant-data ref="applicantDataRef"
                              v-if="user.id !== null"
                              v-model="user"
                              style="margin-top: 23px;"
                              class="q-mx-lg q-mt-none"/>
              <mobile-applicant-c-v v-if="user.id !== null" :applicant-id="user.id"
                             class="q-mx-lg q-mt-lg"></mobile-applicant-c-v>
              <div style="max-height: 60px;">
                <q-card-section class="q-mt-none q-pl-none q-pt-none row justify-between full-width"
                                style="max-height: 10px;">
                  <q-btn size="13px"
                         outline
                         icon="login"
                         class="q-ml-lg bg-accent text-dark q-mt-sm"
                         style="max-height: 15px; border-radius: 6px;"
                         @click="updateData()"
                         label="Zapisz zmiany"
                         />
                </q-card-section>
              </div>
            </div>
            <div v-else>
              <job-offer-details-skeleton></job-offer-details-skeleton>
            </div>
          </div>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script setup>
import {onMounted, reactive, ref} from 'vue'
import AuthenticationService from "@/services/AuthenticationService";

import ApplicantDataService from "@/services/applicant/ApplicantDataService";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import PreferredContactTimeService from "@/services/applicant/PreferredContactTimeService";
import {currentUser} from "@/global-variables/currentUser";
import MobileMenu from "@/components/mobile/MobileMenu";
import JobOfferDetailsSkeleton from "@/components/common/job-offers/skeletons/JobOfferDetailsSkeleton";
import MobileApplicantData from "@/components/mobile/applicantprofile/MobileApplicantData";
import MobileApplicantCV from "@/components/mobile/applicantprofile/MobileApplicantCV";

onMounted(() => {
  initAuthenticatedUserData()
  initUserData()
})

function initUserData() {
  AuthenticationService.getAuthenticatedUser().then(data => {
    data.json().then(body => {
      user.firstName = body.firstName;
      user.id = body.id;
      user.email = body.email;
      user.lastName = body.lastName;
      user.phoneNumber = body.phoneNumber;
      if (body.preferredContactTime !== null) {
        user.contactInSpecificHours.min = PreferredContactTimeService.fromTimeToRangeValue(body.preferredContactTime.from);
        user.contactInSpecificHours.max = PreferredContactTimeService.fromTimeToRangeValue(body.preferredContactTime.to);

      }
    })
  });
}

function initAuthenticatedUserData() {
  AuthenticationService.getAuthenticatedUser()
      .then(response => {
        if (response.status === 200) {
          response.json().then((data) => {
            user.value = data
          })
        } else {
          throw new Error("Cannot get user profile data - user not authenticated")
        }
      });
}

const user = reactive({
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  contactInSpecificHours: {
    min: 30,
    max: 75
  }
});

const $q = useQuasar()

async function updateData() {
  let promise = await validateChildComponents();
  if (promise) {
    ApplicantDataService.updateData(user.id, user.firstName, user.lastName, user.phoneNumber, PreferredContactTimeService.fromRangeValueToTime(user.contactInSpecificHours.min), PreferredContactTimeService.fromRangeValueToTime(user.contactInSpecificHours.max))
        .then(response => {
          if (response.ok) {
            response.json().then(json => {
              Object.assign(currentUser, json);
            })
            NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane profilu')
          }
        })
  }
}

const applicantDataRef = ref(null);

async function validateChildComponents() {
  return applicantDataRef.value.validate()
}
</script>
<style lang="scss">
.q-btn--dense .on-left {
  margin-right: 22px;
  margin-left: 8px;
}

.bg-gradient {
  background: linear-gradient(180deg, $primary 20%, $light-blue-11 150%); /* Gradient procentowy */
  color: white; /* Text color */
}
</style>