import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";
import StringUtils from "@/services/common/StringUtils";

class OrganizationDataService {
    constructor(url) {
        this.url = url;
    }

    async getOrganizationInfo() {
        const url = `${this.url}/organizations/${currentUser.organizationId}`;
        try {
            const requestOptions = {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response; // Return the raw Response object
        } catch (error) {
            console.error("Error during organization GET method:", error);
            throw error;
        }
    }

    async updateOrganizationInfo(organizationData) {
        const url = `${this.url}/organizations/${currentUser.organizationId}`;
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(organizationData),
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response; // Return the raw Response object
        } catch (error) {
            console.error("Error during organization update PUT method:", error);
            throw error;
        }
    }

    async changeLogo(logoInBase64) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/logo`;
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({logoInBase64}),
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response; // Return the raw Response object
        } catch (error) {
            console.error("Error during organization logo update PUT method:", error);
            throw error;
        }
    }

    isControllerClauseToUpdate(clause) {
        return clause === "Informujemy, że __________ sp. z o.o. z siedzibą w __________ pełni rolę administratora danych osobowych (dalej \"administrator\"). Przysługuje Ci prawo dostępu do swoich danych, ich poprawienia, usunięcia, ograniczenia przetwarzania, a także prawo wniesienia sprzeciwu wobec przetwarzania, przenoszenia danych i zgłoszenia skargi do organu nadzorczego. Administrator przetwarza dane wyłącznie na potrzeby procesu rekrutacyjnego. Podanie danych wymaganych zgodnie z ustawą z dnia 26 czerwca 1974 r. Kodeks pracy jest obligatoryjne, natomiast przekazanie danych wykraczających poza ten zakres jest dobrowolne. Brak podania wymaganych danych może uniemożliwić przeprowadzenie rekrutacji. Przetwarzanie danych obowiązkowych opiera się na przepisach prawa. Dane będą przechowywane przez czas trwania procesu rekrutacji oraz przez okres, w którym możliwe jest dochodzenie roszczeń. Dane mogą być przekazywane serwisowi Byte Job oraz innym podmiotom, którym zlecono ich przetwarzanie na potrzeby rekrutacji."
    }

    areMandatoryFieldsMissing(organizationData) {
        const isNameMissing =  StringUtils.isStringEmpty(organizationData.name)
        const isTypeMissing = !organizationData.type;
        const isIndustryMissing =
            !organizationData.industries || organizationData.industries.length <= 0;
        const isSizeMissing = !organizationData.size;
        const isLogoUrlEmpty = this.isStringEmpty(organizationData.logoUrl);
        const isControllerClauseNotUpdated = organizationData.dataControllerClause === "Informujemy, że __________ sp. z o.o. z siedzibą w __________ pełni rolę administratora danych osobowych (dalej \"administrator\"). Przysługuje Ci prawo dostępu do swoich danych, ich poprawienia, usunięcia, ograniczenia przetwarzania, a także prawo wniesienia sprzeciwu wobec przetwarzania, przenoszenia danych i zgłoszenia skargi do organu nadzorczego. Administrator przetwarza dane wyłącznie na potrzeby procesu rekrutacyjnego. Podanie danych wymaganych zgodnie z ustawą z dnia 26 czerwca 1974 r. Kodeks pracy jest obligatoryjne, natomiast przekazanie danych wykraczających poza ten zakres jest dobrowolne. Brak podania wymaganych danych może uniemożliwić przeprowadzenie rekrutacji. Przetwarzanie danych obowiązkowych opiera się na przepisach prawa. Dane będą przechowywane przez czas trwania procesu rekrutacji oraz przez okres, w którym możliwe jest dochodzenie roszczeń. Dane mogą być przekazywane serwisowi Byte Job oraz innym podmiotom, którym zlecono ich przetwarzanie na potrzeby rekrutacji."
        let plainClause = StringUtils.htmlToPlainText(organizationData.dataControllerClause)
        const isControllerClauseEmpty = StringUtils.isStringEmpty(plainClause)
        return isNameMissing || isTypeMissing || isIndustryMissing || isSizeMissing || isLogoUrlEmpty || isControllerClauseNotUpdated || isControllerClauseEmpty;
    }

    isDataControllerClauseNotUpdated(organizationData) {
        let isNotUpdated = organizationData.dataControllerClause === "Informujemy, że __________ sp. z o.o. z siedzibą w __________ pełni rolę administratora danych osobowych (dalej \"administrator\"). Przysługuje Ci prawo dostępu do swoich danych, ich poprawienia, usunięcia, ograniczenia przetwarzania, a także prawo wniesienia sprzeciwu wobec przetwarzania, przenoszenia danych i zgłoszenia skargi do organu nadzorczego. Administrator przetwarza dane wyłącznie na potrzeby procesu rekrutacyjnego. Podanie danych wymaganych zgodnie z ustawą z dnia 26 czerwca 1974 r. Kodeks pracy jest obligatoryjne, natomiast przekazanie danych wykraczających poza ten zakres jest dobrowolne. Brak podania wymaganych danych może uniemożliwić przeprowadzenie rekrutacji. Przetwarzanie danych obowiązkowych opiera się na przepisach prawa. Dane będą przechowywane przez czas trwania procesu rekrutacji oraz przez okres, w którym możliwe jest dochodzenie roszczeń. Dane mogą być przekazywane serwisowi Byte Job oraz innym podmiotom, którym zlecono ich przetwarzanie na potrzeby rekrutacji."
        let plainClause = StringUtils.htmlToPlainText(organizationData.dataControllerClause)
        let isControllerClauseEmpty = StringUtils.isStringEmpty(plainClause)
        return isNotUpdated || isControllerClauseEmpty;
    }

    isStringEmpty(str) {
        return /^\s*$/.test(str);
    }
}

export default new OrganizationDataService(process.env.VUE_APP_BACKEND_URL);
