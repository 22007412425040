import script from "./MobileSimilarOffers.vue?vue&type=script&setup=true&lang=js"
export * from "./MobileSimilarOffers.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QSeparator,QCardSection});
