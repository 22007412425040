<template>
  <div class="row col q-pt-none q-pl-none column">
    <q-dialog v-model="offerFromUrlNotFound" @before-hide="goToAllOffersWhenJobFromUrlNotFound()">
      <q-card style="width: 250px; border-radius: 9px;">
        <q-card-section class="row justify-center q-px-none q-pt-md q-pb-sm">
          <div class="text-center text-h7 text-dark">
            <div class="q-pt-none q-pb-sm q-mb-xs">
              <q-icon size="md" name="fa-solid fa-circle-info" color="primary"></q-icon>
            </div>
            <span style="font-size: 12px">Oferta wygasła lub została usunięta.</span>
          </div>
        </q-card-section>
        <div class="text-center q-mb-sm">
          <q-btn @click="goToAllOffersWhenJobFromUrlNotFound()" flat style="border-radius: 7px" class="q-px-sm" dense label="Zobacz inne oferty" no-caps
                 color="primary"></q-btn>
        </div>
      </q-card>
    </q-dialog>
    <div class="row q-mr-none q-ml-xs q-mt-sm">
      <job-offers-filter
          v-if="!isMobile()"
          ref="filterComponent"
          class="col-8" @filter-values="receiveFilterOffersEvent"
          @observed="val => filterObserved(val)"
      ></job-offers-filter>
      <mobile-job-offers-filter
          v-else
          ref="filterComponent"
          class="col-8" @filter-values="receiveFilterOffersEvent"
          @observed="val => filterObserved(val)"
      ></mobile-job-offers-filter>
    </div>
    <div v-if="noResultFound" class="col row column q-mr-sm items-center" style="margin-top: 70px">
      <q-card bordered class="q-mt-lg" style="border-radius: 25px !important; width: 500px;">
        <q-card-section class="q-pa-lg text-center">
          <div>
            <q-icon class="q-mb-lg" color="dark" size="11rem" name="fa-solid fa-face-sad-tear"></q-icon>
          </div>
          <div>
            <p class="text-dark" style="font-size: 14px">Nie znaleźliśmy ofert odpowiadających wybranym kryteriom. Spróbuj dostosować wyszukiwanie, aby znaleźć interesujące Cię ogłoszenia.</p>
          </div>
          <div>
            <q-btn @click="initOffers()" outline dense class="bg-accent text-dark q-px-md" label="Zobacz wszystkie oferty"></q-btn>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div v-else class="row col q-pb-none q-mt-sm">
      <div id="scrollTargetRef" class="q-pl-xs q-mb-sm q-pr-none q-pt-none q-pb-none col row q-scrollarea" style="overflow-y: auto">
        <div v-if="!isReady" class="col row column q-mr-sm">
          <card-skeleton class="q-mr-xs q-ml-none q-mt-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
          <card-skeleton class="q-mr-xs q-ml-none col row column"></card-skeleton>
        </div>
        <div v-else class="col row" style="overflow-y: auto;">
          <div v-if="isReady" :key="resetCounter" ref="infinitiveScroll"
               style="overflow-y: auto"
               :scroll-target="scrollTargetRef"
               class="q-mr-none q-ml-none q-mt-none col row column">
            <div v-for="jobOffer in offers" :key="getJobOfferId(jobOffer)" class="q-mr-xs"
                 :scroll-target="scrollTargetRef">
              <offer-overview
                  style="max-height: 160px"
                  :job-offer="jobOffer"
                  :is-fav="isFavForLoggedUser(jobOffer.id)"
                  :is-selected="!offerFromUrlNotFound && selectedJobOffer.id === jobOffer.id && shouldShowHighlightedOverview"
                  @jobOffer:id="value => updateCurrentJobOffer(value)"
                  @fav:remove="id => removeFav(id)"
                  @fav:add="id => addFav(id)"
                  class="bg-white">

              </offer-overview>
            </div>
          </div>
        </div>
      </div>
      <div
          class="q-mt-none q-pb-sm col row column q-ml-md"
          style="max-width: 55%">
        <job-offer-details-skeleton
            class="q-pr-md col"
            v-if="isLoading"
        >
        </job-offer-details-skeleton>
        <job-offer-details
            v-else-if="!offerFromUrlNotFound && offers.length  !== 0"
            id="jobOfferDetailsRef"
            :is-copy-link-available="true"
            ref="jobOfferDetailsRef"
            class="col row column"
            :show-apply="true"
            :padding-top="0"
            :job-offer="selectedJobOffer">
        </job-offer-details>
        <q-card v-else-if="selectedJobOffer.value = null" class="q-mx-md q-mt-xs" style="border-radius: 9px">
          <q-card-section>
            <q-icon color="red-5" class="q-mr-md" size="lg" name="fa-solid fa-triangle-exclamation"></q-icon>
            <span style="font-size: 15px" class="text-dark">Oferta wygasła lub została usunięta. Wybierz inną ofertę z listy.</span>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>


<script setup>

import {onMounted, ref,} from 'vue';
import OfferOverview from "@/components/common/job-offers/OfferOverview";
import JobOfferService from "@/services/JobOfferService";
import JobOfferDetails from "@/components/common/job-offers/JobOfferDetails";
import JobOffersFilter from "@/components/common/job-offers/job-offers-filter/JobOffersFilter";
import {useRouter} from 'vue-router';
import CardSkeleton from "@/components/common/job-offers/skeletons/JobOfferOverviewSkeleton";
import JobOfferDetailsSkeleton from "@/components/common/job-offers/skeletons/JobOfferDetailsSkeleton";
import OfferCounterService from "@/services/offer/OfferCounterService";
import {currentUser} from '@/global-variables/currentUser';
import ScrollUtils from "@/services/common/ScrollUtils";
import {useQuasar} from "quasar";
import MobileJobOffersFilter from "@/components/common/job-offers/job-offers-filter/MobileJobOffersFilter";

const filterComponent = ref(null)
const shouldShowHighlightedOverview = ref(true)
const isLoading = ref(true)
const scrollTargetRef = ref(null)
const offers = ref([])
const currentNumberOfElements = ref(null)
const totalNumberOfElements = ref(null)
const infinitiveScroll = ref();
const currentPage = ref(0);
const resetCounter = ref(0);
const noResultFound = ref(false)
const sorting = ref('DEFAULT')
const isOnlyObserved = ref(false)
const offerFromUrlNotFound = ref(false)

onMounted(() => {
  initComponentData();
     initOffers();
})

async function initOffers() {
  await filterComponent.value.resetAllFilters()
  useNewParams();
  await Promise.all([
    fetchOffers2(false, true)
  ]);
}
const $q = useQuasar()

function isMobile() {
  return $q.platform.is.mobile
}
const router = useRouter()

function getJobOfferId(jobOffer) {
  return jobOffer.id
}

function filterObserved(isObserved) {
  isOnlyObserved.value = isObserved
  fetchOffers2(isOnlyObserved.value, false)
}

function addElementsToOffers(content) {
  offers.value.push(...content)
}

let abortAdditionalFetch;

async function fetchOffers2(isOnlyObserved, canBeOfferFromUrl) {
  noResultFound.value = false
  if (currentUser.isAuthenticated()) {
    observedOffersId.value = currentUser.favoriteOffersIds ? currentUser.favoriteOffersIds : [];
  }
  abortPreviousFetches()
  abortAdditionalFetch = new AbortController();
  const signal = abortAdditionalFetch.signal;
  isLoading.value = true;
  isReady.value = false;
  await clearJobOffers();
  let handleOfferFromUrlNotFound1 = await handleOfferFromUrlNotFound();
  if (handleOfferFromUrlNotFound1) {
    return
  } else {
    try {
      // Fetch the first page to get total number of pages
      globalParams.value.set('page', '0');
      const response = await JobOfferService.getAllJobOffers(globalParams.value, signal);
      const {content, page} = await response.json();// Add the first page's offers
      if (page.totalElements === 0 && router.currentRoute.value.params.id !== null) {
        noResultFound.value = true;
      } else {
        addElementsToOffers(content);
        await initSelectedOffer(canBeOfferFromUrl);
        makeOfferComponentReady();
      }
      // Store total pages
      const totalPages = page.totalPages;

      // Prepare an array to hold offers from all pages
      const allOffers = [];
      // Create an array of Promises for the remaining pages
      const fetchPromises = [];

      for (let currentPage = 1; currentPage < totalPages; currentPage++) {
        // Create a new URLSearchParams object for each request to avoid modifying the original
        const params = new URLSearchParams(globalParams.value);
        params.set('page', currentPage.toString());

        // Create a Promise for fetching this page
        const fetchPromise = JobOfferService.getAllJobOffers(params, signal)
            .then(response => response.json())
            .then(data => {
              // Store the content at the correct index
              allOffers[currentPage] = data.content;
            })
            .catch(error => {
              if (error.name === 'AbortError') {
                console.info(`Fetch for page ${currentPage} aborted`);
              } else {
                console.error(`Error fetching page ${currentPage}:`, error);
              }
            });

        fetchPromises.push(fetchPromise);
      }
      await Promise.all(fetchPromises);
      const orderedOffers = allOffers.flat();
      if(isOfferIdFromUrl()){
        removeSelectedOfferFromList(orderedOffers);
      }
      addElementsToOffers(orderedOffers);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.info('Main fetch aborted');
      } else {
        console.error('Error fetching job offers:', error);
      }
    }
  }
}

async function handleOfferFromUrlNotFound() {
  if (isOfferIdFromUrl()) {
    return JobOfferService.getJobOffer(router.currentRoute.value.params.id).then(
        response => {
          if (response.ok) {
            return false
          } else {
            offerFromUrlNotFound.value = true
            return true;
          }
        }
    );
  } else {
    return false;
  }
}

async function goToAllOffersWhenJobFromUrlNotFound(){
  offerFromUrlNotFound.value = false
  router.push({name: 'JobOffersMainBoard'})
  router.currentRoute.value.params.id = null
  fetchOffers2(false,false)
}

function abortPreviousFetches() {
  if (abortAdditionalFetch) {
    isLoading.value = false;
    isReady.value = false;
    noResultFound.value = false
    try {
      abortAdditionalFetch.abort();
    } catch (ex) {
      console.error('Abort error:', ex);
    }
  }
}

function removeSelectedOfferFromList(offers) {
  const offerIndex = offers.findIndex(offer => offer.id === selectedJobOffer.value.id);
  if (offerIndex !== -1) {
    offers.splice(offerIndex, 1);
  }
}

async function initSelectedOffer(canBeOfferFromUrl) {
  if (canBeOfferFromUrl && isOfferIdFromUrl()) {
    let response = await JobOfferService.getJobOffer(router.currentRoute.value.params.id)
    selectedJobOffer.value = await response.json()
    removeSelectedOfferFromList(offers.value);
    offers.value.unshift(selectedJobOffer.value);
    await OfferCounterService.increaseOfferView(selectedJobOffer.value.id);
  } else if (offers.value.length > 0) {
    selectedJobOffer.value = offers.value[0];
    await OfferCounterService.increaseOfferView(selectedJobOffer.value.id);
  }
}

function makeOfferComponentReady() {
  isLoading.value = false
  noResultFound.value = false
  isReady.value = true
}

function useNewParams() {
  let params = new URLSearchParams({})
  params.append('page', "0")
  //TODO - test maximum pagge size that do not affect performance
  params.append('size', "40")
  params.append('sort', sorting.value)
  globalParams.value = params
}

async function clearJobOffers() {
  isReady.value = false
  isLoading.value = true
  noResultFound.value = false
  offers.value = []
  currentPage.value = 0;
}

const globalParams = ref(null)

function receiveFilterOffersEvent(
    jobPositions,
    skills,
    seniorityLevels,
    operatingModes,
    contractTypes,
    salaryFrom,
    salaryTo,
    locations,
    sorting,
    workTypes,
    isObserved,
) {
  resetInfiniteScroll();
  let params = new URLSearchParams({});
  params.append('page', '0');
  params.append('size', '30');

  if (sorting !== 'Obserwowane') {
    params.append('sort', sorting);
  }

  if (skills && skills.length > 0) {
    params.append('skills', concatenateWithComma(skills));
  }

  if (workTypes && workTypes.length > 0) {
    params.append('workTypes', concatenateWithComma(workTypes));
  }

  if (jobPositions && jobPositions.length > 0) {
    params.append('jobPositions', concatenateWithComma(jobPositions));
  }

  if (seniorityLevels && seniorityLevels.length > 0) {
    params.append('seniorityLevels', concatenateWithComma(seniorityLevels));
  }

  if (contractTypes && contractTypes.length > 0) {
    params.append('contractTypes', concatenateWithComma(contractTypes));
  }

  if (locations && locations.length > 0) {
    params.append('cities', concatenateWithComma(locations));
  }

  if (salaryFrom != null) {
    params.append('salaryFrom', salaryFrom);
  }

  if (salaryTo != null && salaryTo < 40000) {
    params.append('salaryTo', salaryTo);
  }

  if (operatingModes && operatingModes.length > 0) {
    params.append('operatingModes', concatenateWithComma(operatingModes));
  }

  if (isObserved) {
    params.append('favorite', true);
  }

  globalParams.value = params;
  currentNumberOfElements.value = null;
  fetchOffers2(isObserved, false);
}

function concatenateWithComma(arr) {
  return arr.join(",");
}

function resetInfiniteScroll() {
  ScrollUtils.scrollToTop('scrollTargetRef')
  clearJobOffers();
  isReady.value = false
  isLoading.value = false;
  noResultFound.value = false
  currentNumberOfElements.value = null;
  totalNumberOfElements.value = null;
  currentPage.value = 0
}

const selectedJobOffer = ref(null);
const jobOfferDetailsRef = ref(null)

function updateCurrentJobOffer(value) {
  if (router.currentRoute.value.params.id !== value) {
    OfferCounterService.increaseOfferView(value)
  }
  shouldShowHighlightedOverview.value = true
  offerFromUrlNotFound.value = false
  selectedJobOffer.value = findJobOfferById(value);
  jobOfferDetailsRef.value.scrollToTop()
}

function findJobOfferById(id) {
  return offers.value.find(job => job.id === id);
}

const observedOffersId = ref([])

const isReady = ref(false)

function initComponentData() {
  isReady.value = false;
  if (currentUser.isAuthenticated()) {
    observedOffersId.value = currentUser.favoriteOffersIds ? currentUser.favoriteOffersIds : [];
  }
}

function isOfferIdFromUrl() {
  let id = router.currentRoute.value.params.id;
  return id !== undefined && id !== null && isNotBlank(id);
}

function isNotBlank(str) {
  if (str === null || str === undefined) {
    return false;
  }
  const trimmedStr = str.trim();
  return trimmedStr !== '';
}

function isFavForLoggedUser(jobOfferId) {
  //todo here is infinitive loop
  return observedOffersId.value.some(id => id === jobOfferId);
}

</script>

<style lang="scss" scoped>
.apexcharts-tooltip {
  background-color: #80207b;
  color: $secondary;
  font-size: 12px;
}

.apexcharts-tooltip-title {
  background-color: #80207b;
  color: white;
  font-size: 12px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: $secondary !important;
}

.input-select {
  width: 150px;
  margin: 0;
  padding: 0
}

.q-field__native, .q-field__prefix, .q-field__suffix, .q-field__input {
  color: $secondary !important;
}

.q-field--auto-height.q-field--dense .q-field__control, .q-field--auto-height.q-field--dense .q-field__native {
  max-height: 34px;
  min-height: 10px;
  border-radius: 8px;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-button {
  background: #ffffff
}

::-webkit-scrollbar-track-piece {
  background: #ffffff
}

::-webkit-scrollbar-thumb {
  background: #8e959a
}

</style>

