<template>
  <email-dialog-with-templates v-model:show-dialog="emailDialog" @open:mails="handleOpenMail"/>
  <application-answers v-model:show-dialog="showAnswers" v-model:model-value="application"></application-answers>
  <q-dialog v-model="showCv" class="row custom-dialog-wrapper" @before-hide="closeNotes()">
    <div class="row no-wrap custom-dialog full-height custom-dialog-content q-pb-none" style="max-width: 1150px">
      <!-- First q-card -->
      <div class="q-mr-lg">
        <q-card class="full-height scrollable-area" style=" border-radius: 9px">
          <vue-pdf-embed :source="srcData" style="width: 700px;"/>
        </q-card>
      </div>
      <div class="col row q-pt-none justify-end">
        <div class="col row column">
          <q-card bordered class="col row" style="border-radius: 9px">
            <q-card-section class="column col q-pa-md">
              <div class="row q-mb-sm" style="margin:0; border-radius: 7px">
                <q-chip style="border-radius: 12px 12px 0 0!important; font-size: 20px!important;"
                        class="full-width q-mb-md text-white" square
                        color="black" text-color="white">
                  Notatki - {{ application.applicantName }}
                </q-chip>
              </div>
              <!--           //TODO add editor validation -->
              <div class="col row" style="width: 100%; height: 100%">
                <q-editor
                    v-model="editor"
                    max-height="auto"
                    toolbar-toggle-color="accent"
                    min-height="100vh"
                    class="full-height full-width q-scrollarea"
                    toolbar-color="dark"
                    :toolbar="[
            ['bold', 'unordered', 'undo', 'redo'],
          ]"
                    :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"/>
              </div>

              <div class="q-pt-md">
                <q-btn style="border-radius: 5px" class="full-width q-px-none q-mx-none bg-accent text-dark"
                       label="Zamknij podgląd" size="14px"
                       outline
                       v-close-popup
                />
              </div>
            </q-card-section>


          </q-card>
        </div>
      </div>
    </div>
  </q-dialog>

  <div class="q-pt-sm q-px-sm q-pb-sm">
    <q-card bordered class="q-pa-sm emaimy-card q-hoverable cursor-pointer" style="border-radius: 9px">
      <span class="q-focus-helper"></span>

      <q-card-section class="q-pa-sm">
        <div class="row justify-between">
          <div class="q-pa-none q-pl-xs">
            <div class="row q-pl-none">
              <q-checkbox class="q-pb-xs" color="primary" size="35px" dense v-model="application.checked" @click="emitCheckUpdate"/>
              <div class="q-pl-sm q-pt-none q-pb-xs">
                <my-text class="text-black" style="font-size: 14px">{{ application.applicantName }}</my-text>
              </div>
            </div>
          </div>
          <div class="q-pb-none q-gutter-sm">
            <q-checkbox
                size="sm"
                dense
                color="teal-14"
                v-model="isAccepted"
                checked-icon="o_thumb_up"
                unchecked-icon="o_thumb_up"
                @click="patchRating('ACCEPTED')"
            />
            <q-checkbox
                size="sm"
                dense
                color="deep-purple-5"
                v-model="isMarked"
                checked-icon="o_live_help"
                unchecked-icon="o_live_help"
                @click="patchRating('UNDECIDED')"

            />
            <q-checkbox
                size="sm"
                dense
                class="q-pr-sm"
                color="warning"
                v-model="isRejected"
                checked-icon="o_thumb_down"
                unchecked-icon="o_thumb_down"
                @click="patchRating('REJECTED')"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-section class="q-pa-none q-pl-sm">
        <div class="row justify-between full-width">
          <div class="q-py-sm">
            <q-icon name="fa-solid fa-phone" size="10px" class="q-mr-xs">
              <q-tooltip class="bg-white text-dark">Preferowany czas kontaktu</q-tooltip>
            </q-icon>
            <my-text class="q-pl-xs text-dark" style="font-size: 11px">{{ getPreferredContactTime() }}</my-text>
          </div>
          <div class="q-pb-none q-mr-xs">
            <q-btn flat
                   size="12px"
                   outline
                   @click="showEmailDialog()"
                   color="black"
                   dense
                   class="q-px-sm bg-accent-1"
                   :label="application.applicantEmail" no-caps style="border-radius: 7px !important;"/>
          </div>
        </div>
      </q-card-section>
      <q-separator class="q-mx-sm"/>
      <q-card-actions class="q-pb-none q-pt-md">
        <div class="row justify-between full-width">
          <div class="q-gutter-sm justify-between">
            <q-btn class="q-mt-xs bg-white text-dark" outline square label="Podgląd" size="8px"
                   style="border-radius: 5px !important;"
                   @click="fetchByteArray"/>
            <q-btn class="q-mt-xs bg-white text-dark" outline v-if="application.answers.length !== 0"
                   label="Odpowiedzi"
                   size="8px"
                   @click="showAnswersDialog()" style="border-radius: 5px !important;"
            />
            <q-btn class="q-mt-xs bg-white text-dark" outline label="Pobierz" size="8px" @click="downloadCV"
                   style="border-radius: 5px !important;"/>
          </div>
          <!--          <div>-->
          <!--            <q-btn size="7px" dense icon="sym_o_phone" disable outline color="primary" label="Nieumówione">-->
          <!--            </q-btn>-->
          <!--          </div>-->
        </div>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted, ref} from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
import organizationApplicationService from '@/services/OrganizationApplicationService.js';
import ApplicationAnswers from "@/components/organization/applications/ApplicationAnswers";
import notificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import EmailDialogWithTemplates from "@/components/organization/applications/EmailDialogWithTemplates";

const srcData = ref(null)
const showCv = ref(false)
const showAnswers = ref(false)

const isAccepted = ref(false);
const isMarked = ref(false);
const isRejected = ref(false);
const props = defineProps({
  modelValue: Object,
  id: String,
})
const $q = useQuasar()

const emit = defineEmits(['modelValue', 'accepted'])

const application = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {
  let rating = application.value.result
  if (rating === 'ACCEPTED') {
    isAccepted.value = true;
  } else if (rating === 'UNDECIDED') {
    setRatingValuesToFalse();
    isMarked.value = true
  } else {
    isRejected.value = true
  }
})

function setRatingValuesToFalse() {
  isAccepted.value = false
  isMarked.value = false
  isRejected.value = false
}


const editor = ref(application.value.notes)

function emitCheckUpdate() {
  emit("checkUpdate")
}

async function downloadCV() {
  let status = await organizationApplicationService.downloadCV(application.value.id)
  if (status === 404) {
    notificationService.showNotification($q, 'negative', 'Nie można pobrać pliku - CV zostało usunięte')
  }
}

async function fetchByteArray() {
  const response = await organizationApplicationService.getCV(application.value.id)
  if (response.ok) {
    const data = await response.json();
    const base64Data = data.file;
    const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
    const blob = new Blob([byteArray], {type: 'application/pdf'});
    srcData.value = URL.createObjectURL(blob);
    showCv.value = true;
  } else {
    notificationService.showNotification($q, 'negative', 'Nie można zobaczyć pliku - CV zostało usunięte')
  }
}

function getPreferredContactTime() {
  if (application.value.preferredContactTime !== null) {
    return application.value.preferredContactTime.from + ' - ' + application.value.preferredContactTime.to
  } else {
    return '00:00 - 23:59'
  }
}

async function closeNotes() {
  await organizationApplicationService.patchApplicationNotes(application.value.id, editor.value)
  showCv.value = false
}

async function patchRating(rating) {
  setRatingValuesToFalse()
  if (rating === 'ACCEPTED') {
    isAccepted.value = true;
  } else if (rating === 'UNDECIDED') {
    isMarked.value = true
  } else {
    isRejected.value = true
  }
  await organizationApplicationService.patchApplicationRating(application.value.id, rating)
}

function showAnswersDialog() {
  showAnswers.value = true;
}

const emailDialog = ref(false)

function showEmailDialog() {
  emailDialog.value = true;
}

import HtmlToPlainTextParser from "@/services/organization/emails/HtmlToPlainTextParser";
function handleOpenMail(data) {
  const {subject, message} = data;
  let plainText = HtmlToPlainTextParser.htmlToText(message);
  const mailtoURL = `mailto:${application.value.applicantEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(plainText)}`;
  const link = document.createElement('a');
  link.href = mailtoURL;
  link.target = '_blank';
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

</script>

<style lang="scss" scoped>

.custom-dialog {
  width: 100%;
  max-width: 1500px;
  max-height: 800px; // Add this line
}


.custom-dialog-wrapper {
  width: 100%;
  padding: 8px; // Adjust this value to control the padding around the q-dialog
}

.custom-card {
  width: calc(76% - 8px);
  max-width: 704px;
  padding: 4px;
  overflow-y: auto; // Add this line
}


.custom-dialog-content {
  margin-left: 0;
  margin-top: 0;
}

.custom-card-second {
  flex-grow: 1;
  padding: 4px;
  max-width: 607px;
  overflow-y: auto; // Add this line
}

.custom-q-editor {
  height: 100%;

  & > .q-editor__content {
    height: 100%;
  }
}


.scrollable-area {
  height: 500px;
  overflow-y: auto;
}
</style>