<template>
  <q-drawer
      v-model="drawerLeft"
      show-if-above
      @mouseover="miniStateLeft = false"
      @mouseout="miniStateLeft = true"
      :breakpoint="500"
      :width="250"
  >
    <div>
      <div class="text-center">
        <q-img
            fit="contain"
            src="@/assets/bytejobwhite.svg"
            style="width: 200px; height: 80px"
        />
      </div>
    </div>

    <q-list class="column q-col-gutter-md" style="margin-top: 50%">
      <!-- MY_PROFILE -->
      <div class="q-mx-md row items-center">
        <q-card
            flat
            class="q-px-sm q-py-xs q-mr-sm"
            :class="getButtonClass('MY_PROFILE')"
            style="border-radius: 12px !important;"
        >
          <q-icon name="fa-regular fa-user" size="sm"></q-icon>
        </q-card>
        <div class="col">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountPage' })"
              dense
              flat
              style="border-radius: 7px"
              align="left"
              class="full-width q-pl-md" 
              :class="getButtonClass('MY_PROFILE', true)"
              label="MÓJ PROFIL"
          />
        </div>
      </div>

      <!-- JOB_OFFERS -->
      <div class="q-mx-md row items-center">
        <q-card
            flat
            class="q-px-sm q-py-xs q-mr-sm"
            :class="getButtonClass('JOB_OFFERS')"
            style="border-radius: 12px !important;"
        >
          <q-icon name="fa-solid fa-briefcase" size="sm"></q-icon>
        </q-card>
        <div class="col">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationJobOffersBoard' })"
              dense
              flat
              style="border-radius: 7px"
              align="left"
              class="full-width q-pl-md"
              :class="getButtonClass('JOB_OFFERS', true)"
              label="OFERTY PRACY"
          />
        </div>
      </div>

      <!-- EMAIL_TEMPLATES -->
      <div class="q-mx-md row items-center">
        <q-card
            flat
            class="q-px-sm q-py-xs q-mr-sm"
            :class="getButtonClass('EMAIL_TEMPLATES')"
            style="border-radius: 12px !important;"
        >
          <q-icon name="fa-solid fa-envelope" size="sm"></q-icon>
        </q-card>
        <div class="col">
          <q-btn
              rounded
              @click="router.push({ name: 'EmailTemplatesPage' })"
              dense
              flat
              style="border-radius: 7px"
              align="left"
              class="full-width q-pl-md" 
              :class="getButtonClass('EMAIL_TEMPLATES', true)"
              label="SZABLONY E-MAIL"
          />
        </div>
      </div>

      <!-- ACCOUNTS -->
      <div class="q-mx-md row items-center">
        <q-card
            flat
            class="q-px-sm q-py-xs q-mr-sm"
            :class="getButtonClass('ACCOUNTS')"
            style="border-radius: 12px !important;"
        >
          <q-icon name="fa-solid fa-address-book" size="sm"></q-icon>
        </q-card>
        <div class="col">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationAccountManagement' })"
              dense
              flat
              style="border-radius: 7px"
              align="left"
              class="full-width q-pl-md" 
              :class="getButtonClass('ACCOUNTS', true)"
              label="KONTA"
          />
        </div>
      </div>

      <!-- ORGANIZATION_DATA -->
      <div class="q-mx-md row items-center">
        <q-card
            flat
            class="q-px-sm q-py-xs q-mr-sm"
            :class="getButtonClass('ORGANIZATION_DATA')"
            style="border-radius: 12px !important;"
        >
          <q-icon name="fa-solid fa-building" size="sm"></q-icon>
        </q-card>
        <div class="col">
          <q-btn
              rounded
              @click="router.push({ name: 'OrganizationDataPage' })"
              dense
              flat
              style="border-radius: 7px"
              align="left"
              class="full-width q-pl-md" 
              :class="getButtonClass('ORGANIZATION_DATA', true)"
              label="DANE ORGANIZACJI"
          />
        </div>
      </div>

      <!-- INVOICES -->
      <div class="q-mx-md row items-center">
        <q-card
            flat
            class="q-px-sm q-py-xs q-mr-sm"
            :class="getButtonClass('INVOICES')"
            style="border-radius: 12px !important;"
        >
          <q-icon name="fa-solid fa-file-lines" size="sm"></q-icon>
        </q-card>
        <div class="col">
          <q-btn
              rounded
              @click="router.push({ name: 'InvoicesMainBoard' })"
              dense
              flat
              style="border-radius: 7px"
              align="left"
              class="full-width q-pl-md" 
              :class="getButtonClass('INVOICES', true)"
              label="FAKTURY"
          />
        </div>
      </div>

      <!-- LOGOUT -->
      <div class="q-mx-md row items-center">
        <q-card
            flat
            class="q-px-sm q-py-xs q-mr-sm"
            :class="getButtonClass('LOGOUT')"
            style="border-radius: 12px !important;"
        >
          <q-icon name="fa-solid fa-arrow-right-from-bracket" size="sm"></q-icon>
        </q-card>
        <div class="col">
          <q-btn
              rounded
              @click="AuthenticationService.logout()"
              dense
              flat
              style="border-radius: 7px"
              align="left"
              class="full-width q-pl-md" 
              :class="getButtonClass('LOGOUT', true)"
              label="WYLOGUJ"
          />
        </div>
      </div>
    </q-list>
  </q-drawer>
</template>

<script setup>
import { useRouter } from "vue-router";
import AuthenticationService from "@/services/AuthenticationService";
import { ref, defineProps } from "vue";
const drawerLeft = ref(false);
const miniStateLeft = ref(true);
const router = useRouter();

// Props
const props = defineProps({
  activeButton: {
    type: String,
    default: 'jobOffers',
  },
});

const getButtonClass = (buttonName, isText = false) => {
  if (props.activeButton !== buttonName) {
    return isText ? 'text-white bg-transparent' : 'bg-transparent text-white';
  } else {
    return isText ? 'text-dark bg-white' : 'bg-transparent text-white';
  }
};
</script>
