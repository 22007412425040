<template>
  <q-dialog v-model="showDialog">
    <q-card bordered class="q-pa-xs" style="border-radius: 9px">
      <q-card-section class="row items-center q-pb-sm">
        <q-icon class="q-pr-sm" name="fa-solid fa-file-circle-xmark" size="md" color="accent" text-color="white"/>
        <span
            class="q-ml-sm text-dark">Czy na pewno chcesz usunąć zaznaczone aplikacje? Liczba plików: {{ checkedAppsCount }}</span>
      </q-card-section>
      <div class="q-mx-md q-my-xs">
        <q-separator></q-separator>
      </div>
      <q-card-actions align="right" class="q-mr-sm">
        <q-btn flat dense label="Anuluj" color="dark" v-close-popup no-caps/>
        <q-btn outline label="Usuń" dense class="bg-accent text-dark" v-close-popup no-caps @click="sendDeleteEmit()"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";

const props = defineProps({
  checkedAppsCount: String,
  showDialog: Boolean
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});

const checkedAppsCount = computed({
  get: () => props.checkedAppsCount,
  set: (val) => emit('update:checkedAppCount', val),
});

const emit = defineEmits(['delete:applications'])

function sendDeleteEmit() {
  emit('delete:applications')
}
</script>