<template>
  <div class="bg-my">
    <q-layout view="hHh Lpr lff" container style="height: 96vh">
      <q-header>
        <!--          <q-btn class="q-pt-none" flat @click="drawerLeft = !drawerLeft" round dense icon="menu" color="primary" />-->
        <menu-bar class="full-width q-pb-none" style="height: 44px"></menu-bar>
        <!--          <q-btn flat @click="drawerRight = !drawerRight" round dense icon="menu" color="primary" />-->
        <q-separator color="white"/>

      </q-header>
      <q-drawer
          v-model="drawerLeft"
          show-if-above
          @mouseover="miniStateLeft = false"
          @mouseout="miniStateLeft = true"
          :width="150"
          :breakpoint="500"
          bordered
          :class="$q.dark.isActive ? 'bg-white' : 'bg-white'"
      >
        <q-list>
          <q-separator/>
          <p class="text-center text-blue-5 q-pt-sm text-bold q-mb-sm" style="font-size: 15px">Główne Menu
            <q-icon class="q-pt-none q-pl-xs" size="23px" color="blue-5" name="info">
              <q-tooltip max-width="120px" class="bg-blue-5 text-white shadow-8" :offset="[10, 10]">
                Główne menu pozwala przejść do sekcji związanych z opublikowanymi ofertami, ulubionymi CV ze wszystkich
                ofert, statystykami oraz kontami pracowników.
              </q-tooltip>
            </q-icon>
          </p>
          <q-separator/>
          <q-item active clickable v-ripple @click="router.push({ name: 'OrganizationJobOffersBoard' });">
            <q-item-section class="text-white">
              <div class="row">
                <q-icon class="text-blue-5" size="25px" name="sym_o_article"/>
                <p class="q-pt-none q-pl-sm text-blue-5">Oferty</p>
              </div>
            </q-item-section>
          </q-item>
          <!--          <q-item active clickable v-ripple @click="this.$router.push({ name: 'EmailsBoard' });">-->
          <!--            TODO IN OTHER STORY-->
          <!--            <q-item-section class="text-white">-->
          <!--              <div class="row">-->
          <!--                <q-icon class="text-blue-5" size="25px" name="sym_o_email"/>-->
          <!--                <p class="q-pt-none q-pl-sm text-blue-5">Wiadomości</p>-->
          <!--              </div>-->
          <!--            </q-item-section>-->
          <!--          </q-item>-->
          <!--          <q-item clickable v-ripple>-->
          <!--            <q-item-section class="text-white">-->
          <!--              <div class="row">-->
          <!--                <q-icon class="text-blue-5" size="25px" name="sym_o_recent_actors"/>-->
          <!--                <p class="q-pt-none q-pl-sm text-blue-5"> Zapisane CV</p>-->
          <!--              </div>-->
          <!--            </q-item-section>-->
          <!--          </q-item>-->
          <!--          <q-item clickable v-ripple>-->
          <!--            <q-item-section class="text-white">-->
          <!--              <div class="row">-->
          <!--                <q-icon class="text-blue-5" size="25px" name="sym_o_monitoring"/>-->
          <!--                <p class="q-pt-none q-pl-sm text-blue-5">Statystyki</p>-->
          <!--              </div>-->
          <!--            </q-item-section>-->
          <!--          </q-item>-->

          <q-separator color="white"/>

          <q-item clickable v-ripple @click="router.push({ name: 'OrganizationAccountManagement' });">
            <q-item-section class="text-white">
              <div class="row">
                <q-icon class="text-blue-5" size="25px" name="sym_o_manage_accounts"/>
                <p class="q-pt-xs q-pl-sm text-blue-5">Rekruterzy</p>
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </q-drawer>
      <q-page-container style="background-color: white;">
        <q-page class="q-pt-sm">
          <div class="page">
            <div class="left-pane q-pl-lg">
              <div class="templates-list q-pt-md">
                <div v-for="(template, index) in templates" :key="index" class="template-row">
                  <q-chip style="height: 40px; width: 90%" outline square color="primary" text-color="white" icon="mail"
                          :label="template.name"
                          v-if="templates.length !== 0"/>
                  <q-btn @click="deleteTemplate(index)" color="secondary" icon="delete" round dense flat
                         class="delete-btn" v-if="templates.length !== 0"/>

                </div>
                <div class="row">
                  <q-chip v-if="templates.length===0" style="height: 40px; width: 90%" outline square text-color="white"
                          icon="mail" class="bg-grey-6"
                          label="Brak zdefiniowanych szablonów"
                  />
                  <q-icon v-if="templates.length === 0" class="q-pt-xs q-pl-xs text-blue-5" size="40px" name="info">
                    <q-tooltip max-width="120px" class="bg-blue-5 text-white shadow-8" :offset="[10, 10]">
                      Brak szablonów. Dodaj nowy szablon maila, gdy chcesz wysłać tą samą wiadomość do kilku aplikantów.
                    </q-tooltip>
                  </q-icon>
                </div>
              </div>
            </div>
            <div class="right-pane q-pr-lg">
              <div class="editor-container">
                <div>
                  <q-input
                      dense
                      outlined
                      v-model="templateName"
                      label="Nazwa szablonu"
                      :rules="[val => !!val || 'Nazwa szablonu jest wymagana.']"
                  />
                </div>
                <div class="q-pt-xs">
                  <q-input dense outlined v-model="emailSubject" label="Temat maila"
                           :rules="[val => !!val || 'Tytuł maila jest wymagany.']"/>
                </div>
                <div class="editor-wrapper q-pt-xs">
                  <q-editor
                      class="q-px-md full-height"
                      v-model="editor"
                      min-height="100%"
                      toolbar-toggle-color="accent"
                      style="border-radius: 9px;overflow-x: auto;word-break: break-word;"
                      toolbar-color="secondary"
                      :toolbar="[['bold', 'unordered', 'undo', 'redo']]"
                      :rules="[val => !!val || 'To pole jest wymagane']"
                      :style="fieldRef && fieldRef.hasError ? 'border-color: #C10015' : ''"
                  />
                </div>
                <div class="q-pt-lg">
                  <q-btn disable v-if="isBlank(emailSubject) || isBlank(templateName) || isBlank(editor)" dense
                         color="grey-8" outline class="full-width" @click="addTemplate"
                         label="Dodaj szablon - wszystkie pola są wymagane"/>
                  <q-btn v-else-if="!containsTemplateWithName(templates,templateName)" dense color="secondary" outline
                         class="full-width" @click="addTemplate" label="Dodaj szablon"/>
                  <q-btn v-else dense color="secondary" outline class="full-width" @click="addTemplate"
                         label="Edytuj szablon"/>
                </div>
              </div>
            </div>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
  <q-separator color="white"/>
  <div class="full-width bg-my-bottom" style="height: 4vh"></div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import MenuBar from "@/components/common/MenuBar";
import JobOfferService from "@/services/JobOfferService";
import {useRouter} from "vue-router/dist/vue-router";

const router = useRouter()

const drawerLeft = ref(false)
const miniStateLeft = ref(true)

const jobOfferTitle = ref(null)

onMounted(() => {
  JobOfferService.getJobOffer(router.currentRoute.value.params.id).then((response) => {
    response.json().then((data) => {
      return jobOfferTitle.value = data.title
    })
  });
})
const editor = ref('');
const emailSubject = ref('');
const templateName = ref('');
const templates = ref([]);

const addTemplate = () => {
  if (emailSubject.value && editor.value && templateName.value) {
    templates.value.push({
      name: templateName.value,
      subject: emailSubject.value,
      content: editor.value,
    });
  }
};

const deleteTemplate = (index) => {
  templates.value.splice(index, 1);
};

function isBlank(str) {
  return /^\s*$/.test(str);
}

function containsTemplateWithName(templates, name) {
  return templates.some(template => template.name === name);
}
</script>

<style lang="scss">
.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}

.page {
  display: flex;
}

.left-pane {
  flex: 1;
  display: flex;
  flex-flow: column;
  height: 88vh
}

.right-pane {
  flex: 1;
  display: flex;
  flex-flow: column;
  height: 88vh
}

.templates-list {
  margin-bottom: 20px;
}

.template-row {
  display: flex;
  margin-bottom: 10px;
}

.delete-btn {
  margin-left: 0;
}

.editor-container {
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.editor-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
}
</style>