<template>
  <div class="column col">
    <q-dialog v-model="cvRemovalDialog">
      <q-card bordered style="width: 300px; border-radius: 9px;">
        <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
          <div class="text-h7 text-dark-9 text-center">
            <div class="q-pt-none q-pb-sm q-mb-none text-center">
              <q-icon size="md" name="fa-regular fa-circle-question" color="negative" class=""></q-icon>
            </div>
            <div>
              <span class="text-center" style="font-size: 13px">Czy na pewno chcesz usunąć plik?</span>
            </div>
            <div>
              <span class="text-center" style="font-size: 13px">Aby aplikować na oferty musisz wgrać przynajmniej jedno CV.</span>
            </div>
          </div>
        </q-card-section>
        <div class="text-center q-mb-md">
          <q-btn @click="removeCv()" style="border-radius: 7px"
                 outline
                 class="q-px-md q-mr-sm bg-accent text-dark" dense label="Ok"
          ></q-btn>
          <q-btn @click="closeCvRemovalDialog()" outline style="border-radius: 7px"
                 class="q-px-md" dense label="Powrót"
                 color="dark"></q-btn>
        </div>
      </q-card>
    </q-dialog>
    <q-card flat bordered v-ripple class="cursor-pointer q-hoverable row col flex-center"
            :class="evaluateCardBackgroundColor()"
            style="border-radius: 9px; border-color:#d5d7ea;"
            @mouseenter="handleMouseOver()"
            @mouseleave="handleMouseOut()"
            @click="handleClickOnCard()">

      <div v-if="!isResumeUploaded()" class="column flex-center">
        <q-icon name="mdi-file-document-plus-outline" size="sm" class="text-grey-5 q-mx-lg q-mt-lg"></q-icon>
        <p class="q-mb-none text-grey-5 ellipsis q-pt-xs q-mb-md">
          dodaj
        </p>
      </div>

      <div v-if="isResumeUploaded()" class="row col-6 flex-center">
        <div class="col">
          <div class="text-center">
            <q-icon name="mdi-file-document-remove-outline" size="sm" class="text-dark q-mt-lg"></q-icon>
          </div>
          <p class="q-mb-none text-dark ellipsis q-pt-xs q-mb-md">
            {{ resume.fileName }}
          </p>
        </div>
      </div>
    </q-card>
    <div class="q-mt-sm">
      <q-checkbox v-model="resume.defaultResume" label="Domyślne" class="text-dark q-pl-none" size="xs"
                  style="font-size: 10px!important;"
                  @click="handleDefaultResumeCheckbox()"
                  v-if="isResumeUploaded()"></q-checkbox>
      <q-checkbox v-model="resume.defaultResume" label="Domyślne"
                  style="font-size: 10px!important;"
                  size="xs" class="text-grey-6" v-else disable
                  @click="handleDefaultResumeCheckbox()"></q-checkbox>
    </div>
  </div>
</template>
<script setup>


import {computed, defineEmits, defineProps, onMounted, ref} from "vue";
import {useQuasar} from "quasar";
import NotificationService from "@/services/notification/NotificationService";
import ApplicationService from "@/services/applicant/ApplicationService";
import CookieService from "@/services/common/CookieService";
import {currentUser} from "@/global-variables/currentUser";

const props = defineProps({
  applicantId: String,
  modelValue: Object,

})
const emit = defineEmits(['resumes:update', 'update:modelValue'])
const resume = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

onMounted(() => {
})

const isCvUploaded = ref(false)
const cvRemovalDialog = ref(false)

function handleClickOnCard() {
  if (isResumeUploaded()) {
    showRemovalConfirmationDialog();
  } else {
    uploadCv()
  }
}

function closeCvRemovalDialog() {
  cvRemovalDialog.value = false
}

function showRemovalConfirmationDialog() {
  cvRemovalDialog.value = true;
}

const $q = useQuasar()

function uploadCv() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.pdf';
  input.onchange = () => {
    let files = Array.from(input.files);
    let file = files[0];
    if (file.size > 5 * 1024 * 1024) {
      NotificationService.showNotification($q, 'negative', 'Maksymalny rozmiar pliku - 5 MB.');
      return;
    }
    isCvUploaded.value = true
    ApplicationService.addResume(file).then((response) => {
      response.json().then(body => {
        if (response.ok) {
          emit("resumes:update")
          NotificationService.showNotification($q, "positive", "Dodano CV")
        } else {
          NotificationService.showNotification($q, "negative", body.title)
        }

      })
    })
  };
  input.click();
}

function removeCv() {
  let resumeId = resume.value.id;
  try {
    return fetch(process.env.VUE_APP_BACKEND_URL + "/applicants/" + props.applicantId + '/resumes/' + resumeId, {
      method: 'DELETE',
      credentials: "include",
      headers: {
        'X-XSRF-TOKEN': CookieService.getCookieValue("XSRF-TOKEN"),
      }
    })
        .then(response => {
          if (response.ok) {
            currentUser.resumes = currentUser.resumes.filter(resume => resume.id !== resumeId);
            cvRemovalDialog.value = false
            NotificationService.showNotification($q, "negative", "Usunięto CV")
            emit("resumes:update")
          }
          return response
        })
  } catch (error) {
    console.error(error);
    throw error
  }
}

// const srcData = ref(null)

// async function fetchByteArray() {
//   const base64Data = getResumes().then(response => {
//     response.
//   });
//   const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
//   const blob = new Blob([byteArray], {type: 'application/pdf'});
//   srcData.value = URL.createObjectURL(blob);
//   showCv.value = true;
// }


function isResumeUploaded() {
  return resume.value.id !== null
}


const isMouseOnCard = ref(false);

function handleMouseOver() {
  isMouseOnCard.value = true;
}

function handleMouseOut() {
  isMouseOnCard.value = false;
}

function evaluateCardBackgroundColor() {
  if (isMouseOnCard.value && !isResumeUploaded()) {
    if (isMouseOnCard.value) {
      return 'bg-dark'
    } else {
      return 'bg-white'
    }
  } else {
    if (isMouseOnCard.value) {
      return 'bg-grey-1'
    } else {
      return 'bg-white'
    }
  }

}

function handleDefaultResumeCheckbox() {
  if (isResumeUploaded()) {
    ApplicationService.setResumeAsDefault(resume.value.id)
        .then(() => {
          emit("resumes:update", resume.value.id)
        })
  }
}

</script>