<template>
  <mobile-menu v-if="isMobile()"></mobile-menu>
  <menu-bar :is-menu-visible="true" v-else></menu-bar>
  <div class="login-center" v-if="successfulPasswordSetup">
    <q-card class="q-px-sm" style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-none">
        <div class="q-pt-none q-pb-sm">
          <q-icon size="xl" name="task_alt" color="dark"></q-icon>
        </div>
        <div class="text-center text-h7 text-dark">Pomyślna rejestracja - przejdź do strony logowania
        </div>
      </q-card-section>
      <q-card-section class="text-center">
        <q-btn outline padding="3px xl" class="bg-accent text-dark" type="submit" label="Ok"
               @click="router.push({ name: 'ApplicantLoginPage' });"></q-btn>
      </q-card-section>
    </q-card>
  </div>
  <div class="login-center" v-else>
    <q-card bordered class="q-px-xs" style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-sm">
        <div class="text-center text-h6 text-dark q-pr-md">Dokończ rejestrację</div>
      </q-card-section>
      <q-form @submit.prevent="submitForm" greedy>
        <q-card-section class="q-pb-xs">
          <q-input
              dense
              outlined
              rounded
              color="dark"
              :type="isPwd ? 'password' : 'text'"
              v-model="password"
              label="Hasło"
              id="companyName"
              :rules="[PasswordValidator.validatePassword]"
          >
            <template v-slot:append>
              <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
          <div class="text-center q-pt-xs q-pb-none"> <!-- Add the text-center class to this div -->
            <q-btn size="13px" flat color="dark" @click="router.push({ name: 'ApplicantLoginPage' });"
                   icon="login"
                   label="Masz konto? - Zaloguj się"
                   no-caps/>
          </div>
        </q-card-section>
        <q-card-actions class="text-center q-pl-md q-pr-md q-pb-md q-pt-sm" align="center">
          <q-btn padding="3px xl"
                 outline
                 type="submit"
                 class="bg-accent text-dark"
                 label="Dołącz"></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script setup>
import MenuBar from "@/components/common/MenuBar";
import {ref} from 'vue'
import ApplicantRegistrationService from "@/services/applicant/ApplicantRegistrationService";
import {useQuasar} from 'quasar'
import {useRouter} from "vue-router";
import MobileMenu from "@/components/mobile/MobileMenu";
import PasswordValidator from "@/services/common/security/PasswordValidator";

const $q = useQuasar();
const router = useRouter()
const password = ref('')
const successfulPasswordSetup = ref(false)
const isPwd = ref(true)

async function submitForm() {
  const userId = router.currentRoute.value.query.userId;
  const token = router.currentRoute.value.query.token;

  const response = await ApplicantRegistrationService.confirmRegistration(userId, token, password.value);
  if (response.status === 200) {
    successfulPasswordSetup.value = true;
  } else if (response.status === 400) {
    $q.notify({
      type: 'negative',
      message: 'Link weryfikacyjny wygasł lub rejestracja została już potwierdzona.'
    })
  } else if (response.status === 404) {
    $q.notify({
      type: 'negative',
      message: 'Konto o podanym id nie istnieje.'
    })
  }
}

function isMobile() {
  return $q.platform.is.mobile
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>