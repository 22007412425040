// services/OrganizationUsersService.js

import StringUtils from "@/services/common/StringUtils";
import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";
import JsonBlankFieldsCleaner from "@/services/common/JsonBlankFieldsCleaner";

class OrganizationUsersService {
    constructor(url) {
        this.url = url;
    }

    // Method to get users from the API
    async getUsersWithCookie() {
        const url = `${this.url}/organizations/${currentUser.organizationId}/users?size=200`;

        try {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return await response.json();
        } catch (error) {
            console.error("Error during organization user GET method:", error);
            throw error;
        }
    }

    async addUser(newUserDto) {
        if (StringUtils.isStringEmpty(newUserDto.firstName)) {
            delete newUserDto.firstName;
        }
        if (StringUtils.isStringEmpty(newUserDto.lastName)) {
            delete newUserDto.lastName;
        }
        if (StringUtils.isStringEmpty(newUserDto.phoneNumber)) {
            delete newUserDto.phoneNumber;
        }

        let jsonWithoutEmptyFields = JsonBlankFieldsCleaner.removeEmptyFields(newUserDto)

        try {
            const requestOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(jsonWithoutEmptyFields),
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during addUser request:", error);
            throw error;
        }
    }

    async changeUserAccountActivity(active, userId) {
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({ active }),
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/${userId}/status`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during changeUserAccountActivity request:", error);
            throw error;
        }
    }

    async getCurrentUser() {
        const url = `${this.url}/organizations/${currentUser.organizationId}/users?size=200`;

        try {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            const json = await response.json();
            return json.content.find(
                (account) => account.email === currentUser.id
            );
        } catch (error) {
            console.error("Error during getCurrentUser method:", error);
            throw error;
        }
    }

    async passAdmin(userId) {
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({ userId }),
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/admin`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            localStorage.clear();
            return response;
        } catch (error) {
            console.error("Error during passAdmin request:", error);
            throw error;
        }
    }

    async updateUserData(user) {
        try {
            let json = {
                firstName: user.firstName,
                lastName: user.lastName,
                phoneNumber: user.phoneNumber,
                position: user.position,
            };
            let jsonWithoutEmptyFields = JsonBlankFieldsCleaner.removeEmptyFields(json)
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(jsonWithoutEmptyFields),
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/${user.id}`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during updateUserData request:", error);
            throw error;
        }
    }

    async getUser(userId) {
        const url = `${this.url}/organizations/${currentUser.organizationId}/users/${userId}`;

        try {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            };

            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return await response.json();
        } catch (error) {
            console.error("Error during getUser method:", error);
            throw error;
        }
    }

    async updateUserPermissions(userId, permissions) {
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(permissions),
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/${userId}/permissions`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during updateUserPermissions request:", error);
            throw error;
        }
    }

    async changeAvatar(userId, fileInBase64, fileName) {
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({ fileInBase64, fileName }),
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/${userId}/avatar`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during changeAvatar request:", error);
            throw error;
        }
    }

    getAvatar(userId) {
        try {
            const requestOptions = {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/${userId}/avatar`;
            return FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error("Error during getAvatar request:", error);
            throw error;
        }
    }

    async deleteAvatar(userId) {
        try {
            const requestOptions = {
                method: "DELETE",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/${userId}/avatar`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during deleteAvatar request:", error);
            throw error;
        }
    }

    async changePassword(userId, oldPassword, newPassword) {
        try {
            const requestOptions = {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({ newPassword, oldPassword }),
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/${userId}/change-password`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during changePassword request:", error);
            throw error;
        }
    }

    async resetPassword(email) {
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify(email),
            };
            const url = `${this.url}/organizations/password-reset`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during resetPassword request:", error);
            throw error;
        }
    }

    async resetSetupForPassword(userId, organizationId, password, token) {
        try {
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
                body: JSON.stringify({
                    password,
                    token,
                }),
            };
            const url = `${this.url}/organizations/${organizationId}/users/${userId}/password`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during resetSetupForPassword request:", error);
            throw error;
        }
    }

    async deleteObservedOffers(currentUser, jobOfferId) {
        try {
            const requestOptions = {
                method: "DELETE",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
            };
            const url = `${this.url}/organizations/${currentUser.organizationId}/users/${currentUser.id}/favorite-offers/${jobOfferId}`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during deleteObservedOffers request:", error);
            throw error;
        }
    }

    async addObservedOffers(currentOrganizationUser, offerId) {
        try {
            const requestOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
                },
            };
            const url = `${this.url}/organizations/${currentOrganizationUser.organizationId}/users/${currentOrganizationUser.id}/favorite-offers?jobOfferId=${offerId}`;
            const response = await FetchWrapper.fetchWithAuth(url, requestOptions);
            return response;
        } catch (error) {
            console.error("Error during addObservedOffers request:", error);
            throw error;
        }
    }
}

export default new OrganizationUsersService(process.env.VUE_APP_BACKEND_URL);
