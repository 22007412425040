<template>
    <q-dialog v-model="showDialog" position="right">
      <q-card bordered style="border-radius: 9px 0 0 9px; max-width: 450px;">
        <div v-for="questionFilter in questionsForFilterModel.questions" :key="questionFilter.questionId"
             class="q-px-md q-pb-xs">
          <q-card bordered flat class="q-mt-lg bg-white" style="border-radius: 9px">
            <q-card-section>
              <p class="text-dark q-px-sm q-ma-none" style="font-size: 13px">{{ questionFilter.question }}</p>
            </q-card-section>
          </q-card>
          <text-answer-filter v-if="isTextTypeAnswer(questionFilter)"
                              :question-filter="questionFilter">
          </text-answer-filter>
          <number-answer v-else-if="isNumberTypeAnswer(questionFilter)"
                         :question-filter="questionFilter">
          </number-answer>
          <boolean-answer-filter v-else-if="isBooleanTypeAnswer(questionFilter)"
                                 :question-filter="questionFilter"
          >
          </boolean-answer-filter>
        </div>
        <q-card-section class="q-pt-none">
          <div class="row justify-start q-pb-none q-pt-none q-gutter-md">
            <q-btn size="md" class="bg-accent text-dark" outline label="Filtruj" @click="emitFilterValues()"
                   style="border-radius: 7px"/>
            <q-btn size="md" color="dark" outline label="Resetuj filtry" @click="emitResetFilters()"
                   style="border-radius: 7px"/>
            <q-btn size="md" color="dark" outline label="Zamknij" @click="showDialog=false"
                   style="border-radius: 7px"/>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";
import NumberAnswer from "@/components/organization/applications/questions/NumberAnswerFilter";
import TextAnswerFilter from "@/components/organization/applications/questions/TextAnswerFilter";
import BooleanAnswerFilter from "@/components/organization/applications/questions/BooleanAnswerFilter";

const props = defineProps({
  checkedAppsCount: String,
  showDialog: Boolean,
  questionsForFilterModel: Object
})

const showDialog = computed({
  get: () => props.showDialog,
  set: (val) => emit('update:showDialog', val),
});

const questionsForFilterModel = computed({
  get: () => props.questionsForFilterModel,
  set: (val) => emit('update:questionsForFilterModel', val),
});

const emit = defineEmits(['update:showDialog'])

function isTextTypeAnswer(question) {
  return question.answerType === "TEXT"
}

function isNumberTypeAnswer(question) {
  return question.answerType === "NUMBER"
}

function isBooleanTypeAnswer(question) {
  return question.answerType === "BOOLEAN"
}

function emitFilterValues() {
  showDialog.value = false
  emit('questionsFilter')
}

function emitResetFilters() {
  questionsForFilterModel.value.questions.forEach(question => {
    question.search = null;
  });

  showDialog.value = false
  emit('resetFilters')
}
</script>