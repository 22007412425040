// services/OrganizatioOfferManagementService.js

import CookieService from "@/services/common/CookieService";
import FetchWrapper from "@/services/common/FetchWrapper";
import {currentUser} from "@/global-variables/currentUser";

class OrganizatioOfferManagementService {
    constructor(url) {
        this.url = url;
    }

    async addJobOffer(jobOffer) {
        const organizationId = currentUser.organizationId
        return await this._saveJobOffer(
            jobOffer,
            "POST",
            `/organizations/${organizationId}/job-offers`
        );
    }

    async updateJobOffer(jobOffer) {
        const organizationId = currentUser.organizationId;
        const cleanedJobOffer = this.removeNullEmptyBlankSalaryFields(jobOffer);
        return await this._saveJobOffer(
            cleanedJobOffer,
            "PUT",
            `/organizations/${organizationId}/job-offers/${jobOffer.id}`
        );
    }

    removeNullEmptyBlankSalaryFields(jobOffer) {
        const cleanedJobOffer = { ...jobOffer }; // Clone to avoid mutating the original object
        if (Array.isArray(cleanedJobOffer.contracts)) {
            cleanedJobOffer.contracts.forEach((contract) => {
                if (contract.salary !== null && typeof contract.salary === "object") {
                    const { from, to, currency } = contract.salary;
                    if (
                        this.isNullOrWhiteSpace(from) ||
                        this.isNullOrWhiteSpace(to) ||
                        this.isNullOrWhiteSpace(currency)
                    ) {
                        delete contract.salary; // Remove salary if any field is null, empty, or blank
                    }
                }
            });
        }
        return cleanedJobOffer;
    }

    isNullOrWhiteSpace(str) {
        return str === null || str === undefined || this.isBlank(str);
    }

    isBlank(str) {
        return /^\s*$/.test(str);
    }

    async _saveJobOffer(object, methodType, endpoint) {
        const requestOptions = {
            method: methodType,
            headers: {
                "Content-Type": "application/json",
                "X-XSRF-TOKEN": CookieService.getCookieValue("XSRF-TOKEN"),
            },
            credentials: "include",
            body: JSON.stringify(object),
        };

        const url = `${process.env.VUE_APP_BACKEND_URL}${endpoint}`;

        try {
            return await FetchWrapper.fetchWithAuth(url, requestOptions);
        } catch (error) {
            console.error("Error saving job offer:", error);
            throw error;
        }
    }
}

export default new OrganizatioOfferManagementService(
    process.env.VUE_APP_BACKEND_URL
);
