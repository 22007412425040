<template>
  <menu-bar :is-menu-visible="true" class="q-pl-sm q-pr-sm"></menu-bar>
  <div class="login-center" v-if="successfulPasswordSetupDialog">
    <q-card bordered style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-none">
        <div class="q-pt-none q-pb-sm q-mr-md">
          <q-icon color="dark" name="fa-regular fa-circle-check q-pt-sm q-pr-sm" size="40px"/>
        </div>
        <div class="q-pt-md text-center text-h7 text-dark q-pb-sm">Pomyślnie ustawiono nowe hasło
        </div>
      </q-card-section>
      <q-card-section class="text-center q-pt-sm">
        <q-btn outline class="bg-accent text-dark" padding="3px xl" type="submit" label="Ok"
               @click="router.push({ name: 'OrganizationLoginPage' });"></q-btn>
      </q-card-section>
    </q-card>
  </div>
  <div v-else-if="showPasswordSetupDialog" class="login-center">
    <q-card style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-sm">
        <!--        <div class="q-pt-xs q-pr-sm">-->
        <!--          <q-icon color="secondary" size="24px" name="sym_o_person"></q-icon>-->
        <!--        </div>-->
        <div class="text-center text-h6 text-black">Dokończ reset hasła</div>
      </q-card-section>
      <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
        <q-btn size="13px" flat @click="router.push({ name: 'OrganizationLoginPage' });"
               label="Masz konto? - Zaloguj się"
               class="accent-3"
               no-caps/>
      </div>
      <q-form @submit.prevent="setNewPassword()" greedy>
        <q-card-section class="q-pb-xs">
          <q-input
              dense
              outlined
              rounded
              color="dark"
              :type="isPwd ? 'password' : 'text'"
              v-model="newPassword"
              label="Hasło"
              id="companyName"
              :rules="[PasswordValidator.validatePassword]"
          >
            <template v-slot:append>
              <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </q-card-section>
        <q-card-actions class="text-center q-pl-md q-pr-md q-pb-lg q-pt-sm" align="center">
          <q-btn outline padding="3px xl" type="submit" label="Zmień"
                 class="bg-accent text-dark"
          ></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
  <div class="login-center" v-else-if="successfulReset">
    <q-card bordered class="q-px-md q-py-xs" style=" border-radius: 9px">
      <q-card-section class="row justify-center q-px-sm q-pb-none">
        <div class="q-pt-none q-pb-sm q-mr-md q-pr-sm">
          <q-spinner-hourglass
              color="dark"
              size="lg"
              :thickness="1"/>
        </div>
        <div class=" text-center text-h7 text-black q-pb-sm q-mt-sm">
          <p>Dokończ reset hasła za pomocą maila</p>
        </div>
      </q-card-section>
      <q-card-section class="text-center q-pt-xs">
        <q-btn outline class="bg-accent text-dark" padding="3px xl" type="submit" label="Ok"
               @click="router.push({ name: 'OrganizationLoginPage' });"></q-btn>
      </q-card-section>
    </q-card>
  </div>
  <div v-else class="login-center">
    <q-card bordered class="q-px-xs" style="width: 350px; border-radius: 9px">
      <q-card-section class="row justify-center q-pb-sm">
        <div class="text-center text-h6 text-black">Resetuj hasło</div>
      </q-card-section>
      <div class="text-center q-pt-none q-pb-none"> <!-- Add the text-center class to this div -->
        <q-btn size="13px"
               flat
               class="accent-3"
               @click="router.push({ name: 'OrganizationLoginPage' });"
               label="Masz konto? - Zaloguj się"
               no-caps/>
      </div>
      <q-form @submit.prevent="resetPassword()" greedy>
        <q-card-section class="q-pb-xs">
          <q-input
              dense
              outlined
              rounded
              color="dark"
              v-model="email"
              label="E-mail"
              id="email"
              :rules="[val => !!val || 'E-mail jest wymagany']"
          ></q-input>
        </q-card-section>
        <q-card-actions class="text-center q-pl-md q-pr-md q-pb-lg" align="center">
          <q-btn rounded padding="3px xl" type="submit"
                 outline
                 class="bg-accent text-dark"
                 label="Resetuj"
          ></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>
</template>

<script setup>
import MenuBar from "@/components/common/MenuBar";
import {onMounted, ref} from 'vue';
import {useRouter} from "vue-router/dist/vue-router";
import {useQuasar} from 'quasar'
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";
import PasswordValidator from "@/services/common/security/PasswordValidator";

const email = ref('')

const organizationId = ref(null)
const userId = ref(null)
const token = ref(null)
const newPassword = ref('')
const router = useRouter()
const showPasswordSetupDialog = ref(null)
const successfulPasswordSetupDialog = ref(false)
const isPwd = ref(true)

onMounted(() => {
  organizationId.value = router.currentRoute.value.query.organizationId
  userId.value = router.currentRoute.value.query.userId
  token.value = router.currentRoute.value.query.token
  showPasswordSetupDialog.value = organizationId.value !== undefined && userId.value !== undefined && token.value !== undefined;
})

const $q = useQuasar();

const successfulReset = ref(false)

async function resetPassword() {
  const response = await OrganizationUsersService.resetPassword(email.value);
  if (response.status === 200) {
    successfulReset.value = true;
  } else if (response.status === 400) {
    const json = await response.json()
    if (json.message.includes('Validation failed on fields: [email].')) {
      $q.notify({
        type: 'negative',
        message: 'Konto o podanym adresie e-mail jest już zarejestrowane w systemie.'
      })
    } else if (json.message.includes('email=must be a well-formed email address')) {
      $q.notify({
        type: 'negative',
        message: 'Niepoprawny format adresu e-mail'
      })
    } else if (response.status === 429) {
      $q.notify({
        type: 'negative',
        message: 'Zbyt wiele prób resetu hasłą'
      })
    }
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas logowania - skontaktuj się z supportem.'
    })
  }
}

async function setNewPassword() {
  const response = await OrganizationUsersService.resetSetupForPassword(userId.value, organizationId.value, newPassword.value, token.value)
  if (response.status === 200) {
    successfulPasswordSetupDialog.value = true;
  } else if (response.status === 400) {
    $q.notify({
      type: 'negative',
      message: 'Token wygasł lub jest niepoprawny'
    })
  } else {
    $q.notify({
      type: 'negative',
      message: 'Błąd podczas logowania - skontaktuj się z supportem'
    })
  }
}
</script>
<style>
.login-center {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
</style>