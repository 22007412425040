<template>
  <div class="row justify-between">
    <div class="q-pr-lg q-pb-none">
      <q-badge outline color="dark" :label="language" />
      <q-rating
          v-model="proficiency"
          size="1em"
          icon="fa-regular fa-square"
          icon-selected="fa-solid fa-square"
          color="dark"
          no-reset
          class="q-pl-sm"
          :max="5"
      />
      <div class="q-pt-xs">
        <p>{{ 'Poziom: ' + describeRatingValue() }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, computed, defineEmits} from 'vue';

const props = defineProps({
  formTemplateData: Object,
  language: String,
  proficiency: Number,
  modelValue: Object
});

const emit = defineEmits(['update:proficiency']);

const proficiency = computed({
  get: () => props.proficiency,
  set: (val) => emit('update:proficiency', val)
});

function describeRatingValue() {
  let level = mapRatingProficiencyToModelValue();
  if (level != null) {
    return level;
  } else {
    return 'wybierz poziom';
  }
}

function mapRatingProficiencyToModelValue() {
  if (proficiency.value === 1) {
    return 'Podstawowy';
  } else if (proficiency.value === 2) {
    return 'Komunikatywny';
  } else if (proficiency.value === 3) {
    return 'Średniozaawansowany';
  } else if (proficiency.value === 4) {
    return 'Zaawansowany';
  } else if (proficiency.value === 5) {
    return 'Biegły';
  }
}
</script>
