<template>
  <q-card class="q-pa-none q-pt-none" flat>
    <q-checkbox
        :model-value="modelValue"
        @update:model-value="updateValue"
        class="q-mt-sm items-center"
        color="dark"
        size="sm"
        keep-color
    >
      <div class="row items-center q-ml-sm">
        <div @click.stop="toggleCheck" class="col">
          <p class="text-black">
            Wyrażam zgodę na otrzymywanie korespondencji handlowej dotyczącej portalu pracy ByteJob.it na podany adres
            e-mail lub numer telefonu.
          </p>
          <!-- Animated content -->
          <div v-if="showMore">
            <p class="q-pt-md">
              Niniejsza zgoda może zostać wycofana w dowolnym momencie z poziomu panelu pracodawcy.
            </p>
            <p class="q-pt-md">
              W przypadku udzielenia zgody, Twoje dane osobowe będą przetwarzane przez Byte Job IT sp. z o.o. z
              siedzibą w Poznaniu, przy ulicy Świętego Marcina 29/8, 61-806 Poznań, KRS nr: 0001129928, jako
              administratora.
            </p>
          </div>
          <q-btn
              flat
              dense
              @click.stop="toggleShowMore"
              no-caps
              size="11px"
              class="q-mt-xs text-bold accent-3"
          >
            {{ showMore ? 'Zwiń' : 'Rozwiń' }}
          </q-btn>
        </div>
      </div>
    </q-checkbox>
  </q-card>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const showMore = ref(false);

const toggleCheck = () => {
  emit('update:modelValue', !props.modelValue);
};

const toggleShowMore = () => {
  showMore.value = !showMore.value;
};

const updateValue = (value) => {
  emit('update:modelValue', value);
};
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5em;
  font-size: 12px !important;
}

.row.items-center {
  display: flex;
  align-items: center;
}
</style>
