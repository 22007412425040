<template>
  <div id="app">
    <cookie-banner/>
    <router-view/>
  </div>
</template>
<script setup>
import CookieBanner from "@/components/common/CookieBanner.vue";
import {ref, nextTick} from "vue";

const bannerReset = ref(0);
nextTick(() => {
  bannerReset.value += 1;
});
</script>


<style lang="scss">
.q-field--standout .q-field__control {
  border-radius: 12px !important;
}

.q-field {
  border-radius: 12px !important;
}

//.q-btn{
//  border-radius: 7px !important;
//}

.q-field--outlined.q-field--rounded .q-field__control {
  border-radius: 12px !important;
}

.q-notification {
  border-radius: 12px !important;
}

:deep(.q-field) {
  border-radius: 12px
}

//
//.q-field--standout.q-field--highlighted .q-field__control {
//  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
//}

.q-chip {
  background: white !important;
}

.q-chip--dense .q-chip__icon {
  font-size: 18px !important;
}

#app > div > div > main > div.row.col.q-pt-none.q-pl-none.column.q-pt-none > div.row.q-mr-md.q-ml-sm > div > label.q-field.row.no-wrap.items-start.q-field--standout.q-select.q-field--auto-height.q-select--with-input.q-select--with-chips.q-select--multiple.q-field--float.q-field--labeled.q-field--dense.col.q-px-md > div > div > div:nth-child(2) > button {
  color: black;
}

#app > div > div > main > div.row.col.q-pt-none.q-pl-none.column.q-pt-none > div.row.q-mr-md.q-ml-sm > div > label.q-field.row.no-wrap.items-start.q-field--standout.q-select.q-field--auto-height.q-select--with-input.q-select--with-chips.q-select--multiple.q-field--float.q-field--labeled.q-field--dense.col.q-px-md > div > div > div:nth-child(3) > i {
  color: #f06292;
}

.q-fab--form-square {
  border-radius: 7px !important
}

input, select {
  color: $input-text-color !important;
  -webkit-text-fill-color: $input-text-color !important;
  -webkit-background-clip: text !important;
}

.q-field__native > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.q-field--outlined .q-field__control:before {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.accent-3 {
  color: $accent-3 !important;
}

.positive-2 {
  color: $accent-3 !important;
}

.bg-accent-3 {
  background-color: $accent-3 !important;
}

.bg-accent-2 {
  background-color: $accent-2 !important;
}

.bg-accent-1 {
  background-color: $accent-1 !important;
}

.dark-3 {
  color: $dark-3 !important;
}


.dark-2 {
  color: $dark-2 !important;
}

.dark-1 {
  color: $dark-1 !important;
}

.bg-primary-1 {
  background-color: $primary-1 !important;
}

.bg-primary-3 {
  background-color: $primary-3 !important;
}

.my-card-with-border {
  border-radius: 9px;
  border-color: $dark;
}

.q-field__control {
  border-radius: 12px !important;
}

.q-field__inner {
  border-radius: 12px !important;
}

.q-field {
  border-radius: 12px !important;
}

.q-chip {
  border-radius: 5px !important;
  font-size: 10px !important;
}

.q-btn {
  border-radius: 11px !important;
}

.q-card {
  border-radius: 18px !important;
  border-color: $dark-3 !important;
}

.q-stepper {
  border-radius: 18px !important;
  border-color: $dark-3 !important;
}

.q-separator {
  background-color: $dark-3 !important;
}

.q-page-container {
  background-color: $secondary !important;
}

.q-field__bottom {
  padding: 3px 12px 0 !important;
}

.q-drawer {
  background: linear-gradient(20deg, $info, $primary) !important; /* Use your primary and accent colors here */
  color: white !important; /* Text color */
}

.bg-gradient-menu-organization {
  background: linear-gradient(90deg, $primary 50%, $secondary 90%) !important;
  color: white !important; /* Text color */
}

p {
  font-size: 14px !important;
}

html, body {
  height: 100%;
  overflow: auto;
}
</style>
