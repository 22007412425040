<template>
  <div class="text-center q-mt-lg">
    <p class="text-h5 text-black q-mt-md" style="font-size: 23px !important;">Znajdź pracownika z <span
        class="text-black">Byte Job
        <span class="text-black">.</span>
      </span> Dodaj
      ogłoszenie już od <span
          class="text-bold text-accent">0zł</span></p>
    <p class="text-h7 q-mt-md text-black q-mt-sm q-mb-sm" style="font-size: 17px!important;">Zyskaj dostęp do systemu
      rekrutacyjnego za darmo</p>
  </div>
  <div id="topDiv" class="row justify-center q-mb-lg col row" v-if="formTemplateData !== null"
       :class="calculatedTopMarginAndPadding()">
    <q-form @submit="showConfirmationDialogForPaymentIfNeeded()" greedy :style="calculateWidthBasedOnStep()">
      <q-stepper
          v-model="step"
          header-nav
          ref="stepper"
          flat
          inactive-color="dark"
          color="primary"
          animated
          style="border-radius: 9px"
          class="q-mb-lg q-mt-md bg-transparent">
        <q-step
            :name="1"
            title="Wybierz ofertę"
            active-color="accent"
            icon="settings"
            :done="step > 1"
            :header-nav="step > 1"
        >
          <div class="row justify-between">
            <plan-basic
                @step:next="nextStep()"
                :plan="getBasicPlan()"
                :is-button-active="areButtonActive"
                class="q-my-lg"
                v-model:plan-for-offer="jobOfferData.plan"
                style="border-width: 1px"
            >
            </plan-basic>

            <plan-basic @step:next="nextStep()"
                        v-if="showSecondPlan"
                        :type="'Standard'"
                        :plan="getStadardPlan()"
                        class="q-my-lg"
                        :is-button-active="areButtonActive"
                        style="border-width: 1px"
                        v-model:plan-for-offer="jobOfferData.plan">
            </plan-basic>
            <div v-else style="width: 30%">

            </div>

            <plan-basic @step:next="nextStep()"
                        v-if="showThirdPlan"
                        :is-button-active="false"
                        :type="'Premium'"
                        :plan="getPremiumPlan()"
                        class="q-my-lg"
                        style="border-width: 1px"
                        v-model:plan-for-offer="jobOfferData.plan">

            </plan-basic>
            <div v-else style="width: 30%">

            </div>
          </div>
          <div class="text-center q-mt-xs q-mb-lg q-pb-xs">
            <p>Ceny netto za miesiąc. Do podanych cen należy doliczyć podatek VAT w wysokości 23%.</p>
          </div>
          <!--          TODO-->
          <!--          <div class="text-center q-mt-lg">-->
          <!--            <p style="font-size: 40px!important;">Interesuje Cię nasza oferta?</p>-->
          <!--          </div>-->
          <!--          <div class="text-center q-mt-xs">-->
          <!--            <p style="font-size: 15px!important;">Zapraszamy do kontaktu – pomożemy Ci podjąć decyzję.</p>-->
          <!--          </div>-->
          <!--          <div class="text-center q-mt-xs">-->
          <!--            <q-btn no-caps outline class="text-bold bg-accent text-black" label="Napisz do nas"></q-btn>-->
          <!--          </div>-->


        </q-step>

        <q-step
            :name="2"
            active-color="accent"
            title="Dane ogłoszenia"
            icon="fa-regular fa-file-lines"
            :done="step > 2"
            :header-nav="step > 2"
        >
          <div class="col row">
            <job-offer-form :form-template-data="formTemplateData"
                            v-model="jobOfferData"
                            @step:next="nextStep"
                            @step:previous="previousStep"
                            :show-buttons="true"
            />
          </div>
        </q-step>
        <q-step
            :name="3"
            :title="getStepThreeTitle()"
            active-color="accent"
            icon="fa-solid fa-list-check"
            :done="step > 3"
            :header-nav="step > 3"
        >

          <q-card bordered class="col row column q-pa-lg">
            <q-card-section>
              <adding-offer-section-description class="q-mb-lg" title="Podgląd"
                                                description=" Twojego ogłoszenia. Jeśli chcesz poprawić wybrane dane, wróć do poprzedniego kroku."></adding-offer-section-description>
              <job-offer-details
                  :is-copy-link-available="false"
                  class="col row column"
                  :show-apply="false"
                  :padding-top="0"
                  :done="step > 4"
                  :job-offer="copiedJobOfferUiRepresentation">
              </job-offer-details>
              <q-stepper-navigation class="q-mt-md">
                <q-btn dense
                       outline
                       class="q-px-md bg-accent text-dark"
                       :label="getStepThreeButtonName()"
                       @click="nextStep()"/>
                <q-btn flat @click="previousStep()" color="dark" dense label="Powrót" class="q-ml-md"/>
              </q-stepper-navigation>
            </q-card-section>
          </q-card>

        </q-step>
        <q-step
            v-if="!isFreeOffer() && step !== 1"
            :name="4"
            title="Płatność"
            active-color="accent"
            icon="fa-solid fa-wallet"
            :header-nav="step > 4"
        >
          <q-card bordered class="col row q-pa-lg full-width">
            <job-offer-payment
                ref="paymentDataRef"
                :plan="jobOfferData.plan"
                :is-logged-organization-user="true"
                v-model:payment="jobOfferData.payment">
            </job-offer-payment>
            <q-stepper-navigation class="q-pt-none q-mb-none q-mt-lg">
              <q-btn type="submit" dense outline class="q-px-md bg-accent text-dark" label="Dodaj ogłoszenie"/>
              <q-btn flat @click="previousStep()" color="dark" dense label="Powrót" class="q-ml-md"/>
            </q-stepper-navigation>
          </q-card>
          <q-dialog v-model="showPaymentConfirmationDialog" persistent>
            <q-card style="width: 300px; border-radius: 9px;">
              <q-card-section class="row justify-center q-px-md q-pt-md q-pb-md">
                <div class="text-h7 text-grey-9 text-center">
                  <div class="q-pt-none q-pb-sm q-mb-none text-center">
                    <q-icon size="md" name="fa-regular fa-circle-question" color="accent" class=""></q-icon>
                  </div>
                  <div>
                    <span class="text-center" style="font-size: 13px">Wybierz kiedy chcesz zapłacić za ofertę.</span>
                  </div>
                  <div>
                    <span class="text-center" style="font-size: 13px">Ogłoszenie zostanie zapisane w panelu pracodawcy i pojawi się na tablicy od razu po dokonaniu płatności. </span>
                  </div>
                </div>
              </q-card-section>
              <div class="q-mb-md row justify-center">
                <div>
                  <q-btn @click="addOfferAndGoToPrzelewy()" style="border-radius: 7px"
                         class="q-pa-sm q-mr-sm bg-accent text-dark" dense label="Przejdź do Przelewy24"
                         outline
                         size="sm"
                  ></q-btn>
                </div>
                <div>
                  <q-btn @click="addOfferAndGoToMainBoard('Zapisano ofertę')" outline style="border-radius: 7px"
                         class="q-pa-sm" dense label="Zapłać później"
                         flat
                         size="sm"
                         color="dark"></q-btn>
                </div>

              </div>
            </q-card>
          </q-dialog>
        </q-step>
      </q-stepper>
    </q-form>
  </div>
</template>

<script setup>
import {nextTick, onMounted, reactive, ref} from "vue"
import JobOfferForm from "@/components/common/add-offer/job-offer/JobOfferForm";
import OfferDataTranslator from "@/services/organization/offer/OfferDataTranslator";
import {useRouter} from "vue-router/dist/vue-router";
import OrganizatioOfferManagementService from "@/services/organization/offer/OrganizatioOfferManagementService";
import JobOfferPlansService from "@/services/organization/offer/JobOfferPlansService";
import PlanBasic from "@/components/common/add-offer/plans/PlanBasic";
import JobOfferService from "@/services/JobOfferService";
import JobOfferSkillsTranslator from "@/services/offer/JobOfferSkillsTranslator";
import LocationTranslator from "@/services/LocationTranslator";
import JobOfferDetails from "@/components/common/job-offers/JobOfferDetails";
import JobOfferPayment from "@/components/organization/job-offers/adding/JobOfferPayment";
import organizationService from "@/services/organization/OrganizationDataService";
import AddingOfferSectionDescription from "@/components/common/add-offer/AddingOfferSectionDescription";
import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import JsonBlankFieldsCleaner from "@/services/common/JsonBlankFieldsCleaner";
import {currentUser} from "@/global-variables/currentUser";

const router = useRouter()
const $q = useQuasar()

const showSecondPlan = ref(false)
const showThirdPlan = ref(false)
const areButtonActive = ref(false)
onMounted(() => {
  areButtonActive.value = router.currentRoute.value.query.areButtonActive
  getFormTemplateData().then(data => {
    formTemplateData.value = data;
    setTimeout(() => {
      showSecondPlan.value = true;
    }, 400);

    setTimeout(() => {
      showThirdPlan.value = true;
    }, 800);
  });
  if (currentUser.isOrganizationUser()) {
    organizationService.getOrganizationInfo().then(response => response.json().then(body => {
      jobOfferData.organization = body;
      //TODO rename to name or company name on API level
      jobOfferData.organization.companyName = body.name
    }))
    let clonedJobOfferId = router.currentRoute.value.params.id;
    if (clonedJobOfferId) {
      JobOfferService.getJobOffer(clonedJobOfferId).then(
          response => {
            response.json().then(jobOffer => {
              jobOfferData.organization.companyName = jobOffer.organization.name
              jobOfferData.title = jobOffer.title;
              jobOfferData.jobPosition = jobOffer.jobPosition;
              jobOfferData.contracts = jobOffer.publication.contracts
              OfferDataTranslator.translateContractsToPolish(jobOffer.publication.contracts);
              jobOfferData.description = jobOffer.description
              jobOfferData.questions.forEach(question => {
                question.answerType = OfferDataTranslator.translateAnswerTypeToPolish(question.answerType);
              });
              jobOfferData.seniority = OfferDataTranslator.translateFromSeniorityToPolish(jobOffer.seniority);
              jobOfferData.languages = OfferDataTranslator.translateArrayLanguagesToPolish(jobOffer.languages);
              jobOfferData.locations = LocationTranslator.getCityArray(jobOffer.locations);
              jobOfferData.skills = JobOfferSkillsTranslator.translateLevelFromEnumToIntegerLevel(jobOffer.skills)
              jobOfferData.operatingModes = jobOffer.operatingModes
              jobOfferData.workTypes = jobOffer.workTypes
              jobOfferData.jobApplicationInfo = jobOffer.jobApplicationInfo
              organizationService.getOrganizationInfo().then(response => {
                response.json().then(body => {
                  jobOfferData.payment.billingData = body.billingData
                });
                jobOfferData.payment.email = "some@email.xom"
                //TODO
                jobOfferData.payment.method = "BLIK"
              })
            })
          }
      )
    }
  }

})
const formTemplateData = ref(null)

function getFormTemplateData() {
  const cacheKey = 'formTemplateData';
  const cacheExpiryKey = 'formTemplateDataExpiry';

  // Set cache expiration time (e.g., 1 hour)
  const cacheExpiryTime = 60 * 60 * 1000; // 1 hour
  const now = new Date().getTime();

  // Check if cached data exists and is not expired
  const cachedData = localStorage.getItem(cacheKey);
  const cacheExpiry = localStorage.getItem(cacheExpiryKey);

  if (cachedData && cacheExpiry && now < cacheExpiry) {
    // Return cached data as a promise
    return Promise.resolve(JSON.parse(cachedData));
  }

  // If there's no valid cache, fetch data from the API with Cache-Control headers
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Cache-Control": "max-age=3600, must-revalidate" // Use caching for 1 hour
    },
  };

  return fetch(process.env.VUE_APP_BACKEND_URL + "/job-offers/form-template-data", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Store the new data and set expiration time
        localStorage.setItem(cacheKey, JSON.stringify(data));
        localStorage.setItem(cacheExpiryKey, (now + cacheExpiryTime).toString());
        return data;
      })
      .catch((error) => {
        console.error("Error fetching form template data:", error);
      });
}

const step = ref(1)
const jobOfferData = reactive({
  title: "",
  seniority: "",
  jobPosition: "",
  contracts: [{type: null, salary: {from: null, to: null, currency: null}, id: 0}],
  questions: [],
  plan: {},
  description: "",
  operatingModes: [],
  skills: [],
  languages: [],
  cities: [],
  locations: [],
  workTypes: [],
  planType: "BASIC",
  contactEmails: [],
  active: true,
  jobApplicationInfo: {
    externalApplicationLink: null,
    applicationType: ref("INTERNAL")
  },
  payment: {
    email: "",
    payNow: false,
  }
})

const copiedJobOfferUiRepresentation = ref(null)

function nextStep() {
  alerty.value = true
  if (step.value === 3 && isFreeOffer()) {
    if (sendPostWithJobOffer("Dodano ofertę")) {
      triggerOngoingAddJobOfferNotification()
      return
    }
  } else {
    step.value = step.value + 1;
    scrollToTop();
    jobOfferData.planType = jobOfferData.plan.type
    if (isStepForJobOfferDetails()) {
      copiedJobOfferUiRepresentation.value = OfferDataTranslator.mapValuesToRequestModel(jobOfferData)
    }
  }
}

function triggerOngoingAddJobOfferNotification()
{
  const notif = $q.notify({
    type: 'ongoing',
    position: 'center',
    message: 'Dodawanie ogłoszenia'
  })
  setTimeout(() => {
    notif({
      type: 'positive',
      position: 'center',
      message: 'Dodano ogłoszenie',
      timeout: 1000
    })
    goToMainBoard()
  }, 3000)
}

function scrollToTop() {
  nextTick(() => {
    setTimeout(() => {
      const element = document.getElementById("topDiv");
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      } else {
        console.error("Element with ID 'topDiv' not found");
      }
    }, 200);
  });
}

function getStepThreeTitle() {
  if (isFreeOffer() || step.value === 1) {
    return 'Podsumowanie'
  } else {
    return "Podgląd"
  }
}

function isStepForJobOfferDetails() {
  return step.value === 3
}

function previousStep() {
  step.value = step.value - 1;
}

function calculateWidthBasedOnStep() {
  if (step.value === 4) {
    return 'width: 60%'
  } else if (step.value === 1) {
    return 'width: 1200px'
  } else {
    return 'width: 900px'
  }
}

function getStepThreeButtonName() {
  if (isFreeOffer()) {
    return 'Opublikuj ogłoszenie'
  } else {
    return 'Dalej'
  }
}

const alerty = ref(true)

const showAddOfferConfirmationDialog = ref(false)

async function sendPostWithJobOffer() {
  let isPaymentDataCorrect = isFreeOffer() || await validatePaymentData();
  if (isPaymentDataCorrect) {
    let postJobOfferRepresentation = OfferDataTranslator.mapValuesToRequestModel(jobOfferData)
    postJobOfferRepresentation.planType = postJobOfferRepresentation.plan.type
    postJobOfferRepresentation.plan = null
    JsonBlankFieldsCleaner.removeEmptyFields(postJobOfferRepresentation)
    try {
      const response = await OrganizatioOfferManagementService.addJobOffer(postJobOfferRepresentation)
      if (response.status === 201) {
        const body = await response.json();
        if (body.payment.url !== null) {
          paymentUrl.value = body.payment.url;
        }
        return true;
      } else if (response.status === 400) {
        const json = await response.json();
        if (json.message.includes('Wrong file format.')) {
          NotificationService.showNotification($q, 'negative', 'Błąd podczas dodawania oferty - skontaktuj sie z supportem');
        } else if (json.errorCode === 'EMAIL_ALREADY_TAKEN') {
          NotificationService.showNotification($q, 'negative', 'Konto o podanym adresie e-mail już istnieje, wróć do danych organizacji i zmień adres e-mail.');
        } else {
          console.error(json);
          NotificationService.showNotification($q, 'negative', 'Błąd podczas dodawania oferty - skontaktuj się z supportem.');
        }
        return false;
      } else {
        console.error(`Unexpected status code: ${response.status}`);
        NotificationService.showNotification($q, 'negative', 'Wystąpił nieoczekiwany błąd - skontaktuj się z supportem.');
        return false;
      }
    } catch (error) {
      console.error('Fetch error:', error);
      NotificationService.showNotification($q, 'negative', 'Wystąpił nieoczekiwany błąd - skontaktuj się z supportem.');
      return false;
    }
  }
}

function getBasicPlan() {
  return JobOfferPlansService.getBasicPlan(formTemplateData.value.plans)
}

function getStadardPlan() {
  return JobOfferPlansService.getStandardPlan(formTemplateData.value.plans)
}

function getPremiumPlan() {
  return JobOfferPlansService.getPremiumPlan(formTemplateData.value.plans)
}

const paymentDataRef = ref(null)


async function validatePaymentData() {
  let validate = paymentDataRef.value.validate();
  return await validate
}


const showPaymentConfirmationDialog = ref(false)

async function showConfirmationDialogForPaymentIfNeeded() {
  let isCorrect = await validatePaymentData();
  if (isCorrect) {
    let planPrice = paymentDataRef.value.getFinalPrice();
    if (planPrice === 0) {
      await addOfferAndGoToMainBoard("Dodano ofertę")
    } else {
      showPaymentConfirmationDialog.value = true
    }
  }
}

function isFreeOffer() {
  return findInitialPrice() === 0
}

function findInitialPrice() {
  if (jobOfferData.plan.type !== null && jobOfferData.plan.type !== undefined) {
    if (jobOfferData.plan.promotionalPrice === null || jobOfferData.plan.promotionalPrice === undefined) {
      return jobOfferData.plan.originalPrice.amount
    } else {
      return jobOfferData.plan.promotionalPrice.amount
    }
  } else {
    return false
  }

}

const paymentUrl = ref(null)

async function addOfferAndGoToPrzelewy() {
  let isAdded = await sendPostWithJobOffer("Zapisano ofertę")
  if (isAdded) {
    window.open(paymentUrl.value, '_blank')
    await goToMainBoard();
  } else {
    showPaymentConfirmationDialog.value = false;
  }

}

async function goToMainBoard() {
  await router.push({name: 'OrganizationJobOffersBoard'});
}

async function addOfferAndGoToMainBoard(message) {
  let isAdded = await sendPostWithJobOffer(message)
  if (isAdded) {
    showAddOfferConfirmationDialog.value = true
  } else {
    showPaymentConfirmationDialog.value = false;
  }
}

function calculatedTopMarginAndPadding() {
  if (step.value === 1) {
    return "q-mt-md q-pt-xs"
  } else {
    return "q-mt-lg q-pt-lg"
  }
}
</script>

<style lang="scss" scoped>
//.my-stepper {
//  width: 950px;
//}

.q-chip {
  background: white;
}

//
//
//.scrollableDiv {
//  overflow-y: auto;
//  width: 100%;
//  flex: 1;
//}

q-dialog q-card {
  max-height: 80vh;
  overflow-y: auto;
}

</style>
