<template>
  <q-card bordered>
    <q-card-section>
      <div class="text-h6 q-ml-md">Zarządzaj zgodami</div>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <div class="row">
        <q-toggle
            color="accent"
            v-model="directMarketingConsent"
            label="Korespondencja handlowa"
            @update:model-value="updateConsent"
        />
        <q-btn class="q-px-sm q-my-xs q-ml-sm q-mb-xs" size="xs" flat color="dark" icon="fa-regular fa-circle-question">
          <q-tooltip style="border-width: 1px" max-width="230px" anchor="top middle" self="bottom middle"
                     :offset="[10, 10]" class="bg-transparent text-dark">
            <q-card>
              <q-card-section>
                <p style="font-size: 12px!important;">
                  Wyrażenie zgody umożliwia przesyłanie informacji handlowych dotyczących promocji, ofert oraz nowych
                  funkcjonalności na podany przez użytkownika adres e-mail.
                </p>
              </q-card-section>
            </q-card>
          </q-tooltip>
        </q-btn>
      </div>
    </q-card-section>
  </q-card>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import OrganizationUserConsentsService from "@/services/organization/consents/OrganizationUserConsentsService";
import NotificationService from "@/services/notification/NotificationService";
import {currentUser} from "@/global-variables/currentUser";
import {useQuasar} from "quasar";

const $q = useQuasar();

const directMarketingConsent = ref(false);

const updateConsent = async () => {
  const consentData = {
    directMarketingConsent: directMarketingConsent.value,
  };
  try {
    await OrganizationUserConsentsService.updateUserConsents(currentUser.organizationId, currentUser.id, consentData);
    console.log('Consent updated successfully');
  } catch (error) {
    console.error('Error updating consent:', error);
    NotificationService.showNotification($q, "negative", "Błąd podczas aktualizacji zgody. Skontaktuj się z supportem.")
  }
};

onMounted(async () => {
  try {
    directMarketingConsent.value = currentUser.isDirectMarketingConsent();
  } catch (error) {
    console.error('Error fetching user consents:', error);
    NotificationService.showNotification($q, "negative", "Błąd podczas pobierania zgód. Skontaktuj się z supportem.")
  }
});
</script>

<style scoped>
.text-h6 {
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
