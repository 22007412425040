<template>
  <q-layout class="col row">
    <q-page-container class="column col row bg-secondary">
      <q-page class="column col row">
        <mobile-menu v-if="isMobile()"></mobile-menu>
        <menu-bar v-else
                  :is-menu-visible="true"
                  @currentUser="val => setCurrentUser(val)">
        </menu-bar>
        <div class="col row q-px-xs" v-if="isMobile()">
          <mobile-offers :key="counterToReInitCompoent" ref="offersRef"
                         class="q-pt-none q-mx-xs q-mb-xs col row">
          </mobile-offers>
        </div>
        <div class="col row q-px-xs" v-else>
          <offers
              :key="counterToReInitCompoent"
              ref="offersRef"
              class="q-pt-none q-mx-sm q-mb-xs">
          </offers>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import MenuBar from "/src/components/common/MenuBar.vue";
import Offers from "@/components/common/job-offers/Offers";
import {ref} from 'vue';
import {onBeforeRouteUpdate} from 'vue-router'
import {useQuasar} from 'quasar'
import MobileOffers from "@/components/mobile/MobileOffers";
import MobileMenu from "@/components/mobile/MobileMenu";
import {currentUser} from '@/global-variables/currentUser';

const offersRef = ref(null)
const counterToReInitCompoent = ref(0)
const $q = useQuasar()

onBeforeRouteUpdate(async (to, from, next) => {
  await initComponent();
  next();
});

async function initComponent() {
  //todo not optimal solution - reinit all component
  counterToReInitCompoent.value = counterToReInitCompoent.value + 1;
}

function setCurrentUser(val) {
  currentUser.value = val;
}

function isMobile() {
  return $q.platform.is.mobile
}
</script>

<style lang="scss" scoped>

.slide-wrap {
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.slide {
  min-width: max-content;
}

</style>
