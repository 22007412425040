<template>
  <div class="col row column">
    <q-layout style="width: 100%;" class="col row column">
      <organization-menu-drawer :active-button="'JOB_OFFERS'"></organization-menu-drawer>
      <q-page-container class="col row column">
        <q-page class="column col row bg-primary">
          <menu-bar class="bg-gradient-menu-organization"/>
          <q-card flat class="bg-secondary col row" style="border-radius: 25px 0 0 0 !important;">
            <div class="column col row">
              <div class="q-ml-sm" v-if="!isLoading">
                <organization-job-offer-details-nav-bar class="q-ma-xs"
                                                        :job-offer-title="jobOffer.title"></organization-job-offer-details-nav-bar>
              </div>
              <div v-if="isLoading" class=" col row full-width q-mt-lg">
                <div class="col row q-ma-lg" style="width: 45%">
                  <job-offer-details-skeleton class="col row "></job-offer-details-skeleton>
                </div>
                <div style="width: 50%" class="q-mr-lg q-mb-lg q-mt-sm col row column">
                  <q-card bordered style="border-radius: 9px" class="q-mt-md col row">
                    <q-item>
                      <q-item-section avatar>
                      </q-item-section>
                    </q-item>
                    <div class="q-mb-sm">
                      <q-separator class="q-mx-md"></q-separator>
                    </div>
                    <q-card-section class="q-pt-sm col row" style="height: 130px">
                    </q-card-section>
                  </q-card>
                  <q-card bordered style="border-radius: 9px" class="q-mt-md col row">
                    <q-item>
                      <q-item-section avatar>
                      </q-item-section>
                    </q-item>
                    <div class="q-mb-sm">
                      <q-separator class="q-mx-md"></q-separator>
                    </div>
                    <q-card-section class="q-pt-sm col row" style="height: 130px">
                    </q-card-section>
                  </q-card>
                </div>
              </div>
              <div v-else class="col row q-mx-sm q-mb-sm">
                <div class="col row">
                  <div class="col-6 row q-mb-md q-ml-md">
                    <organization-job-offer-details
                        style="width: 100%"
                        padding-top="0px"
                        class="col row"
                        :show-apply="false"
                        :job-offer="jobOffer"
                        justifyContent="justify-start">
                    </organization-job-offer-details>
                  </div>
                  <div class="q-mb-md q-ml-none q-mr-md column col row" style="width: 100%;">
                    <q-card bordered class="q-mb-lg col row" style="padding-left: 2px">
                      <apexchart
                          type="area"
                          class="q-ma-none col row q-px-md"
                          height="98%"
                          width="98%"
                          :options="applicationsChartOptions"
                          :series="applicationsSeries"
                      />
                    </q-card>
                    <q-card bordered class="col row" style="width: 100%">
                      <apexchart
                          type="area"
                          height="98%"
                          width="98%"
                          class="q-ma-none col row q-px-md"
                          :options="viewsChartOptions"
                          :series="viewsSeries"
                      />
                    </q-card>
                  </div>
                </div>
              </div>
            </div>
          </q-card>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>
<script setup>
import {onMounted, ref} from 'vue'
import {useRouter} from 'vue-router';
import MenuBar from "@/components/common/MenuBar";
import viewsChartService from "@/services/ViewsChartService";
import jobOfferService from "@/services/JobOfferService";
import applicationsChartService from "@/services/ApplicationsChartService";
import OrganizationJobOfferDetailsNavBar
  from "@/components/organization/job-offers/details/OrganizationJobOfferDetailsNavBar";
import OrganizationJobOfferDetails from "@/components/organization/job-offers/details/OrganizationJobOfferDetails";
import OrganizationMenuDrawer from "@/components/organization/common/OrganizationMenuDrawer";
import JobOfferDetailsSkeleton from "@/components/common/job-offers/skeletons/JobOfferDetailsSkeleton";

const router = useRouter()


const jobOfferId = router.currentRoute.value.params.id

const isLoading = ref(true);
const jobOffer = ref(null);
const isVisible = ref(true)

function fetchData() {
  let queryParams = new URLSearchParams();
  queryParams.append("page", 0);
  queryParams.append("size", 500);
  return jobOfferService.getJobOffersForOrganization(queryParams).then(json => {
    const selectedOffer = json.content.find(offer => offer.id === jobOfferId);
    if (selectedOffer) {
      jobOffer.value = selectedOffer;
      isVisible.value = selectedOffer.visible
      isLoading.value = false
      loadData(jobOffer.value)
      return jobOffer.value;
    } else {
      console.warn("Selected job offer not found.");
    }
  });
}

onMounted(() => {
  fetchData();
});

async function loadData(jobOffer) {
  applicationsChartOptions.value.xaxis.categories = applicationsChartService.get30DaysFromOfferPublicationDateAsArray(jobOffer);
  viewsChartOptions.value.xaxis.categories = applicationsChartService.get30DaysFromOfferPublicationDateAsArray(jobOffer);
  await new Promise(resolve => setTimeout(resolve, 10)); // 500 milliseconds delay
  viewsData.value = viewsChartService.calculateViewsForOneOffer(jobOffer);
  applicationData.value = applicationsChartService.dailyApplicationData(jobOffer);
}

const applicationsChartOptions = ref({
  grid: {
    borderColor: '#63DBD2FF', // Color of grid lines
    yaxis: {
      lines: {
        show: false // Show Y-axis grid lines
      }
    }
  },
  legend: {
    fontSize: '9px'
  },
  chart: {

    toolbar: {
      show: false // Disable toolbar
    },
    id: 'forecast-chart',
    type: 'area',
    stacked: true, // Stacked bar chart
  },
  plotOptions: {},
  dataLabels: {
    style: {
      colors: ['#ffffff'] // Color for data labels
    },
    enabled: false // Disable data labels
  },
  stroke: {
    width: 2, // Stroke width for smoothness
    curve: 'smooth', // Smooth curve for line charts
    colors: ['#63DBD2FF'] // Stroke color
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '11px', // X-axis label font size
        colors: '#868686' // X-axis label color
      },
    },
    categories: [] // Add your categories here
  },
  fill: {
    type: 'gradient', // Use gradient for bar fill
    gradient: {
      shade: 'dark', // Dark gradient shade
      shadeIntensity: 1, // Intensity of the gradient
      type: 'vertical', // Vertical gradient direction
      opacityFrom: 0.8, // Starting opacity for the gradient
      opacityTo: 0.3, // Ending opacity for smoother transition
      stops: [0, 100], // Gradient stops to define the gradient effect
      gradientToColors: ['#B1EDE9'] // Gradient transition color (matching others)
    },
    colors: ['#B1EDE9'], // Start color (matches other charts)
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return Math.floor(value); // Rounds down to remove decimal places
      },
      style: {
        colors: '#868686' // Y-axis label color
      }
    },
    title: {
      text: 'Przesłane aplikacje', // Y-axis title
      style: {
        fontFamily: 'Kanit', // Font family for Y-axis title
        color: '#424242' // Y-axis title color
      }
    },
  }
});

const viewsChartOptions = ref({
  legend: {
    fontSize: '9px', // Smaller font size for the legend
    offsetY: -10 // Adjust the space between legend and chart
  },
  chart: {
    toolbar: {
      show: false // Disable the toolbar
    },
    id: 'forecast-chart',
    type: 'area', // Bar chart type
    stacked: true, // Enable stacking for the bars
  },
  plotOptions: {},
  grid: {
    borderColor: '#63DBD2FF', // Same grid line color
    yaxis: {
      lines: {
        show: false // Hide Y-axis grid lines for cleaner look
      }
    }
  },
  dataLabels: {
    style: {
      colors: ['#ffffff'] // White color for data labels
    },
    enabled: false // Disable data labels
  },
  stroke: {
    width: 2, // Line stroke width for better visibility
    curve: 'smooth', // Smooth curve for the bars
    colors: ['#63DBD2FF'] // Stroke color to match the other chart
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '11px', // X-axis label font size
        fontFamily: 'Kanit', // Consistent font family
        colors: '#868686' // X-axis label color (consistent)
      },
    },
    categories: [] // Add your categories (data) here
  },
  fill: {
    type: 'gradient', // Use gradient for bar fill
    gradient: {
      shade: 'dark', // Dark gradient shade
      shadeIntensity: 1, // Intensity of the gradient
      type: 'vertical', // Vertical gradient direction
      opacityFrom: 0.8, // Starting opacity for the gradient
      opacityTo: 0.3, // Ending opacity for smoother transition
      stops: [0, 100], // Gradient stops to define the gradient effect
      gradientToColors: ['#B1EDE9'] // Gradient transition color (matching others)
    },
    colors: ['#B1EDE9'], // Start color (matches other charts)
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return Math.floor(value); // Rounds down values to remove decimals
      },
      style: {
        fontSize: '11px', // Y-axis label font size
        colors: '#868686', // Y-axis label color (consistent with X-axis)
      }
    },
    title: {
      text: 'Wyświetlenia', // Y-axis title
      style: {
        fontFamily: 'Kanit', // Font family for Y-axis title
        color: '#424242' // Y-axis title color
      }
    },
  },
  tooltip: {
    theme: 'light', // Light theme for tooltips
    x: {
      format: 'dd MMM' // Date format for X-axis tooltips (if dates are used)
    }
  }
});


const viewsData = ref([])
const viewsSeries = ref([
  {
    name: 'Wyświetlenia ofert',
    data: viewsData,
  },
]);

const applicationData = ref([])
const applicationsSeries = ref([
  {
    name: 'Przesłane aplikacje',
    data: applicationData,
    yaxis: 0,
  },
]);
</script>

<style lang="scss">
.bg-my {
  background: linear-gradient(45deg, $primary, $secondary)
}

.bg-my-right {
  background: linear-gradient(180deg, $primary, $secondary)
}

.bg-my-bottom {
  background: linear-gradient(45deg, $secondary, $primary)
}
</style>