<template>
  <q-card flat v-ripple class="q-mt-none"
          bordered
          :class="{ 'q-hoverable': !isMouseOverFavBtn, 'cursor-pointer': !isMouseOverFavBtn }"
          style="border-radius: 7px; border-width: 1px;"
          @click="openJobOffersInNewTab(props.jobOffer.id)">
    <span :class="{ 'q-focus-helper': !isMouseOverFavBtn}" style="color: #454a80"></span>
    <q-card-section class="q-pa-sm">
      <div class="row">
        <div class="q-pa-xs" style=" display: flex;   flex-direction: column;
  justify-content: center;  /* Centering y-axis */
  align-items :center">
          <q-img
              style="width: 55px;height: 55px; border-radius: 9px;"
              v-bind:src="props.jobOffer.organization.compressedLogoUrl"
              fit='fill'
              :ratio="1"
          />
        </div>
        <div class="col q-pl-xs q-pt-xs">
          <div class="justify-between">
            <div class="q-pa-none row justify-between">
              <div class="col q-pl-xs q-pr-xs">
                <div class="justify-between col">
                  <div class="q-pa-none row justify-between q-mr-md">
                    <div class="row col-auto q-pr-sm">
                      <p
                         style="font-size: 16px !important; z-index: 10"
                         id="title" ref="jobTitle"
                         class="q-pl-sm q-mb-none ellipsis col q-pt-none"
                         >
                        <q-tooltip v-if="jobTitle !== null && isEllipsis" class="bg-white text-dark">
                          {{ props.jobOffer.title }}
                        </q-tooltip>
                        {{ props.jobOffer.title }}
                      </p>
                    </div>
                    <div class="col">
                      <q-icon
                          v-if="jobOffer.highlighted"
                          name="fa-solid fa-fire"
                          class="col"
                          size="18px"
                          style="border-radius: 6px"
                          outline color="accent"
                      >
                      </q-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="q-mr-sm">
                <p style="border-width: 1px; max-height: 23px; font-size: 11px; font-weight: 500"
                   :class="getSalaryColor()">
                  {{ salaryToString() }}
                </p>
              </div>
            </div>
            <div class="row q-pl-sm items-center">
              <p class="q-pl-xs q-pa-none q-ma-none text-dark q-mr-sm" style="font-size: 12px!important;">
                {{ props.jobOffer.organization.name }}</p>
              <locations-info-dropdown
                  class="q-ml-md col"
                  :is-selected="true"
                  style="padding-top: 0"
                  :locations="props.jobOffer.locations">
              </locations-info-dropdown>
            </div>
          </div>
        </div>

      </div>
    </q-card-section>
  </q-card>
</template>

<script setup>
import {defineProps, ref, onMounted, onUnmounted} from "vue";

const props = defineProps({
  jobOffer: Object,
  isSelected: Boolean,
  borderColor: String,
  isFav: Boolean
})

import {useRouter} from 'vue-router'
import LocationsInfoDropdown from "@/components/common/job-offers/LocationsInfoDropdown.vue";

const router = useRouter()

const isEllipsis = ref(false)
const jobTitle = ref(null)
const resizeDetector = ref(true)

onMounted(() => {
  window.addEventListener("resize", myEventHandler);
  isFav.value = props.isFav
})

onUnmounted(() => {
  window.removeEventListener("resize", myEventHandler);
})

function myEventHandler() {
  isEllipsis.value = isEllipsisActive()
}

function isEllipsisActive() {
  let e = jobTitle.value
  if (e != null) {
    return e.offsetWidth < e.scrollWidth && resizeDetector.value
  } else {
    return false;
  }
}

function openJobOffersInNewTab(jobId) {
  const url = router.resolve({
    name: 'JobOffersMainBoard',
    params: {id: jobId},
  }).href;

  window.open(url, '_blank');
}

function salaryToString() {
  if (props.jobOffer.salary !== null) {
    return props.jobOffer.salary.from.toString() + ' - ' + props.jobOffer.salary.to.toString() + ' ' + props.jobOffer.salary.currency
  } else {
    return 'BRAK STAWKI'
  }
}

function getSalaryColor() {
  if (salaryToString() === 'BRAK STAWKI') {
    return 'text-dark'
  } else {
    return 'text-dark'
  }
}

const isFav = ref(false)


</script>

<style lang="scss" scoped>
</style>