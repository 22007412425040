import script from "./JobOfferOverviewSkeleton.vue?vue&type=script&setup=true&lang=js"
export * from "./JobOfferOverviewSkeleton.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QItem,QItemSection,QSkeleton,QItemLabel,QSeparator,QCardActions});
