<template>
  <div class="q-pa-xs" id="applicationCardSection">
    <q-card bordered v-if="isLoggedUser !== null"
            :style="{minWidth: getCardStyle()}"
            style="max-width: 500px">
      <q-card-section class="q-pa-lg">
        <div class="q-pb-none">
          <p class="accent-3 text-bold q-mb-sm" type="submit" style="font-size: 17px !important;">Aplikuj na
            stanowisko {{
              position
            }}
          </p>
        </div>
        <div class="q-pb-md">
          <q-separator></q-separator>
        </div>
        <q-form @submit.prevent="handleSubmit()" greedy>
          <div class="row q-gutter-md">
            <div class="col">
              <q-chip class="q-ml-none" style="font-size: 13px" dense color="white" text-color="dark">
                <p class="q-ma-none q-mb-sm">Imię i nazwisko</p>
                <p class="text-red-8 q-ma-none q-mb-sm" style="font-size: 13px">*</p>
              </q-chip>
              <q-input v-if="isLoggedUser && applicantFirstName !== null && applicantLastName !==null"
                       class="q-pt-none bg-white" color="priamry" dense outlined
                       v-model="applicantName"
                       label="Imię i nazwisko"
                       label-color="grey-5" disable/>
              <q-input v-else
                       class="q-pt-none bg-white"
                       color="dark"
                       dense
                       outlined
                       v-model="applicantName"
                       label="Imię i nazwisko"
                       :rules="[val => !isBlank(val) || 'Podaj imię i nazwisko']"
                       label-color="grey-5"/>
            </div>
            <div class="col">
              <q-chip class="q-ml-none" style="font-size: 13px" dense color="white" text-color="dark">
                <p class="q-ma-none q-mb-sm">E-mail</p>
                <p class="text-red-8 q-ma-none q-mb-sm" style="font-size: 13px">*</p>
              </q-chip>
              <q-input v-if="isLoggedUser" class="q-pt-none bg-white" dense color="primary"
                       outlined v-model="email" disable type="email"
                       :rules="[val => EmailValidator.isEmailValid(val) || 'Niepoprawny format e-mail',val => !isBlank(val) || 'Podaj e-mail']"
                       label="E-mail" label-color="grey-5"/>
              <q-input v-else
                       class="q-pt-none bg-white"
                       dense
                       color="dark"
                       outlined v-model="email"
                       :rules="[val => EmailValidator.isEmailValid(val) || 'Niepoprawny format e-mail',val => !isBlank(val) || 'Podaj e-mail']"
                       label="E-mail"
                       label-color="grey-5"/>
            </div>
          </div>
          <div class="q-pt-none">

            <!--            TODO dodaj CV w petli + tooltipa-->
            <div class="q-px-none q-pt-none q-pb-md">
              <div class="row justify-start q-mb-sm">
                <div v-if="loggedUserResumes !== null && loggedUserResumes.length === 0 && isLoggedUser"
                     class="q-ml-none">
                  <div class="q-pl-none q-ml-none q-mr-none q-pr-none" style="font-size: 13px">
                    <div class="q-ma-none">
                      <q-btn outline @click="router.push({ name: 'ApplicantProfilePage' });" no-caps
                             class="q-ma-none q-px-sm q-mr-sm q-mt-xs"
                             style="border-radius: 5px" label="Dodaj CV do swojego profilu" color="accent"
                             size="xs"></q-btn>
                      <q-btn round color="accent" size="xs" dense>
                        <q-icon size="12px" name="fa-solid fa-circle-info"></q-icon>
                        <q-tooltip max-width="200px" anchor="top middle" self="bottom middle" :offset="[10, 10]"
                                   class="bg-white text-dark">
                          Dodaj CV do swojego profilu - będziesz mógł go automatyczie użyć podczas aplikowania.
                        </q-tooltip>
                      </q-btn>
                    </div>
                    <div class="q-pt-none col q-pt-xs" v-if="loggedUserResumes.length === 0">
                      <p class="text-dark q-ma-none" style="font-size: 10px">
                        PDF. Rozmiar max 5 MB
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else-if="loggedUserResumes !== null && loggedUserResumes.length !== 0 && isLoggedUser"
                     class="q-gutter-sm q-ml-none q-ma-none">
                  <span class="q-ml-none row q-pt-none q-ml-xs q-mt-none text-dark">Wybierz CV<span
                      class="text-red-8 q-ma-none " style="font-size: 13px">*</span></span>
                  <div class="q-ma-none row q-gutter-sm" v-if="loggedUserResumes.length !== 0">
                    <q-select class="q-ma-none q-pa-none q-pt-xs" dense outline style="border-radius: 5px"
                              :options="toResumesFileNames()"
                              outlined
                              :model-value="fileName"
                              color="dark" size="xs"
                              @update:model-value="val => setResume(val)"></q-select>
                  </div>
                </div>
              </div>
              <div v-if="!isLoggedUser">
               <span class="q-ml-none row q-pt-none q-ml-xs q-mt-none text-dark">Dodaj CV<span
                   class="text-red-8 q-ma-none "
                   style="font-size: 13px">*</span>
               </span>
                <q-card
                    bordered
                    flat
                    v-ripple
                    class="my-box cursor-pointer q-hoverable q-mb-sm q-mt-sm"
                    style="border-radius: 12px !important;"
                    @mouseenter="handleMouseOver"
                    @mouseleave="handleMouseOut"
                    @click="handleFileInput()">
                  <div v-if="isBlank(uploadedFile)">
                    <q-card-section v-if="isMouseOnCard" class="text-center">
                      <q-icon name="fa-regular fa-file-pdf" size="20px" class="text-accent q-px-none"></q-icon>
                    </q-card-section>
                    <q-card-section v-else class="text-center">
                      <q-icon name="fa-regular fa-file-pdf" size="20px" class="text-grey-7 q-pa-none"></q-icon>
                    </q-card-section>
                  </div>
                  <div v-else>
                    <q-card-section class="text-center row">
                      <div class="col row justify-center">
                        <q-icon name="task" size="20px" class="q-mr-sm text-primary q-px-none"></q-icon>
                        <p class="q-pb-none q-mb-none text-dark">{{ fileName }}</p>
                      </div>
                    </q-card-section>
                  </div>
                  <span class="q-focus-helper">
        </span>
                </q-card>
              </div>
            </div>
          </div>
          <q-space/>
          <q-card-section class="q-pa-none q-mb-sm" v-if="questions.length !== 0">
            <questions ref="questionsRef" v-model="answersWrapper.answers">
            </questions>
          </q-card-section>
          <q-separator class="q-mb-md q-mt-none" v-if="questions.length !== 0"></q-separator>
          <div>
            <div class="row q-mb-sm">
              <q-toggle
                  v-model="shouldContactInSpecificHours"
                  icon="alarm"
                  size="sm"
                  @update:model-value="initContactTime()"
                  color="dark"
                  style="font-size: 13px;"
                  class="text-dark q-mt-none"
                  label="Preferowany kontakt w godzinach:"
              />
              <div style="margin-top: 7px" class="q-ml-none">
                <q-badge v-if="shouldContactInSpecificHours" color="white" style="width: 100px" class="text-dark">
                  {{ PreferredContactTimeService.fromRangeValueToTime(contactInSpecificHour.min) }} -
                  {{ PreferredContactTimeService.fromRangeValueToTime(contactInSpecificHour.max) }}
                </q-badge>
              </div>
            </div>
            <div class="q-px-sm" v-if="shouldContactInSpecificHours">
              <q-range
                  color="dark"
                  v-model="contactInSpecificHour"
                  :min="0"
                  :max="96"
                  :step="1"
              />
            </div>
            <div>
              <data-controller-clause :data-controller-clause="dataControllerClause"></data-controller-clause>
            </div>
            <div v-if="currentUser.isAuthenticated()" class="full-width row q-mt-md">
              <q-btn size="md" icon="fa-regular fa-paper-plane" align="center" label="Aplikuj"
                     type="submit"
                     outline
                     class="col bg-accent text-dark q-mr-lg"/>
              <q-btn size="md" align="center" label="Zamknij"
                     v-close-popup
                     outline
                     class="col bg-white text-dark"/>
            </div>
            <div v-else class="q-mt-md text-center">
              <q-btn size="md" icon="fa-regular fa-paper-plane" align="center" label="Aplikuj"
                     type="submit"
                     outline
                     class="col bg-accent text-dark q-mr-md"/>
              <q-btn size="md" align="center" label="Zamknij"
                     v-close-popup
                     outline
                     class="col bg-white text-dark"/>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, onMounted, reactive, ref} from 'vue';
import ApplicationService from "@/services/applicant/ApplicationService";
import {useRouter} from "vue-router/dist/vue-router";
import Questions from "@/components/common/job-offers/applications/Questions";
import EmailValidator from "@/services/common/EmailValidator";
import {useQuasar} from "quasar";
import NotificationService from "@/services/notification/NotificationService";
import ApplicantDataService from "@/services/applicant/ApplicantDataService";
import {currentUser} from '@/global-variables/currentUser.js';
import PreferredContactTimeService from "@/services/applicant/PreferredContactTimeService";
import DataControllerClause from "@/components/organization/registration/DataControllerClause.vue";

const router = useRouter()

const shouldContactInSpecificHours = ref(false)
const contactInSpecificHour = ref({
  min: 0,
  max: 96
});

const props = defineProps({
  jobOfferId: String,
  position: String,
  questions: Array,
  dataControllerClause: String
});
const applicantName = ref('');
const applicantFirstName = ref(null);
const applicantLastName = ref(null);
const email = ref('');
const uploadedFile = ref('');
const isLoggedUser = ref(null)
const fileName = ref('');
const emit = defineEmits(['close-modal']);
const dataAgreement = ref(false)
const loggedUserResumes = ref(null)
const chooseResumeIdForLogged = ref(null)
const phoneNumber = ref(null)
const answersWrapper = reactive({answers: []})

onMounted(() => {
  initAnswersObject()
  if (currentUser.isAuthenticated()) {
    dataAgreement.value = true
    if (currentUser.firstName !== null) {
      applicantFirstName.value = currentUser.firstName
      applicantName.value = currentUser.firstName
    }
    if (currentUser.lastName !== null) {
      applicantLastName.value = currentUser.lastName
      applicantName.value = applicantName.value + ' ' + currentUser.lastName;
    }

    email.value = currentUser.email
    phoneNumber.value = currentUser.phoneNumber
    isLoggedUser.value = true
    initContactTime();

    //TODO use current user resumes
    ApplicationService.getResumes().then(response => {
      response.json().then(body => {
        loggedUserResumes.value = body
        const defaultResume = body.find(resume => resume.defaultResume);
        chooseResumeIdForLogged.value = defaultResume.id;
        fileName.value = defaultResume.fileName;
        uploadedFile.value = defaultResume.file;
      });
    });
  } else {
    isLoggedUser.value = false
    loggedUserResumes.value = []
  }
})

function getCardStyle() {
  if (isMobile()) {
    return {minWidth: '450px'}
  } else {
    return {}
  }
}

function isMobile() {
  return $q.platform.is.mobile
}

function initContactTime() {
  if (currentUser.preferredContactTime !== null) {
    contactInSpecificHour.value.min = PreferredContactTimeService.fromTimeToRangeValue(currentUser.preferredContactTime.from);
    contactInSpecificHour.value.max = PreferredContactTimeService.fromTimeToRangeValue(currentUser.preferredContactTime.to);
  }
}

function initAnswersObject() {
  props.questions.forEach(q => {
    const answerObj = {
      questionId: q.id,
      question: q.question,
      answer: '',
      required: q.required,
      type: q.answerType
    };
    answersWrapper.answers.push(answerObj);
  });
}

async function handleSubmit() {
  let isValid = await validateQuestions()
  if (isValid && isCvAttached()) {
    answersWrapper.answers.forEach((answer) => {
      if (answer.type === 'BOOLEAN' && typeof answer.answer === 'string') {
        const lowerAnswer = answer.answer.toLowerCase();
        if (lowerAnswer === 'tak') {
          answer.answer = true;
        } else if (lowerAnswer === 'nie') {
          answer.answer = false;
        }
      }
    });
    if (currentUser.isAuthenticated() && currentUser.isApplicant()) {
      await applyAsLoggedUser()
    } else {
      await applyAsAnonymousUser()
    }
  }
}

async function applyAsLoggedUser() {
  const contactTime = calculatePreferredContactTime();
  let contactFrom = contactTime.contactFrom;
  let contactTo = contactTime.contactTo;
  let preferredContactTime = contactFrom + '-' + contactTo;
  await ApplicantDataService.updateData(currentUser.id, currentUser.firstName, currentUser.lastName, phoneNumber.value, contactFrom, contactTo)
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            Object.assign(currentUser, json);
          })
        }
      })
  ApplicationService.applyAsLoggedUser(chooseResumeIdForLogged.value, preferredContactTime, props.jobOfferId, answersWrapper.answers)
      .then(response => {
        if (response.ok) {
          emit('close-modal')
        } else {
          NotificationService.showNotification($q, "negative", "Błąd poczas wysyłania aplikacji - skontaktuj się z supportem")
        }
      })
}

function calculatePreferredContactTime() {
  let contactFrom
  let contactTo
  if (shouldContactInSpecificHours.value) {
    contactFrom = PreferredContactTimeService.fromRangeValueToTime(contactInSpecificHour.value.min);
    contactTo = PreferredContactTimeService.fromRangeValueToTime(contactInSpecificHour.value.max);
  } else {
    contactFrom = PreferredContactTimeService.fromRangeValueToTime(0);
    contactTo = PreferredContactTimeService.fromRangeValueToTime(96);
  }
  return {contactFrom, contactTo};
}

async function applyAsAnonymousUser() {
  const contactTime = calculatePreferredContactTime();
  let contactFrom = contactTime.contactFrom;
  let contactTo = contactTime.contactTo;
  let preferredContactTime = contactFrom + '-' + contactTo;
  ApplicationService.applyAsAnonymousUser(uploadedFile.value, email.value, applicantName.value, preferredContactTime, props.jobOfferId, JSON.stringify({answers: answersWrapper.answers}))
      .then(response => {
        if (response.ok) {
          emit('close-modal')
        } else {
          response.json().then(body => {
            console.error(body.title)
            NotificationService.showNotification($q, "negative", "Błąd poczas wysyłania aplikacji - skontaktuj się z supportem")
          })
        }
      })
}

const $q = useQuasar()

function isCvAttached() {
  if (isBlank(uploadedFile.value)) {
    NotificationService.showNotification($q, 'negative', 'Proszę wgrać swoje CV')
    return false;
  }
  return true;
}

function handleFileInput() {
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = '.pdf';

  input.onchange = () => {
    let files = Array.from(input.files);
    let file = files[0];

    if (file.size > 5 * 1024 * 1024) {
      NotificationService.showNotification($q, 'negative', 'Maksymalny rozmiar pliku - 5 MB.');
    } else {
      uploadedFile.value = file;
      fileName.value = file.name;
      NotificationService.showNotification($q, 'positive', 'Załączono plik - ' + fileName.value);
    }

  };

  input.click();
}


function isBlank(str) {
  return str === null || /^\s*$/.test(str);
}

const isMouseOnCard = ref(false);

function handleMouseOver() {
  isMouseOnCard.value = true;
}

function handleMouseOut() {
  isMouseOnCard.value = false;
}

const questionsRef = ref(null);

async function validateQuestions() {
  if (questionsRef.value !== null) {
    return questionsRef.value.validate()
  } else {
    return true
  }
}

function setResume(localFileName) {
  let resume = loggedUserResumes.value.find(resume => resume.fileName === localFileName);
  uploadedFile.value = resume.file
  fileName.value = localFileName
  chooseResumeIdForLogged.value = resume.id
}

function toResumesFileNames() {
  return loggedUserResumes.value.map(resume => resume.fileName)
}

</script>
