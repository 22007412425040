<template>
  <div id="questions-section">
    <q-card flat bordered class=" q-mt-lg" style="border-radius: 9px;">
      <q-card-section>
        <p class="text-white q-ma-none">
          <span class="text-weight-bold text-primary">Pytania do kandydata</span>
          <span class="text-dark"> - Możesz dodać do pięciu pytań, które pojawią się w formularzu aplikacyjnym dla tej oferty. Dla każdego pytania zdecyduj, czy odpowiedź jest obowiązkowa, i wybierz typ odpowiedzi. Wybór odpowiedniego typu odpowiedzi jest istotny, ponieważ ułatwia filtrowanie i analizę aplikacji spełniających określone kryteria.
        </span>
        </p>
      </q-card-section>
    </q-card>
    <q-form v-if="questions.length !== 0">
      <question
          ref="childComponentRef"
          :form-template-data="formTemplateData"
          v-for="q in questions"
          :key="q.id"
          :v-for-key="q.id"
          v-model:answer-type="q.answerType"
          v-model:question="q.question"
          v-model:required="q.required"
          @add:question="addQuestion"
          @remove:question="removeQuestionById">
      </question>
    </q-form>
    <q-card flat bordered class="q-mx-none q-mt-md q-mb-md" style="border-radius: 12px!important;">
      <q-card-section class="row justify-between q-ma-none">
        <p class="text-white items-center q-ma-none">
          <span class="text-dark q-pa-xs">
            <span v-if="questions.length <= 0">Brak zdefiniowanych pytań</span>
            <span v-else>Liczba zdefiniowanych pytań: {{ questions.length }}</span>
            - maksymalna liczba 5.
        </span>
        </p>
        <q-btn class="q-px-md q-my-none" label="Dodaj pytanie" no-caps color="dark" dense size="5px"
               style="font-size: 10px;border-radius: 7px !important;" @click="addQuestion()">
          <q-icon
              class="q-ml-md"
              name="sym_o_add"
              size="1rem"
          />
        </q-btn>
      </q-card-section>
    </q-card>
    <!--    <div class="q-my-md">-->
    <!--      <q-separator></q-separator>-->
    <!--    </div>-->
  </div>
</template>

<script setup>

import {computed, defineEmits, defineExpose, defineProps, ref} from 'vue'
import Question from "@/components/common/add-offer/job-offer/questions/Question";
import NotificationService from "@/services/notification/NotificationService";

const props = defineProps({
  formTemplateData: Object,
  modelValue: Object
})


const questions = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const questionId = ref(1);

import {useQuasar} from 'quasar'
import ScrollUtils from "@/services/common/ScrollUtils";

const $q = useQuasar();

function validateQuestionsNumber() {
  if (questions.value.length > 4) {
    NotificationService.showNotification($q, 'negative', 'Maksymalna liczba pytań w formularzu wynosi 5');
    return false;
  }
  return true;
}

function addQuestion() {
  if (validateQuestionsNumber()) {
    questions.value.push({id: questionId.value, question: null, answerType: null, required: false})
    questionId.value = questionId.value + 1
  }
}


function removeQuestionById(id) {
  for (let i = 0; i < questions.value.length; i++) {
    if (questions.value[i].id === id) {
      questions.value.splice(i, 1);
      return true;
    }
  }
  return false;
}

const emit = defineEmits(['update:modelValue'])
const childComponentRef = ref(null);

function validateChildComponents() {
  let pass = true;
  const results = childComponentRef.value.map(child => {
    return child.validate();
  });

  results.forEach(result => {
    pass = pass && result;
  });
  pass.then(val =>{
    if (!val) {
      ScrollUtils.scrollToTopWithDelayAndSmooth("questions-section", 0);
    }
  })

  return pass;
}


defineExpose({
  validateChildComponents
})
</script>