import script from "./OrganizationMenuDrawer.vue?vue&type=script&setup=true&lang=js"
export * from "./OrganizationMenuDrawer.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QList from 'quasar/src/components/item/QList.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QImg,QList,QCard,QIcon,QBtn});
