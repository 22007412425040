import { createApp } from 'vue';
import App from './JobHuntApp.vue';
import router from './router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import VueApexCharts from 'vue3-apexcharts';
import vue3GoogleLogin from 'vue3-google-login';

const app = createApp(App);

app.use(router);
app.use(Quasar, quasarUserOptions);
app.use(VueApexCharts);
app.use(vue3GoogleLogin, { clientId: 'YOUR_GOOGLE_CLIENT_ID' });

app.mount('#app');
