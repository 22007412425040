<template>
  <div class="row q-mt-sm">
    <div class="q-pt-md q-pl-sm">
      <span style="font-size: 13px">Odpowiedź:</span>
    </div>
    <div class="q-px-md q-pt-sm">
      <q-select dense
                style="font-size: 10px;border-radius: 5px;min-width: 70px"
                transition-show="scale"
                transition-hide="scale"
                standout="bg-accent text-white"
                v-model="questionFilter.operator"
                class="text-dark"
                :popup-content-style="{borderRadius: '0 0 7px 7px', fontSize: '12px' }"
                :options="mathSignsOptions"
                />
    </div>
    <q-input class="q-pt-sm" color="dark" outlined
             label-color="dark"
             bg-color="white"
             dense
             style="min-width: 200px"
             v-model="questionFilter.search"
             stack-label
             :rules="[val => !isNaN(val) || 'Podaj liczbę']"
             label="Podaj liczbę (opcjonalne)"
    ></q-input>
  </div>
</template>
<script setup>
import {computed, defineEmits, defineProps, ref} from "vue";

const mathSignsOptions = ref(["=", ">", "<", ">=", "<=", "!="])

const props = defineProps({
  questionFilter: Object,
})

const questionFilter = computed({
  get: () => props.questionFilter,
  set: (val) => emit('update:questionFilter', val),
});

const emit = defineEmits(['update:questionFilter'])

</script>