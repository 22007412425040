<template>
  <q-card flat square class="full-width row justify-between q-pl-none  bg-gradient-menu items-center"
          style="padding-top: 10px; padding-bottom: 10px">
    <div class=" items-center col-1 q-pt-xs q-pr-lg q-mr-lg q-ml-md" style="width: 120px">
      <q-img fit="contain" src="@/assets/bytejobwhite.svg" style="height: 30px; width: 120px"/>
    </div>
    <div class="row q-pl-none col items-center">
      <div class="q-px-sm col q-mt-xs">
        <q-btn rounded align="left"
               label="OFERTY"
               flat
               size="17px"
               style="border-radius: 7px"
               class="q-px-md text-white"
               dense
               @click="toJobOffers()"/>
      </div>
    </div>
    <q-btn flat color="black" class="q-ml-md q-mr-md" icon="fa-solid fa-bars">
      <q-menu v-if="currentUser.isAuthenticated()"
              style="--q-pe-top:12px; border-radius: 12px!important;"
              class="q-mr-md"
              transition-show="flip-right"
              transition-hide="flip-left"
      >
        <q-list style="min-width: 100px">
          <q-item clickable>
            <q-item-section>
              <q-btn color="dark"
                     flat
                     label="Profil"
                     @click="toProfile()">

              </q-btn>
            </q-item-section>
          </q-item>
          <q-separator class="q-mx-md"></q-separator>
          <q-item clickable>
            <q-item-section>
              <q-btn color="dark"
                     flat
                     label="Rekrutacje"
                     @click="toApplicantApplications()">

              </q-btn>
            </q-item-section>
          </q-item>
          <q-separator class="q-mx-md"></q-separator>
          <q-item clickable>
            <q-item-section>
              <q-btn color="dark"
                     flat
                     label="wyloguj"
                     @click="logout()"
              >
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
      <q-menu v-else
              style="--q-pe-top:12px; border-radius: 12px!important;"
              class="q-mr-md"
              transition-show="flip-right"
              transition-hide="flip-left"
      >
        <q-list style="min-width: 100px">
          <q-item clickable>
            <q-item-section>
              <q-btn color="dark"
                     flat
                     label="Login"
                     @click="toLoginPage()">

              </q-btn>
            </q-item-section>
          </q-item>
          <q-separator class="q-mx-md"></q-separator>
          <q-item clickable>
            <q-item-section>
              <q-btn color="dark"
                     flat
                     label="Rejestracja"
                     @click="toRegistrationPage()"
              >
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </q-card>
</template>

<script setup>
import {defineProps, onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import {currentUser} from '@/global-variables/currentUser.js';
import AuthenticationService from "@/services/AuthenticationService";

defineProps({
  isMenuVisible: {
    type: Boolean,
    default: false
  }
});

const router = useRouter();

onMounted(() => {
  isRegistrationAvailable();
});

function toJobOffers() {
  router.push({name: 'JobOffersMainBoard'});
}

const registrationAvailable = ref(false);

async function isRegistrationAvailable() {
  try {
    registrationAvailable.value = !currentUser.isAuthenticated();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

function logout() {
  AuthenticationService.logout()
}

function toLoginPage() {
  router.push({name: 'ApplicantLoginPage'});
}

function toRegistrationPage() {
  router.push({name: 'EmployeeRegistration'});
}

function toApplicantApplications() {
  router.push({name: 'ApplicantApplications'});
}

function toProfile() {
  router.push({name: 'ApplicantProfilePage'});
}
</script>

<style lang="scss" scoped>
//.bg-my {
//  background: linear-gradient(45deg, white, $secondary)
//}
//.q-icon .on-left .mdi .mdi-briefcase-edit-outline {
//  margin-right: 8px;
//  margin-left: 8px;
//}

.bg-gradient-menu {
  background: linear-gradient(90deg, $info 12%, $primary 35%, $secondary 85%);
  color: white; /* Text color */
}

//:deep(.q-btn--dense .on-left) {
//  margin-left: 5px !important;
//  margin-right: 10px !important;
//}


</style>

