import script from "./JobHuntApp.vue?vue&type=script&setup=true&lang=js"
export * from "./JobHuntApp.vue?vue&type=script&setup=true&lang=js"

import "./JobHuntApp.vue?vue&type=style&index=0&id=dda84c80&lang=scss"

const __exports__ = script;

export default __exports__
import QField from 'quasar/src/components/field/QField.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QField,QBtn,QChip,QSelect,QFab,QCard,QStepper,QSeparator,QPageContainer,QDrawer});
