class NotificationService {
    constructor() {

    }

    showNotification(q,type, message) {
        q.notify({
            type: type,
            message: message,
        })

    }

    showNotificationWithPosition(q,type, message, position) {
        q.notify({
            type: type,
            message: message,
            position: position
        })

    }

    showNotificationWithPositionTimeout(q,type, message, position, timeout) {
        q.notify({
            type: type,
            message: message,
            position: position,
            timeout: timeout
        })

    }
}

export default new NotificationService();

