<template>
  <q-card bordered class="full-width col row q-pa-md" style="border-radius: 22px !important;">
    <q-card class="row q-pt-none q-pa-none q-pb-none full-width">
      <q-card class="row q-pb-sm q-mb-none" style="border-radius: 12px !important; width: 100%"
              :class="getBasicBackground()"
              :style="calculateBackgroundGradient()">
        <div class="q-pl-none q-mx-sm q-mt-sm" style="border-radius: 6px;">
          <q-img
              style="width: 50px;height: 50px; border-radius: 9px;margin: 1px"
              :src="props.logoInBase64 || props.logo"
              fit='fill'
              :ratio="1"
          />
        </div>
        <div class="q-mx-md q-mt-none col">
          <div class="q-mb-none q-pt-sm row">
            <p class="text-bold q-ma-none text-white" style="font-size: 14px;">
              {{ props.title }}
            </p>
          </div>
          <div class="row">
            <p class="q-pt-sm q-ma-none" style="font-size: 13px;">
              <company-text :class="'text-white'">
                {{ props.companyName }}
              </company-text>
            </p>
            <div class="row q-pt-xs q-pl-md text-white">
              <locations-info-dropdown
                  class="col row text-center q-px-xs"
                  :is-selected="true"
                  style="padding-top: 2px; border-radius: 5px!important;"
                  :locations="props.locations"
                  :text-color="'white'"
                  :is-flat="false">
              </locations-info-dropdown>
            </div>
            <div class="q-pt-xs">
              <q-chip v-if="!isVisible.value" class="text-white text-bold q-ml-md q-my-none" square style="font-size: 11px!important; margin-top: 2px" label="Ukryta"
                      color="negative"></q-chip>
            </div>
          </div>

        </div>
        <div class="q-pl-md row">
          <div class="row q-pr-none q-pt-none q-gutter-xs">
            <div class="q-pt-sm q-pr-sm q-gutter-sm">
              <q-btn v-if="isCopyAvailable" outline color="white" round flat aria-set size="11px"
                     @click="copyLinkToOffer()">
                <q-icon size="sm" name="mdi-content-copy" color="white"/>
              </q-btn>
              <q-btn v-if="isNotBlank(webPageUrl)" outline color="white" round flat aria-set size="11px"
                     @click="openOrganizationPage(webPageUrl)">
                <q-icon size="sm" name="mdi-web" color="white"/>
              </q-btn>
              <q-btn v-if="isNotBlank(instagramPageUrl)" outline color="white" round flat aria-set size="11px"
                     @click="openOrganizationPage(instagramPageUrl)">
                <q-icon size="sm" name="mdi-instagram" color="white"/>
              </q-btn>
              <q-btn v-if="isNotBlank(facebookPageUrl)" outline color="white" round flat aria-set size="11px"
                     @click="openOrganizationPage(facebookPageUrl)">
                <q-icon size="sm" name="mdi-facebook" color="white"/>
              </q-btn>
            </div>
          </div>
        </div>
      </q-card>
      <div class="row col q-px-xs q-pt-none justify-between q-mt-none">
        <q-separator color="white"></q-separator>
        <div class="row justify-between full-width">
          <position-data class="q-pt-none full-width q-px-none col"
                         :job-position="jobPosition"
                         :seniority-level-name="seniorityLevelName"
                         :modes="operatingModes"
                         :workTypes="workTypes"
                         :branding="branding"
                         :locations="locations"
          >
          </position-data>
        </div>
      </div>
    </q-card>
    <div style="width: 100%">
      <q-card flat bordered class="row row q-ma-xs small-card q-mt-md" style="border-radius: 10px !important;">
        <q-card-section class="full-width q-pa-xs">
          <offered-contracts :contracts="contracts"></offered-contracts>
        </q-card-section>
      </q-card>
    </div>

  </q-card>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted} from 'vue';
import OfferedContracts from "@/components/common/job-offers/job-offer-details/intro-card/OfferedContracts";
import PositionData from "@/components/common/job-offers/job-offer-details/intro-card/PositionData";
import {useQuasar} from "quasar";
import NotificationService from "@/services/notification/NotificationService";
import {lighten} from 'polished';
import LocationsInfoDropdown from "@/components/common/job-offers/LocationsInfoDropdown";
// import AdditionalIntroData from "@/components/common/job-offers/job-offer-details/intro-card/AdditionalIntroData";

// const hoverStyle = ref({
//   color: 'red',
//   // Add any other styles you want to apply on hover
// });
function isNotBlank(str) {
  if (str === null || str === undefined) {
    return false;
  }

  // Remove leading and trailing spaces using trim()
  const trimmedStr = str.trim();

  // Check if the resulting string is empty or not
  return trimmedStr !== '';
}

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  isVisible: {
    type: Object,
    required: true,
  },
  logo: {
    type: String,
    required: true,
  },
  companyName: {
    type: String,
    required: true,
  },
  contracts: {
    type: Array,
    required: true
  },
  webPageUrl: {
    type: String,
    required: true
  },
  facebookPageUrl: {
    type: String,
    required: true
  },
  instagramPageUrl: {
    type: String,
    required: true
  },
  jobPosition: {
    type: String,
    required: true
  },
  seniorityLevelName: {
    type: String,
    required: true
  },
  operatingModes: {
    type: Array,
    required: true
  },
  workTypes: {
    type: Array,
    required: true
  },
  id: {
    type: String,
    required: true
  },
  branding: {
    type: Object,
    required: true
  },
  colorBrandingAvailable: {
    type: Boolean,
    required: true
  },
  logoInBase64: {
    type: String,
    required: false
  },
  isCopyAvailable: {
    type: Boolean,
    required: true
  },
  locations: {
    type: Array,
    required: true,
  }
});

onMounted(() => {
})

function openOrganizationPage(page) {
  window.open(page, '_blank')

}

const emit = defineEmits(['update:modelValue'])

const isVisible = computed({
  get: () => props.isVisible,
  set: (val) => emit('update:modelValue', val),
});

const $q = useQuasar()

function copyLinkToOffer() {
  setClipboardText(process.env.VUE_APP_DOMAIN + '/offers/' + props.id)
  NotificationService.showNotificationWithPosition($q, 'positive', 'Skopiowano link do oferty', 'top-right')
}

function setClipboardText(text) {
  // Create a text area element to temporarily hold the text
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Set the text area to be invisible
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.opacity = "0";

  // Append the text area to the DOM
  document.body.appendChild(textArea);

  // Select the text inside the text area
  textArea.select();

  try {
    // Copy the selected text to the clipboard
    document.execCommand("copy");
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }

  // Remove the temporary text area from the DOM
  document.body.removeChild(textArea);
}

function calculateBackgroundGradient() {
  if (props.colorBrandingAvailable) {
    const primaryColor = props.branding.primaryColor;
    const lighterPrimary = lighten(0.32, primaryColor)
    return {
      background: `
      linear-gradient(15deg, ${primaryColor}, ${lighterPrimary})
    `,
      backgroundSize: '100% 100%, 100% 100%',
      backgroundPosition: 'bottom, top',
      backgroundRepeat: 'no-repeat'
    };
  }
}

function getBasicBackground() {
  if (props.colorBrandingAvailable) {
    return ''
  } else {
    return 'bg-company-gradient'
  }
}

</script>
<style scoped lang="scss">
//.my-card {
//  margin: 5px auto;
//}

my-text {
  color: white;
}

company-text {
  color: white;
}

.contracts-bg {
  background: linear-gradient(45deg, $primary, $light-blue-11)
}

.bg-company-gradient {
  background: linear-gradient(15deg, $primary, #c4e5e4);
  color: white; /* Text color */
}

.small-card {
  border-color: $dark-2 !important;
}

</style>