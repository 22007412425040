<template>
  <q-layout>
    <q-header class="bg-white">
    </q-header>
    <organization-menu-drawer :active-button="'MY_PROFILE'"/>
    <q-page-container class="bg-primary">
      <q-page class="column">
        <menu-bar class="bg-gradient-menu-organization" style="padding-top: 22px; padding-bottom: 22px"></menu-bar>
        <q-card flat class="bg-secondary col row column q-pt-lg q-pb-lg q-pl-md q-pr-md items-center" style="border-radius: 25px 0 0 0 !important;">
          <div class="row justify-center" style="width: 900px">
            <div v-if="user.id" class="full-width">
              <div>
                <organization-account-data ref="organizationDataRef"
                                           v-if="user.id !== null"
                                           v-model="user"
                                           class="q-mx-lg q-mt-lg"
                />
              </div>
              <div class="q-ml-lg q-pb-lg">
                <q-btn size="13px"
                       icon="login"
                       outline
                       class="bg-accent text-dark"
                       style="max-height: 15px; border-radius: 6px"
                       @click="updateData()"
                       label="Zapisz zmiany"
                       no-caps/>
              </div>
              <div class="q-mx-lg q-mt-lg">
                <organization-user-consents/>
              </div>
            </div>
          </div>
        </q-card>

      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script setup>
import {onMounted, reactive, ref} from 'vue'
import MenuBar from "@/components/common/MenuBar";
import AuthenticationService from "@/services/AuthenticationService";

onMounted(() => {
  initAuthenticatedUserData()
  initUserData()
})

function initUserData() {
  AuthenticationService.getAuthenticatedUser().then(data => {
    data.json().then(body => {
      user.firstName = body.firstName;
      user.id = body.id;
      user.email = body.email;
      user.lastName = body.lastName;
      user.phoneNumber = body.phoneNumber;
      user.position = body.position
    })
  });
}

function initAuthenticatedUserData() {
  AuthenticationService.getAuthenticatedUser()
      .then(response => {
        if (response.status === 200) {
          response.json().then((data) => {
            user.value = data
          })
        } else {
          throw new Error("Cannot get user profile data - user not authenticated")
        }
      });
}

const user = reactive({
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  position: null,
});

import NotificationService from "@/services/notification/NotificationService";
import {useQuasar} from "quasar";
import OrganizationAccountData from "@/components/organization/organization-accounts/profile/OrganizationAccountData";
import OrganizationUsersService from "@/services/organization/OrganizationUsersService";

const $q = useQuasar()

async function updateData() {
  let promise = await validateChildComponents();
  if (promise) {
    OrganizationUsersService.updateUserData(user)
        .then(response => {
          if (response.ok) {
            response.json().then(json =>{
              Object.assign(currentUser, json);
            })
            NotificationService.showNotification($q, 'positive', 'Zaktualizowano dane profilu')
          }
        })
  }
}

const organizationDataRef = ref(null);

async function validateChildComponents() {
  return organizationDataRef.value.validate()
}
import {currentUser} from "@/global-variables/currentUser";
import OrganizationMenuDrawer from "@/components/organization/common/OrganizationMenuDrawer";
import OrganizationUserConsents
  from "@/components/organization/organization-accounts/profile/OrganizationUserConsents.vue";
</script>
<style lang="scss">
.q-btn--dense .on-left {
  margin-right: 22px;
  margin-left: 8px;
}
</style>